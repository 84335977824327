import {
  ParticipantResponse,
  TestBranding,
  TestBrandingType,
  ThankYouMessageCopy,
} from "Types"
import customContent from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/custom-thank-you-content"
import panelContent from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/panel-thank-you-content"
import thirdPartyContent from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/third-party-thank-you-content"
import uhContent from "UsabilityHub/components/UsabilityTest/content-factory/thank-you-content/uh-branded-thank-you-content"
import { AppearanceProps } from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import { ROUTES } from "UserCrowd/views/routes"
import { reportError } from "Utilities/error"
import { isPanelOrdered, isThirdPartyOrdered } from "Utilities/response"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import practiceTestThankYouContent from "./practice-test-thank-you-content"

interface Props {
  thankYouCopy: ThankYouMessageCopy
  testBranding: TestBranding
  recruitmentLink: RecruitmentLink | null
  redirectLink: string | null
  response: ParticipantResponse
}

export default function thankYouContent(props: Props): AppearanceProps {
  const { response, thankYouCopy, testBranding: branding, redirectLink } = props
  if (isPanelOrdered(response)) {
    return panelContent(response)
  }
  if (window.location.pathname === ROUTES.PRACTICE_TEST.path) {
    return practiceTestThankYouContent()
  }
  if (isThirdPartyOrdered(response)) {
    if (redirectLink) {
      return thirdPartyContent({ redirectLink })
    } else {
      reportError(
        new TypeError(
          "Completed third party ordered response with no redirect link present"
        )
      )
    }
  }
  return branding.type === TestBrandingType.UsabilityHub
    ? uhContent()
    : customContent({
        copy: thankYouCopy,
        logoUrl: branding.logo_url,
        redirectLink,
      })
}
