import { useDisclosure } from "@chakra-ui/react"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SkipUsabilityTestModal } from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { useLanguage } from "Hooks/use-language"
import { Dispatch, State } from "Redux/app-store"
import { cancelResponse } from "Redux/reducers/current-response/action-creators"
import { ParticipantDeletionReason, UsabilityTestSection } from "Types"
import { SectionTask } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTask"
import { beforeUnloadHandler } from "Utilities/before-unload-handler"
import { neverResolve } from "Utilities/promise"
import { frontend, testInterface } from "~/api/all"

import {
  InstructionsModal,
  InstructionsModalState,
} from "./InstructionsModal/InstructionsModal"
import { PrototypeTaskContent } from "./PrototypeTaskContent"
import { usePrototypeTaskContext } from "./PrototypeTaskContext"
import { PrototypeTaskFinishModal } from "./PrototypeTaskFinishModal"

interface PrototypeTaskMobileProps {
  section: UsabilityTestSection
}

export const PrototypeTaskMobile: React.FC<
  React.PropsWithChildren<PrototypeTaskMobileProps>
> = ({ section }) => {
  const [instructionsDisplayState, setInstructionsDisplayState] =
    useState<InstructionsModalState>("mobileStart")
  const prototypeTask = usePrototypeTaskContext()
  const finishModal = useDisclosure()
  const skipModal = useDisclosure()

  const languageCode = useSelector(
    (state: State) => state.participantUsabilityTest?.language_code
  )
  const language = useLanguage(languageCode ?? "en")

  const isPanelist = useSelector<State, boolean>(
    (state: State) => state.currentResponse?.order_id !== null
  )

  const dispatch: Dispatch = useDispatch()
  const [, permitUnload] = beforeUnloadHandler()

  async function onSkip(reason: ParticipantDeletionReason) {
    dispatch(cancelResponse(reason))
    permitUnload()
    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? frontend.usercrowd.path()
        : testInterface.flagged.path()
    await neverResolve()
  }

  const toggleInstructionsDisplayState = useCallback(() => {
    if (instructionsDisplayState === "mobileMinimized")
      setInstructionsDisplayState("mobileDefault")
    if (instructionsDisplayState === "mobileDefault")
      setInstructionsDisplayState("mobileMinimized")
  }, [instructionsDisplayState, setInstructionsDisplayState])

  useEffect(() => {
    if (prototypeTask.isTaskStarted)
      setInstructionsDisplayState("mobileMinimized")
  }, [prototypeTask.isTaskStarted])

  return (
    <SectionTask>
      <>
        <InstructionsModal
          state={instructionsDisplayState}
          toggleState={toggleInstructionsDisplayState}
          section={section}
          openSkipModal={skipModal.onOpen}
          isPanelist={isPanelist}
          prototypeTask={prototypeTask}
        />
        <PrototypeTaskFinishModal {...finishModal} />

        {language && (
          <SkipUsabilityTestModal
            {...skipModal}
            onSkip={onSkip}
            language={language}
          />
        )}
      </>
      <PrototypeTaskContent section={section} />
    </SectionTask>
  )
}
