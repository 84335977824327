import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { Coupon, CreditCard, StripeInvoice } from "Types"
import { useTrialHasNotSubscribed } from "Utilities/account"
import { isPaid } from "Utilities/plan"
import React from "react"
import { GetCurrentAccountResponse } from "~/api/generated/usabilityhub-components"
import { Plan, Subscription } from "~/api/generated/usabilityhubSchemas"
import { BillingHistory } from "./BillingHistory"
import { CouponCode } from "./CouponCode"
import { CreditCardOnFile } from "./CreditCardOnFile"
import { ManualSubscriptionDetails } from "./ManualSubscriptionDetails"
import { NextSubscriptionPayment } from "./NextSubscriptionPayment"
import { YourPlan } from "./YourPlan"
type PaymentAndSubscription = React.FC<
  React.PropsWithChildren<{
    activeManualSubscription?: GetCurrentAccountResponse["manual_subscriptions"][number]
    activeStripeSubscription?: Subscription
    coupon: Coupon | null
    creditCard?: CreditCard
    manualSubscription: boolean
    pendingManualSubscription?: GetCurrentAccountResponse["manual_subscriptions"][number]
    plan: Plan | undefined
    upcomingInvoice: StripeInvoice | null
    refreshUpcomingInvoice: () => void
    isUpcomingInvoiceLoaded: boolean
  }>
>

export const PaymentAndSubscription: PaymentAndSubscription = ({
  activeManualSubscription,
  activeStripeSubscription,
  coupon,
  creditCard,
  manualSubscription,
  pendingManualSubscription,
  plan,
  upcomingInvoice,
  refreshUpcomingInvoice,
  isUpcomingInvoiceLoaded,
}) => {
  const cancelAtTrialEnd = useTrialHasNotSubscribed()

  return (
    <Card>
      <CardHeader>Payment and subscription</CardHeader>
      <CardBody spacing={8}>
        <YourPlan
          activeStripeSubscription={activeStripeSubscription}
          manualSubscription={manualSubscription}
          plan={plan}
        />
        {manualSubscription && plan ? (
          <ManualSubscriptionDetails
            activeManualSubscription={activeManualSubscription}
            pendingManualSubscription={pendingManualSubscription}
            plan={plan}
          />
        ) : plan &&
          isPaid(plan) &&
          activeStripeSubscription &&
          !cancelAtTrialEnd ? (
          <NextSubscriptionPayment
            activeStripeSubscription={activeStripeSubscription}
            plan={plan}
            upcomingInvoice={upcomingInvoice}
            refreshUpcomingInvoice={refreshUpcomingInvoice}
            isUpcomingInvoiceLoaded={isUpcomingInvoiceLoaded}
          />
        ) : null}
        {coupon && <CouponCode coupon={coupon} />}
        <CreditCardOnFile
          creditCard={creditCard}
          manualSubscription={manualSubscription}
        />
        <BillingHistory manualSubscription={manualSubscription} />
      </CardBody>
    </Card>
  )
}
