import React, { FC } from "react"

import { Dimensions } from "Types"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { ScreenClicks } from "../ScreenClicks"
import { ScreenView } from "../ScreenResults"
import { useScreenResult } from "../use-results"

import { ExpandableContainer } from "./ExpandableContainer"

const COLLAPSED_CONTAINER_HEIGHT = 300

function canImageExpand(containerHeight: number, imageHeight: number) {
  return containerHeight < imageHeight
}

function getAspectRatio(dimensions: Dimensions) {
  return (dimensions.width / dimensions.height).toString()
}

interface ExpandableScreenResultDetailsPanelProps {
  clickView: ScreenView
  clicks: NormalizedClick[]
}

/**
This Expandable variant of the ScreenResultsDetailsPanel will set itself to a default max height (COLLAPSED_CONTAINER_HEIGHT) and is able to expand to its full height when the user clicks on it.

Behaviour:
The image will take up its native width up to the point of reaching its parent width and wont grow beyond that point.
In the collapsed stated the image will be cut of at a height of COLLAPSED_CONTAINER_HEIGHT, but it will still be vertically scrollable.
In the expanded state the width will be the same and the height of the container will grow to match the aspect ratio of the figma image.

Technical Implementation:
To maintain the size and correct aspect ratio of the image and canvases (heatmap and clickmap) a flexbox with the flex-bases of the figma-image width is used.

Images natively can display more pixels than the space they take up on screens that have a high pixel density. This keeps the image sharp and the layout consistent.
Unfortunately the canvas element does not do this. It needs to be set to a multiple of the layout size to still show sharp results, then needs to be scaled down to keep the layout consistent again.
This behaviour is archived by wrapping image and canvas in a flexbox. By setting the flex-basis to the original width of the image, this will make the image and canvas child components the same size and
in conjunction with the aspect-ratio css rule they will both have the exact same size, while maintaining their quality for high pixel dense screens.
The flexbox will also cap the max size of the content and will not allow it to grow past the 100% mark.

The canvas max-width is set to the width of the figma image to prevent any difference in size between the canvas and the actual image element.
 */
export const ExpandableScreenResultDetailsPanel: FC<
  ExpandableScreenResultDetailsPanelProps
> = ({ clicks, clickView }) => {
  const { figmaImage } = useScreenResult()
  const isExpandable = canImageExpand(
    COLLAPSED_CONTAINER_HEIGHT,
    figmaImage?.height ?? 0
  )

  return (
    <ExpandableContainer
      collapsedHeight={COLLAPSED_CONTAINER_HEIGHT}
      canExpand={isExpandable}
    >
      <ScreenClicks
        // Image/Heatmap/Clickmap will be in their native sizes (and aspect ratios) until hitting the max width of the container
        style={{
          flex: `0 1 ${figmaImage.width}px`,
          maxWidth: "100%",
          aspectRatio: getAspectRatio(figmaImage),
        }}
        figmaImage={figmaImage}
        clicks={clicks}
        clickView={clickView}
      />
    </ExpandableContainer>
  )
}
