import React from "react"

import { ReactOption, Response } from "Types"

export type Option = ReactOption<string>

export function getOptionsFromMap(map: Map<string, string>): Option[] {
  return [...map.entries()].map(([key, label]) => ({
    value: key,
    label: label,
  }))
}

export const countResponsesBy = (
  responses: ReadonlyArray<Response>,
  getValues: (response: Response) => string | string[]
): Map<string, number> => {
  const acc = new Map<string, number>()

  responses.forEach((response) => {
    const values = [getValues(response)].flat()

    values.map(String).forEach((value) => {
      const count = acc.get(value) || 0
      acc.set(value, count + 1)
    })
  })

  return acc
}

// TODO: useMemo?
const countsAndFilteredCounts = (
  allResponses: Readonly<Response[]>,
  filteredResponses: Readonly<Response[]>,
  getDemographic: (response: Response) => string | string[]
): Map<string, number>[] => {
  const counts = countResponsesBy(allResponses, getDemographic)
  // TODO: This can be skipped if !showFilteredCount
  const filteredCounts = countResponsesBy(filteredResponses, getDemographic)

  return [counts, filteredCounts]
}

type EnhancedOptionsOptions = {
  showFilteredCount: boolean
}

export const getEnhancedOptions = (
  options: Option[],
  allResponses: Readonly<Response[]>,
  filteredResponses: Readonly<Response[]>,
  getDemographic: (response: Response) => string | string[],
  { showFilteredCount }: EnhancedOptionsOptions
): Option[] => {
  const [counts, filteredCounts] = countsAndFilteredCounts(
    allResponses,
    filteredResponses,
    getDemographic
  )

  return (
    options
      // filter out any options that do not have a counts value
      .filter(({ value }) => !!counts.get(value))
      .map(({ value, label }) => {
        const filteredCount = showFilteredCount
          ? (filteredCounts.get(value) ?? 0)
          : null
        const count = counts.get(value)!

        return {
          value,
          label: (
            <>
              <span>{label}</span>
              {filteredCount === null ? (
                <small>{count}</small>
              ) : (
                <small>
                  {filteredCount} of {count}
                </small>
              )}
            </>
          ),
        }
      })
  )
}
