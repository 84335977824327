import {
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react"
import { DeleteStudyModal } from "Components/delete-usability-test-modal/DeleteStudyModal"
import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { MoveUsabilityTestsModal } from "UsabilityHub/components/UsabilityTestContextMenu/MoveUsabilityTestsModal"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import { AnimatePresence, motion } from "framer-motion"
import React, { ComponentProps, useState } from "react"
import { useNavigate } from "react-router"
import { DashboardUsabilityTest } from "~/api/generated/usabilityhubSchemas"
import { ROUTES } from "../routes"
import { EmptyStateNoTests } from "./EmptyStateNoTests"
import { TestRow } from "./TestRow"
import { TestRowSkeleton } from "./TestRowSkeleton"

const MotionBox = motion(Box)

type Props = {
  usabilityTests: DashboardUsabilityTest[]
  projectIdForNewTestsFromEmptyState?: string
  compactPrompt?: boolean
  isLoading: boolean
} & Omit<
  ComponentProps<typeof TestRow>,
  "test" | "isSelected" | "handleSelect" | "disableContextMenu" | "isSelectable"
> &
  FlexProps

export const TestRowList: React.FC<Props> = ({
  usabilityTests,
  projectIdForNewTestsFromEmptyState,
  showProjectInfo,
  compactPrompt,
  isLoading,
  ...props
}) => {
  const { open: openMoveModal } = useModal(MoveUsabilityTestsModal)
  const { open: openDeleteTestModal } = useModal(DeleteStudyModal)
  const [selectedTestIds, setSelectedTestIds] = useState<number[]>([])

  return (
    <>
      <Flex direction="column" gap={4} {...props}>
        {isLoading ? (
          <>
            <Box data-qa="test-skeleton" display="none" />
            <TestRowSkeleton />
            <TestRowSkeleton />
            <TestRowSkeleton />
            <TestRowSkeleton />
          </>
        ) : usabilityTests.length > 0 ? (
          usabilityTests.map((test) => {
            return (
              <TestRow
                key={test.id}
                test={test}
                showProjectInfo={showProjectInfo}
                isSelected={selectedTestIds.includes(test.id)}
                disableContextMenu={selectedTestIds.length > 0}
                isSelectable
                handleSelect={(newValue) => {
                  if (newValue) {
                    setSelectedTestIds([...selectedTestIds, test.id])
                  } else {
                    setSelectedTestIds(
                      selectedTestIds.filter((id) => id !== test.id)
                    )
                  }
                }}
              />
            )
          })
        ) : (
          <EmptyTestPrompt
            projectIdForNewTestsFromEmptyState={
              projectIdForNewTestsFromEmptyState
            }
            compactPrompt={compactPrompt}
          />
        )}
      </Flex>
      {usabilityTests !== undefined && (
        <AnimatePresence>
          {selectedTestIds.length > 0 && (
            <MotionBox
              animate={{ y: 0, x: "-50%" }}
              initial={{ y: 130, x: "-50%" }}
              exit={{ y: 130, x: "-50%" }}
              transition={{
                type: "spring",
                stiffness: 600,
                damping: 30,
              }}
              pos="fixed"
              left="50%"
              bottom="49px"
              bg="white"
            >
              <Flex
                align="center"
                p={4}
                borderColor="gray.300"
                borderWidth={1}
                rounded="md"
                boxShadow="2xl"
                gap={2}
              >
                <IconButton
                  variant="ghost"
                  icon={<Icon as={XOutlineIcon} />}
                  aria-label="De-select all"
                  color="gray.600"
                  onClick={() => setSelectedTestIds([])}
                />
                <Text
                  fontSize="md"
                  fontWeight="semibold"
                  color="text.primary"
                  me={4}
                >
                  {selectedTestIds.length} selected
                </Text>

                <Button
                  variant="outline"
                  color="brand.neutral.default"
                  fontWeight="semibold"
                  onClick={() =>
                    openMoveModal({
                      testIds: selectedTestIds,
                      onSuccess: () => setSelectedTestIds([]),
                    })
                  }
                >
                  Move to
                </Button>

                <Button
                  variant="outline"
                  color="red.500"
                  fontWeight="semibold"
                  onClick={() =>
                    openDeleteTestModal({
                      tests: usabilityTests
                        .filter((test) => selectedTestIds.includes(test.id))
                        .map((test) => ({
                          usabilityTestId: test.id,
                          name: test.name,
                        })),
                      onSuccess: () => setSelectedTestIds([]),
                    })
                  }
                >
                  Delete
                </Button>
              </Flex>
            </MotionBox>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

const EmptyTestPrompt: React.FC<
  Pick<Props, "projectIdForNewTestsFromEmptyState" | "compactPrompt">
> = ({ projectIdForNewTestsFromEmptyState, compactPrompt }) => {
  const isUserAllowedToCreateTests = useCurrentUser().can_manage_tests
  const navigate = useNavigate()

  return compactPrompt ? (
    <VStack
      borderColor="gray.200"
      borderWidth={1}
      rounded="md"
      spacing={3}
      py={8}
    >
      <Text color="text.secondary">
        {isUserAllowedToCreateTests
          ? "Tests you create will be shown here."
          : "Tests will be shown here."}
      </Text>
      {isUserAllowedToCreateTests && (
        <Button
          onClick={() => navigate(ROUTES.TESTS.NEW.path)}
          shadow={"none"}
          color="primary"
          backgroundColor="transparent"
          variant="outline"
          leftIcon={
            <PlusSolidIcon
              color="primary"
              fontSize="lg"
              transform="translateY(-1px)"
            />
          }
        >
          Create your first test
        </Button>
      )}
    </VStack>
  ) : (
    <EmptyStateNoTests
      isUserAllowedToCreateTests={isUserAllowedToCreateTests}
      createTestHref={
        projectIdForNewTestsFromEmptyState
          ? `/tests/new?project_id=${projectIdForNewTestsFromEmptyState}`
          : "/dashboard/templates"
      }
    />
  )
}
