export const Heading = {
  defaultProps: {
    size: "md",
  },
  variants: {
    h1: {
      fontFamily: "var(--chakra-fonts-h1)",
      fontWeight: "normal",
    },
  },
}
