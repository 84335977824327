import { Heading, HeadingProps, StackProps } from "@chakra-ui/react"
import { Stack } from "@chakra-ui/react"
import React from "react"

type Card = React.FC<React.PropsWithChildren<unknown>>

export const Card: Card = ({ children }) => {
  return <Stack spacing={4}>{children}</Stack>
}

export const CardHeader: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  ...headingProps
}) => {
  return (
    <Heading size="md" lineHeight="none" {...headingProps}>
      {children}
    </Heading>
  )
}

type CardGroup = React.FC<React.PropsWithChildren<unknown>>

export const CardGroup: CardGroup = ({ children }) => {
  return <Stack spacing={8}>{children}</Stack>
}

type CardFooter = React.FC<React.PropsWithChildren<StackProps>>

export const CardFooter: CardFooter = ({ children, ...stackProps }) => {
  return (
    <Stack direction="row" {...stackProps}>
      {children}
    </Stack>
  )
}

type CardBody = React.FC<React.PropsWithChildren<StackProps>>

export const CardBody: CardBody = ({ children, ...stackProps }) => {
  return (
    <Stack
      bg="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      p={6}
      spacing={6}
      {...stackProps}
    >
      {children}
    </Stack>
  )
}
