import { Alert, AlertDescription, AlertIcon } from "@chakra-ui/react"
import { reportTestResultsError } from "Redux/reducers/test-builder/action-creators/report"
import React, { Component, ErrorInfo, ReactNode } from "react"
import { connect } from "react-redux"

interface Props {
  readonly reportError: (error: Error, extra: Record<string, unknown>) => void
  readonly children: ReactNode
}

interface State {
  readonly error: Error | null
}

class TestResultsSectionErrorBoundImpl extends Component<Props, State> {
  readonly state = { error: null }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { reportError, ...rest } = this.props

    reportError(error, { errorInfo, ...rest })
  }

  render() {
    if (this.state.error === null) return this.props.children
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>
          Sorry, this section has encountered an error. The error has been
          reported to the development team, who are working to fix it. You can
          try reloading the page to see if the error persists.
        </AlertDescription>
      </Alert>
    )
  }
}

export const TestResultsSectionErrorBound = connect(null, {
  reportError: reportTestResultsError,
})(TestResultsSectionErrorBoundImpl)
