import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { removeQuestionTag } from "Redux/reducers/test-results/action-creators"
import { QuestionTag } from "Types"
import { FunctionalModal } from "Utilities/modals/types"

interface Props {
  questionTag: Readonly<QuestionTag>
  answerCount: number
}

export const ConfirmDeleteTagModal: FunctionalModal<Props> = ({
  answerCount,
  questionTag,
  onClose,
}) => {
  const dispatch = useDispatch<Dispatch>()
  const onConfirm = () => {
    void dispatch(removeQuestionTag(questionTag))
    onClose()
  }
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove tag?</ModalHeader>
        <ModalBody>
          <Stack>
            <Text>
              This question has {answerCount} answers tagged “{questionTag.name}
              ”. Are you sure you want to untag them all?
            </Text>
            <Text>This action can{"\u2019"}t be undone.</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={onConfirm}
              data-qa="remove-tag-button"
            >
              Remove
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
