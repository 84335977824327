import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { assertNever } from "Components/filter-controls/utils"
import { Button } from "DesignSystem/components"
import { GoogleCalendarIcon } from "Shared/icons/GoogleCalendarIcon"
import { MicrosoftOutlookIcon } from "Shared/icons/MicrosoftOutlookIcon"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import React from "react"
import {
  useGetGoogleCalendars,
  useListOutlookCalendars,
} from "~/api/generated/usabilityhub-components"
import {
  ModeratedStudy,
  ModeratedStudyCalendar,
  ModeratedStudyMember,
} from "~/api/generated/usabilityhubSchemas"

type Props = {
  currentMember: ModeratedStudyMember
  bookingCalendar: ModeratedStudy["booking_calendar"] | null
  newBookingCalendar: {
    summary: string
    provider: ModeratedStudyCalendar["provider"]
  } | null
  setBookingCalendar: (calendar: ModeratedStudyCalendar) => void
}

export const BookingCalendarDropdown: React.FC<Props> = ({
  bookingCalendar,
  newBookingCalendar,
  setBookingCalendar,
  currentMember,
}) => {
  const currentUserIsMainHost = currentMember.role === "main_host"
  const showGoogle =
    currentUserIsMainHost && currentMember.has_google_oauth_credentials
  const showMicrosoft =
    currentUserIsMainHost && currentMember.has_microsoft_oauth_credentials

  const { data: googleCalendars } = useGetGoogleCalendars(
    {},
    { enabled: showGoogle }
  )

  const { data: outlookCalendars } = useListOutlookCalendars(
    {},
    { enabled: showMicrosoft }
  )

  const allCalendars = [...(googleCalendars ?? []), ...(outlookCalendars ?? [])]

  // The calendars are sorted in provider order, so we can use this to show a provider heading
  let currentProvider: ModeratedStudyCalendar["provider"]

  return (
    <Menu>
      <MenuButton
        as={Button}
        isDisabled={
          allCalendars.length === 0 ||
          // User's can only see their own calendars and the booking calendar must belong to the main host,
          // so only the main host can set the booking calendar
          !currentUserIsMainHost
        }
        rightIcon={<ChevronDownOutlineIcon color="ds.icon.default" />}
        textAlign="left"
        aria-label="Select which calendar to use to make bookings"
        variant="subtle"
        textOverflow="ellipsis"
        justifyContent="flex-start"
        size="emphasized"
        _hover={{
          bg: "blackAlpha.100",
        }}
        background="white"
        border="1px solid"
        borderColor="ds.border.input"
        alignSelf="flex-start"
      >
        <Text
          textStyle="ds.paragraph.primary"
          textColor={
            bookingCalendar || newBookingCalendar
              ? "ds.text.default"
              : undefined
          }
          as="div"
          // display="inline-block" must come after noOfLines={1} to override
          // its display property for text truncation to work
          noOfLines={1}
          whiteSpace="nowrap"
          display="inline-block"
          textOverflow="ellipsis"
          // arbitrary minW so it doesn't look weirdly small when there's no Google integration
          minW={28}
        >
          {allCalendars.length > 0 ? (
            newBookingCalendar ? (
              <>
                <CheckCircleSolidIcon color="ds.icon.success" boxSize={5} />{" "}
                {newBookingCalendar.summary}
              </>
            ) : bookingCalendar ? (
              <>
                {bookingCalendar.google_calendar_error ? (
                  <Icon
                    as={AlertTriangleSolidIcon}
                    color="ds.icon.danger"
                    aria-label="Attention"
                  />
                ) : (
                  <CheckCircleSolidIcon color="ds.icon.success" boxSize={5} />
                  // providerIcon(bookingCalendar.provider)
                )}{" "}
                {bookingCalendar.summary}
              </>
            ) : (
              "Select a calendar"
            )
          ) : null}
        </Text>
      </MenuButton>
      <MenuList maxW="406px" color="text.primary">
        {allCalendars?.map((calendar, index) => {
          const providerHeading =
            currentProvider !== calendar.provider
              ? providerHeadingFor(calendar.provider)
              : null

          currentProvider = calendar.provider

          return (
            <React.Fragment key={calendar.id}>
              {providerHeading}
              <MenuItem
                fontSize="sm"
                fontWeight="normal"
                onClick={() => {
                  setBookingCalendar(calendar)
                }}
                borderBottomRadius={
                  // match the last MenuItem's border to the MenuList's border so it doesn't overlap
                  allCalendars.length - 1 === index ? 6 : undefined
                }
              >
                <Box
                  // display="block" must come after noOfLines={1} to override
                  // its display property for text truncation to work
                  noOfLines={1}
                  display="block"
                  maxW="100%"
                  whiteSpace="nowrap"
                >
                  <Text as="span">{calendar.summary}</Text>{" "}
                </Box>
              </MenuItem>
            </React.Fragment>
          )
        })}
      </MenuList>
    </Menu>
  )
}

const providerIcon = (provider: ModeratedStudyCalendar["provider"]) => {
  if (!provider) return null

  switch (provider) {
    case "google":
      return <GoogleCalendarIcon boxSize={4} />
    case "microsoft":
      return <MicrosoftOutlookIcon boxSize={4} />
    default:
      return assertNever(provider)
  }
}

const providerFriendlyName = (provider: ModeratedStudyCalendar["provider"]) => {
  if (!provider) return null

  switch (provider) {
    case "google":
      return "Google Calendar"
    case "microsoft":
      return "Microsoft Outlook"
    default:
      return assertNever(provider)
  }
}

const providerHeadingFor = (provider: ModeratedStudyCalendar["provider"]) => {
  return (
    <Flex gap={1.5} align="center" px={3} pt={3}>
      {providerIcon(provider)}

      <Text
        fontSize="xs"
        color="text.subtlest"
        fontWeight="600"
        textTransform="uppercase"
      >
        {providerFriendlyName(provider)}
      </Text>
    </Flex>
  )
}
