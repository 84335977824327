import { Box, Button, Flex, Icon, Text, Tooltip } from "@chakra-ui/react"
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/solid"
import { motion } from "framer-motion"
import React from "react"

import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"

import { UserActivityAvatarList } from "./UserActivityAvatarList"

const MotionBox = motion(Box)

type Props = {
  showHasResponsesWarning: boolean
  openHasResponsesWarningModal: () => void
  showSomeoneIsEditingWarning: boolean
  openSomeoneIsEditingWarningModal: () => void
  showTestHasActiveOrders: boolean
  loadingUserActivities: boolean
}

export const UserActivitiesWidget: React.FC<Props> = ({
  showHasResponsesWarning,
  openHasResponsesWarningModal,
  showSomeoneIsEditingWarning,
  openSomeoneIsEditingWarningModal,
  showTestHasActiveOrders,
  loadingUserActivities,
}) => {
  const { readOnly, userActivities, updateUserActivity } =
    useUsabilityTestUserActivityContext()

  if (
    (!showTestHasActiveOrders &&
      !showHasResponsesWarning &&
      !showSomeoneIsEditingWarning) ||
    loadingUserActivities
  ) {
    return null
  }

  const otherEditors = userActivities.filter(
    (a) => a.session_id !== sessionStorage.getItem("tabId")
  )

  const label = readOnly
    ? "You are in comment-only mode"
    : otherEditors.length === 1
      ? `You and ${otherEditors[0].user.name} are editing`
      : `You and ${otherEditors.length} others are editing`

  return (
    <MotionBox
      animate={{
        top: 28,
      }}
      initial={{
        top: -28,
      }}
      position="fixed"
      left="50%"
      transform="translateX(-50%)"
      zIndex={1}
    >
      <Flex
        alignItems="center"
        bg="white"
        rounded="md"
        boxShadow="xl"
        borderWidth={1}
        borderColor="gray.200"
        p={4}
        gap={4}
      >
        <Tooltip
          hasArrow
          textAlign="center"
          label="If you make any changes while someone else is editing, their changes could be lost. Try comments instead."
          isDisabled={!showSomeoneIsEditingWarning}
          rounded="md"
          p={2}
        >
          <Flex gap={4}>
            <Icon
              as={readOnly ? LockClosedIcon : LockOpenIcon}
              boxSize={6}
              color="gray.500"
            />
            <Text fontWeight="semibold" color="gray.700">
              {label}
            </Text>
          </Flex>
        </Tooltip>
        <UserActivityAvatarList activities={otherEditors} />

        {readOnly && (
          <Tooltip
            hasArrow
            rounded="md"
            p={2}
            textAlign="center"
            label={
              showTestHasActiveOrders
                ? `Sorry, you can${"\u2019"}t edit a test that has active orders!`
                : showHasResponsesWarning
                  ? `You${"\u2019"}re in comment-only mode because this test has complete responses or somebody is responding to it right now.`
                  : null
            }
            shouldWrapChildren
          >
            {/* Wrapping Button with a Box because tooltip doesn't work on a disabled button */}
            <Box>
              <Button
                colorScheme="brand.primary"
                onClick={() => {
                  showSomeoneIsEditingWarning
                    ? openSomeoneIsEditingWarningModal()
                    : showHasResponsesWarning
                      ? openHasResponsesWarningModal()
                      : updateUserActivity("editing")
                }}
                isDisabled={showTestHasActiveOrders}
              >
                Edit this test
              </Button>
            </Box>
          </Tooltip>
        )}
      </Flex>
    </MotionBox>
  )
}
