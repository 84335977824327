import { Box, Flex, Text } from "@chakra-ui/react"
import React, { ReactNode, useMemo } from "react"

import { getNormalizedFigmaTaskClicks } from "Utilities/get-normalized-clicks/get-normalized-figma-task-clicks"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { ScreenClicksWarning } from "../ScreenClicksWarning"
import { ScreenView } from "../ScreenResults"
import { useClickViewState } from "../use-click-view-state"
import { useFilteredClicksByClickOccurrence } from "../use-filtered-clicks"
import { useOnScreenClicks } from "../use-on-screen-clicks"
import { useScreenResult } from "../use-results"

import { ExpandableScreenResultDetailsPanel } from "./ExpandableScreenResultDetailsPanel"
import { ZoomableScreenResultDetailsPanel } from "./ZoomabelScreenResultDetailsPanel"

type ScreenResultDetailsPanelRenderProps = {
  clickView: ScreenView
  clicks: NormalizedClick[]
}

interface ScreenResultDetailsPanelContainerProps {
  children: (renderProps: ScreenResultDetailsPanelRenderProps) => ReactNode
}

function ScreenResultDetailsPanelContainer({
  children,
}: ScreenResultDetailsPanelContainerProps) {
  const [clickView] = useClickViewState()
  const { figmaImage } = useScreenResult()
  const { visibleClicks } = useFilteredClicksByClickOccurrence()

  const normalizedClicks = useMemo(() => {
    if (!figmaImage) return []
    return getNormalizedFigmaTaskClicks(visibleClicks, figmaImage)
  }, [visibleClicks, figmaImage])

  const [onScreenClicks, offScreenClicks] = useOnScreenClicks(normalizedClicks)

  return (
    <Flex flex="1" width="100%" overflow="hidden" flexDirection="column">
      {!figmaImage ? (
        <Text px={6} py={4}>
          This image is still processing. Check back later.
        </Text>
      ) : (
        <>
          {offScreenClicks.length > 0 && (
            <Box p={2} zIndex={1}>
              <ScreenClicksWarning offScreenClicks={offScreenClicks} />
            </Box>
          )}
          {children({ clickView, clicks: onScreenClicks })}
        </>
      )}
    </Flex>
  )
}

export function ScreenResultDetailsExpandablePanel() {
  return (
    <ScreenResultDetailsPanelContainer>
      {(props) => <ExpandableScreenResultDetailsPanel {...props} />}
    </ScreenResultDetailsPanelContainer>
  )
}

export function ScreenResultDetailsZoomablePanel() {
  return (
    <ScreenResultDetailsPanelContainer>
      {(props) => <ZoomableScreenResultDetailsPanel {...props} />}
    </ScreenResultDetailsPanelContainer>
  )
}
