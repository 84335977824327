import { axios } from "Services/axios"
import { CardGroup } from "Shared/components/Card/Card"
import { CreditCard, StripeInvoice } from "Types"
import { StaticPlanDrawer } from "UsabilityHub/components/PlanChanger/plan-drawer"
import {
  useActiveStripeSubscription,
  useCurrentAccount,
  useCurrentPlan,
  useMaybeManualSubscription,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { isFree } from "Utilities/plan"
import { getParsedQueryString } from "Utilities/query-string"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import SettingsApi from "~/api/settingsApi"
import { BillingDetails } from "./BillingDetails"
import { DiscontinuedPlanAlert } from "./DiscontinuedPlanAlert"
import { ManualSubscriptionUnpaidAlert } from "./ManualSubscriptionUnpaidAlert"
import { ModeratedStudiesQuotaInformation } from "./ModeratedStudiesQuotaInformation"
import { PaymentAndSubscription } from "./PaymentAndSubscription"
import { StripeSubscriptionPastDueAlert } from "./StripeSubscriptionPastDueAlert"
import { StripeSubscriptionUnpaidAlert } from "./StripeSubscriptionUnpaidAlert"

const useGetUpcomingInvoice = () => {
  const [isUpcomingInvoiceLoaded, setUpcomingInvoiceIsLoaded] = useState(false)
  const [upcomingInvoice, setUpcomingInvoice] = useState<StripeInvoice | null>(
    null
  )
  const refreshUpcomingInvoice = () => {
    setUpcomingInvoice(null)
    setUpcomingInvoiceIsLoaded(false)
  }

  useEffect(() => {
    if (isUpcomingInvoiceLoaded) {
      return
    }

    const loadInvoice = async () => {
      const response = await axios.get<{ upcoming_invoice: StripeInvoice }>(
        SettingsApi.upcomingInvoice.path()
      )
      setUpcomingInvoice(response.data.upcoming_invoice)
      setUpcomingInvoiceIsLoaded(true)
    }

    void loadInvoice()
  }, [isUpcomingInvoiceLoaded, setUpcomingInvoiceIsLoaded])

  return {
    isUpcomingInvoiceLoaded,
    upcomingInvoice,
    refreshUpcomingInvoice,
  }
}

export const BillingRoute: React.FC = () => {
  const currentAccount = useCurrentAccount()
  const teamMemberCount = useTeamMembers({
    onlyRoles: ["admin", "member"],
  }).length

  let creditCard: CreditCard | undefined
  if (currentAccount.stripe_card_last4) {
    creditCard = {
      expiry: currentAccount.stripe_card_expiry ?? undefined,
      last4: currentAccount.stripe_card_last4,
    }
  }

  const currentPlan = useCurrentPlan()

  const activeStripeSubscription = useActiveStripeSubscription()
  const activeManualSubscription = useMaybeManualSubscription("active")
  const pendingManualSubscription = useMaybeManualSubscription("pending")
  const { upcomingInvoice, refreshUpcomingInvoice, isUpcomingInvoiceLoaded } =
    useGetUpcomingInvoice()
  const showDiscontinuedPlanAlert =
    activeStripeSubscription &&
    !activeStripeSubscription.cancel_at_period_end &&
    currentPlan?.discontinued

  const isShowPlans = "showPlans" in getParsedQueryString()
  return (
    <>
      {isShowPlans && (
        <StaticPlanDrawer
          source="plan_settings"
          initialState={{
            screen: "plan-grid",
            selectedPlan: null,
          }}
        />
      )}
      <CardGroup>
        <Helmet>
          <title>Billing settings</title>
        </Helmet>
        {showDiscontinuedPlanAlert && (
          <DiscontinuedPlanAlert
            planInterval={currentPlan.interval}
            planName={currentPlan.name}
            planUniqueId={currentPlan.unique_id}
            subscriptionGuid={activeStripeSubscription.guid}
            subscriptionRenewalTime={
              activeStripeSubscription.current_period_end
            }
            teamMemberCount={teamMemberCount}
          />
        )}
        {activeStripeSubscription &&
          activeStripeSubscription.stripe_status === "past_due" && (
            <StripeSubscriptionPastDueAlert />
          )}
        {activeStripeSubscription &&
          activeStripeSubscription.stripe_status === "unpaid" && (
            <StripeSubscriptionUnpaidAlert />
          )}
        {currentAccount.manual_subscription &&
          !activeManualSubscription &&
          currentPlan &&
          !isFree(currentPlan) && <ManualSubscriptionUnpaidAlert />}
        <PaymentAndSubscription
          activeManualSubscription={activeManualSubscription}
          activeStripeSubscription={activeStripeSubscription}
          coupon={currentAccount.stripe_coupon_serialized}
          creditCard={creditCard}
          manualSubscription={currentAccount.manual_subscription}
          pendingManualSubscription={pendingManualSubscription}
          plan={currentPlan ?? undefined}
          upcomingInvoice={upcomingInvoice}
          refreshUpcomingInvoice={refreshUpcomingInvoice}
          isUpcomingInvoiceLoaded={isUpcomingInvoiceLoaded}
        />
        <ModeratedStudiesQuotaInformation
          isFreePlan={currentPlan ? isFree(currentPlan) : true}
        />
        <BillingDetails />
      </CardGroup>
    </>
  )
}
