import { Button, Text, useToast } from "@chakra-ui/react"
import React, { useState } from "react"

import { axios, isBadRequestError } from "Services/axios"
import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import UsersApi from "~/api/usersApi"

type LinkExpiredCard = React.FC<{
  id: string
}>

export const LinkExpiredCard: LinkExpiredCard = ({ id }) => {
  const toast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClick = async () => {
    try {
      setIsSubmitting(true)
      const response = await axios.post<{ message: string }>(
        UsersApi.resendInvitation.path({ id })
      )
      toast({
        title: response.data.message,
        status: "success",
      })
    } catch (error) {
      if (isBadRequestError(error)) {
        toast({
          title: error.response.data.message,
          status: "error",
        })
      } else {
        throw error
      }
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <Card>
      <CardHeader size="lg">Link expired</CardHeader>
      <CardBody>
        <Text fontSize="md">Unfortunately, your signup link has expired.</Text>
        <Button
          onClick={handleClick}
          isLoading={isSubmitting}
          colorScheme="brand.primary"
        >
          Resend signup link
        </Button>
      </CardBody>
    </Card>
  )
}
