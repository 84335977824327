import { useCallback } from "react"

import { useImage } from "Hooks/use-image"
import { FigmaImage, FigmaTaskClick } from "Types"
import { createScaledCanvas } from "Utilities/canvas/create-scaled-canvas"
import { downloadCanvas } from "Utilities/canvas/download-canvas"
import { drawClicks } from "Utilities/click-maps/draw-clicks"
import { drawHeatmap } from "Utilities/click-maps/draw-heatmap"
import { drawImage } from "Utilities/click-maps/draw-image"
import { drawOverlay } from "Utilities/click-maps/draw-overlay"
import { getNormalizedFigmaTaskClicks } from "Utilities/get-normalized-clicks/get-normalized-figma-task-clicks"

import { ScreenView } from "./ScreenResults"

// Scale up the image before exporting, to get higher quality heatmap/clickmap
const EXPORT_SCALING_FACTOR = 2

// Set the quality of the exported image (between 0 and 1),
// The higher the quality setting, the bigger the resulting file as well
const EXPORT_IMAGE_QUALITY = 1

function getImageFilename(
  image: FigmaImage,
  clickView: ScreenView,
  extension = "jpg"
) {
  const fileName = image.title ?? image.figma_node_id
  if (clickView === "CLICK_MAP") return `${fileName}_clickmap.${extension}`
  if (clickView === "HEAT_MAP") return `${fileName}_heatmap.${extension}`

  return `${fileName}.${extension}`
}

export function useDownloadScreenResult(
  figmaImage: FigmaImage | undefined,
  clickView: ScreenView,
  clicks: FigmaTaskClick[]
) {
  const { image } = useImage(figmaImage?.url)

  const handleDownload = useCallback(() => {
    if (!image) return
    if (!figmaImage) return

    const normalizedClicks = getNormalizedFigmaTaskClicks(clicks, figmaImage)

    const scaledCanvas = createScaledCanvas(figmaImage, EXPORT_SCALING_FACTOR)
    drawImage(scaledCanvas, image)
    drawOverlay(scaledCanvas)

    if (clickView === "CLICK_MAP") {
      drawClicks(scaledCanvas, normalizedClicks)
    } else if (clickView === "HEAT_MAP") {
      drawHeatmap(scaledCanvas, normalizedClicks)
    }

    downloadCanvas(
      scaledCanvas.canvas,
      getImageFilename(figmaImage, clickView, "jpeg"),
      {
        format: "image/jpeg",
        quality: EXPORT_IMAGE_QUALITY,
      }
    )
  }, [figmaImage, image, clickView, clicks])

  return handleDownload
}
