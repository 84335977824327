import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react"
import { CreditCardIcon } from "@heroicons/react/outline"
import { CreditCardDeclinedMessage } from "Components/credit-card-declined-message"
import { CreditCardFields } from "Components/credit-card-fields/credit-card-fields"
import { useCreditCardFields } from "Components/credit-card-fields/useCreditCardFields"
import { CreditCardDeclinedError } from "Services/stripe"
import * as SubscriptionService from "Services/subscription-service"
import {
  useActiveStripeSubscription,
  useCurrentAccount,
  useRefreshCurrentAccount,
} from "UsabilityHub/hooks/useCurrentAccount"
import { useRefreshTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { useInNoPlanState, useIsTrialing } from "Utilities/account"
import { getDateString } from "Utilities/date-formats"
import { reportError } from "Utilities/error"
import { add } from "date-fns"
import React, { ReactNode, useEffect, useState } from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { BillingSummary } from "./billing-summary"
import {
  PlanChangerAction,
  usePlanChangerContextOnScreen,
} from "./plan-changer-context"

const ANNUAL_PLAN_POSTFIX = "_annual"

export const PaymentDetails: React.FC<FlexProps> = (props) => {
  const {
    dispatch: planChangeDispatch,
    selectedPlan,
    currentPlan,
    showAnnual,
    allPlans,
  } = usePlanChangerContextOnScreen("payment-details")
  const activeStripeSubscription = useActiveStripeSubscription()
  const isTrial = useIsTrialing()
  const isInNoPlanState = useInNoPlanState()
  const currentAccount = useCurrentAccount()
  const [isLoading, setIsLoading] = useState(false)
  const refreshTeamMembers = useRefreshTeamMembers()
  const refreshCurrentAccount = useRefreshCurrentAccount()
  const [useDifferentCard, { on: setUseDifferentCard }] = useBoolean(false)
  const trialPeriodEndText = isTrial
    ? getDateString(activeStripeSubscription!.trial_end!)
    : ""

  const [cardName, setCardName] = useState("")
  const { handleSubmit: handleCreditCardSubmit } = useCreditCardFields({
    cardDeclinedError: <CreditCardDeclinedMessage />,
    onError: () => setIsLoading(false),
    onLoad: () => setIsLoading(true),
    cardName,
  })

  useEffect(() => {
    // When the user signs up with a selected plan, we want to make sure that the showAnnual is
    // set to the same value as the selected plan.
    if (
      (selectedPlan.interval === "year" && !showAnnual) ||
      (selectedPlan.interval === "month" && showAnnual)
    ) {
      planChangeDispatch({ type: "toggle-show-annual" })
    }
  }, [])

  const toast = useToast()

  // Caching this so that the screen doesn't jump over once a credit card is added
  const [creditCardOnMount] = useState(() =>
    currentAccount.stripe_card_last4
      ? ({
          expiry: currentAccount.stripe_card_expiry,
          last4: currentAccount.stripe_card_last4,
        } as const)
      : undefined
  )

  const renewDate = activeStripeSubscription
    ? new Date(activeStripeSubscription.current_period_end!)
    : add(new Date(), {
        months: selectedPlan.interval === "year" ? 12 : 1,
      })
  const renewDateText = renewDate.toLocaleString(navigator.language, {
    dateStyle: "medium",
  })

  const changeTitle = isTrial
    ? "Subscription confirmed"
    : isInNoPlanState
      ? "Payment successful"
      : "Your plan has been changed"

  const changeMessage = isTrial
    ? `Thanks for subscribing to the ${
        selectedPlan.name
      } plan! You${"\u2019"}ll be billed for your new subscription when your free trial ends on ${trialPeriodEndText}.`
    : isInNoPlanState
      ? `Thanks for upgrading to the ${selectedPlan.name} plan!`
      : ""

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      if (activeStripeSubscription) {
        await SubscriptionService.updateSubscription({
          subscriptionGuid: activeStripeSubscription.guid,
          planUniqueId: selectedPlan.unique_id,
        })
        refreshCurrentAccount()
        refreshTeamMembers()
        planChangeDispatch({
          type: "plan-changed",
          title: changeTitle,
          message: changeMessage,
        })
      } else {
        await SubscriptionService.createSubscription({
          plan: selectedPlan,
        })
        refreshCurrentAccount()
        refreshTeamMembers()
        planChangeDispatch({
          type: "plan-changed",
          title: "Payment successful",
          message: `Thanks for upgrading to the ${selectedPlan.name} plan!`,
        })
      }
    } catch (error) {
      if (
        error instanceof CreditCardDeclinedError ||
        error instanceof SubscriptionService.SubscriptionError
      ) {
        const errorMessage: ReactNode =
          error instanceof CreditCardDeclinedError ? (
            <CreditCardDeclinedMessage />
          ) : (
            error.message
          )
        toast({
          status: "error",
          title: errorMessage,
          duration: null,
        })
        setIsLoading(false)
      } else {
        reportError(error)
        toast({
          title: `We weren${"\u2019"}t able to perform that operation. Please contact support for assistance.`,
          status: "error",
        })
        setIsLoading(false)
      }
    }
  }

  return (
    <Flex
      direction="column"
      background="gray.50"
      color="text.primary"
      {...props}
    >
      <Heading fontSize="xl" fontWeight="semibold">
        New subscription details
      </Heading>

      <Text fontWeight="semibold" mt={6} mb={3}>
        Payment method
      </Text>
      {creditCardOnMount && !useDifferentCard ? (
        <>
          <FormControl>
            <FormLabel>Credit card on file</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={CreditCardIcon} />
              </InputLeftElement>
              <Input
                isReadOnly
                value={`•••• •••• •••• ${creditCardOnMount.last4}`}
              />
            </InputGroup>
          </FormControl>
          <Link variant="noUnderline" mt={2} onClick={setUseDifferentCard}>
            Use a different card
          </Link>
        </>
      ) : (
        <>
          <Text mb={3}>
            This card will be saved to your account. It{"\u2019"}ll be used to
            pay for subscription payments and any credits you purchase.
          </Text>
          <CreditCardFields name={cardName} onNameChange={setCardName} />
        </>
      )}

      <BillingPeriod
        showAnnual={showAnnual}
        dispatch={planChangeDispatch}
        selectedPlan={selectedPlan}
        allPlans={allPlans}
      />

      <BillingSummary selectedPlan={selectedPlan} />

      {activeStripeSubscription &&
        !isTrial &&
        !isInNoPlanState &&
        currentPlan && <ChangePlanWarning currentPlan={currentPlan} />}

      <Button
        colorScheme="brand.primary"
        isLoading={isLoading}
        fontWeight="semibold"
        onClick={async () => {
          if (!creditCardOnMount || useDifferentCard) {
            const validCard = await handleCreditCardSubmit()
            if (!validCard) return
          }
          void handleSubmit()
        }}
        my={4}
      >
        {isTrial || isInNoPlanState
          ? `Subscribe to ${selectedPlan.name} plan`
          : activeStripeSubscription
            ? `Change to ${selectedPlan.name} plan`
            : "Pay now & subscribe"}
      </Button>

      <Text align="center" fontSize="sm" fontWeight="normal">
        <BillingTipsMessage
          isTrial={isTrial}
          isInNoPlanState={isInNoPlanState}
          trialPeriodEndText={trialPeriodEndText}
          selectedPlan={selectedPlan}
          renewDate={renewDateText}
        />
      </Text>
    </Flex>
  )
}

const BillingPeriod: React.FC<{
  selectedPlan: Plan
  allPlans: readonly Plan[]
  showAnnual: boolean
  dispatch: React.Dispatch<PlanChangerAction>
}> = ({ showAnnual, dispatch, selectedPlan, allPlans }) => {
  const annualPlanId = showAnnual
    ? selectedPlan.unique_id
    : selectedPlan.unique_id + ANNUAL_PLAN_POSTFIX
  const monthlyPlanId = showAnnual
    ? selectedPlan.unique_id.replace(new RegExp(ANNUAL_PLAN_POSTFIX + "$"), "")
    : selectedPlan.unique_id

  const annualPlan = allPlans.find((p) => p.unique_id === annualPlanId)
  const monthlyPlan = allPlans.find((p) => p.unique_id === monthlyPlanId)

  const savePercent =
    annualPlan && monthlyPlan
      ? Math.floor(
          ((monthlyPlan.monthly_price - annualPlan.monthly_price) /
            monthlyPlan.monthly_price) *
            100
        )
      : null

  return (
    <>
      <Text fontWeight="semibold" mt={6} mb={3}>
        Billing period
      </Text>
      <RadioGroup
        onChange={(value: "annual" | "month") => {
          if (value === "annual" && showAnnual) return
          if (value === "month" && !showAnnual) return

          const planUniqueId = value === "annual" ? annualPlanId : monthlyPlanId

          dispatch({ type: "toggle-show-annual" })
          dispatch({ type: "choose-plan", planUniqueId })
        }}
        value={showAnnual ? "annual" : "month"}
      >
        <Stack direction="row" gap={6}>
          <Radio value="annual">
            Pay annually {savePercent ? `(save ${savePercent}%)` : null}
          </Radio>
          <Radio value="month">Pay monthly</Radio>
        </Stack>
      </RadioGroup>
    </>
  )
}

interface ChangePlanWarningProps {
  readonly currentPlan: Plan
}

const ChangePlanWarning: React.FC<ChangePlanWarningProps> = ({
  currentPlan,
}) => (
  <Alert status="info" alignItems="start" mb={3}>
    <AlertIcon />
    <AlertDescription>
      <Stack>
        <Text>
          You will receive a credit for any unused time on your{" "}
          {currentPlan.name} subscription, which will be automatically applied
          to this and any future subscription payments.
        </Text>
      </Stack>
    </AlertDescription>
  </Alert>
)

const BillingTipsMessage: React.FC<{
  isTrial: boolean
  isInNoPlanState: boolean
  trialPeriodEndText: string
  selectedPlan: Plan
  renewDate: string
}> = ({
  isTrial,
  isInNoPlanState,
  trialPeriodEndText,
  selectedPlan,
  renewDate,
}) => {
  if (isTrial) {
    return (
      <>
        You{"\u2019"}ll be billed for your new subscription when your trial ends
        on {trialPeriodEndText}. You can still choose a different plan or cancel
        your subscription if you change your mind.
      </>
    )
  } else if (isInNoPlanState) {
    return (
      <>
        Your {selectedPlan.name} subscription will start immediately, and will
        automatically renew on {renewDate}
      </>
    )
  } else {
    return (
      <>
        Your {selectedPlan.name} subscription will start immediately, and will
        automatically renew on {renewDate}
      </>
    )
  }
}
