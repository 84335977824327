import { Box } from "@chakra-ui/react"
import React, { useContext } from "react"

import { ClickOccurrenceFilterContext } from "../ClickOccurrenceFilterProvider"
import { useClickViewState } from "../use-click-view-state"
import { useDownloadScreenResult } from "../use-download-screen-result"
import { useFilteredClicksByClickOccurrence } from "../use-filtered-clicks"
import { useScreenResult } from "../use-results"

import { ScreenResultStatsBar } from "./ScreenResultsStatusBar"

export function ScreenResultDetailsStatusBarContainer() {
  const [clickView] = useClickViewState()
  const taskResult = useScreenResult()
  const { clickOccurrences, availableClickOccurrences, setClickOccurrences } =
    useContext(ClickOccurrenceFilterContext)

  const { visibleClicks } = useFilteredClicksByClickOccurrence()

  const handleDownload = useDownloadScreenResult(
    taskResult.figmaImage,
    clickView,
    visibleClicks
  )

  return (
    <Box backgroundColor="gray.50" borderTopColor="gray.200" borderTopWidth={1}>
      <ScreenResultStatsBar
        screenView={clickView}
        screenResultMeta={taskResult.meta}
        clickOccurrences={clickOccurrences}
        availableClickOccurrences={availableClickOccurrences}
        setClickOccurrences={setClickOccurrences}
        onDownload={handleDownload}
      />
    </Box>
  )
}
