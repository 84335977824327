import { axios, isForbiddenError } from "Services/axios"
import { Account, PointTransaction } from "Types"
import { Order } from "~/api/generated/usabilityhubSchemas"
import OrdersApi from "~/api/ordersApi"

interface CancelResult {
  message: string
  account: Account
}

interface CancelOrderResponse {
  account: Account
  refund_transaction: PointTransaction | null
}

export async function cancel(order: Order): Promise<CancelResult> {
  try {
    const data = (
      await axios.post(
        OrdersApi.cancel.path({
          usability_test_id: order.usability_test_unique_id,
          order_unique_id: order.unique_id,
        })
      )
    ).data as CancelOrderResponse
    let message = "Order canceled."
    if (data.refund_transaction) {
      message += ` ${data.refund_transaction.total_points} credits were refunded to your account.`
    }
    return { account: data.account, message }
  } catch (error) {
    if (isForbiddenError(error)) {
      throw new Error(error.response.data)
    }
    throw new Error("Canceling order failed, please try again.")
  }
}
