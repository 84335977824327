import React from "react"

import { Text } from "Components/form/text"
import { Textarea } from "Components/form/textarea"
import { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"

import styles from "./thank-you-message-fields.module.scss"

interface Props {
  readonly isActive: boolean
  readonly onChange: (newValues: Partial<RecruitmentLink>) => void
  readonly values: RecruitmentLink
}

export const ThankYouMessageFields: React.FC<Props> = ({
  isActive,
  onChange,
  values,
}) => (
  <>
    <Text
      className={styles.field}
      onChange={(v) => onChange({ thank_you_heading: v })}
      value={values.thank_you_heading ?? ""}
      placeholder="Title"
      required={isActive}
    />
    <Textarea
      className={styles.field}
      onChange={(event) => onChange({ thank_you_message: event.target.value })}
      value={values.thank_you_message ?? ""}
      placeholder="Message&hellip;"
      required={isActive}
      rows={3}
    />
  </>
)
