import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"
import { Helmet } from "react-helmet"

import { SubmitButton } from "Components/button/submit-button"
import { SUBPROCESSOR_LIST } from "Constants/legal-documents"
import {
  Document,
  DocumentIntro,
  DocumentSection,
} from "Shared/components/Document/Document"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { ROUTES } from "UsabilityHub/views/routes"
import { getDateString } from "Utilities/date-formats"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import { usePostApiSubprocessorListNotificationSubscriptions } from "~/api/generated/usabilityhub-components"

interface FormValues {
  email: string
}

const SubprocessorListNotificationSubscriptionModal: FunctionalModal = ({
  isOpen,
  onClose,
}) => {
  const initialValues: FormValues = {
    email: "",
  }
  const toast = useToast()
  const mutation = usePostApiSubprocessorListNotificationSubscriptions()

  const handleSubmit = (values: FormValues) => {
    const { email } = values
    mutation.mutate(
      {
        body: { email },
      },
      {
        onError: (error) => {
          toast({
            title: error.payload.message,
            status: "error",
          })
        },
        onSuccess: (data) => {
          toast({
            title: data.message,
            status: "success",
          })
          onClose()
        },
      }
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscribe for updates</ModalHeader>

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isValid }) => (
            <Form>
              <ModalBody>
                <Text>
                  Please enter your email address to be notified when a new
                  sub-processor is added.
                </Text>
                <Stack>
                  <Field name="email">
                    {({ field }: FieldProps<FormValues["email"]>) => (
                      <FormControl mt={2}>
                        <FormLabel>Email</FormLabel>
                        <Input
                          {...field}
                          required
                          autoFocus
                          type="email"
                          placeholder="email@example.com"
                        />
                      </FormControl>
                    )}
                  </Field>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <HStack>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <SubmitButton
                    isDisabled={!isValid}
                    isLoading={mutation.isLoading}
                    loadingAction="Subscribing"
                  >
                    Subscribe
                  </SubmitButton>
                </HStack>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export const SubprocessorsRoute: React.FC = () => {
  const { open: openModal } = useModal(
    SubprocessorListNotificationSubscriptionModal
  )

  const handleClick = () => {
    openModal({})
  }

  return (
    <Document title="Sub-processor list">
      <Helmet>
        <title>Sub-processor list</title>
      </Helmet>
      <DocumentIntro>
        <Text>
          Version {SUBPROCESSOR_LIST.currentVersion}, last updated on{" "}
          <strong>{getDateString(SUBPROCESSOR_LIST.lastUpdated)}</strong>.
        </Text>
        <Text>
          As part of the delivery of services to our customers, Lyssna uses data
          processors (sub-processors) that have access to certain customer data
          or personal information. This page provides information about each of
          these sub-processors.
        </Text>
        <Text>
          We establish GDPR compliant data processing agreements with each
          sub-processor, extending GDPR safeguards everywhere personal data is
          processed.
        </Text>
      </DocumentIntro>
      <DocumentSection title="Subscribe for updates">
        <Text>
          We update our sub-processor list from time to time in accordance with
          our{" "}
          <Link as={RoutedLink} to={ROUTES.LEGAL.DPA.path}>
            Data Processing Agreement
          </Link>
          .
        </Text>
        <Text>
          To be notified by email when a new sub-processor is added, please
          subscribe below.
        </Text>
        <Flex direction="row">
          <Button my={3} colorScheme="brand.primary" onClick={handleClick}>
            Subscribe to notifications
          </Button>
        </Flex>
      </DocumentSection>
      <DocumentSection title="Sub-processors">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Sub-processor</Th>
              <Th>Hosting location</Th>
              <Th>Purpose</Th>
              <Th>Controls in place</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <ExternalLink href="https://aws.amazon.com/">
                  Amazon Web Services
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Infrastructure provider</Td>
              <Td>ISO 27001, SOC 2, HIPAA, PCI DSS Level 1, FedRAMP</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://clearbit.com/">
                  Clearbit
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Data enrichment</Td>
              <Td>SOC 2</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://chartmogul.com/">
                  ChartMogul
                </ExternalLink>
              </Td>
              <Td>EU</Td>
              <Td>Analytics</Td>
              <Td>None</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://churnbuster.io/">
                  Churnbuster
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Subscription management</Td>
              <Td>None</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.datadoghq.com/">
                  Datadog
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Developer tooling and logging</Td>
              <Td>ISO 27001, SOC 2, HIPAA</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.fullstory.com/">
                  Fullstory
                </ExternalLink>
              </Td>
              <Td>USA, EU</Td>
              <Td>Analytics</Td>
              <Td>ISO 27001, SOC 2</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://analytics.google.com/">
                  Google Analytics
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Analytics</Td>
              <Td>ISO 27001</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.heroku.com/">
                  Heroku
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Infrastructure provider</Td>
              <Td>
                ISO 27001, SOC 1, SOC 2, PCI DSS Level 1, FISMA Moderate, SOX
              </Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.hubspot.com/">
                  Hubspot
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Sales tool</Td>
              <Td>SOC 2</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://inmoment.com/">
                  InMoment
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>NPS survey tool</Td>
              <Td>None</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.intercom.com/">
                  Intercom
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Customer support tool, external communication</Td>
              <Td>SOC 2</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.mailgun.com/">
                  Mailgun
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Transactional emails</Td>
              <Td>ISO 27001, SOC 2, HIPAA</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://ortto.com">Ortto</ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Analytics, external communication</Td>
              <Td>ISO 27001, SOC 2</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://rev.ai">Rev</ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Transcription services</Td>
              <Td>SOC 2, HIPAA</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://segment.com">Segment</ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Infrastructure and developer tools</Td>
              <Td>ISO 27001, SOC 2</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://stripe.com">Stripe</ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>Billing</Td>
              <Td>PCI DSS Level 1</Td>
            </Tr>
            <Tr>
              <Td>
                <ExternalLink href="https://www.twilio.com">
                  Twilio
                </ExternalLink>
              </Td>
              <Td>USA</Td>
              <Td>SMS verification for UserCrowd panelists</Td>
              <Td>ISO 27001, SOC 2</Td>
            </Tr>
          </Tbody>
        </Table>
      </DocumentSection>
    </Document>
  )
}
