import {
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from "@chakra-ui/react"
import React from "react"

export const TestRowSkeleton: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Flex
    h={[32, null, 24]}
    p={[4, null, 8]}
    gap={4}
    bg="white"
    w="100%"
    border="1px solid"
    borderColor="gray.200"
    rounded="md"
    align={["flex-start", null, "center"]}
    direction={["column", null, "row"]}
  >
    <Flex flexBasis="51%" w={["full", null, "auto"]} align="center" gap={4}>
      <Skeleton boxSize={12} mt={[1, null, 0]} />
      <Stack flexGrow="1" maxW={48} mt={[1, null, 0]}>
        <SkeletonText noOfLines={1} skeletonHeight={3} flexGrow="1" maxW={36} />
        <Flex align="center">
          <SkeletonCircle size="3" me={1} />
          <SkeletonCircle size="3" me={1} />
          <SkeletonCircle size="3" me={1} />
        </Flex>
        <SkeletonText noOfLines={1} flexGrow="1" maxW={24} />
      </Stack>
    </Flex>
    <Flex
      flexBasis="49%"
      flexGrow="1"
      gap={8}
      h="full"
      w={["100%", null, "auto"]}
      ps={[16, null, 0]}
    >
      <Stack flexBasis={["12rem", null, "40%"]}>
        <SkeletonText
          skeletonHeight={[2, null, 4]}
          noOfLines={1}
          w={["full", null, 6]}
        />
        <SkeletonText noOfLines={1} maxW="8rem" />
      </Stack>
      <Stack flexBasis="60%" display={["none", null, "flex"]}>
        <SkeletonText skeletonHeight={4} noOfLines={1} w={6} />
        <SkeletonText noOfLines={1} maxW="8rem" />
      </Stack>
    </Flex>
  </Flex>
)
