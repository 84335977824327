import { Box, Collapse, Flex, Switch } from "@chakra-ui/react"
import { CommentThread } from "Components/comment-thread/CommentThread"
import { Dispatch } from "Redux/app-store"
import { editScreenerQuestion } from "Redux/reducers/test-builder-form/action-creators/screeners"
import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { getId } from "Redux/reducers/test-builder/selectors/test-form"
import { ScreenerForm } from "Shared/components/ScreenerForm/ScreenerForm"
import {
  TestCard,
  TestCardActions,
  TestCardBody,
  TestCardHeader,
  TestCardHeading,
} from "UsabilityHub/components/TestCard/TestCard"
import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Field, WrappedFieldProps } from "redux-form"
import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"
import { useUsabilityTestUserActivityContext } from "../UsabilityTestUserActivityContext"
import { ScreenerInstructions } from "./ScreenerInstructions"
import { useUsabilityTestScreenerQuestions } from "./useUsabilityTestScreenerQuestions"

const COMMENTABLE_ENTITY = {
  entityContext: "test_builder",
  entityType: "screener",
} as const

export const ScreenerCard: React.FC = () => {
  const dispatch = useDispatch<Dispatch>()
  const screenerEnabled = useSelector(
    getFormValue("screener.enabled")
  ) as boolean
  const usabilityTestId = useSelector(getId)
  const { readOnly } = useUsabilityTestUserActivityContext()

  const {
    screenerQuestions,
    appendScreenerQuestion,
    removeScreenerQuestion,
    updateScreenerQuestion,
    duplicateScreenerQuestion,
  } = useUsabilityTestScreenerQuestions()

  const handleFormChange = useCallback(
    (newQuestion: ScreenerQuestion) => {
      dispatch(editScreenerQuestion(newQuestion))
    },
    [dispatch, editScreenerQuestion]
  )

  // If the user enables the screener and its empty, add a blank question for them
  useEffect(() => {
    if (screenerQuestions.length > 0) return

    appendScreenerQuestion()
  }, [screenerQuestions.length])

  return (
    <TestCard id="screener" px={0}>
      <TestCardHeader px={8}>
        <Flex alignItems="center" flexGrow={1} gap={2}>
          <TestCardHeading>
            <ScreenerInstructions>Screener</ScreenerInstructions>
          </TestCardHeading>
        </Flex>
        <TestCardActions>
          <Flex align="center" gap={2}>
            <Field
              name="screener.enabled"
              disabled={readOnly}
              component={ScreenerToggle}
            />
          </Flex>
        </TestCardActions>

        <CommentThread
          entity={COMMENTABLE_ENTITY}
          isEntityPersisted={usabilityTestId !== null}
        />
      </TestCardHeader>
      <Box
        sx={
          screenerEnabled
            ? {
                // Chakra's <Collapse> below adds overflow: hidden which cuts off the side of
                // the inputs. It's only needed during the animations so this is a workaround.
                // See: https://github.com/chakra-ui/chakra-ui/issues/2966
                ".chakra-collapse": {
                  overflow: "initial !important",
                },
              }
            : {}
        }
      >
        <Collapse in={screenerEnabled} unmountOnExit>
          <TestCardBody>
            <ScreenerForm
              screenerQuestions={screenerQuestions}
              removeScreenerQuestion={removeScreenerQuestion}
              appendScreenerQuestion={appendScreenerQuestion}
              updateScreenerQuestion={updateScreenerQuestion}
              duplicateScreenerQuestion={duplicateScreenerQuestion}
              onFormChange={handleFormChange}
              validQuestionTypes={["single_select", "multi_select"]}
              readOnly={readOnly}
              maxQuestionsAllowed={4}
            />
          </TestCardBody>
        </Collapse>
      </Box>
    </TestCard>
  )
}

const ScreenerToggle: React.FC<WrappedFieldProps & { disabled: boolean }> = ({
  input,
  disabled,
}) => {
  return (
    // biome-ignore lint/a11y/noLabelWithoutControl: The <Switch> is an input
    <label>
      Enable
      <Switch
        ml={2}
        isDisabled={disabled}
        isChecked={input.value as boolean}
        {...input}
      />
    </label>
  )
}
