import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Skeleton,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"

import { UsabilityHubNavbar } from "Components/exports"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { DashboardLoadingIndicator } from "UsabilityHub/components/DashboardLoadingIndicator/dashboard-loading-indicator"
import { useInfiniteListUsabilityTests } from "UsabilityHub/hooks/useInfiniteUsabilityTestList"
import { useGetProject } from "~/api/generated/usabilityhub-components"

import { AllDone } from "~/usabilityhub/components/AllDone"
import { ActiveMenu } from "../../dashboard/ActiveMenu"
import { DashboardHeader } from "../../dashboard/DashboardHeader"
import { SortMenu } from "../../dashboard/SortMenu"
import { TestRowList } from "../../dashboard/TestRowList"
import { useViewMenuParams } from "../../dashboard/useViewMenuParams"
import { ROUTES } from "../../routes"

import { Heading } from "DesignSystem/components"
import { ProjectContextMenu } from "./ProjectContextMenu"

const HeadingAndButtonsLoadingState: React.FC = () => (
  <Flex justify="space-between" align="center" gap={4}>
    <Flex flexGrow="1" maxW={72}>
      <Skeleton flexGrow="1" height={10} rounded="full" />
    </Flex>
    <Flex flexGrow="1" maxW={["8rem", "9.5rem", null, "22.5rem"]} gap={[1, 4]}>
      <Skeleton flexGrow="1" height={10} maxW={[10, null, 48]} rounded="md" />
      <Skeleton flexGrow="1" height={10} maxW={[10, null, 24]} rounded="md" />
      <Skeleton flexGrow="1" height={10} maxW={10} rounded="md" />
    </Flex>
  </Flex>
)

export const ProjectRoute: React.FC = () => {
  const { projectWithSlug } = useTypedParams(ROUTES.PROJECT)
  const { showArchived, sortBy, sortDirection } = useViewMenuParams()
  const [, setQueryString] = useMutableQueryString()
  const isLgOrLarger = useBreakpointValue(
    { base: false, lg: true },
    { ssr: false }
  )

  // Take the last 32 hex characters as the UUID since it may or may not have
  // hyphens separating the groups.
  const projectId = projectWithSlug.replaceAll(/[^0-9a-f]+/gi, "").slice(-32)

  const { data, error } = useGetProject(
    {
      pathParams: { id: projectId },
    },
    {
      retry: (_failureCount, error) => {
        // No point retrying for 404 errors, show the error message sooner
        return error.status !== 404
      },
      onSuccess: (data) => {
        if (data.project.archived) {
          setQueryString({
            show_archived: "true",
          })
        }
      },
    }
  )

  const {
    data: testData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    isLoading,
    isError,
  } = useInfiniteListUsabilityTests(
    {
      sort_by: sortBy,
      sort_direction: sortDirection,
    },
    [
      {
        attribute: "project",
        comparator: "eq",
        values: [projectId],
      },
      {
        attribute: "archived",
        comparator: showArchived ? "eq" : "neq",
        values: [],
      },
    ]
  )
  const allVisibleTests =
    testData?.pages.flatMap((page) => page.usability_tests) ?? []
  const infiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(fetchNextPage)
  const pagesShown = testData?.pages.length ?? 0

  const breadcrumbs = (
    <Breadcrumb fontSize="md" color="gray.500">
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={ROUTES.DASHBOARD.path}>
          Dashboard
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={ROUTES.PROJECTS.path}>
          Projects
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  )

  return (
    <Page title="Dashboard">
      <Box shadow="ds.overflow">
        <UsabilityHubNavbar />
        <DashboardHeader>{breadcrumbs}</DashboardHeader>
      </Box>
      <PageMain>
        <PageContent maxW="8xl">
          <Box display={["block", null, "none"]} mb={6}>
            {breadcrumbs}
          </Box>

          {error && (
            <Alert status="error">
              <AlertIcon />

              <AlertDescription>
                {error.status === 404
                  ? "That project could not be found."
                  : "There was an error loading that project."}
              </AlertDescription>
            </Alert>
          )}

          {data ? (
            <>
              {data.project.archived && (
                <Text fontSize="md" fontWeight="semibold" color="gray.400">
                  Archived
                </Text>
              )}
              <Flex align={["flex-start", "center"]} gap={3}>
                <Heading as="h1" textStyle="ds.display.primary">
                  {data.project.name}
                </Heading>
                <Box>
                  <DashboardLoadingIndicator query={{ isFetching, isError }} />
                </Box>

                <Flex align="center" gap={[1, 4]} ms="auto">
                  <ActiveMenu iconOnly={!isLgOrLarger} />
                  <SortMenu iconOnly={!isLgOrLarger} />
                  <ProjectContextMenu
                    variant="outline"
                    size="md"
                    project={data.project}
                  />
                </Flex>
              </Flex>
            </>
          ) : (
            <HeadingAndButtonsLoadingState />
          )}

          {(isLoading || allVisibleTests !== undefined) && (
            <>
              <TestRowList
                usabilityTests={isLoading ? [] : allVisibleTests}
                isLoading={isLoading}
                showProjectInfo={false} // Redundant since we're already in the context of a single project
                my={4}
                projectIdForNewTestsFromEmptyState={projectId}
              />

              {isFetchingNextPage && (
                <Flex justify="center">
                  <Spinner />
                </Flex>
              )}

              <Flex justify="center">
                {hasNextPage ? (
                  <div ref={infiniteScrollerRef} />
                ) : (
                  pagesShown > 1 && <AllDone />
                )}
              </Flex>
            </>
          )}
        </PageContent>
      </PageMain>
    </Page>
  )
}
