import { Box, BoxProps } from "@chakra-ui/react"
import { RequiredProperties } from "Shared/types/RequiredProperties"
import React from "react"

type Props = RequiredProperties<BoxProps, "as" | "textStyle"> & {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

/**
 * As of 2024-04-22, Chakra cannot handle `textStyle` props on Headings.
 * A `Heading` supports _variants_ for styling, but variants cannot support a
 * nested structure, which is how all our typography tokens work.
 *
 * In order to have our Heading typography work as expected like every other
 * place we use text, we need to use the textStyle prop. Segun Adebayo suggests
 * using a `<Box as="h2" />` or similar solution to be able to achieve a
 * semantic HTML heading with a textStyle.
 * https://github.com/chakra-ui/chakra-ui/issues/3501#issuecomment-803610656
 */

/**
 * - Use `textStyle` to control appearance.
 * - Use `as` to choose which HTML element to render (`h1` to `h6`).
 * - If you don't want an HTML heading but still want a heading text style, use a `<Text>`.
 */
export const Heading = (props: Props) => <Box {...props} />

Heading.displayName = "Heading"
