import { Box, Text } from "@chakra-ui/react"
import {
  GroupBase,
  OptionBase,
  OptionProps,
  Props,
  Select,
  chakraComponents,
  createFilter,
} from "chakra-react-select"
import type { ReactElement } from "react"
import React from "react"

export interface OptionWithHelper extends OptionBase {
  label: string
  value: string
  helper: string | null
}

const customComponents = {
  Option: <IsMulti extends boolean>({
    children,
    ...props
  }: OptionProps<OptionWithHelper, IsMulti, GroupBase<OptionWithHelper>>) => (
    <chakraComponents.Option {...props}>
      <Box>
        {children}

        {props.data.helper && (
          <Text
            fontSize="xs"
            color={
              props.isSelected &&
              props.selectProps.selectedOptionStyle === "color"
                ? "white"
                : "gray.500"
            }
          >
            {props.data.helper}
          </Text>
        )}
      </Box>
    </chakraComponents.Option>
  ),
}

// The "stringify" field is what the filter will use to match against, so this will let us
// include the helper text when we search.
const includeHelpers = createFilter<OptionWithHelper>({
  stringify: (option) =>
    `${option.label} ${option.data.helper ?? ""}`.trimEnd(),
})

export const SelectWithOptionHelper = ({
  blurInputOnSelect,
  ...props
}: Props): ReactElement => {
  if (props.closeMenuOnSelect === false && blurInputOnSelect === undefined) {
    // Mobile browsers will often blur the input (inconsistently) on select by default.
    // This can cause issues as blurring will visually close the menu,
    // but the onMenuClose handler isn't always called when this happens.
    // Require explicit opt-in to blur the input.
    blurInputOnSelect = false
  }
  return (
    <Select
      filterOption={includeHelpers}
      menuPortalTarget={document.body}
      components={customComponents}
      blurInputOnSelect={blurInputOnSelect}
      chakraStyles={{
        input: (provided) => ({
          ...provided,
          w: "auto",
          border: "none !important",
          boxShadow: "none !important",
        }),
      }}
      {...props}
    />
  )
}
