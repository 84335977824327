import { S3Config } from "Types"
import { compact } from "lodash"
import { BaseError } from "make-error"

import { Dispatch, GetState, State } from "Redux/app-store"
import { showErrorMessage, showNoticeMessage } from "Redux/reducers/flash"
import { ActionType } from "Redux/reducers/screenshots/constants"
import {
  getScreenshotWithClientId,
  getScreenshotWithId,
} from "Redux/reducers/screenshots/selectors"
import { axios } from "Services/axios"
import { postScreenshot, uploadScreenshotFile } from "Services/screenshot"
import {
  AsyncThunkAction,
  ClientId,
  PayloadAction,
  Persisted,
  RawScreenshot,
  Screenshot,
  ScreenshotFetchStatus,
} from "Types"
import { reportError } from "Utilities/error"
import { loadImage } from "Utilities/image"
import { delay } from "Utilities/promise"
import ScreenshotsApi from "~/api/screenshotsApi"
import {
  MAX_HEIGHT,
  MAX_WIDTH,
} from "~/application/javascripts/constants/screenshot"

// -- Constants --

const POLL_INTERVAL_MS = 3000

// -- Errors --

class ScreenshotRemovedError extends BaseError {}

// -- Helpers --

async function pollScreenshotStatus(
  screenshotId: number,
  getState: GetState
): Promise<Persisted<RawScreenshot>> {
  while (true) {
    // First check if the screenshot in question is still in store. If it's been
    // removed then we don't care about it anymore.
    const { screenshots } = getState()
    const stillExists = screenshots.some((s) => s.id === screenshotId)
    if (!stillExists) {
      throw new ScreenshotRemovedError("Screenshot was removed")
    }

    const screenshot = (
      await axios.get(ScreenshotsApi.get.path({ id: screenshotId }))
    ).data as Persisted<RawScreenshot>
    switch (screenshot.status) {
      case ScreenshotFetchStatus.Done:
        return screenshot
      case ScreenshotFetchStatus.Failed:
        throw new Error("Screenshot processing failed")
      default:
        await delay(POLL_INTERVAL_MS)
    }
  }
}

// -- Action types --

export type Actions =
  | SetScreenshots
  | LoadScreenshotRequest
  | LoadScreenshotSuccess
  | LoadScreenshotFailure
  | UploadScreenshotsRequest
  | UploadScreenshotUploaded
  | UploadScreenshotCreated
  | UploadScreenshotProgress
  | UploadScreenshotSuccess
  | UploadScreenshotFailure
  | UpdateScreenshotRequest
  | UpdateScreenshotSuccess
  | UpdateScreenshotFailure
  | UpdateScreenshotViewed
  | UpdateUnpersistedScreenshot

type SetScreenshots = PayloadAction<
  ActionType.SET_SCREENSHOTS,
  ReadonlyArray<Readonly<Screenshot>>
>
type LoadScreenshotRequest = PayloadAction<
  ActionType.LOAD_SCREENSHOT_REQUEST,
  ClientId
>
type LoadScreenshotSuccess = PayloadAction<
  ActionType.LOAD_SCREENSHOT_SUCCESS,
  ClientId
>
type LoadScreenshotFailure = PayloadAction<
  ActionType.LOAD_SCREENSHOT_FAILURE,
  ClientId
>
type UploadScreenshotsRequest = PayloadAction<
  ActionType.UPLOAD_SCREENSHOTS_REQUEST,
  ReadonlyArray<Readonly<Screenshot>>
>
type UploadScreenshotUploaded = PayloadAction<
  ActionType.UPLOAD_SCREENSHOT_UPLOADED,
  ClientId
>
type UploadScreenshotCreated = PayloadAction<
  ActionType.UPLOAD_SCREENSHOT_CREATED,
  { clientId: ClientId; attributes: Readonly<Persisted<RawScreenshot>> }
>
type UploadScreenshotProgress = PayloadAction<
  ActionType.UPLOAD_SCREENSHOT_PROGRESS,
  { clientId: ClientId; progress: number }
>
type UploadScreenshotSuccess = PayloadAction<
  ActionType.UPLOAD_SCREENSHOT_SUCCESS,
  { clientId: ClientId; attributes: Readonly<Persisted<RawScreenshot>> }
>
type UploadScreenshotFailure = PayloadAction<
  ActionType.UPLOAD_SCREENSHOT_FAILURE,
  ClientId
>
type UpdateScreenshotRequest = PayloadAction<
  ActionType.UPDATE_SCREENSHOT_REQUEST,
  ClientId
>
type UpdateScreenshotSuccess = PayloadAction<
  ActionType.UPDATE_SCREENSHOT_SUCCESS,
  { clientId: ClientId; attributes: Readonly<Persisted<RawScreenshot>> }
>
type UpdateScreenshotFailure = PayloadAction<
  ActionType.UPDATE_SCREENSHOT_FAILURE,
  ClientId
>
type UpdateScreenshotViewed = PayloadAction<
  ActionType.UPDATE_SCREENSHOT_VIEWED,
  { clientId: ClientId }
>
type UpdateUnpersistedScreenshot = PayloadAction<
  ActionType.UPDATE_UNPERSISTED_SCREENSHOT,
  { clientId: ClientId; attributes: Partial<Screenshot> }
>

// -- Action creators --

export const setScreenshots =
  (screenshots: ReadonlyArray<Screenshot>) => (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SET_SCREENSHOTS,
      payload: screenshots,
    })
  }

export const loadScreenshot =
  (clientId: ClientId): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    try {
      const screenshot = getScreenshotWithClientId(getState(), clientId)
      const { url, _isLoaded, _isLoading } = screenshot

      // Can't load a screenshot without a `url` obviously... This should never
      // happen, but if it does (in the case of the test interface, for example)
      // it should be an error.
      if (url == null) {
        dispatch<LoadScreenshotFailure>({
          type: ActionType.LOAD_SCREENSHOT_FAILURE,
          payload: clientId,
        })
        const error = new TypeError(`screenshot.url === ${String(url)}`)
        reportError(error, {
          extra: screenshot,
        })
        throw error
      }
      if (!_isLoaded && !_isLoading) {
        if (screenshot.media_type === "image") {
          dispatch<LoadScreenshotRequest>({
            type: ActionType.LOAD_SCREENSHOT_REQUEST,
            payload: clientId,
          })
          await loadImage(url)
          dispatch<LoadScreenshotSuccess>({
            type: ActionType.LOAD_SCREENSHOT_SUCCESS,
            payload: clientId,
          })
        } else {
          dispatch<LoadScreenshotSuccess>({
            type: ActionType.LOAD_SCREENSHOT_SUCCESS,
            payload: clientId,
          })
        }
      }
    } catch (error) {
      dispatch<LoadScreenshotFailure>({
        type: ActionType.LOAD_SCREENSHOT_FAILURE,
        payload: clientId,
      })
      throw error
    }
  }

export const setScreenshotViewed = (
  clientId: ClientId
): UpdateScreenshotViewed => ({
  type: ActionType.UPDATE_SCREENSHOT_VIEWED,
  payload: {
    clientId,
  },
})

export const uploadScreenshots =
  (
    screenshots: ReadonlyArray<Screenshot>,
    s3Config: S3Config
  ): AsyncThunkAction<State, Array<Readonly<Screenshot>>> =>
  async (dispatch, getState) => {
    if (s3Config == null) {
      throw new TypeError(`s3Config === ${String(s3Config)}`)
    }

    dispatch<UploadScreenshotsRequest>({
      type: ActionType.UPLOAD_SCREENSHOTS_REQUEST,
      payload: screenshots,
    })

    const promises = screenshots.map(async (screenshot) => {
      const clientId = screenshot._clientId
      const onProgress = (progress: number) => {
        dispatch<UploadScreenshotProgress>({
          type: ActionType.UPLOAD_SCREENSHOT_PROGRESS,
          payload: {
            clientId,
            progress,
          },
        })
      }

      try {
        if (screenshot._file === null) {
          throw new TypeError("No screenshot file found!")
        }

        if (
          screenshot.media_type === "image" &&
          (screenshot.width > MAX_WIDTH || screenshot.height > MAX_HEIGHT)
        ) {
          throw new TypeError(
            `The screenshot dimensions can${"\u2019"}t be larger than ${MAX_HEIGHT}x${MAX_WIDTH}`
          )
        }

        const id = await uploadScreenshotFile(
          screenshot._file,
          s3Config,
          onProgress
        )
        dispatch<UploadScreenshotUploaded>({
          type: ActionType.UPLOAD_SCREENSHOT_UPLOADED,
          payload: clientId,
        })
        // Grab latest version of screenshot (in case name, DPI etc have been
        // edited).
        screenshot = getScreenshotWithClientId(getState(), screenshot._clientId)
        const responseAttributes = await postScreenshot({
          ...screenshot,
          direct_upload_id: id,
        })
        dispatch<UploadScreenshotCreated>({
          type: ActionType.UPLOAD_SCREENSHOT_CREATED,
          payload: {
            attributes: responseAttributes,
            clientId,
          },
        })
        dispatch<UploadScreenshotSuccess>({
          type: ActionType.UPLOAD_SCREENSHOT_SUCCESS,
          payload: {
            attributes: await pollScreenshotStatus(
              responseAttributes.id,
              getState
            ),
            clientId,
          },
        })
        return getScreenshotWithId(getState(), responseAttributes.id)
      } catch (error) {
        if (error instanceof ScreenshotRemovedError) {
          return null
        }
        dispatch(
          showErrorMessage(`Image upload failed. ${String(error.message)}`)
        )
        dispatch<UploadScreenshotFailure>({
          type: ActionType.UPLOAD_SCREENSHOT_FAILURE,
          payload: clientId,
        })
        throw error
      }
    })

    return compact(await Promise.all(promises))
  }

export const updateScreenshotNameAndDisplayScale =
  (
    clientId: ClientId,
    name: string,
    displayScale: number
  ): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    try {
      const screenshot = getScreenshotWithClientId(getState(), clientId)

      if (screenshot.id === null) {
        // Screenshot is not persisted, so just update it in place.
        dispatch<UpdateUnpersistedScreenshot>({
          type: ActionType.UPDATE_UNPERSISTED_SCREENSHOT,
          payload: {
            attributes: { name, display_scale: displayScale },
            clientId,
          },
        })
        return
      }

      dispatch<UpdateScreenshotRequest>({
        type: ActionType.UPDATE_SCREENSHOT_REQUEST,
        payload: clientId,
      })

      const updated = (
        await axios.patch<Persisted<RawScreenshot>>(
          ScreenshotsApi.update.path({ id: screenshot.id }),
          {
            display_scale: displayScale,
            id: screenshot.id,
            name,
          }
        )
      ).data

      dispatch<UpdateScreenshotSuccess>({
        type: ActionType.UPDATE_SCREENSHOT_SUCCESS,
        payload: {
          attributes: updated,
          clientId,
        },
      })
      dispatch(showNoticeMessage("Design updated"))
    } catch (error) {
      dispatch(showErrorMessage("Updating design failed"))
      dispatch<UpdateScreenshotFailure>({
        payload: clientId,
        type: ActionType.UPDATE_SCREENSHOT_FAILURE,
      })
      throw error
    }
  }
