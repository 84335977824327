import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react"
import { PurchaseConfirmationModal } from "Components/payments/purchase-confirmation-modal"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { ThirdPartyQuote } from "Types"
import {
  useCurrentAccount,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useState } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useOrderForm } from "../OrderFormProvider"
import { QuoteState } from "../types"
import { useExternalPanelOrderSubmit } from "./useExternalPanelOrderSubmit"

interface Props {
  quote: ThirdPartyQuote | null
  quoteState: QuoteState
}

export const ExternalPanelSubmitOrder: React.FC<Props> = ({
  quote,
  quoteState,
}) => {
  const { testId: usabilityTestUniqueId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const {
    numParticipants,
    ageRange,
    targetLocations,
    selectedOptionIds,
    testHasScreener,
    testHasRecordings,
    estimatedIncidenceRate,
  } = useOrderForm()

  const [isConfirming, setIsConfirming] = useState(false)

  const { submitOrder, orderCreateState } = useExternalPanelOrderSubmit({
    confirmCreditPurchase: () => setIsConfirming(true),
  })

  const canUserPurchaseCredits = useCurrentUser().can_purchase_credits
  const isAccountEligibleToPlaceThirdPartyOrders =
    useCurrentAccount().is_eligible_to_place_third_party_orders
  const hasThirdPartyScreenerFlag = useFeatureFlag(
    "third_party_order_screeners"
  )
  const recordingsEnabled = useFeatureFlag(
    "usability_test_recording_external_orders"
  )

  const targetingNonCountryLocations = targetLocations.some(
    (tl) => tl.type !== "country"
  )

  const queryString = new URLSearchParams(window.location.search)
  const forceEnableSubmit = queryString.get("enable_submit") !== null

  const canSubmit =
    forceEnableSubmit ||
    (quoteState === "success" &&
      !targetingNonCountryLocations &&
      (!testHasScreener || hasThirdPartyScreenerFlag) &&
      (!testHasRecordings || recordingsEnabled) &&
      (canUserPurchaseCredits || quote?.credits_to_be_purchased === 0) &&
      isAccountEligibleToPlaceThirdPartyOrders)

  const handleSubmit = async () => {
    if (!quote || (!forceEnableSubmit && quoteState !== "success")) return

    submitOrder(
      usabilityTestUniqueId,
      numParticipants,
      ageRange[0],
      ageRange[1],
      targetLocations,
      selectedOptionIds,
      quote,
      isConfirming
    )
  }

  const hideQuoteErrors =
    targetingNonCountryLocations ||
    (testHasScreener && !hasThirdPartyScreenerFlag) ||
    (testHasRecordings && !recordingsEnabled)

  return (
    <Flex direction="column" gap={4} mt={4}>
      <QuoteValidationErrors
        targetingNonCountryLocations={targetingNonCountryLocations}
        notAllowedToUseScreener={testHasScreener && !hasThirdPartyScreenerFlag}
        notAllowedToUseRecordings={testHasRecordings && !recordingsEnabled}
        missingIncidenceRate={
          testHasScreener && estimatedIncidenceRate === null
        }
      />
      {quoteState === "error" && !hideQuoteErrors && (
        <Alert maxH="10rem" status="error">
          <AlertIcon />
          <Text fontWeight="bold">
            There was an error calculating your quote. Please try again.
          </Text>
        </Alert>
      )}
      {quoteState === "unavailable" && !hideQuoteErrors && (
        <Alert status="warning">
          <AlertIcon />
          <Text color="text.primary">
            Sorry, we can{"\u2019"}t complete that order from our external
            panel.
          </Text>
        </Alert>
      )}
      <Button
        isDisabled={!canSubmit}
        isLoading={orderCreateState === "loading"}
        colorScheme="brand.primary"
        w="100%"
        onClick={handleSubmit}
        data-intercom-target="submit-order-button"
      >
        Place external panel order
      </Button>
      {isConfirming && quote && (
        <PurchaseConfirmationModal
          submitPurchaseAsync={handleSubmit}
          onCancel={() => setIsConfirming(false)}
          formattedPrice={quote.formatted_price}
          heading={`Purchase ${quote.credit_cost} credits`}
        />
      )}
    </Flex>
  )
}

type QuoteValidationErrorsProps = {
  targetingNonCountryLocations: boolean
  notAllowedToUseScreener: boolean
  notAllowedToUseRecordings: boolean
  missingIncidenceRate: boolean
}

const QuoteValidationErrors: React.FC<QuoteValidationErrorsProps> = ({
  targetingNonCountryLocations,
  notAllowedToUseScreener,
  notAllowedToUseRecordings,
  missingIncidenceRate,
}) => {
  if (
    targetingNonCountryLocations ||
    notAllowedToUseScreener ||
    notAllowedToUseRecordings
  ) {
    return (
      <>
        {targetingNonCountryLocations && (
          <Alert status="warning">
            <AlertIcon />

            <AlertDescription>
              Region/city targeting is not currently supported for external
              panel orders. To continue, please update your location selection
              to countries only.
            </AlertDescription>
          </Alert>
        )}
        {notAllowedToUseScreener && (
          <Alert status="warning">
            <AlertIcon />

            <AlertDescription>
              Lyssna screeners aren{"\u2019"}t currently supported for external
              panel orders. Please remove the screener to continue, or contact
              the Lyssna support team.
            </AlertDescription>
          </Alert>
        )}
        {notAllowedToUseRecordings && (
          <Alert status="warning">
            <AlertIcon />

            <AlertDescription>
              Recording is not currently supported for external panel orders.
              Please remove all recording requirements from your test to
              continue.
            </AlertDescription>
          </Alert>
        )}
      </>
    )
  }

  if (missingIncidenceRate) {
    return (
      <Alert maxH="10rem" status="warning">
        <AlertIcon />
        <AlertDescription fontWeight="bold">
          As this test includes a screener, you must select an estimated
          screen-in rate.
        </AlertDescription>
      </Alert>
    )
  }
}
