import { Button, Text, useDisclosure } from "@chakra-ui/react"
import React from "react"

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "Shared/components/Card/Card"

import { ConfirmDeleteAccountModal } from "./ConfirmDeleteAccountModal"
import { DeleteAccountModal } from "./DeleteAccountModal"

type DeleteAccount = React.FC<React.PropsWithChildren<unknown>>

export const DeleteAccount: DeleteAccount = () => {
  const {
    isOpen: isDeleteAccountModalOpen,
    onOpen: openDeleteAccountModal,
    onClose: closeDeleteAccountModal,
  } = useDisclosure()
  const {
    isOpen: isConfirmDeleteAccountModalOpen,
    onOpen: openConfirmDeleteAccountModal,
    onClose: closeConfirmDeleteAccountModal,
  } = useDisclosure()

  return (
    <>
      <Card>
        <CardHeader>Delete account</CardHeader>
        <CardBody>
          <Text>Deleting your account is irreversible.</Text>
          <CardFooter>
            <Button onClick={openDeleteAccountModal} colorScheme="red">
              Delete account
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={closeDeleteAccountModal}
        onDeleteAccount={() => {
          closeDeleteAccountModal()
          openConfirmDeleteAccountModal()
        }}
      />
      <ConfirmDeleteAccountModal
        isOpen={isConfirmDeleteAccountModalOpen}
        onClose={closeConfirmDeleteAccountModal}
      />
    </>
  )
}
