import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { SkipUsabilityTestModal } from "Components/skip-usability-test-modal/skip-usability-test-modal"
import { axios } from "Services/axios"
import { ReportIcon } from "Shared/icons/ReportIcon"
import { Language, ParticipantDeletionReason, TestBrandingType } from "Types"
import { ScreenerQuestionAnswersContextProvider } from "UsabilityHub/components/ModeratedStudy/Screener/Questions/AnsweredQuestionsContext"
import { ScreenerQuestionAnswer } from "UsabilityHub/components/ModeratedStudy/Screener/Questions/types"
import { ScreenerQuestionAnswersFlow } from "UsabilityHub/components/ModeratedStudy/Screener/ScreenerQuestionAnswersFlow"
import { TestBrandingContextProvider } from "UsabilityHub/components/UsabilityTest/context/testBranding"
import { PreviewBanner } from "UsabilityHub/components/UsabilityTest/preview-banner"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useState } from "react"
import {
  usePreviewSubmitScreener,
  useResponsesStartScreener,
  useResponsesSubmitScreener,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { UsabilityTestResponseFlow } from "~/api/generated/usabilityhubSchemas"
import ResponsesApi from "~/api/responsesApi"
import TestInterfaceApi from "~/api/testInterfaceApi"
import { FakeTestBackground } from "./FakeTestBackground"
import { ScreenedInScreen } from "./ScreenedInScreen"
import { ScreenedOutScreen } from "./ScreenedOutScreen"
import { ScreenerWelcomeScreen } from "./ScreenerWelcomeScreen"

type Props = {
  usabilityTestUniqueId: string
  isPanelist: boolean
  responseId: number | null // null only when previewing
  questions: Extract<
    UsabilityTestResponseFlow,
    { state: "screener" }
  >["screener_questions"]
  testInfo: Extract<
    UsabilityTestResponseFlow,
    { state: "screener" }
  >["test_info"]
  language: Language
}

type Steps = "welcome" | "questions" | "screened_in" | "screened_out"

export const UsabilityTestScreener: React.FC<Props> = ({
  usabilityTestUniqueId,
  isPanelist,
  responseId,
  testInfo,
  language,
  questions,
}) => {
  const initialFocusRef = React.useRef<HTMLButtonElement | null>(null)
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const [showSkipModal, setShowSkipModal] = useState(false)

  const [step, setStep] = useState<Steps>("welcome")
  const [screenedOutCredits, setScreenedOutCredits] = useState<number | null>(
    null
  )
  const [previewTestData, setPreviewTestData] =
    useState<UsabilityTestResponseFlow | null>(null)

  const { mutate: startScreener } = useResponsesStartScreener()
  const { mutateAsync: submitScreener } = useResponsesSubmitScreener({})
  const { mutateAsync: submitScreenerPreview } = usePreviewSubmitScreener({})

  const handleStartScreener = () => {
    if (responseId) {
      startScreener({
        pathParams: {
          responseId,
        },
      })
    }

    setStep("questions")
  }

  const handleSubmittedAllAnswers = async (
    answers: ScreenerQuestionAnswer[]
  ) => {
    let submitResult

    if (responseId) {
      submitResult = await submitScreener({
        pathParams: {
          responseId,
        },
        body: {
          screener_question_answers: answers,
        },
      })

      setScreenedOutCredits(submitResult.screened_out_credits)
    } else {
      submitResult = await submitScreenerPreview({
        pathParams: {
          usabilityTestUniqueId,
        },
        body: {
          screener_question_answers: answers,
        },
      })

      setPreviewTestData(submitResult.new_flow)
    }

    setStep(submitResult.screened_in ? "screened_in" : "screened_out")
  }

  const continueToTest = () => {
    let queryKey

    if (responseId) {
      queryKey = queryKeyFn({
        operationId: "getUsabilityTestResponseFlow",
        path: "/api/responses/{response_id}",
        variables: { pathParams: { responseId } },
      })

      // The API will return with state="usability_test" instead of state="screener" and
      // the whole page will re-render.
      queryClient.invalidateQueries(queryKey)
    } else {
      queryKey = queryKeyFn({
        operationId: "previewUsabilityTest",
        path: "/api/usability_tests/{usability_test_unique_id}/preview",
        variables: { pathParams: { usabilityTestUniqueId } },
      })

      // When previewing, we don't invalidate the preview endpoint since it's totally stateless.
      // Instead, the submitScreener endpoint above returned a new flow which we have stored in local state
      // and we can insert it directly into the query cache here.
      if (previewTestData) {
        queryClient.setQueryData(queryKey, previewTestData)
      }
    }
  }

  const handleSkip = async (reason: ParticipantDeletionReason) => {
    // TODO: This ought to move to OpenAPI at some point
    await axios.put(ResponsesApi.cancel.path({ id: responseId }), {
      deletion_reason: reason,
    })

    // TODO: Should this be used by testers? See also, comment in EditPasswordForm
    const dashboardPath = ROUTES.DASHBOARD.path

    window.location.href =
      reason === ParticipantDeletionReason.Skipped
        ? dashboardPath
        : TestInterfaceApi.flagged.path()
  }

  return (
    <TestBrandingContextProvider
      branding={
        testInfo.button_color
          ? {
              type: testInfo.type as TestBrandingType,
              button_color: testInfo.button_color,
              logo_url: testInfo.logo_url,
            }
          : null
      }
    >
      {responseId === null && (
        // The exact z-index doesn't matter, just needs to be above the blurred background and below the modal at 1400.
        <Box zIndex={1} position="relative">
          <PreviewBanner />
        </Box>
      )}

      <Modal isOpen onClose={() => {}} initialFocusRef={initialFocusRef}>
        <FakeTestBackground testInfo={testInfo} />

        <ModalContent
          maxW="820px"
          h={["100%", "calc(100% - 80px)"]}
          my={[0, 10]}
          rounded={["initial", "md"]}
        >
          {/* The relative position is so that the ScreenerQuestionAnswersFlow can position
              its next/previous buttons inside the modal */}
          <ModalBody display="flex" pos="relative" p={4} overflowY="auto">
            {step === "welcome" && (
              <ScreenerWelcomeScreen
                isPanelist={isPanelist}
                initialFocusRef={initialFocusRef}
                onContinue={handleStartScreener}
                showReportModal={() => setShowSkipModal(true)}
              />
            )}

            {step === "questions" && (
              <ScreenerQuestionAnswersContextProvider
                screenerQuestions={questions}
                onSubmittedAllAnswers={handleSubmittedAllAnswers}
              >
                <Flex
                  direction="column"
                  justify="center"
                  pt={[0, null, "80px"]}
                  pb={["68px", null, 0]}
                  m="auto"
                >
                  {isPanelist && (
                    <Button
                      position="absolute"
                      top={4}
                      left={4}
                      onClick={() => setShowSkipModal(true)}
                    >
                      <ReportIcon />
                    </Button>
                  )}

                  <ScreenerQuestionAnswersFlow />
                </Flex>
              </ScreenerQuestionAnswersContextProvider>
            )}

            {step === "screened_in" && (
              <ScreenedInScreen
                onContinue={continueToTest}
                isPanelist={isPanelist}
                showReportModal={() => setShowSkipModal(true)}
              />
            )}

            {step === "screened_out" && (
              <ScreenedOutScreen
                creditReward={screenedOutCredits}
                isPanelist={isPanelist}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <SkipUsabilityTestModal
        isOpen={showSkipModal}
        onClose={() => setShowSkipModal(false)}
        onSkip={handleSkip}
        language={language}
      />
    </TestBrandingContextProvider>
  )
}
