import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { useLanguages } from "Hooks/use-languages"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import React from "react"
import { useNavigate } from "react-router"
import { PanelEstimateResponse } from "~/api/generated/usabilityhub-components"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"
import { DemographicTargetingSummary } from "../NewOrderPage/DemographicTargetingSummary"
import { useOrderForm } from "../NewOrderPage/OrderFormProvider"
import { QuoteState } from "../NewOrderPage/types"
import { StudyType } from "./Route"

interface Props {
  demographics: DemographicAttributeGroup[]
  languageCode: string
  setLanguageCode: (languageCode: string) => void
  studyType: StudyType
  setStudyType: (studyType: StudyType) => void
  quote: PanelEstimateResponse | null
  quoteState: QuoteState
}

export const UhSummaryPanel: React.FC<React.PropsWithChildren<Props>> = ({
  demographics,
  languageCode,
  setLanguageCode,
  studyType,
  setStudyType,
  quote,
  quoteState,
}) => {
  const navigate = useNavigate()
  const languages = useLanguages()

  const {
    ageRange,
    targetLocations,
    selectedOptionIds,
    setFocusAttributeId,
    clearAgeRange,
    clearTargetLocations,
    clearAllOptionsForAttribute,
  } = useOrderForm()

  const studyTypes = {
    short_survey: "Short survey (5-10 questions)",
    long_survey: "Long survey (20-50 questions)",
    preference_test: "Preference test (4 designs)",
    figma_prototype_test: "Figma prototype test (10 screens)",
    open_card_sort: "Open card sort (20 cards)",
  }

  const activeDemographicAttributes = demographics.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          selectedOptionIds.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  return (
    <Box
      pos="sticky"
      top="10px"
      h="fit-content"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="md"
      bg="white"
    >
      <Stack spacing={0} data-intercom-target="uh-summary">
        <Heading
          as="h2"
          my={6}
          fontSize="18px"
          fontWeight="semibold"
          color="text.primary"
          mx={4}
        >
          Order summary
        </Heading>

        <StackDivider borderTopWidth="1px" borderColor="gray.200" />

        {languages.length > 0 && (
          <>
            <HStack p={4}>
              <Text
                flexShrink={0}
                as="label"
                width={32}
                textStyle="label"
                htmlFor="language_code"
                fontSize="md"
                display="inline-flex"
                alignItems="center"
              >
                Language{" "}
                <HelpPopover>
                  The language panelists need to understand to take the study.
                </HelpPopover>
              </Text>
              <Select
                required
                id="language_code"
                value={languageCode}
                onChange={(e) => {
                  setLanguageCode(e?.target?.value)
                }}
              >
                {languages.map(({ display_string, code }) => (
                  <option key={code} value={code}>
                    {display_string}
                  </option>
                ))}
              </Select>
            </HStack>

            <StackDivider borderTopWidth="1px" borderColor="gray.200" />

            <HStack p={4}>
              <Text
                flexShrink={0}
                as="label"
                width={32}
                textStyle="label"
                htmlFor="study_type"
                fontSize="md"
                display="inline-flex"
                alignItems="center"
              >
                Study type{" "}
                <HelpPopover>
                  Examples of different studies you can create. You can create
                  studies of any composition and complexity in the app.
                </HelpPopover>
              </Text>
              <Select
                required
                id="study_type"
                value={studyType}
                onChange={(e) => {
                  setStudyType(e?.target?.value as StudyType)
                }}
              >
                {Object.entries(studyTypes).map(([type, display_string]) => (
                  <option key={type} value={type}>
                    {display_string}
                  </option>
                ))}
              </Select>
            </HStack>

            <StackDivider borderTopWidth="1px" borderColor="gray.200" />
          </>
        )}

        <DemographicTargetingSummary
          showPresets={false}
          ageRange={ageRange}
          targetLocations={targetLocations}
          activeDemographicAttributes={activeDemographicAttributes}
          setFocusAttributeId={setFocusAttributeId}
          clearAgeRange={clearAgeRange}
          clearTargetLocations={clearTargetLocations}
          clearDemographicAttribute={clearAllOptionsForAttribute}
        />

        <Stack
          p={4}
          pb={2}
          color={quoteState === "loading" ? "gray.400" : "gray.700"}
        >
          {quote ? (
            <>
              <Flex
                justify="space-between"
                data-intercom-target="cost-per-response"
              >
                <Box>Cost per response</Box>
                <Text>{quote.formatted_price_per_response}</Text>
              </Flex>

              <Flex justify="space-between" data-intercom-target="total-cost">
                <Text>
                  <strong>Total cost</strong>
                </Text>
                <Text>
                  <strong>{quote.formatted_price}</strong>
                </Text>
              </Flex>

              {quote.estimated_duration ? (
                <Box pt={4} data-intercom-target="estimated-turnaround-time">
                  <Alert>
                    <AlertIcon />
                    <Flex align="center" gap={1}>
                      <Text>Estimated turnaround time:</Text>
                      {quoteState === "loading" ? (
                        <Spinner size="xs" />
                      ) : (
                        <Text>{quote.estimated_duration}</Text>
                      )}
                    </Flex>
                  </Alert>
                </Box>
              ) : (
                <Alert status="warning">
                  <AlertIcon />
                  <Text color="text.primary">
                    We can{"\u2019"}t fulfill that order from the Lyssna panel.
                  </Text>
                </Alert>
              )}
            </>
          ) : quoteState === "error" ? (
            <Alert maxH="10rem" status="error">
              <AlertIcon />
              <Text fontWeight="bold">
                There was an error calculating your quote. Please try again.
              </Text>
            </Alert>
          ) : (
            <Stack justify="center" align="center" h="192px">
              <Spinner />
            </Stack>
          )}
        </Stack>

        <Stack p={4}>
          <Box pb={2}>
            <Button
              colorScheme="brand.primary"
              w="100%"
              onClick={() => navigate(`/users/sign_up`)}
            >
              Sign Up
            </Button>
          </Box>
          <Center>Create a free account to place an order </Center>
        </Stack>
      </Stack>
    </Box>
  )
}
