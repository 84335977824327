import { Stack } from "@chakra-ui/react"
import {
  Main,
  Sidebar,
} from "Components/fixed-sidebar-layout/fixed-sidebar-layout"
import { UsabilityTest } from "Types"
import { useIsTestBuilderExternalStudy } from "UsabilityHub/hooks/useIsTestBuilderExternalStudy"
import { beforeUnloadHandler } from "Utilities/before-unload-handler"
import { preventFormSubmission } from "Utilities/keyboard-event"
import React, { useEffect } from "react"
import { Form, InjectedFormProps } from "redux-form"
import { ThankYouScreenCard } from "./TestFormCards/CustomizableScreenCards/ThankYouScreenCard/ThankYouScreenCard"
import { WelcomeScreenCard } from "./TestFormCards/CustomizableScreenCards/WelcomeScreenCard/WelcomeScreenCard"
import { ScreenerCard } from "./TestFormCards/ScreenerCard"
import { TestDetailsCard } from "./TestFormCards/TestDetailsCard/TestDetailsCard"
import { TestFormSectionsField } from "./sections/sections"
import { TestFormSidebar } from "./sidebar/TestFormSidebar"

const [_, permitUnload] = beforeUnloadHandler()

type Props = InjectedFormProps<UsabilityTest, Record<string, unknown>>

export const TestFormFields: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = React.memo(
  ({ submitting, handleSubmit }) => {
    const isExternalStudy = useIsTestBuilderExternalStudy()

    // Workaround to not prevent reloading/closing of tab when test form has been unmounted
    // In the parent component we add functionality to prevent unloading when we detect changes in the redux form
    // If the test is never submitted the "permitUnload" is never executed and would show a warning when users close or reload the tab,
    // even though they have left the test builder already.
    // To prevent that we execute the "permitUnload" function on unmount.
    useEffect(() => {
      return permitUnload
    }, [])

    return (
      <Form onSubmit={handleSubmit} onKeyDown={preventFormSubmission}>
        <Sidebar>
          <TestFormSidebar disabled={submitting} />
        </Sidebar>
        <Main>
          <Stack mb={8} spacing={8}>
            <TestDetailsCard />
            <ScreenerCard />
            {!isExternalStudy && <WelcomeScreenCard />}
            <TestFormSectionsField />
            {!isExternalStudy && <ThankYouScreenCard />}
          </Stack>
        </Main>
      </Form>
    )
  },
  (prevProps, nextProps) => {
    // Redux form inserts lots of unused and constantly changing props
    // To prevent unnecessary re-renders, ignore everything except the
    // two props we're actually using
    return (
      prevProps.submitting === nextProps.submitting &&
      prevProps.handleSubmit === nextProps.handleSubmit
    )
  }
)

TestFormFields.displayName = "TestFormFields"
