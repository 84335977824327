import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { FunctionalModal } from "Utilities/modals/types"
import { debounce } from "lodash"
import React, { useCallback } from "react"
import { useUpdateConflictCalendars } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyCalendar } from "~/api/generated/usabilityhubSchemas"
import { GoogleConflictCalendars } from "./GoogleConflictCalendars"
import { OutlookConflictCalendars } from "./OutlookConflictCalendars"

type Props = {
  initialSelectedCalendars: ModeratedStudyCalendar[]
  // If singleProvider is set, only show the calendars for that provider
  // If it's undefined, the modal will show all providers
  singleProvider?: "google" | "outlook"
  hasGoogleCalendarError: boolean
}

export const ConflictCalendarModal: FunctionalModal<Props> = ({
  isOpen,
  onClose,
  initialSelectedCalendars,
  singleProvider,
  hasGoogleCalendarError,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const user = useCurrentUser()

  const [selectedCalendars, setSelectedCalendars] = React.useState<
    ModeratedStudyCalendar[]
  >(initialSelectedCalendars)

  const { mutate: updateConflictCalendars } = useUpdateConflictCalendars({
    onError: (error) => {
      toast({
        title: "Failed to set availability calendars",
        description: error?.payload.message,
        status: "error",
      })
      reportError(error)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["api", "integrations"])
      // Not easy to know which studies might be affected, so invalidate 'em all
      await queryClient.invalidateQueries(["api", "moderated_studies"])
    },
  })

  const debouncedupdateConflictCalendars = useCallback(
    debounce(updateConflictCalendars, 1000),
    [updateConflictCalendars]
  )

  const toggleCalendar = (
    calendar: ModeratedStudyCalendar,
    enabled: boolean
  ) => {
    const newCalendars = enabled
      ? selectedCalendars.concat(calendar)
      : selectedCalendars.filter((c) => c.id !== calendar.id)

    setSelectedCalendars(newCalendars)

    debouncedupdateConflictCalendars({
      body: {
        user_id: user.id,
        conflict_calendars: newCalendars,
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomColor="gray.200" borderBottomWidth={1} px={4}>
          <Heading as="h1" variant="h1" pb={2}>
            Calendars
          </Heading>
          <Text fontSize="sm" color="text.secondary">
            Select calendars to review for conflicts:
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={4} pt={4} pb={8}>
          <Flex direction="column" gap={8}>
            {(!singleProvider || singleProvider === "google") && (
              <GoogleConflictCalendars
                toggleCalendar={toggleCalendar}
                selectedCalendars={selectedCalendars}
                hasGoogleCalendarError={hasGoogleCalendarError}
              />
            )}

            {(!singleProvider || singleProvider === "outlook") && (
              <OutlookConflictCalendars
                toggleCalendar={toggleCalendar}
                selectedCalendars={selectedCalendars}
              />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
