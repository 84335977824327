import React, { HTMLAttributes, useEffect, useRef } from "react"

import { useImage } from "Hooks/use-image"
import { clearCanvas } from "Utilities/canvas/clear-canvas"
import { ScaledCanvas } from "Utilities/canvas/scaled-canvas"
import { drawClicks } from "Utilities/click-maps/draw-clicks"
import { drawImage } from "Utilities/click-maps/draw-image"
import { drawOverlay } from "Utilities/click-maps/draw-overlay"
import { NormalizedClick } from "Utilities/get-normalized-clicks/normalized-clicks"

import { HighDPRCanvas } from "../../../../usabilityhub/components/Canvas/HighDprCanvas"

interface ClickHeatMapProps extends HTMLAttributes<HTMLCanvasElement> {
  clicks: NormalizedClick[]
  imageUrl?: string
  width: number
  height: number
}

export function ClickMap({
  clicks,
  width,
  height,
  imageUrl,
  ...props
}: ClickHeatMapProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { image } = useImage(imageUrl)

  useEffect(() => {
    const canvas = canvasRef.current

    if (!canvas) return

    clearCanvas(canvas)
    const scaledCanvas = new ScaledCanvas(canvas, window.devicePixelRatio)

    if (image) {
      drawImage(scaledCanvas, image)
      drawOverlay(scaledCanvas)
    }

    drawClicks(scaledCanvas, clicks)
  }, [image, clicks, width, height])

  return (
    <HighDPRCanvas {...props} ref={canvasRef} width={width} height={height} />
  )
}
