import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React, { useRef } from "react"
import { useMatch, useNavigate } from "react-router"

import { useDeleteProject } from "~/api/generated/usabilityhub-components"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

interface Props {
  project: NewProject
  onClose: () => void
}

export const DeleteProjectModal: React.FC<Props> = ({ project, onClose }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null)
  const onIndividualProjectPage = useMatch("/projects/:id") !== null
  const toast = useToast()
  const { mutateAsync: deleteMutation, isLoading } = useDeleteProject({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "projects"])
      void queryClient.invalidateQueries(["api", "usability_tests"])
      void queryClient.invalidateQueries(["api", "account", "total_credits"])
    },
  })

  const handleDelete = async () => {
    try {
      const data = await deleteMutation({
        pathParams: { id: project.id.toString() },
      })
      const totalRefundPoints = data.refund_point_count

      let message = `Deleted project "${project.name}"`

      if (totalRefundPoints > 0) {
        message += ` and refunded ${totalRefundPoints} unused credits`
      }

      toast({
        title: `Project deleted`,
        description: message,
        status: "success",
        duration: 3000,
      })

      // If we're on the page for the project we just deleted, redirect to the projects list
      if (onIndividualProjectPage) {
        navigate("/projects")
      }

      onClose()
    } catch (error) {
      toast({
        title: "Project delete failed",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <AlertDialog
      isOpen
      leastDestructiveRef={cancelButtonRef}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            Are you sure you want to delete this project?
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody>
            All studies contained in the project <strong>{project.name}</strong>{" "}
            will be archived. If you want to permanently delete these tests you
            can do so after completing this action.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelButtonRef}
              variant="outline"
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isLoading={isLoading}
              onClick={handleDelete}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
