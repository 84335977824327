import { get } from "lodash"
import { arraySplice, change } from "redux-form"

import { State } from "Redux/app-store"
import { showErrorMessage } from "Redux/reducers/flash"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { isBadRequestError } from "Services/axios"
import {
  duplicateCardImage,
  uploadCardImageFile,
} from "Services/card-sort-cards-image"
import { fileToScreenshot } from "~/application/javascripts/services/screenshot"
import { ValidateFilesResult } from "~/application/javascripts/services/validate-files"
import {
  AsyncThunkAction,
  CardSortCard,
  CardSortCardsImageData,
} from "~/application/javascripts/types"
import { reportError } from "~/application/javascripts/utilities/error"

export const addCardSortCardsImage =
  (
    sectionIndex: number,
    cardIndex: number,
    validatedFiles: Readonly<ValidateFilesResult>
  ): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const { errors, validFiles, extraFiles } = validatedFiles

    // Show Errors, of invalid files
    errors.forEach((error) => {
      dispatch(showErrorMessage(error))
    })

    // If there aren't any files
    if (validFiles.length === 0) return

    // Show warning if there are to many files
    if (extraFiles.length > 0) {
      dispatch(
        showErrorMessage(
          `Oops — too many images chosen! I skipped ${extraFiles.length} of those.`
        )
      )
    }

    // Show 'Uploading' at the start & add additional cards if neccessary
    const values = getFormValues(getState())
    const sectionCardSortCardsName = `sections[${sectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const sectionCardSortCards: CardSortCard[] = get(
      values,
      `${sectionCardSortCardsName}`,
      []
    )
    const cardImageIndexes = []
    let cardOffset = 0
    for (const [i] of validFiles.entries()) {
      let calculatedIndex = cardIndex + i + cardOffset
      // Skip all delete-pending cards
      while (
        sectionCardSortCards[calculatedIndex] &&
        sectionCardSortCards[calculatedIndex]._destroy
      ) {
        // Add 1 to cardOffset and calculatedIndex separately
        cardOffset++
        calculatedIndex++
      }
      if (sectionCardSortCards[calculatedIndex]) {
        sectionCardSortCards[calculatedIndex].is_processing = true
      } else {
        // If the card doesn't exist (e.g. if you've dragged 3 images and there's only 1 or 2 cards)
        sectionCardSortCards[calculatedIndex] = {
          label: "",
          is_processing: true,
        }
      }
      cardImageIndexes[i] = calculatedIndex
    }
    dispatch(
      change(getFormName(), sectionCardSortCardsName, sectionCardSortCards)
    )

    // Process screenshots
    const screenshots = await Promise.all(validFiles.map(fileToScreenshot))

    for (const [screenshotIndex, screenshot] of screenshots.entries()) {
      const calculatedIndex = cardImageIndexes[screenshotIndex]

      const value = get(
        values,
        `${sectionCardSortCardsName}[${calculatedIndex}]`,
        null
      ) as CardSortCard | null

      // Update the card sort card image
      try {
        // Start uploading the image
        const upload_result = await uploadCardImageFile(screenshot._file!)

        const image_data = {
          image_data: JSON.stringify(upload_result.image_data),
          uploaded_image_url: upload_result.url,
        }

        // Update the card sort cards with image_data
        dispatch(
          arraySplice(
            getFormName(),
            sectionCardSortCardsName,
            calculatedIndex,
            1,
            {
              ...value,
              ...image_data,
              is_processing: false,
            }
          )
        )
      } catch (error) {
        // show warning if failed to upload
        if (isBadRequestError(error)) {
          dispatch(showErrorMessage(error.response.data.message))
        } else {
          // This is an unexpected error.
          reportError(error)
          dispatch(showErrorMessage(error.message))
        }
      }
    }
  }

export const duplicateCardSortCardsImages =
  (newSectionIndex: number): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const values = getFormValues(getState())

    // Get the new section
    const newSectionCardSortCardsName = `sections[${newSectionIndex}].card_sort_attributes.card_sort_cards_attributes`
    const newSectionCardSortCards: CardSortCard[] = get(
      values,
      `${newSectionCardSortCardsName}`,
      []
    )

    // Show 'Uploading' view for all images in the new section
    if (newSectionCardSortCards) {
      dispatch(
        change(
          getFormName(),
          newSectionCardSortCardsName,
          newSectionCardSortCards.map((card) => ({
            ...card,
            is_processing: !!card.image_data,
          }))
        )
      )
    }

    // Duplicating images for the new section
    for (const [index, card] of newSectionCardSortCards.entries()) {
      const value = get(
        values,
        `${newSectionCardSortCardsName}[${index}]`,
        null
      ) as CardSortCard | null
      if (card.image_data) {
        const original_image_data = card.image_data as CardSortCardsImageData
        if (typeof original_image_data === "string") {
          // No need to reupload the image if it's a cached image
          dispatch(
            arraySplice(getFormName(), newSectionCardSortCardsName, index, 1, {
              ...value,
              is_processing: false,
            })
          )
          continue
        }

        // Upload the card sort card image
        try {
          // Start uploading the image
          const upload_result = await duplicateCardImage(original_image_data)

          const image_data = {
            image_data: JSON.stringify(upload_result.image_data),
            uploaded_image_url: upload_result.url,
          }

          // Update the card sort cards with image_data
          dispatch(
            arraySplice(getFormName(), newSectionCardSortCardsName, index, 1, {
              ...value,
              ...image_data,
              is_processing: false,
            })
          )
        } catch (error) {
          // show warning if failed to upload
          if (isBadRequestError(error)) {
            dispatch(showErrorMessage(error.response.data.message))
          } else {
            // This is an unexpected error.
            reportError(error)
            dispatch(showErrorMessage(error.message))
          }
        }
      }
    }
  }
