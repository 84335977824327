import { Flex, ListItem, OrderedList, Spinner } from "@chakra-ui/react"
import { CreateNewModeratedStudyModal } from "UsabilityHub/components/CreateNewModeratedStudyModal/CreateNewModeratedStudyModal"
import { ModeratedStudyEmptyState } from "UsabilityHub/components/ModeratedStudyEmptyState/ModeratedStudyEmptyState"
import { useModal } from "Utilities/modals/use-modal"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  useListModeratedStudies,
  useTrackEvent,
} from "~/api/generated/usabilityhub-components"

import { ROUTES } from "UsabilityHub/views/routes"
import ModeratedStudyCard from "./ModeratedStudyCard/ModeratedStudyCard"

export function ModeratedStudyList() {
  const navigate = useNavigate()
  const { data, isLoading } = useListModeratedStudies({})

  const {
    open: openCreateNewModeratedStudyModal,
    close: closeCreateNewModeratedStudyModal,
  } = useModal(CreateNewModeratedStudyModal)

  const openCreateModal = () => {
    openCreateNewModeratedStudyModal({
      onCreate: (id) => {
        navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: id }))
        closeCreateNewModeratedStudyModal()
      },
    })
  }

  const { mutate: trackEvent } = useTrackEvent()

  useEffect(() => {
    if (data) {
      trackEvent({
        body: {
          event: "pageview_interviews",
          event_properties: { number_of_moderated_studies: data.length },
        },
      })
    }
  }, [data])

  if (isLoading) {
    return (
      <Flex
        mt="32"
        justifyContent="center"
        alignItems="center"
        direction="column"
        gap="4"
      >
        <Spinner size="lg" thickness="4px" color="gray.500" />
        Fetching...
      </Flex>
    )
  }

  if (!data || data.length === 0) {
    return <ModeratedStudyEmptyState handleCreateStudy={openCreateModal} />
  }

  return (
    <OrderedList styleType="none" m="0" spacing={4}>
      {data.map((moderatedStudyListEntry) => (
        <ListItem key={moderatedStudyListEntry.id}>
          <ModeratedStudyCard study={moderatedStudyListEntry} />
        </ListItem>
      ))}
    </OrderedList>
  )
}
