// JsFromRoutes CacheKey f3365e689d23e41a2639123808a6a402
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  billingHistory: /* #__PURE__ */ definePathHelper('get', '/api/settings/billing_history'),
  requestUnarchive: /* #__PURE__ */ definePathHelper('post', '/api/settings/request_unarchive'),
  unarchiveRequested: /* #__PURE__ */ definePathHelper('get', '/api/settings/unarchive_requested'),
  teamMemberUnarchiveRequests: /* #__PURE__ */ definePathHelper('get', '/api/settings/team_member_unarchive_requests'),
}
