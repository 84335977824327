import { useEffect, useMemo, useState } from "react"

import { fetchImage } from "Utilities/fetch-image"

export function useImage(url: string | undefined) {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(true)
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined)

  useEffect(() => {
    if (!url) return

    setIsLoading(true)
    void fetchImage(url)
      .then((image) => {
        setImage(image)
        setIsLoading(false)
        setError(false)
      })
      .catch(() => setError(true))
  }, [url])

  return useMemo(
    () => ({
      isLoading,
      error,
      image,
    }),
    [isLoading, error, image]
  )
}
