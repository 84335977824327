import React, { TextareaHTMLAttributes } from "react"
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form"

import { Textarea, TextareaProps } from "Components/form/textarea"

const TextareaAdapter: React.FC<
  React.PropsWithChildren<WrappedFieldProps & TextareaProps>
> = ({ input, onPaste, ...rest }) => (
  <Textarea {...input} {...rest} onPaste={onPaste} />
)

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> &
  BaseFieldProps<HTMLTextAreaElement> &
  TextareaProps

export const TextareaField: React.FC<React.PropsWithChildren<Props>> = ({
  warn,
  ...props
}) => (
  <Field
    component={TextareaAdapter}
    name={props.name}
    props={props}
    warn={warn}
  />
)
