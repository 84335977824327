import { Flex, Stack, Text } from "@chakra-ui/react"
import { ProTrialCallout } from "Components/trial-widget/ProTrialCallout"
import Constants from "Constants/shared.json"
import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { StaticPlanDrawer } from "UsabilityHub/components/PlanChanger/plan-drawer"
import {
  useCurrentAccount,
  useCurrentPlan,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import { usePlan } from "UsabilityHub/hooks/usePlans"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import { ROUTES } from "UsabilityHub/views/routes"
import { useHasPaidSeatAvailable } from "Utilities/account"
import { getParsedQueryString } from "Utilities/query-string"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { InviteUserForm } from "./InviteUserForm"
import { PlanStatesAlert, PlanStatesAllowedUniqueIds } from "./PlanStatesAlert"
import { TeamMemberUnarchiveRequests } from "./TeamMemberUnarchiveRequests"
import { UsersList } from "./UsersList"

export const TeamRoute: React.FC = () => {
  const account = useCurrentAccount()
  const currentUser = useCurrentUser()
  const plan = useCurrentPlan()
  const intendedPlan = usePlan(account.intended_plan_unique_id ?? "")
  const freePlan = usePlan(Constants.FREE_PLAN_UNIQUE_ID)

  const accountHasPaidSeatAvailable = useHasPaidSeatAvailable(account)
  const currentUserCanManageTeam = currentUser.can_manage_team

  const allTeamMembers = useTeamMembers({ ignoreRoles: [] })
  const invitedTeamMembers = allTeamMembers.filter((u) => u.sign_in_count === 0)
  const activeTeamMembers = allTeamMembers.filter((u) => u.sign_in_count > 0)
  const activePaidTeamMembers = activeTeamMembers.filter(
    (u) => u.role !== "archived" && u.role !== "guest"
  )
  const activeViewers = activeTeamMembers.filter((u) => u.role === "guest")
  const archivedTeamMembers = activeTeamMembers.filter(
    (u) => u.role === "archived"
  )

  const paidUserCount = allTeamMembers.filter(
    (u) => u.role !== "archived" && u.role !== "guest"
  ).length

  const [jumpToUserId, setJumpToUserId] = useState<number | null>(null)

  const isOnboarding = "onboarding" in getParsedQueryString()
  // Fall back to free plan if intended plan is not found.
  // This can occur if an account's intended plan has been discontinued.

  const showProTrialWidget = false

  if (!freePlan) return null // Plans are not loaded yet

  return (
    <>
      <Helmet>
        <title>Team settings</title>
      </Helmet>
      {isOnboarding && (
        <StaticPlanDrawer
          source="setup_team"
          initialState={{
            screen: "payment-details",
            selectedPlan:
              intendedPlan && !intendedPlan.discontinued
                ? intendedPlan
                : freePlan,
          }}
        />
      )}
      {archivedTeamMembers.length > 0 && (
        <TeamMemberUnarchiveRequests
          setJumpToUserId={(id: number) => {
            setJumpToUserId(id)
            // Clear the value so we can jump to the same user multiple times
            setTimeout(() => setJumpToUserId(null), 1000)
          }}
        />
      )}
      <Card>
        <CardHeader>
          <Flex justify="space-between" align="baseline">
            {currentUserCanManageTeam ? "Manage your team" : "Your team"}
            {plan &&
              !PlanStatesAllowedUniqueIds.includes(account.plan_unique_id!) && (
                <Text fontSize="md">
                  You{"\u2019"}re on the{" "}
                  <RoutedLink to={ROUTES.SETTINGS.BILLING.path}>
                    {plan.name} plan
                  </RoutedLink>
                </Text>
              )}
          </Flex>
        </CardHeader>
        <CardBody>
          {!showProTrialWidget &&
            plan &&
            PlanStatesAllowedUniqueIds.includes(account.plan_unique_id!) && (
              <PlanStatesAlert
                currentAccount={account}
                currentPlan={plan}
                paidUserCount={paidUserCount}
              />
            )}
          {plan && currentUserCanManageTeam && (
            <InviteUserForm
              currentPlan={plan}
              hasPaidSeatAvailable={accountHasPaidSeatAvailable}
            />
          )}
          {showProTrialWidget && (
            <ProTrialCallout
              title={"Invite your team to get the most out of your free trial"}
              content={`During your ${plan!.name} trial you can invite up to
                ${plan!.collaborator_seat_limit} team members to collaborate with
                you, plus unlimited viewers. When your ${plan!.name} trial ends,
                you'll have a maximum of ${freePlan.included_collaborator_seats}
                collaborators on the Free plan and no viewers, or you can get more by
                upgrading to a paid plan.`}
            />
          )}
          <Stack spacing={10}>
            {invitedTeamMembers.length > 0 && (
              <UsersList
                currentUserCanManageTeam={currentUserCanManageTeam}
                hasPaidSeatAvailable={accountHasPaidSeatAvailable}
                listTitle="Invited"
                users={invitedTeamMembers}
              />
            )}
            {activePaidTeamMembers.length > 0 && (
              <UsersList
                currentUserCanManageTeam={currentUserCanManageTeam}
                hasPaidSeatAvailable={accountHasPaidSeatAvailable}
                listTitle="Collaborators"
                users={activePaidTeamMembers}
              />
            )}
            {activeViewers.length > 0 && (
              <UsersList
                currentUserCanManageTeam={currentUserCanManageTeam}
                hasPaidSeatAvailable={accountHasPaidSeatAvailable}
                listTitle="Viewers"
                users={activeViewers}
              />
            )}
            {archivedTeamMembers.length > 0 && (
              <UsersList
                currentUserCanManageTeam={currentUserCanManageTeam}
                hasPaidSeatAvailable={accountHasPaidSeatAvailable}
                listTitle="Archived"
                users={archivedTeamMembers}
                jumpToUserId={jumpToUserId}
              />
            )}
          </Stack>
        </CardBody>
      </Card>
    </>
  )
}
