import { getEnvState } from "../state/index"

// Ortto Lyssna capture code
export function initializeOrtto(trackingCode, apiUrl, cdnUrl, email) {
  const { APP_OLD_DOMAIN, APP_DOMAIN } = getEnvState()

  // Slightly hacky way to grab the domains without needing to redefine
  // env var just to remove "app" from the front. This allows all subdomains
  // we use Ortto on to be tracked, including the marketing site
  const cookieDomains = [APP_OLD_DOMAIN, APP_DOMAIN].map((domain) => {
    if (domain.startsWith("app")) return domain.slice(3)
  })

  window.ap3c = window.ap3c || {}
  var ap3c = window.ap3c
  ap3c.cmd = ap3c.cmd || []
  ap3c.cmd.push(function () {
    ap3c.init(trackingCode, apiUrl, {
      cookieDomains: cookieDomains,
    })
    updateOrttoTrackingUser(email)
  })
  ap3c.activity = function (act) {
    ap3c.act = ap3c.act || []
    ap3c.act.push(act)
  }
  var s, t
  s = document.createElement("script")
  s.type = "text/javascript"
  s.src = cdnUrl
  t = document.getElementsByTagName("script")[0]
  t.parentNode.insertBefore(s, t)

  return ap3c
}

// Separated from above as in some circumstances (eg. sign up)
// we want the page to immediately recognise the user has been
// updated
export function updateOrttoTrackingUser(email) {
  if (window.ap3c) {
    var ap3c = window.ap3c
    if (email) {
      ap3c.track({ v: 0, email: email })
    } else {
      ap3c.track({ v: 0 })
    }
  }
}
