import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Flex,
  Grid,
  HStack,
  Icon,
  IconButton,
  ResponsiveValue,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import {
  PanelOrderCompositeIcon,
  RecruitmentLinkCompositeIcon,
} from "Shared/components/CompositeIcons/CompositeIcons"
import { AlertTriangleSolidIcon } from "Shared/icons/untitled-ui/AlertTriangleSolidIcon"
import { TruncatableText } from "UsabilityHub/components/TruncatableText/TruncatableText"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useTeamMembers as useTeamMembersHook } from "UsabilityHub/hooks/useTeamMembers"
import { useParticipantInfoDrawerContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ParticipantInfoDrawer/ParticipantInfoDrawer"
import { fullWithoutTime, getTimeString } from "Utilities/date-formats"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useUpdateModeratedStudyBooking } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import ParticipantBookingStateBadge from "../ParticipantBookingStateBadge"
import { Rating } from "../ParticipantRatingForm"
import { BookingContextMenu } from "./BookingContextMenu"
import { HostAvatars } from "./HostAvatars"
import HostEditor from "./HostEditor"
import { LocationEditor } from "./LocationEditor"
import { SessionRecording } from "./SessionRecording"
import { BookingSchema } from "./formSchema"
import { formatInterviewParticipantName } from "./formatInterviewParticipantName"
import { Booking } from "./types"
import useBookingActions from "./useBookingActions"

const useInvalidateBookingsQuery = (moderatedStudyId: string) => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  return useCallback(() => {
    return queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/bookings",
        operationId: "getModeratedStudyBookings",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })
    )
  }, [queryClient, queryKeyFn, moderatedStudyId])
}

const CompleteStates = ["complete", "auto_complete"]
type CompleteStates = typeof CompleteStates
type CompleteState = CompleteStates[number]

/** Determines if a booking can have recordings visible to customers */
export function isRecordingVisible(booking: Booking): boolean {
  return (
    booking.state === "booked" ||
    booking.state === "complete" ||
    booking.state === "auto_complete" ||
    booking.state === "reported" ||
    booking.recordings.length > 0
  )
}

export function isComplete(value: string): value is CompleteState {
  return CompleteStates.includes(value as CompleteState)
}

export function bookingParticipantName(booking: Booking): string {
  if (booking.deleted_participant) {
    return "Deleted Participant"
  } else {
    return formatInterviewParticipantName(booking)
  }
}

export type ResolveMarkedBooking =
  | {
      newState: "complete"
      confirm: boolean
      markAsPaid?: boolean
      rating?: Rating
      comment?: string
    }
  | {
      newState: "no_show"
      confirm: boolean
    }
  | {
      newState: "reported"
      confirm: boolean
      reportReason: string
      reportLink: string
    }

export type MarkBookingFunc = <
  T extends ResolveMarkedBooking["newState"],
>(options: {
  booking: Booking
  as: T
}) => Promise<Extract<ResolveMarkedBooking, { newState: T }>>

export type BookingCardProps = {
  moderatedStudyId: string
  booking: Booking
  showMissingLocationWarning: boolean
  onMarkBooking: MarkBookingFunc
  handleZoomOAuth: () => void
  handleMicrosoftOAuth: () => void
  useUpdate?: typeof useUpdateModeratedStudyBooking
  useTeamMembers?: typeof useTeamMembersHook
  useInvalidateBookings?: typeof useInvalidateBookingsQuery
}

type GridDefinition = ResponsiveValue<string>

const grid: GridDefinition = {
  base: `
    "time toggle" auto
    "name-and-status name-and-status" auto
    "actions actions" auto / 1fr auto
  `,
  md: `
    "time name-and-status actions toggle" auto / 10rem 1fr auto auto
  `,
} as const

const innerGrid: GridDefinition = {
  base: `
    "icon name" auto
    "null status" auto / auto minmax(0, 1fr)
  `,
  lg: `
    "icon name status" auto / auto minmax(0, 1fr) auto
  `,
}

export const BookingCard: React.FC<BookingCardProps> = ({
  moderatedStudyId,
  booking,
  showMissingLocationWarning,
  onMarkBooking,
  handleZoomOAuth,
  handleMicrosoftOAuth,
  useUpdate = useUpdateModeratedStudyBooking,
  useTeamMembers = useTeamMembersHook,
  useInvalidateBookings = useInvalidateBookingsQuery,
}) => {
  const { invalidateStudySummaryQuery, moderatedStudySummary } =
    useModeratedStudyContext()

  const teamMembers = useTeamMembers()

  const { openParticipantInfoDrawer } = useParticipantInfoDrawerContext()

  const bookingForm = useForm({
    resolver: yupResolver(BookingSchema),
    defaultValues: {
      incentive: booking.incentive ?? "",
      location: booking.location,
      location_type: booking.location_type,
      auto_upload_recordings: booking.auto_upload_recordings,
      hosts: booking.host_ids.map((id) => ({ userId: id })),
    },
    mode: "all",
  })

  const {
    watch,
    control,
    reset,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
  } = bookingForm

  const autoUploadRecordings = watch("auto_upload_recordings")
  const toggleAutoUploadRecordings = () => {
    setValue("auto_upload_recordings", !autoUploadRecordings)
    handleSave()
  }

  // Reset the form any time we get updated host data from the server
  // This makes sure the <HostEditor /> doesn't get out of sync with remote changes.
  const hostIdString = booking.host_ids.join(",")
  useEffect(() => {
    reset({
      incentive: booking.incentive ?? "",
      location: booking.location,
      location_type: booking.location_type,
      auto_upload_recordings: booking.auto_upload_recordings,
      hosts: hostIdString.split(",").map((id) => ({ userId: parseInt(id) })),
    })
  }, [hostIdString])

  useEffect(() => {
    trigger().catch(() => void 0)
  }, [trigger])

  const bookingActions = useBookingActions({
    moderatedStudyId,
    booking,
    useUpdate,
    useInvalidateBookings,
    invalidateStudySummaryQuery,
    hasAvailableBookingSlots:
      !!moderatedStudySummary?.has_available_booking_slots,
  })

  const handleSave = handleSubmit(async (data) => {
    await bookingActions.mutateAsync({
      body: {
        location: data.location,
        location_type: data.location_type,
        auto_upload_recordings:
          data.location_type === "zoom" && data.auto_upload_recordings,
        hosts: data.hosts.map((host) => host.userId),
      },
    })
    // Set current state of the form as the new baseline
    reset(data)
  })

  const currentUserCanEdit = useCurrentUser().can_manage_tests

  const showActionMenu =
    currentUserCanEdit &&
    (booking.state === "booked" ||
      (isComplete(booking.state) &&
        booking.incentive &&
        !booking.incentive_paid))

  const isCanceled = String(booking.state).startsWith("canceled")
  const strikeOut =
    isCanceled ||
    booking.state === "rescheduled_by_researcher" ||
    booking.state === "reported"

  const isSelfRecruitedBooking = !booking.is_panelist

  return (
    <AccordionItem
      borderWidth={1}
      borderColor="gray.200"
      rounded="lg"
      gridColumn="2"
      h="fit-content"
    >
      <Flex align="center">
        {/* 15px because it has 1px border and needs to match the height of the date card */}
        <AccordionButton as="div" p="15px" minH="62px" cursor="pointer">
          <Grid
            w="full"
            sx={{ grid }}
            gap={4}
            fontSize="md"
            fontWeight="normal"
            alignItems="center"
          >
            <Text
              gridArea="time"
              color="text.primary"
              fontWeight="bold"
              align="left"
              textDecoration={strikeOut ? "line-through" : undefined}
            >
              {getTimeString(new Date(booking.starts_at))} –{" "}
              {getTimeString(new Date(booking.ends_at))}
            </Text>

            <Grid gridArea="name-and-status" gap={2} sx={{ grid: innerGrid }}>
              <IconButton
                aria-label="View participant information"
                icon={<Avatar boxSize={5} />}
                variant="outline"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation()
                  openParticipantInfoDrawer(
                    booking.moderated_study_application_id
                  )
                }}
              />
              <HStack gap={2} gridArea="name" pe={2}>
                <Text
                  color={
                    booking.deleted_participant
                      ? "text.secondary"
                      : "text.primary"
                  }
                  fontWeight={booking.deleted_participant ? "normal" : "medium"}
                  fontStyle={booking.deleted_participant ? "italic" : undefined}
                  align="left"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  textDecoration={strikeOut ? "line-through" : undefined}
                >
                  {bookingParticipantName(booking)}
                </Text>
                <Tooltip
                  label={
                    booking.is_panelist
                      ? "Recruited from panel"
                      : "Recruited via link"
                  }
                >
                  {booking.is_panelist ? (
                    <PanelOrderCompositeIcon size={5} isRounded />
                  ) : (
                    <RecruitmentLinkCompositeIcon size={5} isRounded />
                  )}
                </Tooltip>
                {showMissingLocationWarning &&
                  (!watch("location") || errors["location"]) && (
                    <Tooltip label="No meeting link">
                      <Icon
                        as={AlertTriangleSolidIcon}
                        color="red.600"
                        aria-label="Attention"
                        style={{ marginLeft: 0 }}
                      />
                    </Tooltip>
                  )}
              </HStack>
              <HStack gridArea="status">
                {booking.state !== "reported" && booking.incentive_paid && (
                  <ParticipantBookingStateBadge status="incentive_paid" />
                )}
                {isComplete(booking.state) && (
                  <>
                    {booking.incentive && !booking.incentive_paid && (
                      <ParticipantBookingStateBadge status="incentive_not_paid" />
                    )}
                    <ParticipantBookingStateBadge status="complete" />
                  </>
                )}
                {booking.report_info?.state === "resolved_added_slot" &&
                  booking.state === "reported" && (
                    <ParticipantBookingStateBadge status="resolved_added_slot" />
                  )}
                {booking.report_info?.state === "resolved_refunded_slot" &&
                  booking.state === "reported" && (
                    <ParticipantBookingStateBadge status="resolved_refunded_slot" />
                  )}
                {booking.state === "reported" && (
                  <ParticipantBookingStateBadge status="reported" />
                )}
                {booking.state === "no_show" && (
                  <ParticipantBookingStateBadge status="no_show" />
                )}
                {booking.state === "rescheduled_by_researcher" &&
                  !booking.declined_reschedule_request && (
                    <ParticipantBookingStateBadge status="reschedule_requested" />
                  )}
              </HStack>
            </Grid>

            <HStack gridArea="actions" gap={2}>
              <HostAvatars booking={booking} />
              {showActionMenu && (
                <BookingContextMenu
                  booking={booking}
                  bookingActions={bookingActions}
                  onMarkBooking={onMarkBooking}
                />
              )}
            </HStack>

            <AccordionIcon gridArea="toggle" />
          </Grid>
        </AccordionButton>
      </Flex>

      <AccordionPanel p={5} cursor="default">
        <Grid
          templateColumns="160px minmax(0, 1fr)"
          gap={4}
          fontSize="md"
          fontWeight="normal"
        >
          {isSelfRecruitedBooking && booking.full_name !== null && (
            <>
              <Text color="text.secondary">Full name</Text>
              <Text color="text.primary">{booking.full_name}</Text>
            </>
          )}
          {isSelfRecruitedBooking && booking.preferred_name !== null && (
            <>
              <Text color="text.secondary">Preferred name</Text>
              <Text color="text.primary">{booking.preferred_name}</Text>
            </>
          )}
          {booking.is_panelist && (
            <>
              <Text color="text.secondary">Name</Text>
              <Text color="text.primary">{booking.preferred_name}</Text>
            </>
          )}
          {isSelfRecruitedBooking && booking.email !== null && (
            <>
              <Text color="text.secondary">Email</Text>
              <Text color="text.primary">{booking.email}</Text>
            </>
          )}
          <Text color="text.secondary">Location</Text>

          <LocationEditor
            booking={booking}
            bookingForm={bookingForm}
            handleSave={handleSave}
            handleZoomOAuth={handleZoomOAuth}
            handleMicrosoftOAuth={handleMicrosoftOAuth}
            isReadOnly={!currentUserCanEdit}
          />

          {booking.incentive && (
            <>
              <Text color="text.secondary">Incentive</Text>
              <Text color="text.primary">{booking.incentive}</Text>
            </>
          )}

          <Text color="text.secondary">Host</Text>
          <HostEditor
            control={control}
            handleSave={handleSave}
            teamMembers={teamMembers}
            isReadOnly={!currentUserCanEdit}
          />

          {isRecordingVisible(booking) && (
            <>
              <Text color="text.secondary">Session recording</Text>

              <SessionRecording
                moderatedStudyId={moderatedStudyId}
                booking={booking}
                autoUploadRecordings={autoUploadRecordings ?? false}
                toggleAutoUploadRecordings={toggleAutoUploadRecordings}
                isReadOnly={!currentUserCanEdit}
              />
            </>
          )}
        </Grid>

        {isCanceled && <CancelationInfo booking={booking} />}
        {booking.state === "reported" && <ReportInfo booking={booking} />}
      </AccordionPanel>
    </AccordionItem>
  )
}

const CancelationInfo: React.FC<{ booking: Booking }> = ({ booking }) => {
  if (!booking.cancelation_info) return null

  return (
    <Alert status="info" mt={4}>
      <AlertIcon alignSelf="flex-start" />

      <AlertDescription>
        <Text>
          <strong>{booking.cancelation_info.canceler_name}</strong> canceled
          this session on{" "}
          {fullWithoutTime(booking.cancelation_info.canceled_at)}
          {booking.cancelation_info.reason ? ", with the note:" : "."}
        </Text>

        {booking.cancelation_info.reason && (
          <TruncatableText
            text={"‟" + booking.cancelation_info.reason.trimEnd() + "”"}
            fontStyle="italic"
            mt={6}
          />
        )}

        {booking.is_panelist && (
          <Text mt={booking.cancelation_info.reason ? 6 : 0}>
            {booking.cancelation_info.canceled_with_short_notice
              ? "As the session was canceled within 12 hours of the session start time, the slot was not refunded and the participant incentive has been paid."
              : "Lyssna automatically replaces this slot with a new participant."}
          </Text>
        )}
      </AlertDescription>
    </Alert>
  )
}

const ReportInfo: React.FC<{ booking: Booking }> = ({ booking }) => {
  if (!booking.report_info) return null

  return (
    <>
      <Alert status="info" mt={4}>
        <AlertIcon alignSelf="flex-start" />

        <AlertDescription>
          <Text>
            <strong>{booking.report_info.reporter_name}</strong> reported this
            session on {fullWithoutTime(booking.report_info.reported_at)} with
            the note:
          </Text>

          {booking.report_info.reason && (
            <TruncatableText
              text={"‟" + booking.report_info.reason.trimEnd() + "”"}
              fontStyle="italic"
              my={6}
            />
          )}

          <Text>Lyssna will review within 1-2 business days.</Text>
        </AlertDescription>
      </Alert>
      {booking.report_info.state === "resolved_added_slot" && (
        <Alert status="success" mt={4}>
          <AlertIcon alignSelf="flex-start" />
          <AlertDescription>
            <Text>Resolved: Session replaced with new panelist.</Text>
          </AlertDescription>
        </Alert>
      )}
      {booking.report_info.state === "resolved_refunded_slot" && (
        <Alert status="success" mt={4}>
          <AlertIcon alignSelf="flex-start" />
          <AlertDescription>
            <Text>Resolved: Session refunded to account.</Text>
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}
