import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react"
import { IconButton } from "DesignSystem/components"
import { DotsVerticalIcon } from "Shared/icons/untitled-ui/DotsVerticalIcon"
import { ArchiveModeratedStudyModal } from "UsabilityHub/views/ModeratedStudy/interviewer/ArchiveModeratedStudyModal"
import { DuplicateModeratedStudyModal } from "UsabilityHub/views/ModeratedStudy/interviewer/DuplicateModeratedStudyModal"
import { ROUTES } from "UsabilityHub/views/routes"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { useUnarchiveModeratedStudy } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyListEntry } from "~/api/generated/usabilityhubSchemas"

type ContextMenuProps = {
  study: ModeratedStudyListEntry
  onRename: () => void
  linkTo: (path: string) => string
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  study,
  onRename,
  linkTo,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { open: openDuplicateModal } = useModal(DuplicateModeratedStudyModal)
  const { open: openArchiveModal } = useModal(ArchiveModeratedStudyModal)

  const { mutate: unarchiveMutation } = useUnarchiveModeratedStudy({
    onSuccess: () => {
      toast({
        title: "Study unarchived",
        status: "success",
      })

      navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: study.id }))
    },
    onError: () => {
      toast({
        title: "Error unarchiving study",
        status: "error",
      })
    },
  })

  const onDuplicate = () => {
    openDuplicateModal({
      moderatedStudyId: study.id,
      studyName: study.internal_name,
    })
  }

  const onArchive = () => {
    openArchiveModal({
      moderatedStudyId: study.id,
      studyName: study.internal_name,
    })
  }

  const onUnarchive = () => {
    unarchiveMutation({
      pathParams: {
        moderatedStudyId: study.id,
      },
    })
  }

  const isArchived = study.archived_at !== null

  const canRename = !isArchived
  const canDuplicate = true
  const canEdit = !isArchived
  const canArchive = !isArchived
  const canUnarchive = isArchived

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<DotsVerticalIcon boxSize={4} color="ds.icon.subtle" />}
        variant="subtle"
        data-hover-only={{ base: "false", lg: "true" }}
      />
      <MenuList overflow="hidden">
        {canRename && <MenuItem onClick={onRename}>Rename</MenuItem>}
        {canDuplicate && <MenuItem onClick={onDuplicate}>Duplicate</MenuItem>}
        {canEdit && (
          <MenuItem as={Link} to={linkTo("edit")}>
            Edit
          </MenuItem>
        )}
        {canArchive && <MenuItem onClick={onArchive}>Archive</MenuItem>}
        {canUnarchive && <MenuItem onClick={onUnarchive}>Unarchive</MenuItem>}
      </MenuList>
    </Menu>
  )
}

export default ContextMenu
