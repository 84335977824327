import { Box, Flex, Link, Stack, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { GranularLocationTags } from "UsabilityHub/components/GranularLocationTags/GranularLocationTags"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import React from "react"
import {
  BasicDemographicAttribute,
  ThirdPartyOrderValidationErrors,
} from "~/application/javascripts/types"
import { SaveAsPresetButton } from "./DemographicPresets/SaveAsPresetButton"
import { useOrderForm } from "./OrderFormProvider"
import { ValidationError } from "./ValidationError"
import { DEFAULT_AGE_RANGE } from "./constants"
import { TargetLocation } from "./types"

const FlexShowOnParentHover = styled(Flex)`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .demo-section:hover & {
    opacity: 1;
  }
`

const toSentence = (arr: string[]) => {
  return arr
    .reduce(
      (prev, curr, i) => prev + curr + (i === arr.length - 2 ? " and " : ", "),
      ""
    )
    .slice(0, -2)
}

interface Props {
  showPresets: boolean
  activeDemographicAttributes: BasicDemographicAttribute[]
  ageRange: [number, number]
  targetLocations: TargetLocation[]
  validationErrors?: ThirdPartyOrderValidationErrors
  setFocusAttributeId?: (id: number) => void
  clearAgeRange?: () => void
  clearTargetLocations?: () => void
  clearDemographicAttribute?: (demographicAttributeId: number) => void
}

export const DemographicTargetingSummary: React.FC<
  React.PropsWithChildren<Props>
> = ({
  showPresets,
  activeDemographicAttributes,
  ageRange,
  targetLocations,
  validationErrors = {},
  setFocusAttributeId,
  clearAgeRange,
  clearTargetLocations,
  clearDemographicAttribute,
}) => {
  const { context } = useOrderForm()
  const ageRangeTargeting =
    ageRange[0] !== DEFAULT_AGE_RANGE[0] || ageRange[1] !== DEFAULT_AGE_RANGE[1]
  const anyTargeting =
    activeDemographicAttributes.length ||
    targetLocations.length ||
    ageRangeTargeting
  const locationNames = useFlatLocations(context)

  if (!anyTargeting) return null

  return (
    <Stack spacing={0} py={4} borderBottomWidth={1} borderColor="gray.200">
      {targetLocations.length ? (
        <Box className="demo-section" px={4} py={2}>
          <Flex justify="space-between">
            <Flex direction="column">
              <Text mb={2}>Locations</Text>
              <FlexShowOnParentHover gap={1}>
                {setFocusAttributeId && (
                  <>
                    <Link
                      variant="noUnderline"
                      onClick={() => setFocusAttributeId(-1)}
                    >
                      view
                    </Link>
                    ·
                  </>
                )}

                {clearTargetLocations && (
                  <Link
                    variant="noUnderline"
                    colorScheme="red"
                    onClick={clearTargetLocations}
                  >
                    remove
                  </Link>
                )}
              </FlexShowOnParentHover>
            </Flex>

            <Text
              fontSize="14px"
              fontWeight="medium"
              textAlign="right"
              maxW="50%"
            >
              <GranularLocationTags
                scope={context}
                targetLocations={targetLocations}
              />
            </Text>
          </Flex>
          {validationErrors.invalid_countries && (
            <Box mt={4}>
              <ValidationError>
                {toSentence(
                  validationErrors.invalid_countries.map(
                    (code) =>
                      locationNames.country[code]?.qualifiedName ?? "Unknown"
                  )
                )}{" "}
                {validationErrors.invalid_countries.length > 1 ? "are" : "is"}{" "}
                not supported for external panel orders. Please remove{" "}
                {validationErrors.invalid_countries.length > 1
                  ? "these selections"
                  : "this selection"}{" "}
                to continue.
              </ValidationError>
            </Box>
          )}
        </Box>
      ) : null}

      {ageRangeTargeting ? (
        <Box className="demo-section" px={4} py={2}>
          <Flex justify="space-between">
            <Flex direction="column">
              <Text mb={2}>Age</Text>
              <FlexShowOnParentHover gap={1}>
                {setFocusAttributeId && (
                  <>
                    <Link
                      variant="noUnderline"
                      onClick={() => setFocusAttributeId(-2)}
                    >
                      view
                    </Link>
                    ·
                  </>
                )}
                {clearAgeRange && (
                  <Link
                    variant="noUnderline"
                    colorScheme="red"
                    onClick={clearAgeRange}
                  >
                    remove
                  </Link>
                )}
              </FlexShowOnParentHover>
            </Flex>

            <Text
              fontSize="14px"
              fontWeight="medium"
              textAlign="right"
              maxW="50%"
            >
              {ageRange[0] !== ageRange[1] ? `${ageRange[0]} — ` : null}
              {ageRange[1] === 100 ? "100+" : ageRange[1]}
            </Text>
          </Flex>
        </Box>
      ) : null}

      {activeDemographicAttributes.map((attribute) => {
        return (
          <Box className="demo-section" key={attribute.id} px={4} py={2}>
            <Flex justify="space-between">
              <Flex direction="column">
                <Text mb={2}>{attribute.name}</Text>
                <FlexShowOnParentHover gap={1}>
                  {setFocusAttributeId && (
                    <>
                      <Link
                        variant="noUnderline"
                        onClick={() => setFocusAttributeId(attribute.id)}
                      >
                        view
                      </Link>
                      ·
                    </>
                  )}
                  {clearDemographicAttribute && (
                    <Link
                      variant="noUnderline"
                      colorScheme="red"
                      onClick={() => clearDemographicAttribute(attribute.id)}
                    >
                      remove
                    </Link>
                  )}
                </FlexShowOnParentHover>
              </Flex>

              <Text
                fontSize="14px"
                fontWeight="medium"
                textAlign="right"
                maxW="50%"
              >
                {attribute.options.map((option) => option.value).join(", ")}
              </Text>
            </Flex>

            {validationErrors.unsupported_demographics?.includes(
              attribute.id
            ) ? (
              <Box mt={4}>
                <ValidationError>
                  {attribute.name} is not a supported target for external panel
                  orders. Please remove this selection to continue.
                </ValidationError>
              </Box>
            ) : null}
          </Box>
        )
      })}

      {showPresets && (
        <Flex justify="flex-end" me={4}>
          <SaveAsPresetButton />
        </Flex>
      )}
    </Stack>
  )
}
