import { Center, Flex, Stack, Text } from "@chakra-ui/react"
import { DndContext, DragOverEvent, useDroppable } from "@dnd-kit/core"
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import React, { useContext } from "react"

import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { DragHandle } from "Components/drag-handle/drag-handle"
import { DragLockContext } from "UsabilityHub/components/UsabilityTestSectionTask/SectionTasks/PrototypeTask/InstructionsModal/DragLockContext"
import { isUnique } from "Utilities/array"
import { reportError } from "Utilities/error"

interface OptionProps {
  readonly option: string
  readonly index: number
}

const Option: React.FC<OptionProps> = ({ option, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: option })

  const style = {
    // Using `CSS.Translate` instead of `CSS.Transform` since the items can be variable heights and
    // we don't want them to warp.
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <Flex
      align="center"
      gap={2}
      ref={setNodeRef}
      w="100%"
      zIndex={isDragging ? 2 : 1}
      style={style}
      {...attributes}
      {...listeners}
      data-qa="reorderable-list-option"
      userSelect="none"
      sx={{ touchAction: "none" }}
    >
      <Text color="gray.400" fontSize="xs">
        {index + 1}
      </Text>
      <Flex
        flexGrow={1}
        align="stretch"
        borderWidth={1}
        borderColor="gray.200"
        rounded="md"
        bg="white"
        shadow={isDragging ? "lg" : "none"}
      >
        <Flex flexGrow={1} p={2}>
          <DisplayInlineMarkdownText text={option} />
        </Flex>
        <Center borderLeftWidth={1} px={2}>
          <DragHandle color="gray.400" isDisabled={false} />
        </Center>
      </Flex>
    </Flex>
  )
}

export interface Props {
  /**
   * Current values in given order. These values *must be unique* or the
   * component will break.
   */
  value: string[]
  onChange: (options: string[]) => void
}

export const ReorderableList: React.FC<Props> = ({
  value,
  onChange,
  ...rest
}) => {
  const { setNodeRef } = useDroppable({ id: "ranking" })
  const { lockDragging, unlockDragging } = useContext(DragLockContext)

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = value.indexOf(active.id as string)
      const newIndex = value.indexOf(over.id as string)

      onChange(arrayMove(value, oldIndex, newIndex))
    }
  }

  if (!isUnique(value)) {
    reportError(new TypeError("value must not contain duplicates"), {
      extra: { value },
    })
  }
  return (
    <DndContext
      onDragOver={handleDragOver}
      onDragStart={lockDragging}
      onDragEnd={unlockDragging}
      {...rest}
    >
      <Stack ref={setNodeRef}>
        <SortableContext
          strategy={() => null}
          items={value.map((v) => ({ id: v }))}
        >
          {value.map((option, index) => (
            <Option key={option} option={option} index={index} />
          ))}
        </SortableContext>
      </Stack>
    </DndContext>
  )
}
