import { HStack, Switch, Text } from "@chakra-ui/react"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

const WrappedCustomizableScreenToggle: React.FC<
  React.PropsWithChildren<WrappedFieldProps & { disabled: boolean }>
> = ({ input, disabled }) => {
  return (
    <HStack>
      <Text as="label" htmlFor={input.name}>
        Customize
      </Text>
      <Switch
        id={input.name}
        display="inline-flex" // Fix for Chakra switch not being vertically aligned
        isDisabled={disabled}
        isChecked={input.value as boolean}
        {...input}
      />
    </HStack>
  )
}

interface CustomizableScreenToggleFieldProps {
  screenType: "welcome" | "thankyou"
  disabled?: boolean
}

export const CustomizableScreenToggle: React.FC<
  React.PropsWithChildren<CustomizableScreenToggleFieldProps>
> = ({ screenType, disabled = false }) => (
  <Field
    name={`customize_${screenType}`}
    component={WrappedCustomizableScreenToggle}
    disabled={disabled}
  />
)
