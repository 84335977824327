import {
  Accordion,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  ExpandedIndex,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Tag,
} from "@chakra-ui/react"
import { SearchMdOutlineIcon } from "Shared/icons/untitled-ui/SearchMdOutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"
import { AgeRangeSection } from "./AgeRangeSection"
import { DemographicAttributeSection } from "./DemographicAttributeSection"
import { SelectPresetButton } from "./DemographicPresets/SelectPresetButton"
import { GranularLocationSection } from "./GranularLocationSection/GranularLocationSection"
import { useOrderForm } from "./OrderFormProvider"
import { DEFAULT_AGE_RANGE } from "./constants"
import { highlightSearchTerm } from "./highlightSearchTerm"
import { Panel } from "./types"

interface SearchableDemographic {
  name: string
  options?: { value: string }[]
}

const attributeVisible = (attribute: SearchableDemographic, search: string) => {
  if (attribute.name.toLowerCase().includes(search)) {
    return true
  }

  return attribute.options?.some((option) =>
    option.value.toLowerCase().includes(search)
  )
}

interface Props {
  demographics: DemographicAttributeGroup[]
  activePanel: Panel
  showPresets: boolean
}

function useArrayRef<T>(): [T[], (el: T) => void] {
  const refs = useRef<T[]>([])
  refs.current = [] // Clear each render
  return [refs.current, (el: T | null) => el && refs.current.push(el)]
}

// Any special demographics that don't come back from the API
// They need a unique id so the summary panel can scroll to them
const CUSTOM_ATTRIBUTES = [
  { id: -1, name: "Location" },
  { id: -2, name: "Age" },
]

export const DemographicsPanel: React.FC<React.PropsWithChildren<Props>> = ({
  demographics,
  activePanel,
  showPresets,
}) => {
  const {
    selectedOptionIds,
    selectOption,
    ageRange,
    setAgeRange,
    targetLocations,
    focusAttributeId,
    setFocusAttributeId,
  } = useOrderForm()

  const [accordionElements, accordionRef] = useArrayRef<HTMLButtonElement>()
  const [search, setSearch] = useState("")
  const normalizedSearch = search.toLowerCase().replace(/[^\w ]+/g, "")
  const [openAttributes, setOpenAttributes] = useState<ExpandedIndex>([])

  const flattenedDemographics = useMemo(() => {
    return [
      ...CUSTOM_ATTRIBUTES,
      ...demographics.flatMap((g) => g.demographic_attributes),
    ]
  }, [demographics])

  // When an attribute is focused, we are going to:
  // * clear the search if one is present (which will reinstate the user's accordion state)
  // * make sure the accordion for the focused attribute is open
  // * scroll it into view
  useEffect(() => {
    if (focusAttributeId === null) return

    setSearch("")
    const focusAttributeIndex = flattenedDemographics.findIndex(
      (a) => a.id === focusAttributeId
    )
    setOpenAttributes((o: unknown) =>
      Array.isArray(o) ? o.concat(focusAttributeIndex) : focusAttributeIndex
    )

    const timeout = setTimeout(() => {
      // We're scrolling slightly further to account for the search bar
      const targetY = accordionElements[focusAttributeIndex]?.offsetTop - 73
      window.scrollTo({
        top: targetY,
        behavior: "smooth",
      })
      accordionElements[focusAttributeIndex]?.focus()
      setFocusAttributeId(null)
    }, 100)

    return () => clearTimeout(timeout)
  }, [focusAttributeId, flattenedDemographics])

  const currentlySearching = normalizedSearch.length >= 3
  const attributeVisibility = flattenedDemographics.map((attribute) =>
    attributeVisible(attribute, normalizedSearch)
  )
  const ageRangeTargeting =
    ageRange[0] !== DEFAULT_AGE_RANGE[0] || ageRange[1] !== DEFAULT_AGE_RANGE[1]

  const visibleIndexes = currentlySearching
    ? attributeVisibility.filter((visible) => visible).map((_, index) => index)
    : openAttributes

  return (
    <Stack spacing={0}>
      <Flex
        justify="space-between"
        align="center"
        px={4}
        py={6}
        borderBottomWidth={1}
        borderColor="gray.200"
      >
        <Heading
          as="h2"
          color="text.primary"
          fontSize="18px"
          fontWeight="semibold"
        >
          Demographics
        </Heading>

        {showPresets && <SelectPresetButton />}
      </Flex>

      <Box
        pos="sticky"
        top="3rem"
        py={4}
        bg="white"
        borderBottom="1px solid"
        borderColor="gray.200"
        zIndex="2"
      >
        <InputGroup mx={4} w="calc(100% - 32px)">
          <InputLeftElement>
            <Icon as={SearchMdOutlineIcon} />
          </InputLeftElement>
          {search !== "" && (
            <InputRightElement>
              <IconButton
                variant="ghost"
                size="sm"
                isRound
                aria-label="Clear search"
                onClick={() => setSearch("")}
                icon={<Icon as={XOutlineIcon} />}
              />
            </InputRightElement>
          )}
          <Input
            value={search}
            rounded="full"
            placeholder="Type to filter demographics"
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </Box>

      {currentlySearching && attributeVisibility.every((a) => !a) ? (
        <Box p={4}>
          <Alert>
            <AlertIcon />
            <AlertDescription>
              None of our demographic attributes matched that search. Want us to
              add a new one? Please{" "}
              <Link
                href="https://usabi.li/do/736046fc5f03/434e?source=order"
                target="_blank"
                rel="noopener noreferer"
              >
                let us know here
              </Link>
              .
            </AlertDescription>
          </Alert>
        </Box>
      ) : (
        <Accordion
          index={visibleIndexes}
          allowMultiple
          onChange={(indexes) => {
            // Don't update state when we are showing search results since all panels
            // are locked open at that time
            if (currentlySearching) return
            setOpenAttributes(indexes)
          }}
        >
          {demographics.map((group, groupIndex) => {
            const filteredAttributes = group.demographic_attributes.filter(
              (a) => {
                // If any options are selected for this attribute, always show it
                if (a.options.some((o) => selectedOptionIds.includes(o.id))) {
                  return true
                }

                // Otherwise we'll only show possible attributes for the currently active panel
                return activePanel === "usabilityhub" || a.available_on_cint
              }
            )
            const visibleAttributeIds = filteredAttributes
              .filter((attr) =>
                currentlySearching
                  ? attributeVisible(attr, normalizedSearch)
                  : true
              )
              .map((attr) => attr.id)

            return (
              <React.Fragment key={group.group_name}>
                {visibleAttributeIds.length ? (
                  <Box
                    borderTop="1px"
                    borderColor="gray.200"
                    _first={{ borderTop: "none" }}
                  >
                    <Heading
                      fontSize="18px"
                      fontWeight="semibold"
                      color="text.primary"
                      key={group.group_name}
                      mx={4}
                      mt={6}
                      mb={4}
                    >
                      {group.group_name}
                    </Heading>
                  </Box>
                ) : null}

                {groupIndex === 0 ? (
                  // These are custom demographics that aren't part of the API response, since they
                  // have unique logic and inputs.  They appear at the beginning of the first ("General")
                  // section.
                  <>
                    <DemographicAttributeSection
                      allowOverflow
                      visible={
                        !currentlySearching ||
                        "location".includes(normalizedSearch)
                      }
                      name="Location"
                      badgeText={
                        targetLocations.length
                          ? `${targetLocations.length} selected`
                          : undefined
                      }
                      textToHighlight={normalizedSearch}
                      lockedOpen={currentlySearching}
                      ref={accordionRef}
                    >
                      <GranularLocationSection />
                    </DemographicAttributeSection>

                    <DemographicAttributeSection
                      visible={
                        !currentlySearching || "age".includes(normalizedSearch)
                      }
                      name="Age"
                      badgeText={ageRangeTargeting ? "selected" : undefined}
                      textToHighlight={normalizedSearch}
                      lockedOpen={currentlySearching}
                      ref={accordionRef}
                    >
                      {({ isExpanded }) =>
                        isExpanded ? (
                          <AgeRangeSection
                            ageRange={ageRange}
                            setAgeRange={setAgeRange}
                          />
                        ) : null
                      }
                    </DemographicAttributeSection>
                  </>
                ) : null}

                {group.demographic_attributes.map((attribute) => {
                  const selectedOptionCount = attribute.options.filter((o) =>
                    selectedOptionIds.includes(o.id)
                  ).length

                  return (
                    <DemographicAttributeSection
                      key={attribute.id}
                      visible={visibleAttributeIds.includes(attribute.id)}
                      demographicAttributeId={attribute.id}
                      name={attribute.name}
                      badgeText={
                        selectedOptionCount
                          ? `${selectedOptionCount} selected`
                          : undefined
                      }
                      textToHighlight={normalizedSearch}
                      lockedOpen={currentlySearching}
                      tooltipContent={attribute.target_helper}
                      ref={accordionRef}
                    >
                      <Flex wrap="wrap" gap={2}>
                        <Tag
                          colorScheme={
                            selectedOptionCount ? undefined : "brand.primary"
                          }
                          variant={selectedOptionCount ? "subtle" : "solid"}
                          size="md"
                          cursor="pointer"
                          onClick={() =>
                            selectOption(
                              attribute.options.map((o) => o.id),
                              false
                            )
                          }
                          py={2}
                        >
                          Any
                        </Tag>
                        {attribute.options?.map((option) => {
                          const selected = selectedOptionIds.includes(option.id)

                          return (
                            <Tag
                              colorScheme={
                                selected ? "brand.primary" : undefined
                              }
                              variant={selected ? "solid" : "subtle"}
                              key={option.id}
                              size="md"
                              cursor="pointer"
                              onClick={() => selectOption(option.id, !selected)}
                              py={2}
                            >
                              {currentlySearching
                                ? highlightSearchTerm(
                                    option.value,
                                    normalizedSearch
                                  )
                                : option.value}
                            </Tag>
                          )
                        })}
                      </Flex>
                    </DemographicAttributeSection>
                  )
                })}
              </React.Fragment>
            )
          })}
        </Accordion>
      )}
    </Stack>
  )
}
