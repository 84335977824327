/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from "@tanstack/react-query";
import {
  useUsabilityhubContext,
  UsabilityhubContext,
} from "./usabilityhub-context";
import type * as Fetcher from "./usabilityhub-fetcher";
import { usabilityhubFetch } from "./usabilityhub-fetcher";
import type * as Schemas from "./usabilityhubSchemas";
import type * as Responses from "./usabilityhubResponses";

export type AdminDemographicProfileUpdateFluenciesPathParams = {
  /**
   * Unique ID of the (panelist) user
   */
  id: number;
};

export type AdminDemographicProfileUpdateFluenciesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type AdminDemographicProfileUpdateFluenciesResponse = {
  fluencies: Schemas.Fluency[];
};

export type AdminDemographicProfileUpdateFluenciesRequestBody = {
  fluencies: {
    id: number | null;
    language_code: string;
    is_fluent: boolean;
  }[];
};

export type AdminDemographicProfileUpdateFluenciesVariables = {
  body: AdminDemographicProfileUpdateFluenciesRequestBody;
  pathParams: AdminDemographicProfileUpdateFluenciesPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a demographic profile fluencies (admin)
 */
export const fetchAdminDemographicProfileUpdateFluencies = (
  variables: AdminDemographicProfileUpdateFluenciesVariables,
) =>
  usabilityhubFetch<
    AdminDemographicProfileUpdateFluenciesResponse,
    AdminDemographicProfileUpdateFluenciesError,
    AdminDemographicProfileUpdateFluenciesRequestBody,
    {},
    {},
    AdminDemographicProfileUpdateFluenciesPathParams
  >({
    url: "/api/admin/demographic_profiles/{id}/update_fluencies",
    method: "post",
    ...variables,
  });

/**
 * Update a demographic profile fluencies (admin)
 */
export const useAdminDemographicProfileUpdateFluencies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AdminDemographicProfileUpdateFluenciesResponse,
      AdminDemographicProfileUpdateFluenciesError,
      AdminDemographicProfileUpdateFluenciesVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    AdminDemographicProfileUpdateFluenciesResponse,
    AdminDemographicProfileUpdateFluenciesError,
    AdminDemographicProfileUpdateFluenciesVariables
  >(
    (variables: AdminDemographicProfileUpdateFluenciesVariables) =>
      fetchAdminDemographicProfileUpdateFluencies({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type AdminDemographicProfileFluenciesPathParams = {
  /**
   * Unique ID of the (panelist) user
   */
  id: number;
};

export type AdminDemographicProfileFluenciesError =
  Fetcher.ErrorWrapper<undefined>;

export type AdminDemographicProfileFluenciesResponse = {
  fluencies: Schemas.Fluency[];
};

export type AdminDemographicProfileFluenciesVariables = {
  pathParams: AdminDemographicProfileFluenciesPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Retrive a demographic profile fluencies (admin)
 */
export const fetchAdminDemographicProfileFluencies = (
  variables: AdminDemographicProfileFluenciesVariables,
) =>
  usabilityhubFetch<
    AdminDemographicProfileFluenciesResponse,
    AdminDemographicProfileFluenciesError,
    undefined,
    {},
    {},
    AdminDemographicProfileFluenciesPathParams
  >({
    url: "/api/admin/demographic_profiles/{id}/fluencies",
    method: "get",
    ...variables,
  });

/**
 * Retrive a demographic profile fluencies (admin)
 */
export const useAdminDemographicProfileFluencies = <
  TData = AdminDemographicProfileFluenciesResponse,
>(
  variables: AdminDemographicProfileFluenciesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AdminDemographicProfileFluenciesResponse,
      AdminDemographicProfileFluenciesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    AdminDemographicProfileFluenciesResponse,
    AdminDemographicProfileFluenciesError,
    TData
  >(
    queryKeyFn({
      path: "/api/admin/demographic_profiles/{id}/fluencies",
      operationId: "adminDemographicProfileFluencies",
      variables,
    }),
    () =>
      fetchAdminDemographicProfileFluencies({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type AdminListDemographicsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type AdminListDemographicsResponse = {
  id: number;
  name: string;
  demographic_attribute_group_id: number;
  profile_helper: string | null;
  target_helper: string | null;
  question: string;
  group: string;
  multi_select: boolean;
  sort_order: number;
  individual_sort_order: number;
  visible_to_panelists: boolean;
  visible_to_customers: boolean;
  options: {
    id: number;
    value: string;
    profile_helper: string | null;
    is_none_of_above: boolean;
    is_prefer_not_to_say: boolean;
  }[];
}[];

export type AdminListDemographicsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * List all active demographic attributes and their options
 */
export const fetchAdminListDemographics = (
  variables: AdminListDemographicsVariables,
) =>
  usabilityhubFetch<
    AdminListDemographicsResponse,
    AdminListDemographicsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/admin/demographics", method: "get", ...variables });

/**
 * List all active demographic attributes and their options
 */
export const useAdminListDemographics = <
  TData = AdminListDemographicsResponse,
>(
  variables: AdminListDemographicsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AdminListDemographicsResponse,
      AdminListDemographicsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    AdminListDemographicsResponse,
    AdminListDemographicsError,
    TData
  >(
    queryKeyFn({
      path: "/api/admin/demographics",
      operationId: "adminListDemographics",
      variables,
    }),
    () => fetchAdminListDemographics({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type AdminCreateDemographicError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type AdminCreateDemographicVariables = {
  body: Schemas.DemographicAttributeRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a new demographic attribute, not visible to panelists or customers
 */
export const fetchAdminCreateDemographic = (
  variables: AdminCreateDemographicVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    AdminCreateDemographicError,
    Schemas.DemographicAttributeRequestBody,
    {},
    {},
    {}
  >({ url: "/api/admin/demographics", method: "post", ...variables });

/**
 * Create a new demographic attribute, not visible to panelists or customers
 */
export const useAdminCreateDemographic = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AdminCreateDemographicError,
      AdminCreateDemographicVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AdminCreateDemographicError,
    AdminCreateDemographicVariables
  >(
    (variables: AdminCreateDemographicVariables) =>
      fetchAdminCreateDemographic({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type AdminUpdateDemographicPathParams = {
  /**
   * ID of the demographic attribute
   */
  demographicAttributeId: number;
};

export type AdminUpdateDemographicError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type AdminUpdateDemographicVariables = {
  body: Schemas.DemographicAttributeRequestBody;
  pathParams: AdminUpdateDemographicPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates an existing demographic attribute
 */
export const fetchAdminUpdateDemographic = (
  variables: AdminUpdateDemographicVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    AdminUpdateDemographicError,
    Schemas.DemographicAttributeRequestBody,
    {},
    {},
    AdminUpdateDemographicPathParams
  >({
    url: "/api/admin/demographics/{demographicAttributeId}",
    method: "patch",
    ...variables,
  });

/**
 * Updates an existing demographic attribute
 */
export const useAdminUpdateDemographic = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AdminUpdateDemographicError,
      AdminUpdateDemographicVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AdminUpdateDemographicError,
    AdminUpdateDemographicVariables
  >(
    (variables: AdminUpdateDemographicVariables) =>
      fetchAdminUpdateDemographic({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type AdminListDemographicGroupsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type AdminListDemographicGroupsResponse = {
  id: number;
  name: string;
}[];

export type AdminListDemographicGroupsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * List all demographic attribute groups
 */
export const fetchAdminListDemographicGroups = (
  variables: AdminListDemographicGroupsVariables,
) =>
  usabilityhubFetch<
    AdminListDemographicGroupsResponse,
    AdminListDemographicGroupsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/admin/demographics/groups", method: "get", ...variables });

/**
 * List all demographic attribute groups
 */
export const useAdminListDemographicGroups = <
  TData = AdminListDemographicGroupsResponse,
>(
  variables: AdminListDemographicGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AdminListDemographicGroupsResponse,
      AdminListDemographicGroupsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    AdminListDemographicGroupsResponse,
    AdminListDemographicGroupsError,
    TData
  >(
    queryKeyFn({
      path: "/api/admin/demographics/groups",
      operationId: "adminListDemographicGroups",
      variables,
    }),
    () => fetchAdminListDemographicGroups({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetCurrentAccountError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type GetCurrentAccountResponse = {
  current_account: Schemas.CurrentAccount;
  current_user: Schemas.CurrentUser;
  current_plan?: Schemas.Plan;
  active_stripe_subscription?: Schemas.Subscription;
  manual_subscriptions: {
    id: number;
    plan_unique_id: string;
    period_start: string | null;
    period_end?: string | null;
    paid: boolean;
    created_at: string;
    updated_at: string;
    state: "active" | "pending" | "canceled";
    price_in_cents: number;
    paid_seat_count: number;
  }[];
};

export type GetCurrentAccountVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Gets detailed account information for the current account
 */
export const fetchGetCurrentAccount = (variables: GetCurrentAccountVariables) =>
  usabilityhubFetch<
    GetCurrentAccountResponse,
    GetCurrentAccountError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/account/current", method: "get", ...variables });

/**
 * Gets detailed account information for the current account
 */
export const useGetCurrentAccount = <TData = GetCurrentAccountResponse,>(
  variables: GetCurrentAccountVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetCurrentAccountResponse,
      GetCurrentAccountError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetCurrentAccountResponse,
    GetCurrentAccountError,
    TData
  >(
    queryKeyFn({
      path: "/api/account/current",
      operationId: "getCurrentAccount",
      variables,
    }),
    () => fetchGetCurrentAccount({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetAccountTotalCreditsError = Fetcher.ErrorWrapper<undefined>;

export type GetAccountTotalCreditsResponse = {
  total_credits: number;
};

export type GetAccountTotalCreditsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Gets the total credits of the current account
 */
export const fetchGetAccountTotalCredits = (
  variables: GetAccountTotalCreditsVariables,
) =>
  usabilityhubFetch<
    GetAccountTotalCreditsResponse,
    GetAccountTotalCreditsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/account/total_credits", method: "get", ...variables });

/**
 * Gets the total credits of the current account
 */
export const useGetAccountTotalCredits = <
  TData = GetAccountTotalCreditsResponse,
>(
  variables: GetAccountTotalCreditsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAccountTotalCreditsResponse,
      GetAccountTotalCreditsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetAccountTotalCreditsResponse,
    GetAccountTotalCreditsError,
    TData
  >(
    queryKeyFn({
      path: "/api/account/total_credits",
      operationId: "getAccountTotalCredits",
      variables,
    }),
    () => fetchGetAccountTotalCredits({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type SwitchAccountToFreePlanError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type SwitchAccountToFreePlanVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Switches the account to a new plan
 */
export const fetchSwitchAccountToFreePlan = (
  variables: SwitchAccountToFreePlanVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    SwitchAccountToFreePlanError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/account/switch_to_free_plan", method: "post", ...variables });

/**
 * Switches the account to a new plan
 */
export const useSwitchAccountToFreePlan = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      SwitchAccountToFreePlanError,
      SwitchAccountToFreePlanVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    SwitchAccountToFreePlanError,
    SwitchAccountToFreePlanVariables
  >(
    (variables: SwitchAccountToFreePlanVariables) =>
      fetchSwitchAccountToFreePlan({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UploadCardImageError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R400;
    }
  | {
      status: 500;
      payload: Responses.R500;
    }
>;

export type UploadCardImageRequestBody = {
  /**
   * @format binary
   */
  file: Blob;
};

export type UploadCardImageVariables = {
  body: UploadCardImageRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Upload an image to a card sort card
 */
export const fetchUploadCardImage = (variables: UploadCardImageVariables) =>
  usabilityhubFetch<
    Schemas.CardSortCardImage,
    UploadCardImageError,
    UploadCardImageRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/card_sort_cards/upload_card_image",
    method: "post",
    ...variables,
  });

/**
 * Upload an image to a card sort card
 */
export const useUploadCardImage = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CardSortCardImage,
      UploadCardImageError,
      UploadCardImageVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.CardSortCardImage,
    UploadCardImageError,
    UploadCardImageVariables
  >(
    (variables: UploadCardImageVariables) =>
      fetchUploadCardImage({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DuplicateCardImageError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R400;
    }
  | {
      status: 500;
      payload: Responses.R500;
    }
>;

export type DuplicateCardImageRequestBody = {
  image_data: Schemas.ShrineData;
};

export type DuplicateCardImageVariables = {
  body: DuplicateCardImageRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Duplicate an image on a card sort card
 */
export const fetchDuplicateCardImage = (
  variables: DuplicateCardImageVariables,
) =>
  usabilityhubFetch<
    Schemas.CardSortCardImage,
    DuplicateCardImageError,
    DuplicateCardImageRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/card_sort_cards/duplicate_card_image",
    method: "post",
    ...variables,
  });

/**
 * Duplicate an image on a card sort card
 */
export const useDuplicateCardImage = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CardSortCardImage,
      DuplicateCardImageError,
      DuplicateCardImageVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.CardSortCardImage,
    DuplicateCardImageError,
    DuplicateCardImageVariables
  >(
    (variables: DuplicateCardImageVariables) =>
      fetchDuplicateCardImage({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type RemoveTempCardImageError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R400;
    }
  | {
      status: 500;
      payload: Responses.R500;
    }
>;

export type RemoveTempCardImageResponse = {
  message: string;
};

export type RemoveTempCardImageRequestBody = {
  image_data: string;
};

export type RemoveTempCardImageVariables = {
  body: RemoveTempCardImageRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Remove an image cached for a card sort card
 */
export const fetchRemoveTempCardImage = (
  variables: RemoveTempCardImageVariables,
) =>
  usabilityhubFetch<
    RemoveTempCardImageResponse,
    RemoveTempCardImageError,
    RemoveTempCardImageRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/card_sort_cards/remove_temp_card_image",
    method: "post",
    ...variables,
  });

/**
 * Remove an image cached for a card sort card
 */
export const useRemoveTempCardImage = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RemoveTempCardImageResponse,
      RemoveTempCardImageError,
      RemoveTempCardImageVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    RemoveTempCardImageResponse,
    RemoveTempCardImageError,
    RemoveTempCardImageVariables
  >(
    (variables: RemoveTempCardImageVariables) =>
      fetchRemoveTempCardImage({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PurchaseCreditsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type PurchaseCreditsResponse = {
  purchase: {
    credit_count: number;
    price_in_cents: number;
  };
  message: string;
};

export type PurchaseCreditsRequestBody = {
  /**
   * @minimum 1
   */
  credits: number;
};

export type PurchaseCreditsVariables = {
  body: PurchaseCreditsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Purchase credits
 */
export const fetchPurchaseCredits = (variables: PurchaseCreditsVariables) =>
  usabilityhubFetch<
    PurchaseCreditsResponse,
    PurchaseCreditsError,
    PurchaseCreditsRequestBody,
    {},
    {},
    {}
  >({ url: "/api/credits/purchase", method: "post", ...variables });

/**
 * Purchase credits
 */
export const usePurchaseCredits = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PurchaseCreditsResponse,
      PurchaseCreditsError,
      PurchaseCreditsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PurchaseCreditsResponse,
    PurchaseCreditsError,
    PurchaseCreditsVariables
  >(
    (variables: PurchaseCreditsVariables) =>
      fetchPurchaseCredits({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListCreditBatchesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ListCreditBatchesResponse = {
  batches: {
    amount: number;
    price: number;
  }[];
};

export type ListCreditBatchesVariables = UsabilityhubContext["fetcherOptions"];

/**
 * List available credit batches
 */
export const fetchListCreditBatches = (variables: ListCreditBatchesVariables) =>
  usabilityhubFetch<
    ListCreditBatchesResponse,
    ListCreditBatchesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/credits/batches", method: "get", ...variables });

/**
 * List available credit batches
 */
export const useListCreditBatches = <TData = ListCreditBatchesResponse,>(
  variables: ListCreditBatchesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListCreditBatchesResponse,
      ListCreditBatchesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListCreditBatchesResponse,
    ListCreditBatchesError,
    TData
  >(
    queryKeyFn({
      path: "/api/credits/batches",
      operationId: "listCreditBatches",
      variables,
    }),
    () => fetchListCreditBatches({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ClaimCreditCodeError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ClaimCreditCodeResponse = {
  claimed_points: number;
};

export type ClaimCreditCodeRequestBody = {
  credit_code: string;
};

export type ClaimCreditCodeVariables = {
  body: ClaimCreditCodeRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Claim some credits using a code
 */
export const fetchClaimCreditCode = (variables: ClaimCreditCodeVariables) =>
  usabilityhubFetch<
    ClaimCreditCodeResponse,
    ClaimCreditCodeError,
    ClaimCreditCodeRequestBody,
    {},
    {},
    {}
  >({ url: "/api/credit_codes/claim", method: "post", ...variables });

/**
 * Claim some credits using a code
 */
export const useClaimCreditCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ClaimCreditCodeResponse,
      ClaimCreditCodeError,
      ClaimCreditCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ClaimCreditCodeResponse,
    ClaimCreditCodeError,
    ClaimCreditCodeVariables
  >(
    (variables: ClaimCreditCodeVariables) =>
      fetchClaimCreditCode({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListDemographicsError = Fetcher.ErrorWrapper<undefined>;

export type ListDemographicsResponse = Schemas.DemographicAttributeGroup[];

export type ListDemographicsVariables = UsabilityhubContext["fetcherOptions"];

/**
 * This is used to list all demographic attributes
 */
export const fetchListDemographics = (variables: ListDemographicsVariables) =>
  usabilityhubFetch<
    ListDemographicsResponse,
    ListDemographicsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/demographics", method: "get", ...variables });

/**
 * This is used to list all demographic attributes
 */
export const useListDemographics = <TData = ListDemographicsResponse,>(
  variables: ListDemographicsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDemographicsResponse,
      ListDemographicsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListDemographicsResponse,
    ListDemographicsError,
    TData
  >(
    queryKeyFn({
      path: "/api/demographics",
      operationId: "listDemographics",
      variables,
    }),
    () => fetchListDemographics({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DemographicProfileCompleteProfileError =
  Fetcher.ErrorWrapper<undefined>;

export type DemographicProfileCompleteProfileResponse = {
  /**
   * @format date-time
   */
  completed_profile_at: string | null;
};

export type DemographicProfileCompleteProfileVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Complete demographic profile
 */
export const fetchDemographicProfileCompleteProfile = (
  variables: DemographicProfileCompleteProfileVariables,
) =>
  usabilityhubFetch<
    DemographicProfileCompleteProfileResponse,
    DemographicProfileCompleteProfileError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_complete_profile",
    method: "post",
    ...variables,
  });

/**
 * Complete demographic profile
 */
export const useDemographicProfileCompleteProfile = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DemographicProfileCompleteProfileResponse,
      DemographicProfileCompleteProfileError,
      DemographicProfileCompleteProfileVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DemographicProfileCompleteProfileResponse,
    DemographicProfileCompleteProfileError,
    DemographicProfileCompleteProfileVariables
  >(
    (variables: DemographicProfileCompleteProfileVariables) =>
      fetchDemographicProfileCompleteProfile({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type DemographicProfileForUserError = Fetcher.ErrorWrapper<undefined>;

export type DemographicProfileForUserResponse = {
  selected_demographic_attribute_option_ids: number[];
  country: string | null;
  formatted_address: string | null;
  google_place_id: string | null;
  fluencies: {
    id: number;
    is_fluent: boolean;
    language_code: string;
    updater_id: number;
    panelist_id: number;
  }[];
  year_of_birth: number | null;
};

export type DemographicProfileForUserVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Demographic profile for user
 */
export const fetchDemographicProfileForUser = (
  variables: DemographicProfileForUserVariables,
) =>
  usabilityhubFetch<
    DemographicProfileForUserResponse,
    DemographicProfileForUserError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_for_user",
    method: "get",
    ...variables,
  });

/**
 * Demographic profile for user
 */
export const useDemographicProfileForUser = <
  TData = DemographicProfileForUserResponse,
>(
  variables: DemographicProfileForUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DemographicProfileForUserResponse,
      DemographicProfileForUserError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    DemographicProfileForUserResponse,
    DemographicProfileForUserError,
    TData
  >(
    queryKeyFn({
      path: "/api/demographic_profiles/demographic_profiles_for_user",
      operationId: "demographicProfileForUser",
      variables,
    }),
    () => fetchDemographicProfileForUser({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DemographicProfileIndexError = Fetcher.ErrorWrapper<undefined>;

export type DemographicProfileIndexResponse = {
  group_name: string;
  demographic_attributes: {
    id: number;
    name: string;
    code: string;
    question: string;
    profile_helper: string | null;
    individual_sort_order: number;
    multi_select: boolean;
    required: boolean;
    options: {
      id: number;
      code: string | null;
      value: string;
      profile_helper: string | null;
      is_none_of_above: boolean;
      is_prefer_not_to_say: boolean;
    }[];
  }[];
}[];

export type DemographicProfileIndexVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Demographic profile data for a panelist
 */
export const fetchDemographicProfileIndex = (
  variables: DemographicProfileIndexVariables,
) =>
  usabilityhubFetch<
    DemographicProfileIndexResponse,
    DemographicProfileIndexError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_index",
    method: "get",
    ...variables,
  });

/**
 * Demographic profile data for a panelist
 */
export const useDemographicProfileIndex = <
  TData = DemographicProfileIndexResponse,
>(
  variables: DemographicProfileIndexVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DemographicProfileIndexResponse,
      DemographicProfileIndexError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    DemographicProfileIndexResponse,
    DemographicProfileIndexError,
    TData
  >(
    queryKeyFn({
      path: "/api/demographic_profiles/demographic_profiles_index",
      operationId: "demographicProfileIndex",
      variables,
    }),
    () => fetchDemographicProfileIndex({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DemographicProfileUpdateError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type DemographicProfileUpdateRequestBody = {
  demographic_attribute_id: number;
  selected_demographic_attribute_option_ids: string[];
};

export type DemographicProfileUpdateVariables = {
  body: DemographicProfileUpdateRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a demographic attribute
 */
export const fetchDemographicProfileUpdate = (
  variables: DemographicProfileUpdateVariables,
) =>
  usabilityhubFetch<
    Record<string, any>,
    DemographicProfileUpdateError,
    DemographicProfileUpdateRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_update",
    method: "post",
    ...variables,
  });

/**
 * Update a demographic attribute
 */
export const useDemographicProfileUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      DemographicProfileUpdateError,
      DemographicProfileUpdateVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Record<string, any>,
    DemographicProfileUpdateError,
    DemographicProfileUpdateVariables
  >(
    (variables: DemographicProfileUpdateVariables) =>
      fetchDemographicProfileUpdate({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdatePanelistLocationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 500;
      payload: Responses.R500;
    }
>;

export type UpdatePanelistLocationResponse = {
  require_phone_validation: boolean;
};

export type UpdatePanelistLocationRequestBody = {
  google_place_id: string;
};

export type UpdatePanelistLocationVariables = {
  body: UpdatePanelistLocationRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update granular location for a panelist
 */
export const fetchUpdatePanelistLocation = (
  variables: UpdatePanelistLocationVariables,
) =>
  usabilityhubFetch<
    UpdatePanelistLocationResponse,
    UpdatePanelistLocationError,
    UpdatePanelistLocationRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_update_location",
    method: "post",
    ...variables,
  });

/**
 * Update granular location for a panelist
 */
export const useUpdatePanelistLocation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdatePanelistLocationResponse,
      UpdatePanelistLocationError,
      UpdatePanelistLocationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    UpdatePanelistLocationResponse,
    UpdatePanelistLocationError,
    UpdatePanelistLocationVariables
  >(
    (variables: UpdatePanelistLocationVariables) =>
      fetchUpdatePanelistLocation({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DemographicProfileUpdateFluenciesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type DemographicProfileUpdateFluenciesResponse = {
  fluencies: Schemas.Fluency[];
};

export type DemographicProfileUpdateFluenciesRequestBody = {
  fluencies: {
    id: number | null;
    language_code: string;
    is_fluent: boolean;
  }[];
};

export type DemographicProfileUpdateFluenciesVariables = {
  body: DemographicProfileUpdateFluenciesRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a demographic profile fluencies
 */
export const fetchDemographicProfileUpdateFluencies = (
  variables: DemographicProfileUpdateFluenciesVariables,
) =>
  usabilityhubFetch<
    DemographicProfileUpdateFluenciesResponse,
    DemographicProfileUpdateFluenciesError,
    DemographicProfileUpdateFluenciesRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_update_fluencies",
    method: "post",
    ...variables,
  });

/**
 * Update a demographic profile fluencies
 */
export const useDemographicProfileUpdateFluencies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DemographicProfileUpdateFluenciesResponse,
      DemographicProfileUpdateFluenciesError,
      DemographicProfileUpdateFluenciesVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DemographicProfileUpdateFluenciesResponse,
    DemographicProfileUpdateFluenciesError,
    DemographicProfileUpdateFluenciesVariables
  >(
    (variables: DemographicProfileUpdateFluenciesVariables) =>
      fetchDemographicProfileUpdateFluencies({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type DemographicProfileUpdateYearOfBirthError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type DemographicProfileUpdateYearOfBirthRequestBody = {
  year_of_birth: number;
};

export type DemographicProfileUpdateYearOfBirthVariables = {
  body: DemographicProfileUpdateYearOfBirthRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a demographic profile year of birth
 */
export const fetchDemographicProfileUpdateYearOfBirth = (
  variables: DemographicProfileUpdateYearOfBirthVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DemographicProfileUpdateYearOfBirthError,
    DemographicProfileUpdateYearOfBirthRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/demographic_profiles/demographic_profiles_update_year_of_birth",
    method: "post",
    ...variables,
  });

/**
 * Update a demographic profile year of birth
 */
export const useDemographicProfileUpdateYearOfBirth = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DemographicProfileUpdateYearOfBirthError,
      DemographicProfileUpdateYearOfBirthVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DemographicProfileUpdateYearOfBirthError,
    DemographicProfileUpdateYearOfBirthVariables
  >(
    (variables: DemographicProfileUpdateYearOfBirthVariables) =>
      fetchDemographicProfileUpdateYearOfBirth({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type GetActiveFeatureFlagsError = Fetcher.ErrorWrapper<undefined>;

export type GetActiveFeatureFlagsResponse = {
  feature_flags: Schemas.FeatureFlag[];
};

export type GetActiveFeatureFlagsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns a list of active feature flags for the current user
 */
export const fetchGetActiveFeatureFlags = (
  variables: GetActiveFeatureFlagsVariables,
) =>
  usabilityhubFetch<
    GetActiveFeatureFlagsResponse,
    GetActiveFeatureFlagsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/feature_flags", method: "get", ...variables });

/**
 * Returns a list of active feature flags for the current user
 */
export const useGetActiveFeatureFlags = <
  TData = GetActiveFeatureFlagsResponse,
>(
  variables: GetActiveFeatureFlagsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetActiveFeatureFlagsResponse,
      GetActiveFeatureFlagsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetActiveFeatureFlagsResponse,
    GetActiveFeatureFlagsError,
    TData
  >(
    queryKeyFn({
      path: "/api/feature_flags",
      operationId: "getActiveFeatureFlags",
      variables,
    }),
    () => fetchGetActiveFeatureFlags({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListAllFeatureFlagsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type ListAllFeatureFlagsResponse = {
  feature_flags: {
    flag: Schemas.FeatureFlag;
    description: string;
    added_at: string;
    owner: string;
    enabled_globally: boolean;
  }[];
};

export type ListAllFeatureFlagsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns a list of active feature flags for the current user
 */
export const fetchListAllFeatureFlags = (
  variables: ListAllFeatureFlagsVariables,
) =>
  usabilityhubFetch<
    ListAllFeatureFlagsResponse,
    ListAllFeatureFlagsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/feature_flags/available", method: "get", ...variables });

/**
 * Returns a list of active feature flags for the current user
 */
export const useListAllFeatureFlags = <TData = ListAllFeatureFlagsResponse,>(
  variables: ListAllFeatureFlagsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListAllFeatureFlagsResponse,
      ListAllFeatureFlagsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListAllFeatureFlagsResponse,
    ListAllFeatureFlagsError,
    TData
  >(
    queryKeyFn({
      path: "/api/feature_flags/available",
      operationId: "listAllFeatureFlags",
      variables,
    }),
    () => fetchListAllFeatureFlags({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ToggleFeatureFlagPathParams = {
  /**
   * name of the feature flag
   */
  featureFlagName: Schemas.FeatureFlag;
};

export type ToggleFeatureFlagError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ToggleFeatureFlagRequestBody = {
  enabled: boolean;
};

export type ToggleFeatureFlagVariables = {
  body: ToggleFeatureFlagRequestBody;
  pathParams: ToggleFeatureFlagPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Enable or disable a feature flag for the current user
 */
export const fetchToggleFeatureFlag = (variables: ToggleFeatureFlagVariables) =>
  usabilityhubFetch<
    Responses.R204,
    ToggleFeatureFlagError,
    ToggleFeatureFlagRequestBody,
    {},
    {},
    ToggleFeatureFlagPathParams
  >({
    url: "/api/feature_flags/{featureFlagName}",
    method: "patch",
    ...variables,
  });

/**
 * Enable or disable a feature flag for the current user
 */
export const useToggleFeatureFlag = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ToggleFeatureFlagError,
      ToggleFeatureFlagVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ToggleFeatureFlagError,
    ToggleFeatureFlagVariables
  >(
    (variables: ToggleFeatureFlagVariables) =>
      fetchToggleFeatureFlag({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CreateOrderError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CreateOrderResponse = {
  id: number;
  purchase?: {
    credit_count: number;
    price_in_cents: number;
  };
};

export type CreateOrderRequestBody = {
  usability_test_unique_id: string;
  /**
   * @minimum 1
   */
  requested_response_count: number;
  demographic_target_id: number | null;
  estimated_incidence_rate: number | null;
};

export type CreateOrderVariables = {
  body: CreateOrderRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a new order for the related UsabilityTest. You'll also need to pass a demographic_target_id representing the targeting (if any) and the number of responses needed.
 */
export const fetchCreateOrder = (variables: CreateOrderVariables) =>
  usabilityhubFetch<
    CreateOrderResponse,
    CreateOrderError,
    CreateOrderRequestBody,
    {},
    {},
    {}
  >({ url: "/api/orders", method: "post", ...variables });

/**
 * Creates a new order for the related UsabilityTest. You'll also need to pass a demographic_target_id representing the targeting (if any) and the number of responses needed.
 */
export const useCreateOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateOrderResponse,
      CreateOrderError,
      CreateOrderVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateOrderResponse,
    CreateOrderError,
    CreateOrderVariables
  >(
    (variables: CreateOrderVariables) =>
      fetchCreateOrder({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CalculatePriceAndEstimatedDurationQueryParams = {
  usability_test_id: string;
  demographic_attribute_option_ids: string;
  min_age?: number;
  max_age?: number;
  requested_response_count: number;
  estimated_incidence_rate?: number;
};

export type CalculatePriceAndEstimatedDurationError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type CalculatePriceAndEstimatedDurationResponse = {
  test_duration_range: {
    lower_minutes: number;
    upper_minutes: number;
  };
  per_response_cost_breakdown: {
    total_cost: number;
    test_cost: number;
    screener_cost: number;
    recording_cost: number;
  };
  credit_cost: number;
  credits_scale: {
    from: number;
    to: number | null;
    price_in_cents: number;
  }[];
  credits_to_be_purchased: number;
  discount_applied: boolean;
  discount: number | null;
  formatted_discount: string | null;
  formatted_price_before_discount: string | null;
  formatted_price: string;
  price_before_discount: number | null;
  price_per_credit: string;
  price: number;
  requested_response_count: number;
  estimated_duration: string | null;
  demographic_target_id: number | null;
  estimated_incidence_rate: number | null;
};

export type CalculatePriceAndEstimatedDurationRequestBody = {
  target_locations: {
    type: "country" | "state" | "city";
    id: string;
  }[];
};

export type CalculatePriceAndEstimatedDurationVariables = {
  body: CalculatePriceAndEstimatedDurationRequestBody;
  queryParams: CalculatePriceAndEstimatedDurationQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns price and duration estimates for an order
 */
export const fetchCalculatePriceAndEstimatedDuration = (
  variables: CalculatePriceAndEstimatedDurationVariables,
) =>
  usabilityhubFetch<
    CalculatePriceAndEstimatedDurationResponse,
    CalculatePriceAndEstimatedDurationError,
    CalculatePriceAndEstimatedDurationRequestBody,
    {},
    CalculatePriceAndEstimatedDurationQueryParams,
    {}
  >({
    url: "/api/orders/price_and_estimated_duration",
    method: "post",
    ...variables,
  });

/**
 * Returns price and duration estimates for an order
 */
export const useCalculatePriceAndEstimatedDuration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CalculatePriceAndEstimatedDurationResponse,
      CalculatePriceAndEstimatedDurationError,
      CalculatePriceAndEstimatedDurationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CalculatePriceAndEstimatedDurationResponse,
    CalculatePriceAndEstimatedDurationError,
    CalculatePriceAndEstimatedDurationVariables
  >(
    (variables: CalculatePriceAndEstimatedDurationVariables) =>
      fetchCalculatePriceAndEstimatedDuration({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type PanelEstimateError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type PanelEstimateResponse = {
  formatted_price_per_response: string;
  formatted_price: string;
  requested_response_count: number;
  estimated_duration: string | null;
};

export type PanelEstimateRequestBody = {
  demographic_attribute_option_ids: string;
  min_age?: number;
  max_age?: number;
  target_locations: {
    type?: "country" | "state" | "city";
    id?: string;
  }[];
  requested_response_count: number;
  study_type:
    | "short_survey"
    | "long_survey"
    | "preference_test"
    | "figma_prototype_test"
    | "open_card_sort";
  language_code: string;
};

export type PanelEstimateVariables = {
  body: PanelEstimateRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns price and duration estimates for an order
 */
export const fetchPanelEstimate = (variables: PanelEstimateVariables) =>
  usabilityhubFetch<
    PanelEstimateResponse,
    PanelEstimateError,
    PanelEstimateRequestBody,
    {},
    {},
    {}
  >({ url: "/api/panel_estimate", method: "post", ...variables });

/**
 * Returns price and duration estimates for an order
 */
export const usePanelEstimate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PanelEstimateResponse,
      PanelEstimateError,
      PanelEstimateVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PanelEstimateResponse,
    PanelEstimateError,
    PanelEstimateVariables
  >(
    (variables: PanelEstimateVariables) =>
      fetchPanelEstimate({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListLanguagesError = Fetcher.ErrorWrapper<undefined>;

export type ListLanguagesResponse = Schemas.Language[];

export type ListLanguagesVariables = UsabilityhubContext["fetcherOptions"];

/**
 * List all languages the platform supports
 */
export const fetchListLanguages = (variables: ListLanguagesVariables) =>
  usabilityhubFetch<
    ListLanguagesResponse,
    ListLanguagesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/languages", method: "get", ...variables });

/**
 * List all languages the platform supports
 */
export const useListLanguages = <TData = ListLanguagesResponse,>(
  variables: ListLanguagesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListLanguagesResponse,
      ListLanguagesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<ListLanguagesResponse, ListLanguagesError, TData>(
    queryKeyFn({
      path: "/api/languages",
      operationId: "listLanguages",
      variables,
    }),
    () => fetchListLanguages({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListPlansError = Fetcher.ErrorWrapper<undefined>;

export type ListPlansResponse = Schemas.Plan[];

export type ListPlansVariables = UsabilityhubContext["fetcherOptions"];

/**
 * List all currently available plans
 */
export const fetchListPlans = (variables: ListPlansVariables) =>
  usabilityhubFetch<ListPlansResponse, ListPlansError, undefined, {}, {}, {}>({
    url: "/api/plans",
    method: "get",
    ...variables,
  });

/**
 * List all currently available plans
 */
export const useListPlans = <TData = ListPlansResponse,>(
  variables: ListPlansVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListPlansResponse, ListPlansError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<ListPlansResponse, ListPlansError, TData>(
    queryKeyFn({ path: "/api/plans", operationId: "listPlans", variables }),
    () => fetchListPlans({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListTranslationsQueryParams = {
  /**
   * The locale to fetch translations for, defaults to the current user's locale
   */
  locale?: string;
  /**
   * Comma-separated list of which translation keys to fetch
   */
  keys: string;
};

export type ListTranslationsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ListTranslationsVariables = {
  queryParams: ListTranslationsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get translated strings for the current user's locale
 */
export const fetchListTranslations = (variables: ListTranslationsVariables) =>
  usabilityhubFetch<
    Record<string, any>,
    ListTranslationsError,
    undefined,
    {},
    ListTranslationsQueryParams,
    {}
  >({ url: "/api/translations", method: "get", ...variables });

/**
 * Get translated strings for the current user's locale
 */
export const useListTranslations = <TData = Record<string, any>,>(
  variables: ListTranslationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      ListTranslationsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<Record<string, any>, ListTranslationsError, TData>(
    queryKeyFn({
      path: "/api/translations",
      operationId: "listTranslations",
      variables,
    }),
    () => fetchListTranslations({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type TrackEventError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 500;
      payload: Responses.R500;
    }
>;

export type TrackEventRequestBody = {
  event: string;
  event_properties?: Record<string, any>;
};

export type TrackEventVariables = {
  body: TrackEventRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Track an event on Ortto, Segment or Eventy
 */
export const fetchTrackEvent = (variables: TrackEventVariables) =>
  usabilityhubFetch<
    Responses.R204,
    TrackEventError,
    TrackEventRequestBody,
    {},
    {},
    {}
  >({ url: "/api/events/track", method: "post", ...variables });

/**
 * Track an event on Ortto, Segment or Eventy
 */
export const useTrackEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      TrackEventError,
      TrackEventVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    TrackEventError,
    TrackEventVariables
  >(
    (variables: TrackEventVariables) =>
      fetchTrackEvent({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UploadRecordingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 500;
      payload: Schemas.Error;
    }
>;

export type UploadRecordingResponse = {
  /**
   * @format uuid
   */
  recording_id: string;
};

export type UploadRecordingRequestBody = {
  /**
   * @format binary
   */
  file: Blob;
  recording_types: Schemas.RecordingType[];
  /**
   * @format date-time
   */
  started_at: string;
  /**
   * @format date-time
   */
  ended_at: string;
};

export type UploadRecordingVariables = {
  body: UploadRecordingRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Upload a recording of a usability test section during test taking
 */
export const fetchUploadRecording = (variables: UploadRecordingVariables) =>
  usabilityhubFetch<
    UploadRecordingResponse,
    UploadRecordingError,
    UploadRecordingRequestBody,
    {},
    {},
    {}
  >({ url: "/api/recordings", method: "post", ...variables });

/**
 * Upload a recording of a usability test section during test taking
 */
export const useUploadRecording = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UploadRecordingResponse,
      UploadRecordingError,
      UploadRecordingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    UploadRecordingResponse,
    UploadRecordingError,
    UploadRecordingVariables
  >(
    (variables: UploadRecordingVariables) =>
      fetchUploadRecording({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListModeratedStudiesQueryParams = {
  show_archived?: boolean;
  search?: string;
  sort_by?: "name" | "created_at" | "updated_at";
  sort_direction?: "asc" | "desc";
};

export type ListModeratedStudiesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ListModeratedStudiesResponse = Schemas.ModeratedStudyListEntry[];

export type ListModeratedStudiesVariables = {
  queryParams?: ListModeratedStudiesQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns all moderated studies for the current account with sorting/filtering options
 */
export const fetchListModeratedStudies = (
  variables: ListModeratedStudiesVariables,
) =>
  usabilityhubFetch<
    ListModeratedStudiesResponse,
    ListModeratedStudiesError,
    undefined,
    {},
    ListModeratedStudiesQueryParams,
    {}
  >({ url: "/api/moderated_studies", method: "get", ...variables });

/**
 * Returns all moderated studies for the current account with sorting/filtering options
 */
export const useListModeratedStudies = <TData = ListModeratedStudiesResponse,>(
  variables: ListModeratedStudiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListModeratedStudiesResponse,
      ListModeratedStudiesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListModeratedStudiesResponse,
    ListModeratedStudiesError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies",
      operationId: "listModeratedStudies",
      variables,
    }),
    () => fetchListModeratedStudies({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PostApiModeratedStudiesModeratedStudyIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type PostApiModeratedStudiesModeratedStudyIdResponse = {
  moderated_study_id: string;
};

export type PostApiModeratedStudiesModeratedStudyIdRequestBody = {
  internal_name: string;
  timezone: string;
};

export type PostApiModeratedStudiesModeratedStudyIdVariables = {
  body: PostApiModeratedStudiesModeratedStudyIdRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a newModerated Study and returns the UUID.
 */
export const fetchPostApiModeratedStudiesModeratedStudyId = (
  variables: PostApiModeratedStudiesModeratedStudyIdVariables,
) =>
  usabilityhubFetch<
    PostApiModeratedStudiesModeratedStudyIdResponse,
    PostApiModeratedStudiesModeratedStudyIdError,
    PostApiModeratedStudiesModeratedStudyIdRequestBody,
    {},
    {},
    {}
  >({ url: "/api/moderated_studies", method: "post", ...variables });

/**
 * Creates a newModerated Study and returns the UUID.
 */
export const usePostApiModeratedStudiesModeratedStudyId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostApiModeratedStudiesModeratedStudyIdResponse,
      PostApiModeratedStudiesModeratedStudyIdError,
      PostApiModeratedStudiesModeratedStudyIdVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PostApiModeratedStudiesModeratedStudyIdResponse,
    PostApiModeratedStudiesModeratedStudyIdError,
    PostApiModeratedStudiesModeratedStudyIdVariables
  >(
    (variables: PostApiModeratedStudiesModeratedStudyIdVariables) =>
      fetchPostApiModeratedStudiesModeratedStudyId({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type GetModeratedStudyPathParams = {
  /**
   * Moderated Study ID
   */
  moderatedStudyId: string;
};

export type GetModeratedStudyError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetModeratedStudyVariables = {
  pathParams: GetModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns a researcher view of a moderated study with nested attributes for other pieces that will be needed on the edit page.
 */
export const fetchGetModeratedStudy = (variables: GetModeratedStudyVariables) =>
  usabilityhubFetch<
    Schemas.ModeratedStudy,
    GetModeratedStudyError,
    undefined,
    {},
    {},
    GetModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}",
    method: "get",
    ...variables,
  });

/**
 * Returns a researcher view of a moderated study with nested attributes for other pieces that will be needed on the edit page.
 */
export const useGetModeratedStudy = <TData = Schemas.ModeratedStudy,>(
  variables: GetModeratedStudyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ModeratedStudy,
      GetModeratedStudyError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.ModeratedStudy,
    GetModeratedStudyError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}",
      operationId: "getModeratedStudy",
      variables,
    }),
    () => fetchGetModeratedStudy({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PutApiModeratedStudiesIdPathParams = {
  /**
   * Moderated Study ID
   */
  moderatedStudyId: string;
};

export type PutApiModeratedStudiesIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type PutApiModeratedStudiesIdRequestBody = {
  internal_name: string;
  external_name?: string;
  description?: string;
};

export type PutApiModeratedStudiesIdVariables = {
  body: PutApiModeratedStudiesIdRequestBody;
  pathParams: PutApiModeratedStudiesIdPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchPutApiModeratedStudiesId = (
  variables: PutApiModeratedStudiesIdVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    PutApiModeratedStudiesIdError,
    PutApiModeratedStudiesIdRequestBody,
    {},
    {},
    PutApiModeratedStudiesIdPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}",
    method: "put",
    ...variables,
  });

/**
 * TODO
 */
export const usePutApiModeratedStudiesId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      PutApiModeratedStudiesIdError,
      PutApiModeratedStudiesIdVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    PutApiModeratedStudiesIdError,
    PutApiModeratedStudiesIdVariables
  >(
    (variables: PutApiModeratedStudiesIdVariables) =>
      fetchPutApiModeratedStudiesId({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetModeratedStudyLocationsPathParams = {
  /**
   * Moderated Study ID
   */
  moderatedStudyId: string;
};

export type GetModeratedStudyLocationsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetModeratedStudyLocationsResponse = {
  locations: {
    type: "country" | "state" | "city";
    id: string;
  }[];
};

export type GetModeratedStudyLocationsVariables = {
  pathParams: GetModeratedStudyLocationsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns a list of all locations that are used on an application for this study.
 */
export const fetchGetModeratedStudyLocations = (
  variables: GetModeratedStudyLocationsVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyLocationsResponse,
    GetModeratedStudyLocationsError,
    undefined,
    {},
    {},
    GetModeratedStudyLocationsPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/locations",
    method: "get",
    ...variables,
  });

/**
 * Returns a list of all locations that are used on an application for this study.
 */
export const useGetModeratedStudyLocations = <
  TData = GetModeratedStudyLocationsResponse,
>(
  variables: GetModeratedStudyLocationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyLocationsResponse,
      GetModeratedStudyLocationsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyLocationsResponse,
    GetModeratedStudyLocationsError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/locations",
      operationId: "getModeratedStudyLocations",
      variables,
    }),
    () => fetchGetModeratedStudyLocations({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetModeratedStudySummaryPathParams = {
  /**
   * ID of the moderated study
   */
  moderatedStudyId: string;
};

export type GetModeratedStudySummaryError = Fetcher.ErrorWrapper<undefined>;

export type GetModeratedStudySummaryResponse = {
  has_active_recruitment_link: boolean;
  applications_count: number;
  recordings_count: number;
  bookings_count: number;
  has_available_booking_slots: boolean;
};

export type GetModeratedStudySummaryVariables = {
  pathParams: GetModeratedStudySummaryPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Gets the summary of a moderated study. This includes whether the study has an active recruitment link.
 */
export const fetchGetModeratedStudySummary = (
  variables: GetModeratedStudySummaryVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudySummaryResponse,
    GetModeratedStudySummaryError,
    undefined,
    {},
    {},
    GetModeratedStudySummaryPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/summary",
    method: "get",
    ...variables,
  });

/**
 * Gets the summary of a moderated study. This includes whether the study has an active recruitment link.
 */
export const useGetModeratedStudySummary = <
  TData = GetModeratedStudySummaryResponse,
>(
  variables: GetModeratedStudySummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudySummaryResponse,
      GetModeratedStudySummaryError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudySummaryResponse,
    GetModeratedStudySummaryError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/summary",
      operationId: "getModeratedStudySummary",
      variables,
    }),
    () => fetchGetModeratedStudySummary({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DisableModeratedStudyScreenerPathParams = {
  moderatedStudyId: string;
};

export type DisableModeratedStudyScreenerError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type DisableModeratedStudyScreenerVariables = {
  pathParams: DisableModeratedStudyScreenerPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Disables a moderated study screener so that it will no longer be editable in the test builder or visible to the applicant.
 */
export const fetchDisableModeratedStudyScreener = (
  variables: DisableModeratedStudyScreenerVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DisableModeratedStudyScreenerError,
    undefined,
    {},
    {},
    DisableModeratedStudyScreenerPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/disable_screener",
    method: "post",
    ...variables,
  });

/**
 * Disables a moderated study screener so that it will no longer be editable in the test builder or visible to the applicant.
 */
export const useDisableModeratedStudyScreener = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DisableModeratedStudyScreenerError,
      DisableModeratedStudyScreenerVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DisableModeratedStudyScreenerError,
    DisableModeratedStudyScreenerVariables
  >(
    (variables: DisableModeratedStudyScreenerVariables) =>
      fetchDisableModeratedStudyScreener({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type EnableModeratedStudyScreenerPathParams = {
  moderatedStudyId: string;
};

export type EnableModeratedStudyScreenerError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type EnableModeratedStudyScreenerVariables = {
  pathParams: EnableModeratedStudyScreenerPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Enables a moderated study screener so that it will be both editable in the test builder and visible to the applicant.
 */
export const fetchEnableModeratedStudyScreener = (
  variables: EnableModeratedStudyScreenerVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    EnableModeratedStudyScreenerError,
    undefined,
    {},
    {},
    EnableModeratedStudyScreenerPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/enable_screener",
    method: "post",
    ...variables,
  });

/**
 * Enables a moderated study screener so that it will be both editable in the test builder and visible to the applicant.
 */
export const useEnableModeratedStudyScreener = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      EnableModeratedStudyScreenerError,
      EnableModeratedStudyScreenerVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    EnableModeratedStudyScreenerError,
    EnableModeratedStudyScreenerVariables
  >(
    (variables: EnableModeratedStudyScreenerVariables) =>
      fetchEnableModeratedStudyScreener({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type AddRecordingToModeratedStudyBookingPathParams = {
  moderatedStudyId: string;
  moderatedStudyBookingId: string;
};

export type AddRecordingToModeratedStudyBookingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type AddRecordingToModeratedStudyBookingResponse = {
  /**
   * Unique ID of the job processing the recording
   *
   * @format uuid
   */
  job_id: string;
};

export type AddRecordingToModeratedStudyBookingRequestBody = {
  key: string;
  filename: string;
};

export type AddRecordingToModeratedStudyBookingVariables = {
  body: AddRecordingToModeratedStudyBookingRequestBody;
  pathParams: AddRecordingToModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Adds a recording to a Moderated Study Booking
 */
export const fetchAddRecordingToModeratedStudyBooking = (
  variables: AddRecordingToModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    AddRecordingToModeratedStudyBookingResponse,
    AddRecordingToModeratedStudyBookingError,
    AddRecordingToModeratedStudyBookingRequestBody,
    {},
    {},
    AddRecordingToModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings",
    method: "post",
    ...variables,
  });

/**
 * Adds a recording to a Moderated Study Booking
 */
export const useAddRecordingToModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AddRecordingToModeratedStudyBookingResponse,
      AddRecordingToModeratedStudyBookingError,
      AddRecordingToModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    AddRecordingToModeratedStudyBookingResponse,
    AddRecordingToModeratedStudyBookingError,
    AddRecordingToModeratedStudyBookingVariables
  >(
    (variables: AddRecordingToModeratedStudyBookingVariables) =>
      fetchAddRecordingToModeratedStudyBooking({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type GetModeratedStudyBookingRecordingsPathParams = {
  moderatedStudyId: string;
  moderatedStudyBookingId: string;
};

export type GetModeratedStudyBookingRecordingsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetModeratedStudyBookingRecordingsResponse =
  Schemas.ModeratedStudyBookingRecording[];

export type GetModeratedStudyBookingRecordingsVariables = {
  pathParams: GetModeratedStudyBookingRecordingsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get the Moderated Study Booking recording
 */
export const fetchGetModeratedStudyBookingRecordings = (
  variables: GetModeratedStudyBookingRecordingsVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyBookingRecordingsResponse,
    GetModeratedStudyBookingRecordingsError,
    undefined,
    {},
    {},
    GetModeratedStudyBookingRecordingsPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings",
    method: "get",
    ...variables,
  });

/**
 * Get the Moderated Study Booking recording
 */
export const useGetModeratedStudyBookingRecordings = <
  TData = GetModeratedStudyBookingRecordingsResponse,
>(
  variables: GetModeratedStudyBookingRecordingsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyBookingRecordingsResponse,
      GetModeratedStudyBookingRecordingsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyBookingRecordingsResponse,
    GetModeratedStudyBookingRecordingsError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/bookings/{moderated_study_booking_id}/recordings",
      operationId: "getModeratedStudyBookingRecordings",
      variables,
    }),
    () =>
      fetchGetModeratedStudyBookingRecordings({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateModeratedStudyBookingRecordingPathParams = {
  moderatedStudyId: string;
  moderatedStudyBookingId: string;
  recordingId: string;
};

export type UpdateModeratedStudyBookingRecordingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateModeratedStudyBookingRecordingRequestBody = {
  filename?: string;
};

export type UpdateModeratedStudyBookingRecordingVariables = {
  body?: UpdateModeratedStudyBookingRecordingRequestBody;
  pathParams: UpdateModeratedStudyBookingRecordingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a Moderated Study Booking recording
 */
export const fetchUpdateModeratedStudyBookingRecording = (
  variables: UpdateModeratedStudyBookingRecordingVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateModeratedStudyBookingRecordingError,
    UpdateModeratedStudyBookingRecordingRequestBody,
    {},
    {},
    UpdateModeratedStudyBookingRecordingPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}",
    method: "patch",
    ...variables,
  });

/**
 * Update a Moderated Study Booking recording
 */
export const useUpdateModeratedStudyBookingRecording = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateModeratedStudyBookingRecordingError,
      UpdateModeratedStudyBookingRecordingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateModeratedStudyBookingRecordingError,
    UpdateModeratedStudyBookingRecordingVariables
  >(
    (variables: UpdateModeratedStudyBookingRecordingVariables) =>
      fetchUpdateModeratedStudyBookingRecording({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type DeleteModeratedStudyBookingRecordingPathParams = {
  moderatedStudyId: string;
  moderatedStudyBookingId: string;
  recordingId: string;
};

export type DeleteModeratedStudyBookingRecordingError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type DeleteModeratedStudyBookingRecordingVariables = {
  pathParams: DeleteModeratedStudyBookingRecordingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Delete a recording from a Moderated Study Booking
 */
export const fetchDeleteModeratedStudyBookingRecording = (
  variables: DeleteModeratedStudyBookingRecordingVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteModeratedStudyBookingRecordingError,
    undefined,
    {},
    {},
    DeleteModeratedStudyBookingRecordingPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}",
    method: "delete",
    ...variables,
  });

/**
 * Delete a recording from a Moderated Study Booking
 */
export const useDeleteModeratedStudyBookingRecording = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteModeratedStudyBookingRecordingError,
      DeleteModeratedStudyBookingRecordingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteModeratedStudyBookingRecordingError,
    DeleteModeratedStudyBookingRecordingVariables
  >(
    (variables: DeleteModeratedStudyBookingRecordingVariables) =>
      fetchDeleteModeratedStudyBookingRecording({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type GetModeratedStudyInterviewTranscriptPathParams = {
  /**
   * ID of the moderated study
   */
  moderatedStudyId: string;
  /**
   * ID of the booking
   */
  moderatedStudyBookingId: string;
  /**
   * ID of the recording
   */
  recordingId: string;
};

export type GetModeratedStudyInterviewTranscriptError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetModeratedStudyInterviewTranscriptVariables = {
  pathParams: GetModeratedStudyInterviewTranscriptPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Retrieve a transcript
 */
export const fetchGetModeratedStudyInterviewTranscript = (
  variables: GetModeratedStudyInterviewTranscriptVariables,
) =>
  usabilityhubFetch<
    Schemas.InterviewTranscript,
    GetModeratedStudyInterviewTranscriptError,
    undefined,
    {},
    {},
    GetModeratedStudyInterviewTranscriptPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}/transcript",
    method: "get",
    ...variables,
  });

/**
 * Retrieve a transcript
 */
export const useGetModeratedStudyInterviewTranscript = <
  TData = Schemas.InterviewTranscript,
>(
  variables: GetModeratedStudyInterviewTranscriptVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.InterviewTranscript,
      GetModeratedStudyInterviewTranscriptError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.InterviewTranscript,
    GetModeratedStudyInterviewTranscriptError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/bookings/{moderated_study_booking_id}/recordings/{recording_id}/transcript",
      operationId: "getModeratedStudyInterviewTranscript",
      variables,
    }),
    () =>
      fetchGetModeratedStudyInterviewTranscript({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateModeratedStudyInterviewTranscriptPathParams = {
  /**
   * ID of the moderated study
   */
  moderatedStudyId: string;
  /**
   * ID of the booking
   */
  moderatedStudyBookingId: string;
  /**
   * ID of the recording
   */
  recordingId: string;
};

export type CreateModeratedStudyInterviewTranscriptError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CreateModeratedStudyInterviewTranscriptRequestBody = {
  language_code: string | null;
};

export type CreateModeratedStudyInterviewTranscriptVariables = {
  body: CreateModeratedStudyInterviewTranscriptRequestBody;
  pathParams: CreateModeratedStudyInterviewTranscriptPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Request an automated transcription
 */
export const fetchCreateModeratedStudyInterviewTranscript = (
  variables: CreateModeratedStudyInterviewTranscriptVariables,
) =>
  usabilityhubFetch<
    Schemas.InterviewTranscript,
    CreateModeratedStudyInterviewTranscriptError,
    CreateModeratedStudyInterviewTranscriptRequestBody,
    {},
    {},
    CreateModeratedStudyInterviewTranscriptPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}/transcript",
    method: "post",
    ...variables,
  });

/**
 * Request an automated transcription
 */
export const useCreateModeratedStudyInterviewTranscript = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.InterviewTranscript,
      CreateModeratedStudyInterviewTranscriptError,
      CreateModeratedStudyInterviewTranscriptVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.InterviewTranscript,
    CreateModeratedStudyInterviewTranscriptError,
    CreateModeratedStudyInterviewTranscriptVariables
  >(
    (variables: CreateModeratedStudyInterviewTranscriptVariables) =>
      fetchCreateModeratedStudyInterviewTranscript({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type UpdateModeratedStudyInterviewTranscriptPathParams = {
  /**
   * ID of the moderated study
   */
  moderatedStudyId: string;
  /**
   * ID of the booking
   */
  moderatedStudyBookingId: string;
  /**
   * ID of the recording
   */
  recordingId: string;
};

export type UpdateModeratedStudyInterviewTranscriptError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateModeratedStudyInterviewTranscriptRequestBody = {
  speaker_names?: string[];
};

export type UpdateModeratedStudyInterviewTranscriptVariables = {
  body?: UpdateModeratedStudyInterviewTranscriptRequestBody;
  pathParams: UpdateModeratedStudyInterviewTranscriptPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a transcript
 */
export const fetchUpdateModeratedStudyInterviewTranscript = (
  variables: UpdateModeratedStudyInterviewTranscriptVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateModeratedStudyInterviewTranscriptError,
    UpdateModeratedStudyInterviewTranscriptRequestBody,
    {},
    {},
    UpdateModeratedStudyInterviewTranscriptPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}/transcript",
    method: "put",
    ...variables,
  });

/**
 * Update a transcript
 */
export const useUpdateModeratedStudyInterviewTranscript = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateModeratedStudyInterviewTranscriptError,
      UpdateModeratedStudyInterviewTranscriptVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateModeratedStudyInterviewTranscriptError,
    UpdateModeratedStudyInterviewTranscriptVariables
  >(
    (variables: UpdateModeratedStudyInterviewTranscriptVariables) =>
      fetchUpdateModeratedStudyInterviewTranscript({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type DeleteModeratedStudyInterviewTranscriptPathParams = {
  /**
   * ID of the moderated study
   */
  moderatedStudyId: string;
  /**
   * ID of the booking
   */
  moderatedStudyBookingId: string;
  /**
   * ID of the recording
   */
  recordingId: string;
};

export type DeleteModeratedStudyInterviewTranscriptError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type DeleteModeratedStudyInterviewTranscriptVariables = {
  pathParams: DeleteModeratedStudyInterviewTranscriptPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Delete an interview transcript from a Moderated Study Booking
 */
export const fetchDeleteModeratedStudyInterviewTranscript = (
  variables: DeleteModeratedStudyInterviewTranscriptVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteModeratedStudyInterviewTranscriptError,
    undefined,
    {},
    {},
    DeleteModeratedStudyInterviewTranscriptPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}/transcript",
    method: "delete",
    ...variables,
  });

/**
 * Delete an interview transcript from a Moderated Study Booking
 */
export const useDeleteModeratedStudyInterviewTranscript = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteModeratedStudyInterviewTranscriptError,
      DeleteModeratedStudyInterviewTranscriptVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteModeratedStudyInterviewTranscriptError,
    DeleteModeratedStudyInterviewTranscriptVariables
  >(
    (variables: DeleteModeratedStudyInterviewTranscriptVariables) =>
      fetchDeleteModeratedStudyInterviewTranscript({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type UploadModeratedStudyInterviewTranscriptPathParams = {
  /**
   * ID of the moderated study
   */
  moderatedStudyId: string;
  /**
   * ID of the booking
   */
  moderatedStudyBookingId: string;
  /**
   * ID of the recording
   */
  recordingId: string;
};

export type UploadModeratedStudyInterviewTranscriptError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UploadModeratedStudyInterviewTranscriptRequestBody = {
  text: string;
};

export type UploadModeratedStudyInterviewTranscriptVariables = {
  body: UploadModeratedStudyInterviewTranscriptRequestBody;
  pathParams: UploadModeratedStudyInterviewTranscriptPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Upload a transcript
 */
export const fetchUploadModeratedStudyInterviewTranscript = (
  variables: UploadModeratedStudyInterviewTranscriptVariables,
) =>
  usabilityhubFetch<
    Schemas.InterviewTranscript,
    UploadModeratedStudyInterviewTranscriptError,
    UploadModeratedStudyInterviewTranscriptRequestBody,
    {},
    {},
    UploadModeratedStudyInterviewTranscriptPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/recordings/{recordingId}/transcript/upload",
    method: "post",
    ...variables,
  });

/**
 * Upload a transcript
 */
export const useUploadModeratedStudyInterviewTranscript = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.InterviewTranscript,
      UploadModeratedStudyInterviewTranscriptError,
      UploadModeratedStudyInterviewTranscriptVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.InterviewTranscript,
    UploadModeratedStudyInterviewTranscriptError,
    UploadModeratedStudyInterviewTranscriptVariables
  >(
    (variables: UploadModeratedStudyInterviewTranscriptVariables) =>
      fetchUploadModeratedStudyInterviewTranscript({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type UpdateScreenerRequiredPathParams = {
  /**
   * ID of the moderated study application to edit
   */
  moderatedStudyApplicationId: string;
};

export type UpdateScreenerRequiredError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type UpdateScreenerRequiredVariables = {
  pathParams: UpdateScreenerRequiredPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Disable screener required when screener questions are deleted
 */
export const fetchUpdateScreenerRequired = (
  variables: UpdateScreenerRequiredVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateScreenerRequiredError,
    undefined,
    {},
    {},
    UpdateScreenerRequiredPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/update_screener_required",
    method: "post",
    ...variables,
  });

/**
 * Disable screener required when screener questions are deleted
 */
export const useUpdateScreenerRequired = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateScreenerRequiredError,
      UpdateScreenerRequiredVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateScreenerRequiredError,
    UpdateScreenerRequiredVariables
  >(
    (variables: UpdateScreenerRequiredVariables) =>
      fetchUpdateScreenerRequired({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListModeratedStudyBookingSlotsPathParams = {
  moderatedStudyApplicationId: string;
};

export type ListModeratedStudyBookingSlotsQueryParams = {
  /**
   * Which year to check for available slots
   */
  year: number;
  /**
   * Which month to check for available slots
   */
  month: number;
  /**
   * Which timezone to present available slots in
   */
  timezone: string;
};

export type ListModeratedStudyBookingSlotsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ListModeratedStudyBookingSlotsResponse = {
  booking_slots: {
    /**
     * @format date-time
     */
    start_time: string;
  }[];
  earlier_data: boolean;
  later_data: boolean;
  /**
   * Indicates whether the moderated study has available booking slots or not.
   */
  has_available_booking_slots: boolean;
};

export type ListModeratedStudyBookingSlotsVariables = {
  pathParams: ListModeratedStudyBookingSlotsPathParams;
  queryParams: ListModeratedStudyBookingSlotsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns available booking slots for a study so that candidates can select one to use when creating a booking.  One month of data will be returned at a time, with a 1 day buffer either side so the user can display a whole month in their local timezone.
 */
export const fetchListModeratedStudyBookingSlots = (
  variables: ListModeratedStudyBookingSlotsVariables,
) =>
  usabilityhubFetch<
    ListModeratedStudyBookingSlotsResponse,
    ListModeratedStudyBookingSlotsError,
    undefined,
    {},
    ListModeratedStudyBookingSlotsQueryParams,
    ListModeratedStudyBookingSlotsPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/booking_slots",
    method: "get",
    ...variables,
  });

/**
 * Returns available booking slots for a study so that candidates can select one to use when creating a booking.  One month of data will be returned at a time, with a 1 day buffer either side so the user can display a whole month in their local timezone.
 */
export const useListModeratedStudyBookingSlots = <
  TData = ListModeratedStudyBookingSlotsResponse,
>(
  variables: ListModeratedStudyBookingSlotsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListModeratedStudyBookingSlotsResponse,
      ListModeratedStudyBookingSlotsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListModeratedStudyBookingSlotsResponse,
    ListModeratedStudyBookingSlotsError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_study_applications/{moderated_study_application_id}/booking_slots",
      operationId: "listModeratedStudyBookingSlots",
      variables,
    }),
    () =>
      fetchListModeratedStudyBookingSlots({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type TrackJoinButtonClickPathParams = {
  /**
   * ID of the moderated study application
   */
  moderatedStudyApplicationId: string;
  /**
   * ID of the moderated study booking
   */
  moderatedStudyBookingId: string;
};

export type TrackJoinButtonClickError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type TrackJoinButtonClickRequestBody = {
  missing_meeting_link: boolean;
};

export type TrackJoinButtonClickVariables = {
  body: TrackJoinButtonClickRequestBody;
  pathParams: TrackJoinButtonClickPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Track clicking the join call button
 */
export const fetchTrackJoinButtonClick = (
  variables: TrackJoinButtonClickVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    TrackJoinButtonClickError,
    TrackJoinButtonClickRequestBody,
    {},
    {},
    TrackJoinButtonClickPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/moderated_study_bookings/{moderatedStudyBookingId}/join_button_click",
    method: "post",
    ...variables,
  });

/**
 * Track clicking the join call button
 */
export const useTrackJoinButtonClick = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      TrackJoinButtonClickError,
      TrackJoinButtonClickVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    TrackJoinButtonClickError,
    TrackJoinButtonClickVariables
  >(
    (variables: TrackJoinButtonClickVariables) =>
      fetchTrackJoinButtonClick({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PreviewModeratedStudyBookingSlotsPathParams = {
  moderatedStudyId: string;
};

export type PreviewModeratedStudyBookingSlotsQueryParams = {
  /**
   * Which year to check for available slots
   */
  year: number;
  /**
   * Which month to check for available slots
   */
  month: number;
  /**
   * Which timezone to present available slots in
   */
  timezone: string;
};

export type PreviewModeratedStudyBookingSlotsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PreviewModeratedStudyBookingSlotsResponse = {
  booking_slots: {
    /**
     * @format date-time
     */
    start_time: string;
  }[];
  earlier_data: boolean;
  later_data: boolean;
  /**
   * Indicates whether the moderated study has available booking slots or not.
   */
  has_available_booking_slots: boolean;
};

export type PreviewModeratedStudyBookingSlotsVariables = {
  pathParams: PreviewModeratedStudyBookingSlotsPathParams;
  queryParams: PreviewModeratedStudyBookingSlotsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns preview of available booking slots for a study so that candidates can select one to use when creating a booking.  One month of data will be returned at a time, with a 1 day buffer either side so the user can display a whole month in their local timezone.
 */
export const fetchPreviewModeratedStudyBookingSlots = (
  variables: PreviewModeratedStudyBookingSlotsVariables,
) =>
  usabilityhubFetch<
    PreviewModeratedStudyBookingSlotsResponse,
    PreviewModeratedStudyBookingSlotsError,
    undefined,
    {},
    PreviewModeratedStudyBookingSlotsQueryParams,
    PreviewModeratedStudyBookingSlotsPathParams
  >({
    url: "/api/moderated_study_previews/{moderatedStudyId}/booking_slots",
    method: "get",
    ...variables,
  });

/**
 * Returns preview of available booking slots for a study so that candidates can select one to use when creating a booking.  One month of data will be returned at a time, with a 1 day buffer either side so the user can display a whole month in their local timezone.
 */
export const usePreviewModeratedStudyBookingSlots = <
  TData = PreviewModeratedStudyBookingSlotsResponse,
>(
  variables: PreviewModeratedStudyBookingSlotsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      PreviewModeratedStudyBookingSlotsResponse,
      PreviewModeratedStudyBookingSlotsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    PreviewModeratedStudyBookingSlotsResponse,
    PreviewModeratedStudyBookingSlotsError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_study_previews/{moderated_study_id}/booking_slots",
      operationId: "previewModeratedStudyBookingSlots",
      variables,
    }),
    () =>
      fetchPreviewModeratedStudyBookingSlots({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateProfileError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type UpdateProfileResponse = {
  message: string;
  user: Schemas.User;
};

export type UpdateProfileRequestBody = {
  name?: string;
  preferred_name?: string;
  job_role?: string;
  custom_job_role?: string;
  email?: string;
  email_general?: "true" | "false";
  email_newsletters_and_promotions?: "true" | "false";
  email_comment_notifications?: "true" | "false";
  email_product_updates?: "true" | "false";
  /**
   * @format binary
   */
  avatar?: Blob;
};

export type UpdateProfileVariables = {
  body?: UpdateProfileRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update the current user's profile
 */
export const fetchUpdateProfile = (variables: UpdateProfileVariables) =>
  usabilityhubFetch<
    UpdateProfileResponse,
    UpdateProfileError,
    UpdateProfileRequestBody,
    {},
    {},
    {}
  >({ url: "/api/profile", method: "patch", ...variables });

/**
 * Update the current user's profile
 */
export const useUpdateProfile = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateProfileResponse,
      UpdateProfileError,
      UpdateProfileVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    UpdateProfileResponse,
    UpdateProfileError,
    UpdateProfileVariables
  >(
    (variables: UpdateProfileVariables) =>
      fetchUpdateProfile({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetNoticesError = Fetcher.ErrorWrapper<undefined>;

export type GetNoticesResponse = {
  user_notices: Schemas.Notice[];
};

export type GetNoticesVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Returns an array of all the user_dismissed_notices that have not yet been dismissed
 */
export const fetchGetNotices = (variables: GetNoticesVariables) =>
  usabilityhubFetch<GetNoticesResponse, GetNoticesError, undefined, {}, {}, {}>(
    { url: "/api/user_notices", method: "get", ...variables },
  );

/**
 * Returns an array of all the user_dismissed_notices that have not yet been dismissed
 */
export const useGetNotices = <TData = GetNoticesResponse,>(
  variables: GetNoticesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetNoticesResponse, GetNoticesError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<GetNoticesResponse, GetNoticesError, TData>(
    queryKeyFn({
      path: "/api/user_notices",
      operationId: "getNotices",
      variables,
    }),
    () => fetchGetNotices({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DismissNoticePathParams = {
  slug: Schemas.Notice;
};

export type DismissNoticeError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type DismissNoticeVariables = {
  pathParams: DismissNoticePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Mark a user notice as dismissed for the current user
 */
export const fetchDismissNotice = (variables: DismissNoticeVariables) =>
  usabilityhubFetch<
    Responses.R204,
    DismissNoticeError,
    undefined,
    {},
    {},
    DismissNoticePathParams
  >({ url: "/api/user_notices/{slug}/dismiss", method: "post", ...variables });

/**
 * Mark a user notice as dismissed for the current user
 */
export const useDismissNotice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DismissNoticeError,
      DismissNoticeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DismissNoticeError,
    DismissNoticeVariables
  >(
    (variables: DismissNoticeVariables) =>
      fetchDismissNotice({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetRedirectSessionError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type GetRedirectSessionVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Redirects session with uuid to persist on new domain
 */
export const fetchGetRedirectSession = (
  variables: GetRedirectSessionVariables,
) =>
  usabilityhubFetch<string, GetRedirectSessionError, undefined, {}, {}, {}>({
    url: "/redirect_session",
    method: "get",
    ...variables,
  });

/**
 * Redirects session with uuid to persist on new domain
 */
export const useGetRedirectSession = <TData = string,>(
  variables: GetRedirectSessionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, GetRedirectSessionError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<string, GetRedirectSessionError, TData>(
    queryKeyFn({
      path: "/redirect_session",
      operationId: "getRedirectSession",
      variables,
    }),
    () => fetchGetRedirectSession({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PostRedirectSessionError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type PostRedirectSessionRequestBody = {
  uuid: string;
};

export type PostRedirectSessionVariables = {
  body: PostRedirectSessionRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Matches sessions to uuid
 */
export const fetchPostRedirectSession = (
  variables: PostRedirectSessionVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    PostRedirectSessionError,
    PostRedirectSessionRequestBody,
    {},
    {},
    {}
  >({ url: "/redirect_session", method: "post", ...variables });

/**
 * Matches sessions to uuid
 */
export const usePostRedirectSession = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      PostRedirectSessionError,
      PostRedirectSessionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    PostRedirectSessionError,
    PostRedirectSessionVariables
  >(
    (variables: PostRedirectSessionVariables) =>
      fetchPostRedirectSession({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CreateMultipartUploadError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type CreateMultipartUploadResponse = {
  key: string;
  upload_id: string;
};

export type CreateMultipartUploadVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Create a new multipart upload
 */
export const fetchCreateMultipartUpload = (
  variables: CreateMultipartUploadVariables,
) =>
  usabilityhubFetch<
    CreateMultipartUploadResponse,
    CreateMultipartUploadError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/storage/multipart", method: "post", ...variables });

/**
 * Create a new multipart upload
 */
export const useCreateMultipartUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateMultipartUploadResponse,
      CreateMultipartUploadError,
      CreateMultipartUploadVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateMultipartUploadResponse,
    CreateMultipartUploadError,
    CreateMultipartUploadVariables
  >(
    (variables: CreateMultipartUploadVariables) =>
      fetchCreateMultipartUpload({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListPartsPathParams = {
  /**
   * Unique identifier for the object being uploaded
   */
  key: string;
  /**
   * Unique identifier for the multipart upload
   */
  uploadId: string;
};

export type ListPartsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type ListPartsResponse = {
  part_number: number;
  size: number;
  etag: string;
}[];

export type ListPartsVariables = {
  pathParams: ListPartsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * List the parts in a multipart upload
 */
export const fetchListParts = (variables: ListPartsVariables) =>
  usabilityhubFetch<
    ListPartsResponse,
    ListPartsError,
    undefined,
    {},
    {},
    ListPartsPathParams
  >({
    url: "/api/storage/multipart/{key}/{uploadId}",
    method: "get",
    ...variables,
  });

/**
 * List the parts in a multipart upload
 */
export const useListParts = <TData = ListPartsResponse,>(
  variables: ListPartsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListPartsResponse, ListPartsError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<ListPartsResponse, ListPartsError, TData>(
    queryKeyFn({
      path: "/api/storage/multipart/{key}/{upload_id}",
      operationId: "listParts",
      variables,
    }),
    () => fetchListParts({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CompleteMultipartUploadPathParams = {
  /**
   * Unique identifier for the object being uploaded
   */
  key: string;
  /**
   * Unique identifier for the multipart upload
   */
  uploadId: string;
};

export type CompleteMultipartUploadError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type CompleteMultipartUploadResponse = {
  /**
   * @format uri
   */
  location: string;
};

export type CompleteMultipartUploadRequestBody = {
  parts: {
    part_number: number;
    etag: string;
  }[];
};

export type CompleteMultipartUploadVariables = {
  body: CompleteMultipartUploadRequestBody;
  pathParams: CompleteMultipartUploadPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Complete a multipart upload
 */
export const fetchCompleteMultipartUpload = (
  variables: CompleteMultipartUploadVariables,
) =>
  usabilityhubFetch<
    CompleteMultipartUploadResponse,
    CompleteMultipartUploadError,
    CompleteMultipartUploadRequestBody,
    {},
    {},
    CompleteMultipartUploadPathParams
  >({
    url: "/api/storage/multipart/{key}/{uploadId}",
    method: "post",
    ...variables,
  });

/**
 * Complete a multipart upload
 */
export const useCompleteMultipartUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CompleteMultipartUploadResponse,
      CompleteMultipartUploadError,
      CompleteMultipartUploadVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CompleteMultipartUploadResponse,
    CompleteMultipartUploadError,
    CompleteMultipartUploadVariables
  >(
    (variables: CompleteMultipartUploadVariables) =>
      fetchCompleteMultipartUpload({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type AbortMultipartUploadPathParams = {
  /**
   * Unique identifier for the object being uploaded
   */
  key: string;
  /**
   * Unique identifier for the multipart upload
   */
  uploadId: string;
};

export type AbortMultipartUploadError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type AbortMultipartUploadVariables = {
  pathParams: AbortMultipartUploadPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Abort a multipart upload
 */
export const fetchAbortMultipartUpload = (
  variables: AbortMultipartUploadVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    AbortMultipartUploadError,
    undefined,
    {},
    {},
    AbortMultipartUploadPathParams
  >({
    url: "/api/storage/multipart/{key}/{uploadId}",
    method: "delete",
    ...variables,
  });

/**
 * Abort a multipart upload
 */
export const useAbortMultipartUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AbortMultipartUploadError,
      AbortMultipartUploadVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AbortMultipartUploadError,
    AbortMultipartUploadVariables
  >(
    (variables: AbortMultipartUploadVariables) =>
      fetchAbortMultipartUpload({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type SignPartPathParams = {
  /**
   * Unique identifier for the object being uploaded
   */
  key: string;
  /**
   * Unique identifier for the multipart upload
   */
  uploadId: string;
  /**
   * The part of a multipart upload to sign
   */
  partNumber: number;
};

export type SignPartError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type SignPartResponse = {
  /**
   * @format uri
   */
  url: string;
};

export type SignPartVariables = {
  pathParams: SignPartPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a signed URL for part of a multipart upload
 */
export const fetchSignPart = (variables: SignPartVariables) =>
  usabilityhubFetch<
    SignPartResponse,
    SignPartError,
    undefined,
    {},
    {},
    SignPartPathParams
  >({
    url: "/api/storage/multipart/{key}/{uploadId}/{partNumber}",
    method: "post",
    ...variables,
  });

/**
 * Create a signed URL for part of a multipart upload
 */
export const useSignPart = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SignPartResponse,
      SignPartError,
      SignPartVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    SignPartResponse,
    SignPartError,
    SignPartVariables
  >(
    (variables: SignPartVariables) =>
      fetchSignPart({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PostPresignedConfigError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 500;
      payload: Responses.R500;
    }
>;

export type PostPresignedConfigResponse = {
  /**
   * @format uri
   */
  url: string;
  fields: Record<string, any>;
};

export type PostPresignedConfigVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Generate config for a short lived, pre-signed URL to upload a file
 */
export const fetchPostPresignedConfig = (
  variables: PostPresignedConfigVariables,
) =>
  usabilityhubFetch<
    PostPresignedConfigResponse,
    PostPresignedConfigError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/storage/presigned_config", method: "post", ...variables });

/**
 * Generate config for a short lived, pre-signed URL to upload a file
 */
export const usePostPresignedConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostPresignedConfigResponse,
      PostPresignedConfigError,
      PostPresignedConfigVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PostPresignedConfigResponse,
    PostPresignedConfigError,
    PostPresignedConfigVariables
  >(
    (variables: PostPresignedConfigVariables) =>
      fetchPostPresignedConfig({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PostStorageRecordingError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type PostStorageRecordingResponse = {
  id: string;
} & {
  [key: string]: any;
};

export type PostStorageRecordingVariables = {
  body?: Record<string, any>;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Dev and test endpoint for direct uploads
 */
export const fetchPostStorageRecording = (
  variables: PostStorageRecordingVariables,
) =>
  usabilityhubFetch<
    PostStorageRecordingResponse,
    PostStorageRecordingError,
    Record<string, any>,
    {},
    {},
    {}
  >({ url: "/api/storage/recording", method: "post", ...variables });

/**
 * Dev and test endpoint for direct uploads
 */
export const usePostStorageRecording = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostStorageRecordingResponse,
      PostStorageRecordingError,
      PostStorageRecordingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PostStorageRecordingResponse,
    PostStorageRecordingError,
    PostStorageRecordingVariables
  >(
    (variables: PostStorageRecordingVariables) =>
      fetchPostStorageRecording({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetDeletingUserInfoPathParams = {
  userId: number;
};

export type GetDeletingUserInfoError = Fetcher.ErrorWrapper<undefined>;

export type GetDeletingUserInfoResponse = {
  moderated_study_host_count: number;
  moderated_study_active_booking_host_count: number;
};

export type GetDeletingUserInfoVariables = {
  pathParams: GetDeletingUserInfoPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns info about the user to be deleted
 */
export const fetchGetDeletingUserInfo = (
  variables: GetDeletingUserInfoVariables,
) =>
  usabilityhubFetch<
    GetDeletingUserInfoResponse,
    GetDeletingUserInfoError,
    undefined,
    {},
    {},
    GetDeletingUserInfoPathParams
  >({
    url: "/api/team_members/deleting_user_info/{userId}",
    method: "get",
    ...variables,
  });

/**
 * Returns info about the user to be deleted
 */
export const useGetDeletingUserInfo = <TData = GetDeletingUserInfoResponse,>(
  variables: GetDeletingUserInfoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDeletingUserInfoResponse,
      GetDeletingUserInfoError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetDeletingUserInfoResponse,
    GetDeletingUserInfoError,
    TData
  >(
    queryKeyFn({
      path: "/api/team_members/deleting_user_info/{user_id}",
      operationId: "getDeletingUserInfo",
      variables,
    }),
    () => fetchGetDeletingUserInfo({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetTeamMembersSummaryError = Fetcher.ErrorWrapper<undefined>;

export type GetTeamMembersSummaryResponse = {
  collaborators_count: number;
};

export type GetTeamMembersSummaryVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns a summary of an account's team members
 */
export const fetchGetTeamMembersSummary = (
  variables: GetTeamMembersSummaryVariables,
) =>
  usabilityhubFetch<
    GetTeamMembersSummaryResponse,
    GetTeamMembersSummaryError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/team_members/summary", method: "get", ...variables });

/**
 * Returns a summary of an account's team members
 */
export const useGetTeamMembersSummary = <
  TData = GetTeamMembersSummaryResponse,
>(
  variables: GetTeamMembersSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTeamMembersSummaryResponse,
      GetTeamMembersSummaryError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetTeamMembersSummaryResponse,
    GetTeamMembersSummaryError,
    TData
  >(
    queryKeyFn({
      path: "/api/team_members/summary",
      operationId: "getTeamMembersSummary",
      variables,
    }),
    () => fetchGetTeamMembersSummary({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListTeamMembersError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type ListTeamMembersResponse = Schemas.TeamMember[];

export type ListTeamMembersVariables = UsabilityhubContext["fetcherOptions"];

/**
 * List team members
 */
export const fetchListTeamMembers = (variables: ListTeamMembersVariables) =>
  usabilityhubFetch<
    ListTeamMembersResponse,
    ListTeamMembersError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/team_members", method: "get", ...variables });

/**
 * List team members
 */
export const useListTeamMembers = <TData = ListTeamMembersResponse,>(
  variables: ListTeamMembersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListTeamMembersResponse,
      ListTeamMembersError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListTeamMembersResponse,
    ListTeamMembersError,
    TData
  >(
    queryKeyFn({
      path: "/api/team_members",
      operationId: "listTeamMembers",
      variables,
    }),
    () => fetchListTeamMembers({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type InviteTeamMemberError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type InviteTeamMemberRequestBody = {
  name: string | null;
  email: string;
  role: "admin";
};

export type InviteTeamMemberVariables = {
  body: InviteTeamMemberRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Invite a team member to an account
 */
export const fetchInviteTeamMember = (variables: InviteTeamMemberVariables) =>
  usabilityhubFetch<
    Responses.R204,
    InviteTeamMemberError,
    InviteTeamMemberRequestBody,
    {},
    {},
    {}
  >({ url: "/api/team_members", method: "post", ...variables });

/**
 * Invite a team member to an account
 */
export const useInviteTeamMember = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      InviteTeamMemberError,
      InviteTeamMemberVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    InviteTeamMemberError,
    InviteTeamMemberVariables
  >(
    (variables: InviteTeamMemberVariables) =>
      fetchInviteTeamMember({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetTestResultsIndividualResponsePathParams = {
  usabilityTestUniqueId: string;
  responseId: number;
};

export type GetTestResultsIndividualResponseQueryParams = {
  private_id?: string;
};

export type GetTestResultsIndividualResponseError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type GetTestResultsIndividualResponseResponse = {
  id: number;
  usability_test_id: number;
  user_id: number | null;
  order_id: number | null;
  third_party_order_id: number | null;
  deleted_at: string | null;
  deletion_reason:
    | "confusing_test"
    | "inappropriate_test"
    | "incorrect_test_language"
    | "incorrect_profile_language"
    | "skipped"
    | "technical_problem"
    | "images_failed_to_load"
    | "inactive"
    | "canceled_to_start_another_response"
    | "weak_language"
    | "spam"
    | "malicious"
    | "low_effort"
    | "unspecified"
    | "disconnected"
    | "timed_out"
    | "too_fast_response"
    | "test_requested_personal_information"
    | "test_sent_me_offsite";
  device_type: string | null;
  duration_ms: number | null;
  estimated_duration_ms: number | null;
  platform: string | null;
  review_status: number | null;
  automated_review_status: number | null;
  submitted_at: string | null;
  query_parameters: Record<string, any>;
  response_demographic_profile: {
    age: number | null;
    location: {
      type: "country" | "state" | "city";
      id: string;
    } | null;
    demographic_attribute_option_ids: number[];
  } | null;
  cint_respondent_id: string | null;
  screener_response: {
    questions: {
      type: "long_text" | "single_select" | "multi_select" | "short_text";
      text: string;
      answers: string[];
    }[];
  } | null;
};

export type GetTestResultsIndividualResponseVariables = {
  pathParams: GetTestResultsIndividualResponsePathParams;
  queryParams?: GetTestResultsIndividualResponseQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns information about an individual test response
 */
export const fetchGetTestResultsIndividualResponse = (
  variables: GetTestResultsIndividualResponseVariables,
) =>
  usabilityhubFetch<
    GetTestResultsIndividualResponseResponse,
    GetTestResultsIndividualResponseError,
    undefined,
    {},
    GetTestResultsIndividualResponseQueryParams,
    GetTestResultsIndividualResponsePathParams
  >({
    url: "/api/test_results/{usabilityTestUniqueId}/responses/{responseId}",
    method: "get",
    ...variables,
  });

/**
 * Returns information about an individual test response
 */
export const useGetTestResultsIndividualResponse = <
  TData = GetTestResultsIndividualResponseResponse,
>(
  variables: GetTestResultsIndividualResponseVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTestResultsIndividualResponseResponse,
      GetTestResultsIndividualResponseError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetTestResultsIndividualResponseResponse,
    GetTestResultsIndividualResponseError,
    TData
  >(
    queryKeyFn({
      path: "/api/test_results/{usability_test_unique_id}/responses/{response_id}",
      operationId: "getTestResultsIndividualResponse",
      variables,
    }),
    () =>
      fetchGetTestResultsIndividualResponse({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetPanelistNotificationPreferencesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type GetPanelistNotificationPreferencesResponse = {
  notification_preferences: {
    type: string;
    enabled: boolean;
  }[];
};

export type GetPanelistNotificationPreferencesVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Gets the notification preferences for a panelist
 */
export const fetchGetPanelistNotificationPreferences = (
  variables: GetPanelistNotificationPreferencesVariables,
) =>
  usabilityhubFetch<
    GetPanelistNotificationPreferencesResponse,
    GetPanelistNotificationPreferencesError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/notification_preferences",
    method: "get",
    ...variables,
  });

/**
 * Gets the notification preferences for a panelist
 */
export const useGetPanelistNotificationPreferences = <
  TData = GetPanelistNotificationPreferencesResponse,
>(
  variables: GetPanelistNotificationPreferencesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPanelistNotificationPreferencesResponse,
      GetPanelistNotificationPreferencesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetPanelistNotificationPreferencesResponse,
    GetPanelistNotificationPreferencesError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/notification_preferences",
      operationId: "getPanelistNotificationPreferences",
      variables,
    }),
    () =>
      fetchGetPanelistNotificationPreferences({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdatePanelistNotificationPreferenceError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type UpdatePanelistNotificationPreferenceRequestBody = {
  type: "usability_tests" | "moderated_studies" | "usability_test_recordings";
  enabled: boolean;
};

export type UpdatePanelistNotificationPreferenceVariables = {
  body: UpdatePanelistNotificationPreferenceRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates a notification preference for a panelist
 */
export const fetchUpdatePanelistNotificationPreference = (
  variables: UpdatePanelistNotificationPreferenceVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdatePanelistNotificationPreferenceError,
    UpdatePanelistNotificationPreferenceRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/notification_preferences",
    method: "patch",
    ...variables,
  });

/**
 * Updates a notification preference for a panelist
 */
export const useUpdatePanelistNotificationPreference = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdatePanelistNotificationPreferenceError,
      UpdatePanelistNotificationPreferenceVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdatePanelistNotificationPreferenceError,
    UpdatePanelistNotificationPreferenceVariables
  >(
    (variables: UpdatePanelistNotificationPreferenceVariables) =>
      fetchUpdatePanelistNotificationPreference({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ListUsercrowdOrderAssignmentsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type ListUsercrowdOrderAssignmentsResponse = {
  panelist_order_assignments: Schemas.PanelOrderAssignment[];
  moderated_study_order_assignments: Schemas.ModeratedStudyOrderAssignment[];
};

export type ListUsercrowdOrderAssignmentsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns all order assignments for the current panelist
 */
export const fetchListUsercrowdOrderAssignments = (
  variables: ListUsercrowdOrderAssignmentsVariables,
) =>
  usabilityhubFetch<
    ListUsercrowdOrderAssignmentsResponse,
    ListUsercrowdOrderAssignmentsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/order_assignments", method: "get", ...variables });

/**
 * Returns all order assignments for the current panelist
 */
export const useListUsercrowdOrderAssignments = <
  TData = ListUsercrowdOrderAssignmentsResponse,
>(
  variables: ListUsercrowdOrderAssignmentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListUsercrowdOrderAssignmentsResponse,
      ListUsercrowdOrderAssignmentsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListUsercrowdOrderAssignmentsResponse,
    ListUsercrowdOrderAssignmentsError,
    TData
  >(
    queryKeyFn({
      path: "/api/order_assignments",
      operationId: "listUsercrowdOrderAssignments",
      variables,
    }),
    () =>
      fetchListUsercrowdOrderAssignments({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetUsercrowdOrderAssignmentPathParams = {
  id: number;
};

export type GetUsercrowdOrderAssignmentError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetUsercrowdOrderAssignmentVariables = {
  pathParams: GetUsercrowdOrderAssignmentPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns a single order assignments
 */
export const fetchGetUsercrowdOrderAssignment = (
  variables: GetUsercrowdOrderAssignmentVariables,
) =>
  usabilityhubFetch<
    Schemas.PanelOrderAssignment,
    GetUsercrowdOrderAssignmentError,
    undefined,
    {},
    {},
    GetUsercrowdOrderAssignmentPathParams
  >({ url: "/api/order_assignments/{id}", method: "get", ...variables });

/**
 * Returns a single order assignments
 */
export const useGetUsercrowdOrderAssignment = <
  TData = Schemas.PanelOrderAssignment,
>(
  variables: GetUsercrowdOrderAssignmentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PanelOrderAssignment,
      GetUsercrowdOrderAssignmentError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.PanelOrderAssignment,
    GetUsercrowdOrderAssignmentError,
    TData
  >(
    queryKeyFn({
      path: "/api/order_assignments/{id}",
      operationId: "getUsercrowdOrderAssignment",
      variables,
    }),
    () => fetchGetUsercrowdOrderAssignment({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type AcceptModeratedStudyOrderAssignmentPathParams = {
  id: string;
};

export type AcceptModeratedStudyOrderAssignmentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type AcceptModeratedStudyOrderAssignmentResponse = {
  moderated_study_application_id: string;
};

export type AcceptModeratedStudyOrderAssignmentVariables = {
  pathParams: AcceptModeratedStudyOrderAssignmentPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Accept a moderated study order assignment
 */
export const fetchAcceptModeratedStudyOrderAssignment = (
  variables: AcceptModeratedStudyOrderAssignmentVariables,
) =>
  usabilityhubFetch<
    AcceptModeratedStudyOrderAssignmentResponse,
    AcceptModeratedStudyOrderAssignmentError,
    undefined,
    {},
    {},
    AcceptModeratedStudyOrderAssignmentPathParams
  >({
    url: "/api/moderated_study_order_assignments/{id}/accept",
    method: "post",
    ...variables,
  });

/**
 * Accept a moderated study order assignment
 */
export const useAcceptModeratedStudyOrderAssignment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AcceptModeratedStudyOrderAssignmentResponse,
      AcceptModeratedStudyOrderAssignmentError,
      AcceptModeratedStudyOrderAssignmentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    AcceptModeratedStudyOrderAssignmentResponse,
    AcceptModeratedStudyOrderAssignmentError,
    AcceptModeratedStudyOrderAssignmentVariables
  >(
    (variables: AcceptModeratedStudyOrderAssignmentVariables) =>
      fetchAcceptModeratedStudyOrderAssignment({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type RejectModeratedStudyOrderAssignmentPathParams = {
  id: string;
};

export type RejectModeratedStudyOrderAssignmentError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type RejectModeratedStudyOrderAssignmentVariables = {
  pathParams: RejectModeratedStudyOrderAssignmentPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Rejects a moderated study order assignment
 */
export const fetchRejectModeratedStudyOrderAssignment = (
  variables: RejectModeratedStudyOrderAssignmentVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RejectModeratedStudyOrderAssignmentError,
    undefined,
    {},
    {},
    RejectModeratedStudyOrderAssignmentPathParams
  >({
    url: "/api/moderated_study_order_assignments/{id}/reject",
    method: "post",
    ...variables,
  });

/**
 * Rejects a moderated study order assignment
 */
export const useRejectModeratedStudyOrderAssignment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RejectModeratedStudyOrderAssignmentError,
      RejectModeratedStudyOrderAssignmentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RejectModeratedStudyOrderAssignmentError,
    RejectModeratedStudyOrderAssignmentVariables
  >(
    (variables: RejectModeratedStudyOrderAssignmentVariables) =>
      fetchRejectModeratedStudyOrderAssignment({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ListNotificationSubscriptionsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type ListNotificationSubscriptionsResponse = {
  notification_subscriptions: {
    id: number;
    endpoint: string;
    host: string;
  }[];
};

export type ListNotificationSubscriptionsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns all notification subscriptions for the current user
 */
export const fetchListNotificationSubscriptions = (
  variables: ListNotificationSubscriptionsVariables,
) =>
  usabilityhubFetch<
    ListNotificationSubscriptionsResponse,
    ListNotificationSubscriptionsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/notification_subscriptions",
    method: "get",
    ...variables,
  });

/**
 * Returns all notification subscriptions for the current user
 */
export const useListNotificationSubscriptions = <
  TData = ListNotificationSubscriptionsResponse,
>(
  variables: ListNotificationSubscriptionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListNotificationSubscriptionsResponse,
      ListNotificationSubscriptionsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListNotificationSubscriptionsResponse,
    ListNotificationSubscriptionsError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/notification_subscriptions",
      operationId: "listNotificationSubscriptions",
      variables,
    }),
    () =>
      fetchListNotificationSubscriptions({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type AgreeToCurrentTermsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type AgreeToCurrentTermsRequestBody = {
  agreed: {
    [key: string]: any;
  };
};

export type AgreeToCurrentTermsVariables = {
  body: AgreeToCurrentTermsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Marks the currently logged in panelist as having agreed to the current Tester Agreement
 */
export const fetchAgreeToCurrentTerms = (
  variables: AgreeToCurrentTermsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    AgreeToCurrentTermsError,
    AgreeToCurrentTermsRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/panelist/agree_to_terms",
    method: "post",
    ...variables,
  });

/**
 * Marks the currently logged in panelist as having agreed to the current Tester Agreement
 */
export const useAgreeToCurrentTerms = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AgreeToCurrentTermsError,
      AgreeToCurrentTermsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AgreeToCurrentTermsError,
    AgreeToCurrentTermsVariables
  >(
    (variables: AgreeToCurrentTermsVariables) =>
      fetchAgreeToCurrentTerms({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetPanelistAvailabilityError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type GetPanelistAvailabilityResponse = {
  timezone: string | null;
  start_time: string;
  end_time: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
};

export type GetPanelistAvailabilityVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Show availability information for the currently logged-in panelist
 */
export const fetchGetPanelistAvailability = (
  variables: GetPanelistAvailabilityVariables,
) =>
  usabilityhubFetch<
    GetPanelistAvailabilityResponse,
    GetPanelistAvailabilityError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/panelist/availability",
    method: "get",
    ...variables,
  });

/**
 * Show availability information for the currently logged-in panelist
 */
export const useGetPanelistAvailability = <
  TData = GetPanelistAvailabilityResponse,
>(
  variables: GetPanelistAvailabilityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPanelistAvailabilityResponse,
      GetPanelistAvailabilityError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetPanelistAvailabilityResponse,
    GetPanelistAvailabilityError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/panelist/availability",
      operationId: "getPanelistAvailability",
      variables,
    }),
    () => fetchGetPanelistAvailability({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdatePanelistAvailabilityError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type UpdatePanelistAvailabilityRequestBody = {
  timezone: string | null;
  start_time: string;
  end_time: string;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
};

export type UpdatePanelistAvailabilityVariables = {
  body: UpdatePanelistAvailabilityRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates availability information for the currently logged-in panelist
 */
export const fetchUpdatePanelistAvailability = (
  variables: UpdatePanelistAvailabilityVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdatePanelistAvailabilityError,
    UpdatePanelistAvailabilityRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/panelist/availability",
    method: "patch",
    ...variables,
  });

/**
 * Updates availability information for the currently logged-in panelist
 */
export const useUpdatePanelistAvailability = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdatePanelistAvailabilityError,
      UpdatePanelistAvailabilityVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdatePanelistAvailabilityError,
    UpdatePanelistAvailabilityVariables
  >(
    (variables: UpdatePanelistAvailabilityVariables) =>
      fetchUpdatePanelistAvailability({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CurrentPanelistInfoError = Fetcher.ErrorWrapper<undefined>;

export type CurrentPanelistInfoResponse = {
  id: number;
  account_id: number;
  name: string;
  preferred_name: string | null;
  email: string;
  phone_number: string | null;
  country: string | null;
  created_at: string;
  intercom_user_hash: string;
  current_device_type: "mobile" | "tablet" | "console" | "desktop";
  agreed_to_current_tester_terms: boolean;
  completed_profile_at: string | null;
  phone_number_required_for_panel: boolean;
  panelist_state: "waitlisted" | "accepted" | "verified";
  email_confirmation_state: {
    confirmation_id: number;
    is_expired: boolean;
  } | null;
  practice_test_state:
    | "not_required"
    | "not_started"
    | "in_progress"
    | "awaiting_review"
    | "passed"
    | "auto_review_fail"
    | "manual_qa_fail";
};

export type CurrentPanelistInfoVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Has basic panelist info for the currently logged-in user
 */
export const fetchCurrentPanelistInfo = (
  variables: CurrentPanelistInfoVariables,
) =>
  usabilityhubFetch<
    CurrentPanelistInfoResponse,
    CurrentPanelistInfoError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/me", method: "get", ...variables });

/**
 * Has basic panelist info for the currently logged-in user
 */
export const useCurrentPanelistInfo = <TData = CurrentPanelistInfoResponse,>(
  variables: CurrentPanelistInfoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      CurrentPanelistInfoResponse,
      CurrentPanelistInfoError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    CurrentPanelistInfoResponse,
    CurrentPanelistInfoError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/panelist/me",
      operationId: "currentPanelistInfo",
      variables,
    }),
    () => fetchCurrentPanelistInfo({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdatePanelistPasswordError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type UpdatePanelistPasswordResponse = {
  message: string;
};

export type UpdatePanelistPasswordRequestBody = {
  current_password: string;
  password: string;
  password_confirmation: string;
};

export type UpdatePanelistPasswordVariables = {
  body: UpdatePanelistPasswordRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update panelist password
 */
export const fetchUpdatePanelistPassword = (
  variables: UpdatePanelistPasswordVariables,
) =>
  usabilityhubFetch<
    UpdatePanelistPasswordResponse,
    UpdatePanelistPasswordError,
    UpdatePanelistPasswordRequestBody,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/password", method: "patch", ...variables });

/**
 * Update panelist password
 */
export const useUpdatePanelistPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdatePanelistPasswordResponse,
      UpdatePanelistPasswordError,
      UpdatePanelistPasswordVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    UpdatePanelistPasswordResponse,
    UpdatePanelistPasswordError,
    UpdatePanelistPasswordVariables
  >(
    (variables: UpdatePanelistPasswordVariables) =>
      fetchUpdatePanelistPassword({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PanelistStatsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type PanelistStatsResponse = {
  credit_balance: {
    current: number;
    pending: number;
  };
  studies_completed: {
    tests: number;
    interviews: number;
  };
  profile_completeness: number;
  rating: number | null;
  recent_interview: boolean;
  upcoming_interview: boolean;
  estimated_payout_review_time_in_days: number;
};

export type PanelistStatsVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Has high-level panelist stats about profile completion, tests completed, etc.
 */
export const fetchPanelistStats = (variables: PanelistStatsVariables) =>
  usabilityhubFetch<
    PanelistStatsResponse,
    PanelistStatsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/stats", method: "get", ...variables });

/**
 * Has high-level panelist stats about profile completion, tests completed, etc.
 */
export const usePanelistStats = <TData = PanelistStatsResponse,>(
  variables: PanelistStatsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      PanelistStatsResponse,
      PanelistStatsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<PanelistStatsResponse, PanelistStatsError, TData>(
    queryKeyFn({
      path: "/api/usercrowd/panelist/stats",
      operationId: "panelistStats",
      variables,
    }),
    () => fetchPanelistStats({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdatePanelistAccountDetailsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type UpdatePanelistAccountDetailsRequestBody = {
  name?: string;
  preferred_name?: string;
  email?: string;
  paypal_email_address?: string | null;
  email_product_updates?: boolean;
};

export type UpdatePanelistAccountDetailsVariables = {
  body?: UpdatePanelistAccountDetailsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates account information for the currently logged-in panelist
 */
export const fetchUpdatePanelistAccountDetails = (
  variables: UpdatePanelistAccountDetailsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdatePanelistAccountDetailsError,
    UpdatePanelistAccountDetailsRequestBody,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/account", method: "patch", ...variables });

/**
 * Updates account information for the currently logged-in panelist
 */
export const useUpdatePanelistAccountDetails = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdatePanelistAccountDetailsError,
      UpdatePanelistAccountDetailsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdatePanelistAccountDetailsError,
    UpdatePanelistAccountDetailsVariables
  >(
    (variables: UpdatePanelistAccountDetailsVariables) =>
      fetchUpdatePanelistAccountDetails({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DeletePanelistAccountError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type DeletePanelistAccountResponse = {
  message: string;
};

export type DeletePanelistAccountVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Deletes the account of the currently logged-in panelist
 */
export const fetchDeletePanelistAccount = (
  variables: DeletePanelistAccountVariables,
) =>
  usabilityhubFetch<
    DeletePanelistAccountResponse,
    DeletePanelistAccountError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/panelist/delete_account",
    method: "delete",
    ...variables,
  });

/**
 * Deletes the account of the currently logged-in panelist
 */
export const useDeletePanelistAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DeletePanelistAccountResponse,
      DeletePanelistAccountError,
      DeletePanelistAccountVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DeletePanelistAccountResponse,
    DeletePanelistAccountError,
    DeletePanelistAccountVariables
  >(
    (variables: DeletePanelistAccountVariables) =>
      fetchDeletePanelistAccount({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PanelistHistoryQueryParams = {
  page?: number;
};

export type PanelistHistoryError = Fetcher.ErrorWrapper<undefined>;

export type PanelistHistoryResponse = {
  total_pages: number;
  point_transactions: {
    id: number;
    karma_points: number;
    updated_at: string;
    reason: string;
  }[];
};

export type PanelistHistoryVariables = {
  queryParams?: PanelistHistoryQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Information about PointTransactions and Payouts for the current panelist
 */
export const fetchPanelistHistory = (variables: PanelistHistoryVariables) =>
  usabilityhubFetch<
    PanelistHistoryResponse,
    PanelistHistoryError,
    undefined,
    {},
    PanelistHistoryQueryParams,
    {}
  >({ url: "/api/usercrowd/panelist/history", method: "get", ...variables });

/**
 * Information about PointTransactions and Payouts for the current panelist
 */
export const usePanelistHistory = <TData = PanelistHistoryResponse,>(
  variables: PanelistHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      PanelistHistoryResponse,
      PanelistHistoryError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    PanelistHistoryResponse,
    PanelistHistoryError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/panelist/history",
      operationId: "panelistHistory",
      variables,
    }),
    () => fetchPanelistHistory({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PanelistPayoutsError = Fetcher.ErrorWrapper<undefined>;

export type PanelistPayoutsResponse = {
  payouts: {
    id: number;
    status:
      | "open"
      | "approved"
      | "paid"
      | "payment_failed"
      | "rejected"
      | "payment_pending";
    amount: number;
    created_at: string;
    reviewed_amount: number | null;
    paid_at: string | null;
    paypal_email_address: string;
  }[];
};

export type PanelistPayoutsVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Returns an (unpaginated) list of all panelists for the currently logged-in user
 */
export const fetchPanelistPayouts = (variables: PanelistPayoutsVariables) =>
  usabilityhubFetch<
    PanelistPayoutsResponse,
    PanelistPayoutsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/payouts", method: "get", ...variables });

/**
 * Returns an (unpaginated) list of all panelists for the currently logged-in user
 */
export const usePanelistPayouts = <TData = PanelistPayoutsResponse,>(
  variables: PanelistPayoutsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      PanelistPayoutsResponse,
      PanelistPayoutsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    PanelistPayoutsResponse,
    PanelistPayoutsError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/panelist/payouts",
      operationId: "panelistPayouts",
      variables,
    }),
    () => fetchPanelistPayouts({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetPanelistSettingsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type GetPanelistSettingsResponse = {
  paypal_email_address: string | null;
  last_dismissed_timezone_suggestion: string | null;
  email_product_updates: boolean;
  notify_assignments_by_email: boolean;
};

export type GetPanelistSettingsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Get current settings for the logged-in panelist
 */
export const fetchGetPanelistSettings = (
  variables: GetPanelistSettingsVariables,
) =>
  usabilityhubFetch<
    GetPanelistSettingsResponse,
    GetPanelistSettingsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/settings", method: "get", ...variables });

/**
 * Get current settings for the logged-in panelist
 */
export const useGetPanelistSettings = <TData = GetPanelistSettingsResponse,>(
  variables: GetPanelistSettingsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPanelistSettingsResponse,
      GetPanelistSettingsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetPanelistSettingsResponse,
    GetPanelistSettingsError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/panelist/settings",
      operationId: "getPanelistSettings",
      variables,
    }),
    () => fetchGetPanelistSettings({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdatePanelistSettingsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type UpdatePanelistSettingsRequestBody = {
  notify_assignments_by_email: boolean;
  last_dismissed_timezone_suggestion: string | null;
};

export type UpdatePanelistSettingsVariables = {
  body: UpdatePanelistSettingsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates settings for the currently logged-in panelist
 */
export const fetchUpdatePanelistSettings = (
  variables: UpdatePanelistSettingsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdatePanelistSettingsError,
    UpdatePanelistSettingsRequestBody,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/panelist/settings", method: "patch", ...variables });

/**
 * Updates settings for the currently logged-in panelist
 */
export const useUpdatePanelistSettings = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdatePanelistSettingsError,
      UpdatePanelistSettingsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdatePanelistSettingsError,
    UpdatePanelistSettingsVariables
  >(
    (variables: UpdatePanelistSettingsVariables) =>
      fetchUpdatePanelistSettings({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type RequestPayoutError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type RequestPayoutResponse = {
  message: string;
};

export type RequestPayoutVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Initiates a payout request for the current panelist
 */
export const fetchRequestPayout = (variables: RequestPayoutVariables) =>
  usabilityhubFetch<
    RequestPayoutResponse,
    RequestPayoutError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/usercrowd/payouts", method: "post", ...variables });

/**
 * Initiates a payout request for the current panelist
 */
export const useRequestPayout = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RequestPayoutResponse,
      RequestPayoutError,
      RequestPayoutVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    RequestPayoutResponse,
    RequestPayoutError,
    RequestPayoutVariables
  >(
    (variables: RequestPayoutVariables) =>
      fetchRequestPayout({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListUsercrowdUpcomingBookingsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type ListUsercrowdUpcomingBookingsResponse = {
  moderated_study_bookings: {
    moderated_study_application_id: string;
    external_name: string;
    location: string;
    incentive_text: string;
    /**
     * @format date-time
     */
    starts_at: string;
    /**
     * @format date-time
     */
    ends_at: string;
    device_requirement: Schemas.DeviceRequirement;
  }[];
};

export type ListUsercrowdUpcomingBookingsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns all upcoming bookings for the current panelist
 */
export const fetchListUsercrowdUpcomingBookings = (
  variables: ListUsercrowdUpcomingBookingsVariables,
) =>
  usabilityhubFetch<
    ListUsercrowdUpcomingBookingsResponse,
    ListUsercrowdUpcomingBookingsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/usercrowd/moderated_study_bookings/upcoming",
    method: "get",
    ...variables,
  });

/**
 * Returns all upcoming bookings for the current panelist
 */
export const useListUsercrowdUpcomingBookings = <
  TData = ListUsercrowdUpcomingBookingsResponse,
>(
  variables: ListUsercrowdUpcomingBookingsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListUsercrowdUpcomingBookingsResponse,
      ListUsercrowdUpcomingBookingsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListUsercrowdUpcomingBookingsResponse,
    ListUsercrowdUpcomingBookingsError,
    TData
  >(
    queryKeyFn({
      path: "/api/usercrowd/moderated_study_bookings/upcoming",
      operationId: "listUsercrowdUpcomingBookings",
      variables,
    }),
    () =>
      fetchListUsercrowdUpcomingBookings({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetVersionError = Fetcher.ErrorWrapper<undefined>;

export type GetVersionResponse = {
  version: string;
};

export type GetVersionVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Returns the current version of the API
 */
export const fetchGetVersion = (variables: GetVersionVariables) =>
  usabilityhubFetch<GetVersionResponse, GetVersionError, undefined, {}, {}, {}>(
    { url: "/api/version", method: "get", ...variables },
  );

/**
 * Returns the current version of the API
 */
export const useGetVersion = <TData = GetVersionResponse,>(
  variables: GetVersionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetVersionResponse, GetVersionError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<GetVersionResponse, GetVersionError, TData>(
    queryKeyFn({ path: "/api/version", operationId: "getVersion", variables }),
    () => fetchGetVersion({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type SignUpPanelistError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type SignUpPanelistRequestBody = {
  user: {
    full_name: string;
    preferred_name: string;
    country: string;
    email: string;
    password: string;
    accept_terms: boolean;
  };
  ["g-recaptcha-response"]: string;
};

export type SignUpPanelistVariables = {
  body: SignUpPanelistRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Endpoint for submitting the panelist sign-up form
 */
export const fetchSignUpPanelist = (variables: SignUpPanelistVariables) =>
  usabilityhubFetch<
    Responses.R204,
    SignUpPanelistError,
    SignUpPanelistRequestBody,
    {},
    {},
    {}
  >({ url: "/testers/sign_up", method: "post", ...variables });

/**
 * Endpoint for submitting the panelist sign-up form
 */
export const useSignUpPanelist = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      SignUpPanelistError,
      SignUpPanelistVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    SignUpPanelistError,
    SignUpPanelistVariables
  >(
    (variables: SignUpPanelistVariables) =>
      fetchSignUpPanelist({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CreateModeratedStudyOrderPathParams = {
  moderatedStudyId: string;
};

export type CreateModeratedStudyOrderError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CreateModeratedStudyOrderRequestBody = {
  demographic_target_id: number;
  requested_response_count: number;
  handpick_panelists: boolean;
};

export type CreateModeratedStudyOrderVariables = {
  body: CreateModeratedStudyOrderRequestBody;
  pathParams: CreateModeratedStudyOrderPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates an order for the given moderated study with a response count and demographic target
 */
export const fetchCreateModeratedStudyOrder = (
  variables: CreateModeratedStudyOrderVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    CreateModeratedStudyOrderError,
    CreateModeratedStudyOrderRequestBody,
    {},
    {},
    CreateModeratedStudyOrderPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/orders",
    method: "post",
    ...variables,
  });

/**
 * Creates an order for the given moderated study with a response count and demographic target
 */
export const useCreateModeratedStudyOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      CreateModeratedStudyOrderError,
      CreateModeratedStudyOrderVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    CreateModeratedStudyOrderError,
    CreateModeratedStudyOrderVariables
  >(
    (variables: CreateModeratedStudyOrderVariables) =>
      fetchCreateModeratedStudyOrder({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetModeratedStudyOrderQuotePathParams = {
  moderatedStudyId: string;
};

export type GetModeratedStudyOrderQuoteError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetModeratedStudyOrderQuoteResponse = {
  demographic_target_id: number;
  requested_response_count: number;
  credit_cost: number;
  credits_to_be_purchased: number;
  estimated_fulfillment_time: number | null;
  credits_per_response: number;
  price_in_cents: number;
  price_per_credit: string;
  credits_scale: {
    from: number;
    to: number | null;
    price_in_cents: number;
  }[];
};

export type GetModeratedStudyOrderQuoteRequestBody = {
  min_age: number;
  max_age: number;
  target_locations: {
    type: "country" | "state" | "city";
    id: string;
  }[];
  demographic_attribute_option_ids: number[];
  requested_response_count: number;
  handpick_panelists: boolean;
};

export type GetModeratedStudyOrderQuoteVariables = {
  body: GetModeratedStudyOrderQuoteRequestBody;
  pathParams: GetModeratedStudyOrderQuotePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns information and cost and estimated duration for a set of order parameters
 */
export const fetchGetModeratedStudyOrderQuote = (
  variables: GetModeratedStudyOrderQuoteVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyOrderQuoteResponse,
    GetModeratedStudyOrderQuoteError,
    GetModeratedStudyOrderQuoteRequestBody,
    {},
    {},
    GetModeratedStudyOrderQuotePathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/orders/quote",
    method: "post",
    ...variables,
  });

/**
 * Returns information and cost and estimated duration for a set of order parameters
 */
export const useGetModeratedStudyOrderQuote = <
  TData = GetModeratedStudyOrderQuoteResponse,
>(
  variables: GetModeratedStudyOrderQuoteVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyOrderQuoteResponse,
      GetModeratedStudyOrderQuoteError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyOrderQuoteResponse,
    GetModeratedStudyOrderQuoteError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/orders/quote",
      operationId: "getModeratedStudyOrderQuote",
      variables,
    }),
    () => fetchGetModeratedStudyOrderQuote({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CancelModeratedStudyOrderPathParams = {
  moderatedStudyId: string;
  id: string;
};

export type CancelModeratedStudyOrderError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type CancelModeratedStudyOrderVariables = {
  pathParams: CancelModeratedStudyOrderPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Cancel a moderated study order
 */
export const fetchCancelModeratedStudyOrder = (
  variables: CancelModeratedStudyOrderVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    CancelModeratedStudyOrderError,
    undefined,
    {},
    {},
    CancelModeratedStudyOrderPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/orders/{id}/cancel",
    method: "post",
    ...variables,
  });

/**
 * Cancel a moderated study order
 */
export const useCancelModeratedStudyOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      CancelModeratedStudyOrderError,
      CancelModeratedStudyOrderVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    CancelModeratedStudyOrderError,
    CancelModeratedStudyOrderVariables
  >(
    (variables: CancelModeratedStudyOrderVariables) =>
      fetchCancelModeratedStudyOrder({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type RefreshSessionError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type RefreshSessionRequestBody = {
  password: string;
};

export type RefreshSessionVariables = {
  body: RefreshSessionRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Refreshes the current session
 */
export const fetchRefreshSession = (variables: RefreshSessionVariables) =>
  usabilityhubFetch<
    Responses.R204,
    RefreshSessionError,
    RefreshSessionRequestBody,
    {},
    {},
    {}
  >({ url: "/api/sessions/refresh", method: "post", ...variables });

/**
 * Refreshes the current session
 */
export const useRefreshSession = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RefreshSessionError,
      RefreshSessionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RefreshSessionError,
    RefreshSessionVariables
  >(
    (variables: RefreshSessionVariables) =>
      fetchRefreshSession({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type SendModeratedStudyPreviewEmailPathParams = {
  moderatedStudyId: string;
  email:
    | "invited"
    | "booking_confirmation"
    | "rescheduled"
    | "canceled_by_researcher"
    | "reminder_15m";
};

export type SendModeratedStudyPreviewEmailError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type SendModeratedStudyPreviewEmailVariables = {
  pathParams: SendModeratedStudyPreviewEmailPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Sends an email with preview content to the current user's email address
 */
export const fetchSendModeratedStudyPreviewEmail = (
  variables: SendModeratedStudyPreviewEmailVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    SendModeratedStudyPreviewEmailError,
    undefined,
    {},
    {},
    SendModeratedStudyPreviewEmailPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/preview_email/{email}",
    method: "post",
    ...variables,
  });

/**
 * Sends an email with preview content to the current user's email address
 */
export const useSendModeratedStudyPreviewEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      SendModeratedStudyPreviewEmailError,
      SendModeratedStudyPreviewEmailVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    SendModeratedStudyPreviewEmailError,
    SendModeratedStudyPreviewEmailVariables
  >(
    (variables: SendModeratedStudyPreviewEmailVariables) =>
      fetchSendModeratedStudyPreviewEmail({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CalculateScreenerResultError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CalculateScreenerResultResponse = {
  result: boolean;
};

export type CalculateScreenerResultRequestBody = {
  screener_question_answers: (
    | {
        /**
         * @format uuid
         */
        screener_question_id: string;
        type: "short_text" | "long_text";
        text: string;
      }
    | {
        /**
         * @format uuid
         */
        screener_question_id: string;
        type: "single_select" | "multi_select";
        screener_question_options: string[];
      }
  )[];
};

export type CalculateScreenerResultVariables = {
  body: CalculateScreenerResultRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Calculate what the result of a screener would be without creating a response
 */
export const fetchCalculateScreenerResult = (
  variables: CalculateScreenerResultVariables,
) =>
  usabilityhubFetch<
    CalculateScreenerResultResponse,
    CalculateScreenerResultError,
    CalculateScreenerResultRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/moderated_study_previews/screener_result",
    method: "post",
    ...variables,
  });

/**
 * Calculate what the result of a screener would be without creating a response
 */
export const useCalculateScreenerResult = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CalculateScreenerResultResponse,
      CalculateScreenerResultError,
      CalculateScreenerResultVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CalculateScreenerResultResponse,
    CalculateScreenerResultError,
    CalculateScreenerResultVariables
  >(
    (variables: CalculateScreenerResultVariables) =>
      fetchCalculateScreenerResult({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetDynamicTemplatesError = Fetcher.ErrorWrapper<undefined>;

export type GetDynamicTemplatesResponse = Schemas.DynamicTemplate[];

export type GetDynamicTemplatesVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Get dynamic template data
 */
export const fetchGetDynamicTemplates = (
  variables: GetDynamicTemplatesVariables,
) =>
  usabilityhubFetch<
    GetDynamicTemplatesResponse,
    GetDynamicTemplatesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/dynamic_templates", method: "get", ...variables });

/**
 * Get dynamic template data
 */
export const useGetDynamicTemplates = <TData = GetDynamicTemplatesResponse,>(
  variables: GetDynamicTemplatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDynamicTemplatesResponse,
      GetDynamicTemplatesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetDynamicTemplatesResponse,
    GetDynamicTemplatesError,
    TData
  >(
    queryKeyFn({
      path: "/api/dynamic_templates",
      operationId: "getDynamicTemplates",
      variables,
    }),
    () => fetchGetDynamicTemplates({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CheckDynamicTemplateExistsPathParams = {
  /**
   * Unique ID of the template
   */
  id: string;
};

export type CheckDynamicTemplateExistsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type CheckDynamicTemplateExistsVariables = {
  pathParams: CheckDynamicTemplateExistsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Check if a template exists in the marketing account
 */
export const fetchCheckDynamicTemplateExists = (
  variables: CheckDynamicTemplateExistsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    CheckDynamicTemplateExistsError,
    undefined,
    {},
    {},
    CheckDynamicTemplateExistsPathParams
  >({ url: "/api/dynamic_templates/{id}", method: "get", ...variables });

/**
 * Check if a template exists in the marketing account
 */
export const useCheckDynamicTemplateExists = <TData = Responses.R204,>(
  variables: CheckDynamicTemplateExistsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.R204,
      CheckDynamicTemplateExistsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Responses.R204,
    CheckDynamicTemplateExistsError,
    TData
  >(
    queryKeyFn({
      path: "/api/dynamic_templates/{id}",
      operationId: "checkDynamicTemplateExists",
      variables,
    }),
    () => fetchCheckDynamicTemplateExists({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ApplyDynamicTemplatePathParams = {
  /**
   * Unique ID of the template
   */
  id: string;
};

export type ApplyDynamicTemplateError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type ApplyDynamicTemplateResponse = {
  /**
   * Unique ID of the job to listen to
   *
   * @format uuid
   */
  job_id?: string;
};

export type ApplyDynamicTemplateRequestBody = {
  /**
   * Test slug
   */
  slug?: string;
};

export type ApplyDynamicTemplateVariables = {
  body?: ApplyDynamicTemplateRequestBody;
  pathParams: ApplyDynamicTemplatePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a new test from the given template
 */
export const fetchApplyDynamicTemplate = (
  variables: ApplyDynamicTemplateVariables,
) =>
  usabilityhubFetch<
    ApplyDynamicTemplateResponse,
    ApplyDynamicTemplateError,
    ApplyDynamicTemplateRequestBody,
    {},
    {},
    ApplyDynamicTemplatePathParams
  >({ url: "/api/dynamic_templates/{id}/apply", method: "post", ...variables });

/**
 * Create a new test from the given template
 */
export const useApplyDynamicTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ApplyDynamicTemplateResponse,
      ApplyDynamicTemplateError,
      ApplyDynamicTemplateVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ApplyDynamicTemplateResponse,
    ApplyDynamicTemplateError,
    ApplyDynamicTemplateVariables
  >(
    (variables: ApplyDynamicTemplateVariables) =>
      fetchApplyDynamicTemplate({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetModeratedStudyQuotaError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type GetModeratedStudyQuotaResponse = {
  /**
   * @format date-time
   */
  period_start: string;
  /**
   * @format date-time
   */
  period_end: string;
  self_recruited_sessions: {
    used: number;
    quota: number;
  };
  transcription_hours: {
    used: number;
    quota: number;
  };
  video_storage_gb: {
    used: number;
    quota: number;
  };
};

export type GetModeratedStudyQuotaVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Returns information about limits for the currently logged-in account
 */
export const fetchGetModeratedStudyQuota = (
  variables: GetModeratedStudyQuotaVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyQuotaResponse,
    GetModeratedStudyQuotaError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/account/moderated_studies_quota",
    method: "get",
    ...variables,
  });

/**
 * Returns information about limits for the currently logged-in account
 */
export const useGetModeratedStudyQuota = <
  TData = GetModeratedStudyQuotaResponse,
>(
  variables: GetModeratedStudyQuotaVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyQuotaResponse,
      GetModeratedStudyQuotaError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyQuotaResponse,
    GetModeratedStudyQuotaError,
    TData
  >(
    queryKeyFn({
      path: "/api/account/moderated_studies_quota",
      operationId: "getModeratedStudyQuota",
      variables,
    }),
    () => fetchGetModeratedStudyQuota({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type RegisterNewUserError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type RegisterNewUserResponse = {
  user: Schemas.User;
  account: Schemas.Account;
  redirect_to: string | null;
};

export type RegisterNewUserRequestBody = {
  intended_plan_unique_id: string;
  ["g-recaptcha-response"]: string;
  user: {
    name: string;
    email: string;
    password: string;
  };
};

export type RegisterNewUserVariables = {
  body: RegisterNewUserRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Register a new user
 */
export const fetchRegisterNewUser = (variables: RegisterNewUserVariables) =>
  usabilityhubFetch<
    RegisterNewUserResponse,
    RegisterNewUserError,
    RegisterNewUserRequestBody,
    {},
    {},
    {}
  >({ url: "/api/users/sign_up", method: "post", ...variables });

/**
 * Register a new user
 */
export const useRegisterNewUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      RegisterNewUserResponse,
      RegisterNewUserError,
      RegisterNewUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    RegisterNewUserResponse,
    RegisterNewUserError,
    RegisterNewUserVariables
  >(
    (variables: RegisterNewUserVariables) =>
      fetchRegisterNewUser({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetOnboardingError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Responses.R401;
}>;

export type GetOnboardingVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Get onboarding data for the current user
 */
export const fetchGetOnboarding = (variables: GetOnboardingVariables) =>
  usabilityhubFetch<
    Record<string, any>,
    GetOnboardingError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/onboarding", method: "get", ...variables });

/**
 * Get onboarding data for the current user
 */
export const useGetOnboarding = <TData = Record<string, any>,>(
  variables: GetOnboardingVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Record<string, any>, GetOnboardingError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<Record<string, any>, GetOnboardingError, TData>(
    queryKeyFn({
      path: "/api/onboarding",
      operationId: "getOnboarding",
      variables,
    }),
    () => fetchGetOnboarding({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateOnboardingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type UpdateOnboardingVariables = {
  body?: Record<string, any>;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Set onboarding data for the current user
 */
export const fetchUpdateOnboarding = (variables: UpdateOnboardingVariables) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateOnboardingError,
    Record<string, any>,
    {},
    {},
    {}
  >({ url: "/api/onboarding", method: "patch", ...variables });

/**
 * Set onboarding data for the current user
 */
export const useUpdateOnboarding = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateOnboardingError,
      UpdateOnboardingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateOnboardingError,
    UpdateOnboardingVariables
  >(
    (variables: UpdateOnboardingVariables) =>
      fetchUpdateOnboarding({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DeleteAccountError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type DeleteAccountResponse = {
  message: string;
};

export type DeleteAccountVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Deletes the account of the currently logged-in user
 */
export const fetchDeleteAccount = (variables: DeleteAccountVariables) =>
  usabilityhubFetch<
    DeleteAccountResponse,
    DeleteAccountError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/settings/delete_account", method: "delete", ...variables });

/**
 * Deletes the account of the currently logged-in user
 */
export const useDeleteAccount = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DeleteAccountResponse,
      DeleteAccountError,
      DeleteAccountVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DeleteAccountResponse,
    DeleteAccountError,
    DeleteAccountVariables
  >(
    (variables: DeleteAccountVariables) =>
      fetchDeleteAccount({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListDisabledFeaturesError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type ListDisabledFeaturesResponse = {
  disabled_features: Schemas.FeatureName[];
};

export type ListDisabledFeaturesVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * List the disabled feature of the current account
 */
export const fetchListDisabledFeatures = (
  variables: ListDisabledFeaturesVariables,
) =>
  usabilityhubFetch<
    ListDisabledFeaturesResponse,
    ListDisabledFeaturesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/disabled_features", method: "get", ...variables });

/**
 * List the disabled feature of the current account
 */
export const useListDisabledFeatures = <TData = ListDisabledFeaturesResponse,>(
  variables: ListDisabledFeaturesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDisabledFeaturesResponse,
      ListDisabledFeaturesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListDisabledFeaturesResponse,
    ListDisabledFeaturesError,
    TData
  >(
    queryKeyFn({
      path: "/api/disabled_features",
      operationId: "listDisabledFeatures",
      variables,
    }),
    () => fetchListDisabledFeatures({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ToggleFeatureError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type ToggleFeatureRequestBody = {
  feature_name: Schemas.FeatureName;
  enabled: boolean;
};

export type ToggleFeatureVariables = {
  body: ToggleFeatureRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Disable or enable a feature for an account
 */
export const fetchToggleFeature = (variables: ToggleFeatureVariables) =>
  usabilityhubFetch<
    Responses.R204,
    ToggleFeatureError,
    ToggleFeatureRequestBody,
    {},
    {},
    {}
  >({ url: "/api/toggle_feature", method: "patch", ...variables });

/**
 * Disable or enable a feature for an account
 */
export const useToggleFeature = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ToggleFeatureError,
      ToggleFeatureVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ToggleFeatureError,
    ToggleFeatureVariables
  >(
    (variables: ToggleFeatureVariables) =>
      fetchToggleFeature({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListTestsWithRecordingEnabledError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type ListTestsWithRecordingEnabledResponse = {
  tests: {
    unique_id: string;
    name: string;
  }[];
};

export type ListTestsWithRecordingEnabledVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * List the tests with recording enabled for the current account
 */
export const fetchListTestsWithRecordingEnabled = (
  variables: ListTestsWithRecordingEnabledVariables,
) =>
  usabilityhubFetch<
    ListTestsWithRecordingEnabledResponse,
    ListTestsWithRecordingEnabledError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/tests_with_recording_enabled", method: "get", ...variables });

/**
 * List the tests with recording enabled for the current account
 */
export const useListTestsWithRecordingEnabled = <
  TData = ListTestsWithRecordingEnabledResponse,
>(
  variables: ListTestsWithRecordingEnabledVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListTestsWithRecordingEnabledResponse,
      ListTestsWithRecordingEnabledError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListTestsWithRecordingEnabledResponse,
    ListTestsWithRecordingEnabledError,
    TData
  >(
    queryKeyFn({
      path: "/api/tests_with_recording_enabled",
      operationId: "listTestsWithRecordingEnabled",
      variables,
    }),
    () =>
      fetchListTestsWithRecordingEnabled({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateVariationSetError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type CreateVariationSetResponse = {
  test_set: {
    id: number;
    unique_id: string;
  };
};

export type CreateVariationSetRequestBody = {
  name: string;
  usability_test_ids?: number[];
};

export type CreateVariationSetVariables = {
  body: CreateVariationSetRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a variation set.
 */
export const fetchCreateVariationSet = (
  variables: CreateVariationSetVariables,
) =>
  usabilityhubFetch<
    CreateVariationSetResponse,
    CreateVariationSetError,
    CreateVariationSetRequestBody,
    {},
    {},
    {}
  >({ url: "/api/test_sets", method: "post", ...variables });

/**
 * Create a variation set.
 */
export const useCreateVariationSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateVariationSetResponse,
      CreateVariationSetError,
      CreateVariationSetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateVariationSetResponse,
    CreateVariationSetError,
    CreateVariationSetVariables
  >(
    (variables: CreateVariationSetVariables) =>
      fetchCreateVariationSet({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListVariationSetsQueryParams = {
  /**
   * Determine which attribute to order results
   */
  sort_by: "created_at" | "updated_at" | "name";
  /**
   * Whether to sort the sets ascending or descending
   */
  sort_direction: "asc" | "desc";
  /**
   * Return only sets where the name includes this string (case insensitive)
   */
  search?: string;
  /**
   * Pass the value of the previous call's next_page to retrieve further pages
   */
  cursor?: string;
};

export type ListVariationSetsError = Fetcher.ErrorWrapper<undefined>;

export type ListVariationSetsResponse = {
  variation_sets: {
    id: number;
    unique_id: string;
    name: string;
    status: "Ready" | "Collecting" | "Complete";
    status_tags: string[];
    usability_tests: Schemas.VariationSetUsabilityTest[];
  }[];
  next_page: string | null;
  /**
   * @minimum 0
   */
  total_records: number;
};

export type ListVariationSetsVariables = {
  queryParams: ListVariationSetsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get variation sets of current account
 */
export const fetchListVariationSets = (variables: ListVariationSetsVariables) =>
  usabilityhubFetch<
    ListVariationSetsResponse,
    ListVariationSetsError,
    undefined,
    {},
    ListVariationSetsQueryParams,
    {}
  >({ url: "/api/test_sets/variation_sets", method: "get", ...variables });

/**
 * Get variation sets of current account
 */
export const useListVariationSets = <TData = ListVariationSetsResponse,>(
  variables: ListVariationSetsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListVariationSetsResponse,
      ListVariationSetsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListVariationSetsResponse,
    ListVariationSetsError,
    TData
  >(
    queryKeyFn({
      path: "/api/test_sets/variation_sets",
      operationId: "listVariationSets",
      variables,
    }),
    () => fetchListVariationSets({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DeleteVariationSetPathParams = {
  uniqueId: string;
};

export type DeleteVariationSetError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type DeleteVariationSetVariables = {
  pathParams: DeleteVariationSetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Deletes a variation set
 */
export const fetchDeleteVariationSet = (
  variables: DeleteVariationSetVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteVariationSetError,
    undefined,
    {},
    {},
    DeleteVariationSetPathParams
  >({ url: "/api/test_sets/{uniqueId}", method: "delete", ...variables });

/**
 * Deletes a variation set
 */
export const useDeleteVariationSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteVariationSetError,
      DeleteVariationSetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteVariationSetError,
    DeleteVariationSetVariables
  >(
    (variables: DeleteVariationSetVariables) =>
      fetchDeleteVariationSet({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type AddTestToTestSetPathParams = {
  uniqueId: string;
};

export type AddTestToTestSetError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type AddTestToTestSetRequestBody = {
  usability_test_id: number;
};

export type AddTestToTestSetVariables = {
  body: AddTestToTestSetRequestBody;
  pathParams: AddTestToTestSetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Adds the usability test to the provided test set.
 */
export const fetchAddTestToTestSet = (variables: AddTestToTestSetVariables) =>
  usabilityhubFetch<
    Responses.R204,
    AddTestToTestSetError,
    AddTestToTestSetRequestBody,
    {},
    {},
    AddTestToTestSetPathParams
  >({
    url: "/api/test_sets/{uniqueId}/add_test",
    method: "post",
    ...variables,
  });

/**
 * Adds the usability test to the provided test set.
 */
export const useAddTestToTestSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AddTestToTestSetError,
      AddTestToTestSetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AddTestToTestSetError,
    AddTestToTestSetVariables
  >(
    (variables: AddTestToTestSetVariables) =>
      fetchAddTestToTestSet({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PreviewTestSetPathParams = {
  uniqueId: string;
};

export type PreviewTestSetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PreviewTestSetVariables = {
  pathParams: PreviewTestSetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Retrieve preview information for a random usability test from the provided set
 */
export const fetchPreviewTestSet = (variables: PreviewTestSetVariables) =>
  usabilityhubFetch<
    Schemas.UsabilityTestResponseFlow,
    PreviewTestSetError,
    undefined,
    {},
    {},
    PreviewTestSetPathParams
  >({ url: "/api/test_sets/{uniqueId}/preview", method: "get", ...variables });

/**
 * Retrieve preview information for a random usability test from the provided set
 */
export const usePreviewTestSet = <TData = Schemas.UsabilityTestResponseFlow,>(
  variables: PreviewTestSetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UsabilityTestResponseFlow,
      PreviewTestSetError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.UsabilityTestResponseFlow,
    PreviewTestSetError,
    TData
  >(
    queryKeyFn({
      path: "/api/test_sets/{unique_id}/preview",
      operationId: "previewTestSet",
      variables,
    }),
    () => fetchPreviewTestSet({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type RemoveTestFromTestSetPathParams = {
  uniqueId: string;
};

export type RemoveTestFromTestSetError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type RemoveTestFromTestSetRequestBody = {
  usability_test_id: number;
};

export type RemoveTestFromTestSetVariables = {
  body: RemoveTestFromTestSetRequestBody;
  pathParams: RemoveTestFromTestSetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Removes the usability test from the provided test set.
 */
export const fetchRemoveTestFromTestSet = (
  variables: RemoveTestFromTestSetVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RemoveTestFromTestSetError,
    RemoveTestFromTestSetRequestBody,
    {},
    {},
    RemoveTestFromTestSetPathParams
  >({
    url: "/api/test_sets/{uniqueId}/remove_test",
    method: "delete",
    ...variables,
  });

/**
 * Removes the usability test from the provided test set.
 */
export const useRemoveTestFromTestSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RemoveTestFromTestSetError,
      RemoveTestFromTestSetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RemoveTestFromTestSetError,
    RemoveTestFromTestSetVariables
  >(
    (variables: RemoveTestFromTestSetVariables) =>
      fetchRemoveTestFromTestSet({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type RenameVariationSetPathParams = {
  uniqueId: string;
};

export type RenameVariationSetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type RenameVariationSetRequestBody = {
  name: string;
};

export type RenameVariationSetVariables = {
  body: RenameVariationSetRequestBody;
  pathParams: RenameVariationSetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Renames a variation set
 */
export const fetchRenameVariationSet = (
  variables: RenameVariationSetVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RenameVariationSetError,
    RenameVariationSetRequestBody,
    {},
    {},
    RenameVariationSetPathParams
  >({ url: "/api/test_sets/{uniqueId}/rename", method: "patch", ...variables });

/**
 * Renames a variation set
 */
export const useRenameVariationSet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RenameVariationSetError,
      RenameVariationSetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RenameVariationSetError,
    RenameVariationSetVariables
  >(
    (variables: RenameVariationSetVariables) =>
      fetchRenameVariationSet({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetVariationSetPathParams = {
  uniqueId: string;
};

export type GetVariationSetError = Fetcher.ErrorWrapper<undefined>;

export type GetVariationSetResponse = {
  variation_set: Schemas.VariationSet;
};

export type GetVariationSetVariables = {
  pathParams: GetVariationSetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Gets variation set by unique_id
 */
export const fetchGetVariationSet = (variables: GetVariationSetVariables) =>
  usabilityhubFetch<
    GetVariationSetResponse,
    GetVariationSetError,
    undefined,
    {},
    {},
    GetVariationSetPathParams
  >({
    url: "/api/test_sets/{uniqueId}/variation_set",
    method: "get",
    ...variables,
  });

/**
 * Gets variation set by unique_id
 */
export const useGetVariationSet = <TData = GetVariationSetResponse,>(
  variables: GetVariationSetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetVariationSetResponse,
      GetVariationSetError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetVariationSetResponse,
    GetVariationSetError,
    TData
  >(
    queryKeyFn({
      path: "/api/test_sets/{unique_id}/variation_set",
      operationId: "getVariationSet",
      variables,
    }),
    () => fetchGetVariationSet({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListFilterOptionsQueryParams = {
  /**
   * Determine which attribute to order results
   */
  sort_by?: "created_at" | "updated_at" | "name";
  /**
   * Whether to sort the sets ascending or descending
   */
  sort_direction?: "asc" | "desc";
  /**
   * The filter to fetch
   */
  filter: "projects" | "variation_sets" | "usability_tests";
  /**
   * A comma-separated list of scopes to apply to the filter, if any
   */
  scopes?: string;
  /**
   * The string to search (case insensitive)
   */
  search?: string;
  /**
   * Pass the value of the previous call's next_page to retrieve further pages
   */
  cursor?: string;
  /**
   * Whether to enable pagination
   */
  pagination?: boolean;
};

export type ListFilterOptionsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ListFilterOptionsResponse = {
  options: Schemas.FilterOptionWithName[];
  next_page: string | null;
  total_records: number;
};

export type ListFilterOptionsVariables = {
  queryParams: ListFilterOptionsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get filter options of the required filter for current account
 */
export const fetchListFilterOptions = (variables: ListFilterOptionsVariables) =>
  usabilityhubFetch<
    ListFilterOptionsResponse,
    ListFilterOptionsError,
    undefined,
    {},
    ListFilterOptionsQueryParams,
    {}
  >({ url: "/api/filters/options", method: "get", ...variables });

/**
 * Get filter options of the required filter for current account
 */
export const useListFilterOptions = <TData = ListFilterOptionsResponse,>(
  variables: ListFilterOptionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListFilterOptionsResponse,
      ListFilterOptionsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListFilterOptionsResponse,
    ListFilterOptionsError,
    TData
  >(
    queryKeyFn({
      path: "/api/filters/options",
      operationId: "listFilterOptions",
      variables,
    }),
    () => fetchListFilterOptions({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UsabilityTestNewQueryParams = {
  name?: string;
  template?: string;
  test_set_id?: number;
};

export type UsabilityTestNewError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type UsabilityTestNewVariables = {
  queryParams?: UsabilityTestNewQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Data for the new usability test page
 */
export const fetchUsabilityTestNew = (variables: UsabilityTestNewVariables) =>
  usabilityhubFetch<
    Record<string, any>,
    UsabilityTestNewError,
    undefined,
    {},
    UsabilityTestNewQueryParams,
    {}
  >({ url: "/api/usability_tests/new", method: "get", ...variables });

/**
 * Data for the new usability test page
 */
export const useUsabilityTestNew = <TData = Record<string, any>,>(
  variables: UsabilityTestNewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      UsabilityTestNewError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<Record<string, any>, UsabilityTestNewError, TData>(
    queryKeyFn({
      path: "/api/usability_tests/new",
      operationId: "usabilityTestNew",
      variables,
    }),
    () => fetchUsabilityTestNew({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateUsabilityTestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CreateUsabilityTestVariables = {
  body: Schemas.UsabilityTestCreateUpdateRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a usability test
 */
export const fetchCreateUsabilityTest = (
  variables: CreateUsabilityTestVariables,
) =>
  usabilityhubFetch<
    Schemas.UsabilityTestCreateUpdateResponseBody,
    CreateUsabilityTestError,
    Schemas.UsabilityTestCreateUpdateRequestBody,
    {},
    {},
    {}
  >({ url: "/api/usability_tests/create", method: "post", ...variables });

/**
 * Create a usability test
 */
export const useCreateUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UsabilityTestCreateUpdateResponseBody,
      CreateUsabilityTestError,
      CreateUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.UsabilityTestCreateUpdateResponseBody,
    CreateUsabilityTestError,
    CreateUsabilityTestVariables
  >(
    (variables: CreateUsabilityTestVariables) =>
      fetchCreateUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdateUsabilityTestPathParams = {
  usabilityTestId: string;
};

export type UpdateUsabilityTestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateUsabilityTestVariables = {
  body: Schemas.UsabilityTestCreateUpdateRequestBody;
  pathParams: UpdateUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a usability test
 */
export const fetchUpdateUsabilityTest = (
  variables: UpdateUsabilityTestVariables,
) =>
  usabilityhubFetch<
    Schemas.UsabilityTestCreateUpdateResponseBody,
    UpdateUsabilityTestError,
    Schemas.UsabilityTestCreateUpdateRequestBody,
    {},
    {},
    UpdateUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}",
    method: "patch",
    ...variables,
  });

/**
 * Update a usability test
 */
export const useUpdateUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UsabilityTestCreateUpdateResponseBody,
      UpdateUsabilityTestError,
      UpdateUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.UsabilityTestCreateUpdateResponseBody,
    UpdateUsabilityTestError,
    UpdateUsabilityTestVariables
  >(
    (variables: UpdateUsabilityTestVariables) =>
      fetchUpdateUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DeleteUsabilityTestPathParams = {
  usabilityTestId: string;
};

export type DeleteUsabilityTestError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUsabilityTestResponse = {
  refund_point_count: number;
};

export type DeleteUsabilityTestVariables = {
  pathParams: DeleteUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Delete a usability test
 */
export const fetchDeleteUsabilityTest = (
  variables: DeleteUsabilityTestVariables,
) =>
  usabilityhubFetch<
    DeleteUsabilityTestResponse,
    DeleteUsabilityTestError,
    undefined,
    {},
    {},
    DeleteUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}",
    method: "delete",
    ...variables,
  });

/**
 * Delete a usability test
 */
export const useDeleteUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DeleteUsabilityTestResponse,
      DeleteUsabilityTestError,
      DeleteUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DeleteUsabilityTestResponse,
    DeleteUsabilityTestError,
    DeleteUsabilityTestVariables
  >(
    (variables: DeleteUsabilityTestVariables) =>
      fetchDeleteUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UsabilityTestEditPathParams = {
  usabilityTestId: string;
};

export type UsabilityTestEditError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UsabilityTestEditVariables = {
  pathParams: UsabilityTestEditPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Data for editing a usability test
 */
export const fetchUsabilityTestEdit = (variables: UsabilityTestEditVariables) =>
  usabilityhubFetch<
    Record<string, any>,
    UsabilityTestEditError,
    undefined,
    {},
    {},
    UsabilityTestEditPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/edit",
    method: "get",
    ...variables,
  });

/**
 * Data for editing a usability test
 */
export const useUsabilityTestEdit = <TData = Record<string, any>,>(
  variables: UsabilityTestEditVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Record<string, any>,
      UsabilityTestEditError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Record<string, any>,
    UsabilityTestEditError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests/{usability_test_id}/edit",
      operationId: "usabilityTestEdit",
      variables,
    }),
    () => fetchUsabilityTestEdit({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UsabilityTestEnableShareLinkPathParams = {
  usabilityTestId: number;
};

export type UsabilityTestEnableShareLinkError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type UsabilityTestEnableShareLinkVariables = {
  pathParams: UsabilityTestEnableShareLinkPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Enable the share link of a Usability Test
 */
export const fetchUsabilityTestEnableShareLink = (
  variables: UsabilityTestEnableShareLinkVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UsabilityTestEnableShareLinkError,
    undefined,
    {},
    {},
    UsabilityTestEnableShareLinkPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/enable_share_link",
    method: "post",
    ...variables,
  });

/**
 * Enable the share link of a Usability Test
 */
export const useUsabilityTestEnableShareLink = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UsabilityTestEnableShareLinkError,
      UsabilityTestEnableShareLinkVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UsabilityTestEnableShareLinkError,
    UsabilityTestEnableShareLinkVariables
  >(
    (variables: UsabilityTestEnableShareLinkVariables) =>
      fetchUsabilityTestEnableShareLink({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UsabilityTestDisableShareLinkPathParams = {
  usabilityTestId: number;
};

export type UsabilityTestDisableShareLinkError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type UsabilityTestDisableShareLinkVariables = {
  pathParams: UsabilityTestDisableShareLinkPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Disable the share link of a Usability Test
 */
export const fetchUsabilityTestDisableShareLink = (
  variables: UsabilityTestDisableShareLinkVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UsabilityTestDisableShareLinkError,
    undefined,
    {},
    {},
    UsabilityTestDisableShareLinkPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/disable_share_link",
    method: "post",
    ...variables,
  });

/**
 * Disable the share link of a Usability Test
 */
export const useUsabilityTestDisableShareLink = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UsabilityTestDisableShareLinkError,
      UsabilityTestDisableShareLinkVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UsabilityTestDisableShareLinkError,
    UsabilityTestDisableShareLinkVariables
  >(
    (variables: UsabilityTestDisableShareLinkVariables) =>
      fetchUsabilityTestDisableShareLink({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PreviewUsabilityTestPathParams = {
  usabilityTestUniqueId: string;
};

export type PreviewUsabilityTestError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PreviewUsabilityTestVariables = {
  pathParams: PreviewUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Retrieve flow information to preview a usability test
 */
export const fetchPreviewUsabilityTest = (
  variables: PreviewUsabilityTestVariables,
) =>
  usabilityhubFetch<
    Schemas.UsabilityTestResponseFlow,
    PreviewUsabilityTestError,
    undefined,
    {},
    {},
    PreviewUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestUniqueId}/preview",
    method: "get",
    ...variables,
  });

/**
 * Retrieve flow information to preview a usability test
 */
export const usePreviewUsabilityTest = <
  TData = Schemas.UsabilityTestResponseFlow,
>(
  variables: PreviewUsabilityTestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UsabilityTestResponseFlow,
      PreviewUsabilityTestError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.UsabilityTestResponseFlow,
    PreviewUsabilityTestError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests/{usability_test_unique_id}/preview",
      operationId: "previewUsabilityTest",
      variables,
    }),
    () => fetchPreviewUsabilityTest({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PreviewSubmitScreenerPathParams = {
  usabilityTestUniqueId: string;
};

export type PreviewSubmitScreenerError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type PreviewSubmitScreenerResponse = {
  screened_in: boolean;
  new_flow: Schemas.UsabilityTestResponseFlow;
};

export type PreviewSubmitScreenerVariables = {
  body: Schemas.SubmitScreenerRequestBody;
  pathParams: PreviewSubmitScreenerPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Submit a screener when previewing a usability test
 */
export const fetchPreviewSubmitScreener = (
  variables: PreviewSubmitScreenerVariables,
) =>
  usabilityhubFetch<
    PreviewSubmitScreenerResponse,
    PreviewSubmitScreenerError,
    Schemas.SubmitScreenerRequestBody,
    {},
    {},
    PreviewSubmitScreenerPathParams
  >({
    url: "/api/usability_tests/{usabilityTestUniqueId}/preview/submit_screener",
    method: "post",
    ...variables,
  });

/**
 * Submit a screener when previewing a usability test
 */
export const usePreviewSubmitScreener = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PreviewSubmitScreenerResponse,
      PreviewSubmitScreenerError,
      PreviewSubmitScreenerVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PreviewSubmitScreenerResponse,
    PreviewSubmitScreenerError,
    PreviewSubmitScreenerVariables
  >(
    (variables: PreviewSubmitScreenerVariables) =>
      fetchPreviewSubmitScreener({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetAggregatedTreeTestResultsPathParams = {
  usabilityTestId: string;
  privateId: string;
  sectionId: number;
};

export type GetAggregatedTreeTestResultsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetAggregatedTreeTestResultsResponse = {
  nodes: Schemas.TreeTestNode[];
  links: Schemas.TreeTestLink[];
};

export type GetAggregatedTreeTestResultsRequestBody = {
  filtered_response_ids?: number[];
};

export type GetAggregatedTreeTestResultsVariables = {
  body?: GetAggregatedTreeTestResultsRequestBody;
  pathParams: GetAggregatedTreeTestResultsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get aggregated results for the selected tree test
 */
export const fetchGetAggregatedTreeTestResults = (
  variables: GetAggregatedTreeTestResultsVariables,
) =>
  usabilityhubFetch<
    GetAggregatedTreeTestResultsResponse,
    GetAggregatedTreeTestResultsError,
    GetAggregatedTreeTestResultsRequestBody,
    {},
    {},
    GetAggregatedTreeTestResultsPathParams
  >({
    url: "/api/tests/{usabilityTestId}/results/{privateId}/sections/{sectionId}/tree_testing/aggregated",
    method: "post",
    ...variables,
  });

/**
 * Get aggregated results for the selected tree test
 */
export const useGetAggregatedTreeTestResults = <
  TData = GetAggregatedTreeTestResultsResponse,
>(
  variables: GetAggregatedTreeTestResultsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAggregatedTreeTestResultsResponse,
      GetAggregatedTreeTestResultsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetAggregatedTreeTestResultsResponse,
    GetAggregatedTreeTestResultsError,
    TData
  >(
    queryKeyFn({
      path: "/api/tests/{usability_test_id}/results/{private_id}/sections/{section_id}/tree_testing/aggregated",
      operationId: "getAggregatedTreeTestResults",
      variables,
    }),
    () =>
      fetchGetAggregatedTreeTestResults({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetNewOrderPagePathParams = {
  usabilityTestId: string;
};

export type GetNewOrderPageError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetNewOrderPageResponse = {
  has_screener: boolean;
  has_recordings: boolean;
  is_external: boolean;
};

export type GetNewOrderPageVariables = {
  pathParams: GetNewOrderPagePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get data for the new order page
 */
export const fetchGetNewOrderPage = (variables: GetNewOrderPageVariables) =>
  usabilityhubFetch<
    GetNewOrderPageResponse,
    GetNewOrderPageError,
    undefined,
    {},
    {},
    GetNewOrderPagePathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/orders/new",
    method: "get",
    ...variables,
  });

/**
 * Get data for the new order page
 */
export const useGetNewOrderPage = <TData = GetNewOrderPageResponse,>(
  variables: GetNewOrderPageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetNewOrderPageResponse,
      GetNewOrderPageError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetNewOrderPageResponse,
    GetNewOrderPageError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests/{usability_test_id}/orders/new",
      operationId: "getNewOrderPage",
      variables,
    }),
    () => fetchGetNewOrderPage({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UsabilityTestOrdersPathParams = {
  usabilityTestId: string;
};

export type UsabilityTestOrdersError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type UsabilityTestOrdersResponse = {
  orders: Schemas.Order[];
  third_party_orders: Schemas.ThirdPartyOrder[];
};

export type UsabilityTestOrdersVariables = {
  pathParams: UsabilityTestOrdersPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get orders for a usability test
 */
export const fetchUsabilityTestOrders = (
  variables: UsabilityTestOrdersVariables,
) =>
  usabilityhubFetch<
    UsabilityTestOrdersResponse,
    UsabilityTestOrdersError,
    undefined,
    {},
    {},
    UsabilityTestOrdersPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/orders",
    method: "get",
    ...variables,
  });

/**
 * Get orders for a usability test
 */
export const useUsabilityTestOrders = <TData = UsabilityTestOrdersResponse,>(
  variables: UsabilityTestOrdersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      UsabilityTestOrdersResponse,
      UsabilityTestOrdersError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    UsabilityTestOrdersResponse,
    UsabilityTestOrdersError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests/{usability_test_id}/orders",
      operationId: "usabilityTestOrders",
      variables,
    }),
    () => fetchUsabilityTestOrders({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UsabilityTestRecruitPagePathParams = {
  usabilityTestId: string;
};

export type UsabilityTestRecruitPageError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UsabilityTestRecruitPageResponse = {
  usability_test: {
    id: number;
    unique_id: string;
    upgraded: boolean;
    name: string;
    archived: boolean;
    admin_disabled: boolean;
    customize_thankyou: boolean;
    customize_welcome: boolean;
    has_test_logic: boolean;
    permitted_device_type: "any" | "desktop_only" | "mobile_only";
    last_estimated_duration_upper_minutes: number;
    recruited_response_count: number;
    screened_out_response_count: number;
    screener_enabled: boolean;
    language_code: string;
    test_recording_types: Schemas.RecordingType[];
  };
};

export type UsabilityTestRecruitPageVariables = {
  pathParams: UsabilityTestRecruitPagePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get data for the Usability Test recruit page
 */
export const fetchUsabilityTestRecruitPage = (
  variables: UsabilityTestRecruitPageVariables,
) =>
  usabilityhubFetch<
    UsabilityTestRecruitPageResponse,
    UsabilityTestRecruitPageError,
    undefined,
    {},
    {},
    UsabilityTestRecruitPagePathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/recruit",
    method: "get",
    ...variables,
  });

/**
 * Get data for the Usability Test recruit page
 */
export const useUsabilityTestRecruitPage = <
  TData = UsabilityTestRecruitPageResponse,
>(
  variables: UsabilityTestRecruitPageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      UsabilityTestRecruitPageResponse,
      UsabilityTestRecruitPageError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    UsabilityTestRecruitPageResponse,
    UsabilityTestRecruitPageError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests/{usability_test_id}/recruit",
      operationId: "usabilityTestRecruitPage",
      variables,
    }),
    () => fetchUsabilityTestRecruitPage({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UsabilityTestPagePathParams = {
  usabilityTestId: string;
};

export type UsabilityTestPageError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UsabilityTestPageResponse = {
  usability_test: {
    id: number;
    unique_id: string;
    upgraded: boolean;
    name: string;
    archived: boolean;
    response_count: number;
    admin_disabled: boolean;
    flagged_for_incorrect_language: boolean;
    /**
     * @format date-time
     */
    created_at: string;
    has_active_third_party_orders: boolean;
    creator?: {
      id: number;
      name: string;
      email?: string;
    } | null;
    project: {
      /**
       * @format uuid
       */
      id: string;
      name: string;
    } | null;
    variation_set: {
      id: number;
      unique_id: string;
      name: string;
      tests_count: number;
    } | null;
    is_external_study: boolean;
    is_valid_external_study: boolean;
  };
};

export type UsabilityTestPageVariables = {
  pathParams: UsabilityTestPagePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get data for the Usability Test pages
 */
export const fetchUsabilityTestPage = (variables: UsabilityTestPageVariables) =>
  usabilityhubFetch<
    UsabilityTestPageResponse,
    UsabilityTestPageError,
    undefined,
    {},
    {},
    UsabilityTestPagePathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/page",
    method: "get",
    ...variables,
  });

/**
 * Get data for the Usability Test pages
 */
export const useUsabilityTestPage = <TData = UsabilityTestPageResponse,>(
  variables: UsabilityTestPageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      UsabilityTestPageResponse,
      UsabilityTestPageError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    UsabilityTestPageResponse,
    UsabilityTestPageError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests/{usability_test_id}/page",
      operationId: "usabilityTestPage",
      variables,
    }),
    () => fetchUsabilityTestPage({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type RatePanelResponsesPathParams = {
  /**
   * The unique_id of the usability test to add a rating to
   */
  usabilityTestUniqueId: string;
};

export type RatePanelResponsesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type RatePanelResponsesRequestBody = {
  /**
   * @format uuid
   */
  id: string;
  /**
   * @minimum 1
   * @maximum 5
   */
  rating: number;
};

export type RatePanelResponsesVariables = {
  body: RatePanelResponsesRequestBody;
  pathParams: RatePanelResponsesPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Add a CSAT rating for the usability test panel responses
 */
export const fetchRatePanelResponses = (
  variables: RatePanelResponsesVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RatePanelResponsesError,
    RatePanelResponsesRequestBody,
    {},
    {},
    RatePanelResponsesPathParams
  >({
    url: "/api/usability_tests/{usabilityTestUniqueId}/rate",
    method: "post",
    ...variables,
  });

/**
 * Add a CSAT rating for the usability test panel responses
 */
export const useRatePanelResponses = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RatePanelResponsesError,
      RatePanelResponsesVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RatePanelResponsesError,
    RatePanelResponsesVariables
  >(
    (variables: RatePanelResponsesVariables) =>
      fetchRatePanelResponses({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type AddPanelResponsesCommentPathParams = {
  /**
   * The unique_id of the usability test to add a rating comment to
   */
  usabilityTestUniqueId: string;
};

export type AddPanelResponsesCommentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type AddPanelResponsesCommentRequestBody = {
  /**
   * @format uuid
   */
  id: string;
  comment: string;
};

export type AddPanelResponsesCommentVariables = {
  body: AddPanelResponsesCommentRequestBody;
  pathParams: AddPanelResponsesCommentPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Add a CSAT rating comment for the usability test panel responses
 */
export const fetchAddPanelResponsesComment = (
  variables: AddPanelResponsesCommentVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    AddPanelResponsesCommentError,
    AddPanelResponsesCommentRequestBody,
    {},
    {},
    AddPanelResponsesCommentPathParams
  >({
    url: "/api/usability_tests/{usabilityTestUniqueId}/add_comment",
    method: "post",
    ...variables,
  });

/**
 * Add a CSAT rating comment for the usability test panel responses
 */
export const useAddPanelResponsesComment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AddPanelResponsesCommentError,
      AddPanelResponsesCommentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AddPanelResponsesCommentError,
    AddPanelResponsesCommentVariables
  >(
    (variables: AddPanelResponsesCommentVariables) =>
      fetchAddPanelResponsesComment({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CreateUserError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type CreateUserRequestBody = {
  name: string | null;
  email: string;
  role: "admin" | "member" | "guest" | "archived";
};

export type CreateUserVariables = {
  body: CreateUserRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a new user
 */
export const fetchCreateUser = (variables: CreateUserVariables) =>
  usabilityhubFetch<
    Responses.R204,
    CreateUserError,
    CreateUserRequestBody,
    {},
    {},
    {}
  >({ url: "/api/users", method: "post", ...variables });

/**
 * Create a new user
 */
export const useCreateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      CreateUserError,
      CreateUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    CreateUserError,
    CreateUserVariables
  >(
    (variables: CreateUserVariables) =>
      fetchCreateUser({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdateUserPathParams = {
  userId: number;
};

export type UpdateUserError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R401;
    }
>;

export type UpdateUserResponse = {
  message: string;
};

export type UpdateUserRequestBody = {
  avatar?: string;
  email?: string;
  name?: string;
  make_owner?: "true" | "false";
  role?: "admin" | "member" | "guest" | "archived";
};

export type UpdateUserVariables = {
  body?: UpdateUserRequestBody;
  pathParams: UpdateUserPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update a user
 */
export const fetchUpdateUser = (variables: UpdateUserVariables) =>
  usabilityhubFetch<
    UpdateUserResponse,
    UpdateUserError,
    UpdateUserRequestBody,
    {},
    {},
    UpdateUserPathParams
  >({ url: "/api/users/{userId}", method: "put", ...variables });

/**
 * Update a user
 */
export const useUpdateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      UpdateUserResponse,
      UpdateUserError,
      UpdateUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    UpdateUserResponse,
    UpdateUserError,
    UpdateUserVariables
  >(
    (variables: UpdateUserVariables) =>
      fetchUpdateUser({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DeleteUserPathParams = {
  userId: number;
};

export type DeleteUserError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type DeleteUserVariables = {
  pathParams: DeleteUserPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Delete a user
 */
export const fetchDeleteUser = (variables: DeleteUserVariables) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteUserError,
    undefined,
    {},
    {},
    DeleteUserPathParams
  >({ url: "/api/users/{userId}", method: "delete", ...variables });

/**
 * Delete a user
 */
export const useDeleteUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteUserError,
      DeleteUserVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteUserError,
    DeleteUserVariables
  >(
    (variables: DeleteUserVariables) =>
      fetchDeleteUser({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type MakeUserOwnerPathParams = {
  userId: number;
};

export type MakeUserOwnerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
>;

export type MakeUserOwnerResponse = {
  message: string;
};

export type MakeUserOwnerVariables = {
  pathParams: MakeUserOwnerPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Grants ownership of the account to another user
 */
export const fetchMakeUserOwner = (variables: MakeUserOwnerVariables) =>
  usabilityhubFetch<
    MakeUserOwnerResponse,
    MakeUserOwnerError,
    undefined,
    {},
    {},
    MakeUserOwnerPathParams
  >({ url: "/api/users/{userId}/make_owner", method: "put", ...variables });

/**
 * Grants ownership of the account to another user
 */
export const useMakeUserOwner = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      MakeUserOwnerResponse,
      MakeUserOwnerError,
      MakeUserOwnerVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    MakeUserOwnerResponse,
    MakeUserOwnerError,
    MakeUserOwnerVariables
  >(
    (variables: MakeUserOwnerVariables) =>
      fetchMakeUserOwner({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetUsabilityTestResponseFlowPathParams = {
  responseId: number;
};

export type GetUsabilityTestResponseFlowError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type GetUsabilityTestResponseFlowVariables = {
  pathParams: GetUsabilityTestResponseFlowPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Retrieve information about the current response flow for a usability test
 */
export const fetchGetUsabilityTestResponseFlow = (
  variables: GetUsabilityTestResponseFlowVariables,
) =>
  usabilityhubFetch<
    Schemas.UsabilityTestResponseFlow,
    GetUsabilityTestResponseFlowError,
    undefined,
    {},
    {},
    GetUsabilityTestResponseFlowPathParams
  >({ url: "/api/responses/{responseId}", method: "get", ...variables });

/**
 * Retrieve information about the current response flow for a usability test
 */
export const useGetUsabilityTestResponseFlow = <
  TData = Schemas.UsabilityTestResponseFlow,
>(
  variables: GetUsabilityTestResponseFlowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UsabilityTestResponseFlow,
      GetUsabilityTestResponseFlowError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.UsabilityTestResponseFlow,
    GetUsabilityTestResponseFlowError,
    TData
  >(
    queryKeyFn({
      path: "/api/responses/{response_id}",
      operationId: "getUsabilityTestResponseFlow",
      variables,
    }),
    () =>
      fetchGetUsabilityTestResponseFlow({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ResponsesStartScreenerPathParams = {
  responseId: number;
};

export type ResponsesStartScreenerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ResponsesStartScreenerResponse = {
  /**
   * @format uuid
   */
  screener_response_id: string;
};

export type ResponsesStartScreenerVariables = {
  pathParams: ResponsesStartScreenerPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Start a screener for a usability test response
 */
export const fetchResponsesStartScreener = (
  variables: ResponsesStartScreenerVariables,
) =>
  usabilityhubFetch<
    ResponsesStartScreenerResponse,
    ResponsesStartScreenerError,
    undefined,
    {},
    {},
    ResponsesStartScreenerPathParams
  >({
    url: "/api/responses/{responseId}/start_screener",
    method: "post",
    ...variables,
  });

/**
 * Start a screener for a usability test response
 */
export const useResponsesStartScreener = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ResponsesStartScreenerResponse,
      ResponsesStartScreenerError,
      ResponsesStartScreenerVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ResponsesStartScreenerResponse,
    ResponsesStartScreenerError,
    ResponsesStartScreenerVariables
  >(
    (variables: ResponsesStartScreenerVariables) =>
      fetchResponsesStartScreener({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ResponsesSubmitScreenerPathParams = {
  responseId: number;
};

export type ResponsesSubmitScreenerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ResponsesSubmitScreenerResponse = {
  screened_in: boolean;
  screened_out_credits: number | null;
};

export type ResponsesSubmitScreenerVariables = {
  body: Schemas.SubmitScreenerRequestBody;
  pathParams: ResponsesSubmitScreenerPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Submit a screener for a usability test response
 */
export const fetchResponsesSubmitScreener = (
  variables: ResponsesSubmitScreenerVariables,
) =>
  usabilityhubFetch<
    ResponsesSubmitScreenerResponse,
    ResponsesSubmitScreenerError,
    Schemas.SubmitScreenerRequestBody,
    {},
    {},
    ResponsesSubmitScreenerPathParams
  >({
    url: "/api/responses/{responseId}/submit_screener",
    method: "post",
    ...variables,
  });

/**
 * Submit a screener for a usability test response
 */
export const useResponsesSubmitScreener = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ResponsesSubmitScreenerResponse,
      ResponsesSubmitScreenerError,
      ResponsesSubmitScreenerVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ResponsesSubmitScreenerResponse,
    ResponsesSubmitScreenerError,
    ResponsesSubmitScreenerVariables
  >(
    (variables: ResponsesSubmitScreenerVariables) =>
      fetchResponsesSubmitScreener({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ImportTreeTestTreeError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ImportTreeTestTreeResponse = {
  tree_id?: number;
  line_count?: number;
};

export type ImportTreeTestTreeRequestBody = {
  tree_id?: string | null;
};

export type ImportTreeTestTreeVariables = {
  body?: ImportTreeTestTreeRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Import tree test tree
 */
export const fetchImportTreeTestTree = (
  variables: ImportTreeTestTreeVariables,
) =>
  usabilityhubFetch<
    ImportTreeTestTreeResponse,
    ImportTreeTestTreeError,
    ImportTreeTestTreeRequestBody,
    {},
    {},
    {}
  >({ url: "/api/tree_testing/trees", method: "post", ...variables });

/**
 * Import tree test tree
 */
export const useImportTreeTestTree = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ImportTreeTestTreeResponse,
      ImportTreeTestTreeError,
      ImportTreeTestTreeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ImportTreeTestTreeResponse,
    ImportTreeTestTreeError,
    ImportTreeTestTreeVariables
  >(
    (variables: ImportTreeTestTreeVariables) =>
      fetchImportTreeTestTree({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetTreeTestTreePathParams = {
  id: string;
};

export type GetTreeTestTreeError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetTreeTestTreeResponse = {
  id: string;
  name: string;
  description?: string;
  nodes: {
    id: string;
    label: string;
    parent_id: string | null;
    left: number;
    right: number;
    depth: number;
  }[];
};

export type GetTreeTestTreeVariables = {
  pathParams: GetTreeTestTreePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Get tree test tree
 */
export const fetchGetTreeTestTree = (variables: GetTreeTestTreeVariables) =>
  usabilityhubFetch<
    GetTreeTestTreeResponse,
    GetTreeTestTreeError,
    undefined,
    {},
    {},
    GetTreeTestTreePathParams
  >({ url: "/api/tree_testing/trees/{id}", method: "get", ...variables });

/**
 * Get tree test tree
 */
export const useGetTreeTestTree = <TData = GetTreeTestTreeResponse,>(
  variables: GetTreeTestTreeVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTreeTestTreeResponse,
      GetTreeTestTreeError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetTreeTestTreeResponse,
    GetTreeTestTreeError,
    TData
  >(
    queryKeyFn({
      path: "/api/tree_testing/trees/{id}",
      operationId: "getTreeTestTree",
      variables,
    }),
    () => fetchGetTreeTestTree({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetExperimentsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetExperimentsResponse = {
  [key: string]: any;
};

export type GetExperimentsVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Gets all Ordeal experiments for the current user
 */
export const fetchGetExperiments = (variables: GetExperimentsVariables) =>
  usabilityhubFetch<
    GetExperimentsResponse,
    GetExperimentsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/ordeal", method: "get", ...variables });

/**
 * Gets all Ordeal experiments for the current user
 */
export const useGetExperiments = <TData = GetExperimentsResponse,>(
  variables: GetExperimentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetExperimentsResponse,
      GetExperimentsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetExperimentsResponse,
    GetExperimentsError,
    TData
  >(
    queryKeyFn({
      path: "/api/ordeal",
      operationId: "getExperiments",
      variables,
    }),
    () => fetchGetExperiments({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetOrdealExperimentsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetOrdealExperimentsResponse = Schemas.OrdealExperiment[];

export type GetOrdealExperimentsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Gets all Ordeal experiments
 */
export const fetchGetOrdealExperiments = (
  variables: GetOrdealExperimentsVariables,
) =>
  usabilityhubFetch<
    GetOrdealExperimentsResponse,
    GetOrdealExperimentsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/admin/ordeal/api/experiments", method: "get", ...variables });

/**
 * Gets all Ordeal experiments
 */
export const useGetOrdealExperiments = <TData = GetOrdealExperimentsResponse,>(
  variables: GetOrdealExperimentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetOrdealExperimentsResponse,
      GetOrdealExperimentsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetOrdealExperimentsResponse,
    GetOrdealExperimentsError,
    TData
  >(
    queryKeyFn({
      path: "/admin/ordeal/api/experiments",
      operationId: "getOrdealExperiments",
      variables,
    }),
    () => fetchGetOrdealExperiments({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type StartOrdealExperimentPathParams = {
  experimentId: string;
};

export type StartOrdealExperimentError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type StartOrdealExperimentVariables = {
  pathParams: StartOrdealExperimentPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Starts an Ordeal experiment
 */
export const fetchStartOrdealExperiment = (
  variables: StartOrdealExperimentVariables,
) =>
  usabilityhubFetch<
    Schemas.OrdealExperiment,
    StartOrdealExperimentError,
    undefined,
    {},
    {},
    StartOrdealExperimentPathParams
  >({
    url: "/admin/ordeal/api/experiments/{experimentId}/start",
    method: "post",
    ...variables,
  });

/**
 * Starts an Ordeal experiment
 */
export const useStartOrdealExperiment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrdealExperiment,
      StartOrdealExperimentError,
      StartOrdealExperimentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.OrdealExperiment,
    StartOrdealExperimentError,
    StartOrdealExperimentVariables
  >(
    (variables: StartOrdealExperimentVariables) =>
      fetchStartOrdealExperiment({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type FinishOrdealExperimentPathParams = {
  experimentId: string;
};

export type FinishOrdealExperimentError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type FinishOrdealExperimentVariables = {
  pathParams: FinishOrdealExperimentPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Stops an Ordeal experiment
 */
export const fetchFinishOrdealExperiment = (
  variables: FinishOrdealExperimentVariables,
) =>
  usabilityhubFetch<
    Schemas.OrdealExperiment,
    FinishOrdealExperimentError,
    undefined,
    {},
    {},
    FinishOrdealExperimentPathParams
  >({
    url: "/admin/ordeal/api/experiments/{experimentId}/finish",
    method: "post",
    ...variables,
  });

/**
 * Stops an Ordeal experiment
 */
export const useFinishOrdealExperiment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrdealExperiment,
      FinishOrdealExperimentError,
      FinishOrdealExperimentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.OrdealExperiment,
    FinishOrdealExperimentError,
    FinishOrdealExperimentVariables
  >(
    (variables: FinishOrdealExperimentVariables) =>
      fetchFinishOrdealExperiment({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PinOrdealExperimentVariantPathParams = {
  experimentId: string;
};

export type PinOrdealExperimentVariantError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type PinOrdealExperimentVariantRequestBody = {
  variant: string | null;
};

export type PinOrdealExperimentVariantVariables = {
  body: PinOrdealExperimentVariantRequestBody;
  pathParams: PinOrdealExperimentVariantPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Pins an Ordeal experiment variant
 */
export const fetchPinOrdealExperimentVariant = (
  variables: PinOrdealExperimentVariantVariables,
) =>
  usabilityhubFetch<
    Schemas.OrdealExperiment,
    PinOrdealExperimentVariantError,
    PinOrdealExperimentVariantRequestBody,
    {},
    {},
    PinOrdealExperimentVariantPathParams
  >({
    url: "/admin/ordeal/api/experiments/{experimentId}/pin",
    method: "post",
    ...variables,
  });

/**
 * Pins an Ordeal experiment variant
 */
export const usePinOrdealExperimentVariant = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrdealExperiment,
      PinOrdealExperimentVariantError,
      PinOrdealExperimentVariantVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.OrdealExperiment,
    PinOrdealExperimentVariantError,
    PinOrdealExperimentVariantVariables
  >(
    (variables: PinOrdealExperimentVariantVariables) =>
      fetchPinOrdealExperimentVariant({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListDemographicPresetsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type ListDemographicPresetsResponse = Schemas.DemographicPreset[];

export type ListDemographicPresetsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Return all demographic presets for the current account
 */
export const fetchListDemographicPresets = (
  variables: ListDemographicPresetsVariables,
) =>
  usabilityhubFetch<
    ListDemographicPresetsResponse,
    ListDemographicPresetsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/demographic_presets", method: "get", ...variables });

/**
 * Return all demographic presets for the current account
 */
export const useListDemographicPresets = <
  TData = ListDemographicPresetsResponse,
>(
  variables: ListDemographicPresetsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListDemographicPresetsResponse,
      ListDemographicPresetsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListDemographicPresetsResponse,
    ListDemographicPresetsError,
    TData
  >(
    queryKeyFn({
      path: "/api/demographic_presets",
      operationId: "listDemographicPresets",
      variables,
    }),
    () => fetchListDemographicPresets({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateDemographicPresetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type CreateDemographicPresetVariables = {
  body: Schemas.DemographicPresetRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a demographic preset in the current account
 */
export const fetchCreateDemographicPreset = (
  variables: CreateDemographicPresetVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    CreateDemographicPresetError,
    Schemas.DemographicPresetRequestBody,
    {},
    {},
    {}
  >({ url: "/api/demographic_presets", method: "post", ...variables });

/**
 * Creates a demographic preset in the current account
 */
export const useCreateDemographicPreset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      CreateDemographicPresetError,
      CreateDemographicPresetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    CreateDemographicPresetError,
    CreateDemographicPresetVariables
  >(
    (variables: CreateDemographicPresetVariables) =>
      fetchCreateDemographicPreset({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdateDemographicPresetPathParams = {
  id: string;
};

export type UpdateDemographicPresetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateDemographicPresetVariables = {
  body: Schemas.DemographicPresetRequestBody;
  pathParams: UpdateDemographicPresetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates a demographic preset
 */
export const fetchUpdateDemographicPreset = (
  variables: UpdateDemographicPresetVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateDemographicPresetError,
    Schemas.DemographicPresetRequestBody,
    {},
    {},
    UpdateDemographicPresetPathParams
  >({ url: "/api/demographic_presets/{id}", method: "patch", ...variables });

/**
 * Updates a demographic preset
 */
export const useUpdateDemographicPreset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateDemographicPresetError,
      UpdateDemographicPresetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateDemographicPresetError,
    UpdateDemographicPresetVariables
  >(
    (variables: UpdateDemographicPresetVariables) =>
      fetchUpdateDemographicPreset({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DestroyDemographicPresetPathParams = {
  id: string;
};

export type DestroyDemographicPresetError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type DestroyDemographicPresetVariables = {
  pathParams: DestroyDemographicPresetPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Destroys a demographic preset
 */
export const fetchDestroyDemographicPreset = (
  variables: DestroyDemographicPresetVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DestroyDemographicPresetError,
    undefined,
    {},
    {},
    DestroyDemographicPresetPathParams
  >({ url: "/api/demographic_presets/{id}", method: "delete", ...variables });

/**
 * Destroys a demographic preset
 */
export const useDestroyDemographicPreset = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DestroyDemographicPresetError,
      DestroyDemographicPresetVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DestroyDemographicPresetError,
    DestroyDemographicPresetVariables
  >(
    (variables: DestroyDemographicPresetVariables) =>
      fetchDestroyDemographicPreset({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PurchaseVendorCreditsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type PurchaseVendorCreditsResponse = {
  message: string;
  account: Schemas.Account;
  purchase: {
    credit_count: number;
    price_in_cents: number;
  };
};

export type PurchaseVendorCreditsRequestBody = {
  credits: number;
};

export type PurchaseVendorCreditsVariables = {
  body: PurchaseVendorCreditsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Purchase credits as a vendor
 */
export const fetchPurchaseVendorCredits = (
  variables: PurchaseVendorCreditsVariables,
) =>
  usabilityhubFetch<
    PurchaseVendorCreditsResponse,
    PurchaseVendorCreditsError,
    PurchaseVendorCreditsRequestBody,
    {},
    {},
    {}
  >({ url: "/api/vendors/purchase_credits", method: "post", ...variables });

/**
 * Purchase credits as a vendor
 */
export const usePurchaseVendorCredits = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PurchaseVendorCreditsResponse,
      PurchaseVendorCreditsError,
      PurchaseVendorCreditsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PurchaseVendorCreditsResponse,
    PurchaseVendorCreditsError,
    PurchaseVendorCreditsVariables
  >(
    (variables: PurchaseVendorCreditsVariables) =>
      fetchPurchaseVendorCredits({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetDashboardNewError = Fetcher.ErrorWrapper<undefined>;

export type GetDashboardNewResponse = {
  projects: Schemas.NewProject[];
  usabilityTests: Schemas.DashboardUsabilityTest[];
};

export type GetDashboardNewVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Returns projects and usability tests for the dashboard
 */
export const fetchGetDashboardNew = (variables: GetDashboardNewVariables) =>
  usabilityhubFetch<
    GetDashboardNewResponse,
    GetDashboardNewError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/dashboard_new", method: "get", ...variables });

/**
 * Returns projects and usability tests for the dashboard
 */
export const useGetDashboardNew = <TData = GetDashboardNewResponse,>(
  variables: GetDashboardNewVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetDashboardNewResponse,
      GetDashboardNewError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetDashboardNewResponse,
    GetDashboardNewError,
    TData
  >(
    queryKeyFn({
      path: "/api/dashboard_new",
      operationId: "getDashboardNew",
      variables,
    }),
    () => fetchGetDashboardNew({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetTestBuilderPathParams = {
  /**
   * ID of the usability test to edit, if any
   */
  usabilityTestUniqueId: string;
};

export type GetTestBuilderError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetTestBuilderResponse = {
  projects: string[];
  comments: Schemas.Comment[];
};

export type GetTestBuilderVariables = {
  pathParams: GetTestBuilderPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * The aim is for this endpoint to eventually become a BFF endpoint serving the test builder for both new and existing tests.  We'll gradually add necessary data to it until it can replace the current ReduxHelper situation.
 */
export const fetchGetTestBuilder = (variables: GetTestBuilderVariables) =>
  usabilityhubFetch<
    GetTestBuilderResponse,
    GetTestBuilderError,
    undefined,
    {},
    {},
    GetTestBuilderPathParams
  >({
    url: "/api/test_builder/{usabilityTestUniqueId}",
    method: "get",
    ...variables,
  });

/**
 * The aim is for this endpoint to eventually become a BFF endpoint serving the test builder for both new and existing tests.  We'll gradually add necessary data to it until it can replace the current ReduxHelper situation.
 */
export const useGetTestBuilder = <TData = GetTestBuilderResponse,>(
  variables: GetTestBuilderVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetTestBuilderResponse,
      GetTestBuilderError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetTestBuilderResponse,
    GetTestBuilderError,
    TData
  >(
    queryKeyFn({
      path: "/api/test_builder/{usability_test_unique_id}",
      operationId: "getTestBuilder",
      variables,
    }),
    () => fetchGetTestBuilder({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PostAddCommentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PostAddCommentRequestBody = {
  /**
   * @format uuid
   */
  id: string;
  usability_test_id?: string;
  context?: "test_builder" | "test_results";
  entity_type: string;
  entity_id: string | null;
  content: string;
};

export type PostAddCommentVariables = {
  body: PostAddCommentRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a new comment on the given test + context + entity combination. Note that the IDs for these are UUIDs, and can be generated on the client and passed through.
 */
export const fetchPostAddComment = (variables: PostAddCommentVariables) =>
  usabilityhubFetch<
    Record<string, any>,
    PostAddCommentError,
    PostAddCommentRequestBody,
    {},
    {},
    {}
  >({ url: "/api/comments", method: "post", ...variables });

/**
 * Creates a new comment on the given test + context + entity combination. Note that the IDs for these are UUIDs, and can be generated on the client and passed through.
 */
export const usePostAddComment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      PostAddCommentError,
      PostAddCommentVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Record<string, any>,
    PostAddCommentError,
    PostAddCommentVariables
  >(
    (variables: PostAddCommentVariables) =>
      fetchPostAddComment({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PostCommentsMarkAsReadError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PostCommentsMarkAsReadResponse = {
  comment_ids: string[];
};

export type PostCommentsMarkAsReadRequestBody = {
  /**
   * @format uuid
   */
  comment_ids: string[];
};

export type PostCommentsMarkAsReadVariables = {
  body: PostCommentsMarkAsReadRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Mark comments as read
 */
export const fetchPostCommentsMarkAsRead = (
  variables: PostCommentsMarkAsReadVariables,
) =>
  usabilityhubFetch<
    PostCommentsMarkAsReadResponse,
    PostCommentsMarkAsReadError,
    PostCommentsMarkAsReadRequestBody,
    {},
    {},
    {}
  >({ url: "/api/comments/mark_as_read", method: "post", ...variables });

/**
 * Mark comments as read
 */
export const usePostCommentsMarkAsRead = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostCommentsMarkAsReadResponse,
      PostCommentsMarkAsReadError,
      PostCommentsMarkAsReadVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PostCommentsMarkAsReadResponse,
    PostCommentsMarkAsReadError,
    PostCommentsMarkAsReadVariables
  >(
    (variables: PostCommentsMarkAsReadVariables) =>
      fetchPostCommentsMarkAsRead({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DeleteCommentsPathParams = {
  /**
   * @format uuid
   */
  commentId: string;
};

export type DeleteCommentsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type DeleteCommentsResponse = {
  /**
   * @format date-time
   */
  deleted_at: string;
};

export type DeleteCommentsVariables = {
  pathParams: DeleteCommentsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Deletes a comment given its UUID.
 */
export const fetchDeleteComments = (variables: DeleteCommentsVariables) =>
  usabilityhubFetch<
    DeleteCommentsResponse,
    DeleteCommentsError,
    undefined,
    {},
    {},
    DeleteCommentsPathParams
  >({ url: "/api/comments/{commentId}", method: "delete", ...variables });

/**
 * Deletes a comment given its UUID.
 */
export const useDeleteComments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DeleteCommentsResponse,
      DeleteCommentsError,
      DeleteCommentsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DeleteCommentsResponse,
    DeleteCommentsError,
    DeleteCommentsVariables
  >(
    (variables: DeleteCommentsVariables) =>
      fetchDeleteComments({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PutCommentsPathParams = {
  /**
   * @format uuid
   */
  commentId: string;
};

export type PutCommentsError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type PutCommentsResponse = {
  /**
   * @format date-time
   */
  last_edited_at: string;
};

export type PutCommentsRequestBody = {
  content: string;
};

export type PutCommentsVariables = {
  body: PutCommentsRequestBody;
  pathParams: PutCommentsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates a comment given its UUID.
 */
export const fetchPutComments = (variables: PutCommentsVariables) =>
  usabilityhubFetch<
    PutCommentsResponse,
    PutCommentsError,
    PutCommentsRequestBody,
    {},
    {},
    PutCommentsPathParams
  >({ url: "/api/comments/{commentId}", method: "put", ...variables });

/**
 * Updates a comment given its UUID.
 */
export const usePutComments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PutCommentsResponse,
      PutCommentsError,
      PutCommentsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PutCommentsResponse,
    PutCommentsError,
    PutCommentsVariables
  >(
    (variables: PutCommentsVariables) =>
      fetchPutComments({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListTargetableLocationsPathParams = {
  scope: "usability_tests" | "interviews";
};

export type ListTargetableLocationsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ListTargetableLocationsResponse = {
  locations: {
    code: string;
    name: string;
    states: {
      id: number;
      short_name: string;
      long_name: string;
      cities: {
        id: number;
        short_name: string;
        long_name: string;
      }[];
    }[];
  }[];
};

export type ListTargetableLocationsVariables = {
  pathParams: ListTargetableLocationsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns a nested structure with all locations that can be targeted for a panel order
 */
export const fetchListTargetableLocations = (
  variables: ListTargetableLocationsVariables,
) =>
  usabilityhubFetch<
    ListTargetableLocationsResponse,
    ListTargetableLocationsError,
    undefined,
    {},
    {},
    ListTargetableLocationsPathParams
  >({ url: "/api/targetable_locations/{scope}", method: "get", ...variables });

/**
 * Returns a nested structure with all locations that can be targeted for a panel order
 */
export const useListTargetableLocations = <
  TData = ListTargetableLocationsResponse,
>(
  variables: ListTargetableLocationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListTargetableLocationsResponse,
      ListTargetableLocationsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListTargetableLocationsResponse,
    ListTargetableLocationsError,
    TData
  >(
    queryKeyFn({
      path: "/api/targetable_locations/{scope}",
      operationId: "listTargetableLocations",
      variables,
    }),
    () => fetchListTargetableLocations({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetIntegrationsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type GetIntegrationsResponse = {
  figma?: {
    /**
     * @format date-time
     */
    created_at: string;
  } | null;
  google: {
    /**
     * @format date-time
     */
    created_at: string;
    error: boolean;
  } | null;
  zoom: {
    /**
     * @format date-time
     */
    created_at: string;
    settings: {
      waiting_room: boolean;
      join_before_host: boolean;
    };
  } | null;
  microsoft: {
    teams_enabled: boolean;
    /**
     * @format date-time
     */
    created_at: string;
  } | null;
  conflict_calendars: Schemas.ModeratedStudyCalendar[];
};

export type GetIntegrationsVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Get the current users integrations
 */
export const fetchGetIntegrations = (variables: GetIntegrationsVariables) =>
  usabilityhubFetch<
    GetIntegrationsResponse,
    GetIntegrationsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/integrations", method: "get", ...variables });

/**
 * Get the current users integrations
 */
export const useGetIntegrations = <TData = GetIntegrationsResponse,>(
  variables: GetIntegrationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetIntegrationsResponse,
      GetIntegrationsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetIntegrationsResponse,
    GetIntegrationsError,
    TData
  >(
    queryKeyFn({
      path: "/api/integrations",
      operationId: "getIntegrations",
      variables,
    }),
    () => fetchGetIntegrations({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DeleteIntegrationError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type DeleteIntegrationRequestBody = {
  integration?: "google" | "zoom" | "microsoft" | "figma";
};

export type DeleteIntegrationVariables = {
  body?: DeleteIntegrationRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Deletes the integration for the current user
 */
export const fetchDeleteIntegration = (variables: DeleteIntegrationVariables) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteIntegrationError,
    DeleteIntegrationRequestBody,
    {},
    {},
    {}
  >({ url: "/api/integrations", method: "delete", ...variables });

/**
 * Deletes the integration for the current user
 */
export const useDeleteIntegration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteIntegrationError,
      DeleteIntegrationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteIntegrationError,
    DeleteIntegrationVariables
  >(
    (variables: DeleteIntegrationVariables) =>
      fetchDeleteIntegration({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ConfirmUserEmailError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ConfirmUserEmailRequestBody = {
  token: string;
};

export type ConfirmUserEmailVariables = {
  body: ConfirmUserEmailRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Confirm a users email
 */
export const fetchConfirmUserEmail = (variables: ConfirmUserEmailVariables) =>
  usabilityhubFetch<
    Responses.R204,
    ConfirmUserEmailError,
    ConfirmUserEmailRequestBody,
    {},
    {},
    {}
  >({ url: "/api/users/confirm_email", method: "post", ...variables });

/**
 * Confirm a users email
 */
export const useConfirmUserEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ConfirmUserEmailError,
      ConfirmUserEmailVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ConfirmUserEmailError,
    ConfirmUserEmailVariables
  >(
    (variables: ConfirmUserEmailVariables) =>
      fetchConfirmUserEmail({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ResendUserEmailConfirmationError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ResendUserEmailConfirmationRequestBody = {
  user_email_confirmation_id: number;
};

export type ResendUserEmailConfirmationVariables = {
  body: ResendUserEmailConfirmationRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Resend user email confirmation
 */
export const fetchResendUserEmailConfirmation = (
  variables: ResendUserEmailConfirmationVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ResendUserEmailConfirmationError,
    ResendUserEmailConfirmationRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/users/resend_email_confirmation",
    method: "post",
    ...variables,
  });

/**
 * Resend user email confirmation
 */
export const useResendUserEmailConfirmation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ResendUserEmailConfirmationError,
      ResendUserEmailConfirmationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ResendUserEmailConfirmationError,
    ResendUserEmailConfirmationVariables
  >(
    (variables: ResendUserEmailConfirmationVariables) =>
      fetchResendUserEmailConfirmation({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CreateExternalStudyError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type CreateExternalStudyResponse = {
  /**
   * @format uuid
   */
  unique_id: string;
};

export type CreateExternalStudyRequestBody = {
  name: string;
};

export type CreateExternalStudyVariables = {
  body: CreateExternalStudyRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a new blank external study
 */
export const fetchCreateExternalStudy = (
  variables: CreateExternalStudyVariables,
) =>
  usabilityhubFetch<
    CreateExternalStudyResponse,
    CreateExternalStudyError,
    CreateExternalStudyRequestBody,
    {},
    {},
    {}
  >({ url: "/api/external_studies", method: "post", ...variables });

/**
 * Creates a new blank external study
 */
export const useCreateExternalStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateExternalStudyResponse,
      CreateExternalStudyError,
      CreateExternalStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateExternalStudyResponse,
    CreateExternalStudyError,
    CreateExternalStudyVariables
  >(
    (variables: CreateExternalStudyVariables) =>
      fetchCreateExternalStudy({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CheckExternalStudyCompletionCodeError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type CheckExternalStudyCompletionCodeRequestBody = {
  usability_test_id: number;
  completion_code: string;
};

export type CheckExternalStudyCompletionCodeVariables = {
  body: CheckExternalStudyCompletionCodeRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Check the completion code for an external study
 */
export const fetchCheckExternalStudyCompletionCode = (
  variables: CheckExternalStudyCompletionCodeVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    CheckExternalStudyCompletionCodeError,
    CheckExternalStudyCompletionCodeRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/external_studies/check_completion_code",
    method: "post",
    ...variables,
  });

/**
 * Check the completion code for an external study
 */
export const useCheckExternalStudyCompletionCode = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      CheckExternalStudyCompletionCodeError,
      CheckExternalStudyCompletionCodeVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    CheckExternalStudyCompletionCodeError,
    CheckExternalStudyCompletionCodeVariables
  >(
    (variables: CheckExternalStudyCompletionCodeVariables) =>
      fetchCheckExternalStudyCompletionCode({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type AssignUsabilityTestPathParams = {
  usabilityTestId: string;
};

export type AssignUsabilityTestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type AssignUsabilityTestResponse = {
  /**
   * @format url
   */
  url: string;
};

export type AssignUsabilityTestRequestBody = {
  /**
   * @format email
   */
  email: string;
};

export type AssignUsabilityTestVariables = {
  body: AssignUsabilityTestRequestBody;
  pathParams: AssignUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Assign a usability test to a panelist
 */
export const fetchAssignUsabilityTest = (
  variables: AssignUsabilityTestVariables,
) =>
  usabilityhubFetch<
    AssignUsabilityTestResponse,
    AssignUsabilityTestError,
    AssignUsabilityTestRequestBody,
    {},
    {},
    AssignUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/assignments",
    method: "post",
    ...variables,
  });

/**
 * Assign a usability test to a panelist
 */
export const useAssignUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      AssignUsabilityTestResponse,
      AssignUsabilityTestError,
      AssignUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    AssignUsabilityTestResponse,
    AssignUsabilityTestError,
    AssignUsabilityTestVariables
  >(
    (variables: AssignUsabilityTestVariables) =>
      fetchAssignUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PostApiSubprocessorListNotificationSubscriptionsError =
  Fetcher.ErrorWrapper<{
    status: 400;
    payload: Responses.R400;
  }>;

export type PostApiSubprocessorListNotificationSubscriptionsResponse = {
  message: string;
};

export type PostApiSubprocessorListNotificationSubscriptionsRequestBody = {
  email: string;
};

export type PostApiSubprocessorListNotificationSubscriptionsVariables = {
  body: PostApiSubprocessorListNotificationSubscriptionsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchPostApiSubprocessorListNotificationSubscriptions = (
  variables: PostApiSubprocessorListNotificationSubscriptionsVariables,
) =>
  usabilityhubFetch<
    PostApiSubprocessorListNotificationSubscriptionsResponse,
    PostApiSubprocessorListNotificationSubscriptionsError,
    PostApiSubprocessorListNotificationSubscriptionsRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/subprocessor_list_notification_subscriptions",
    method: "post",
    ...variables,
  });

/**
 * TODO
 */
export const usePostApiSubprocessorListNotificationSubscriptions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostApiSubprocessorListNotificationSubscriptionsResponse,
      PostApiSubprocessorListNotificationSubscriptionsError,
      PostApiSubprocessorListNotificationSubscriptionsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    PostApiSubprocessorListNotificationSubscriptionsResponse,
    PostApiSubprocessorListNotificationSubscriptionsError,
    PostApiSubprocessorListNotificationSubscriptionsVariables
  >(
    (variables: PostApiSubprocessorListNotificationSubscriptionsVariables) =>
      fetchPostApiSubprocessorListNotificationSubscriptions({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type DeleteApiSubprocessorListNotificationSubscriptionsPathParams = {
  /**
   * UUID of the subprocessor list notification subscription
   */
  id: string;
};

export type DeleteApiSubprocessorListNotificationSubscriptionsError =
  Fetcher.ErrorWrapper<{
    status: 400;
    payload: Responses.R400;
  }>;

export type DeleteApiSubprocessorListNotificationSubscriptionsResponse = {
  message: string;
};

export type DeleteApiSubprocessorListNotificationSubscriptionsVariables = {
  pathParams: DeleteApiSubprocessorListNotificationSubscriptionsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Unsubscribe a subprocessor list notification subscription from future notifications
 */
export const fetchDeleteApiSubprocessorListNotificationSubscriptions = (
  variables: DeleteApiSubprocessorListNotificationSubscriptionsVariables,
) =>
  usabilityhubFetch<
    DeleteApiSubprocessorListNotificationSubscriptionsResponse,
    DeleteApiSubprocessorListNotificationSubscriptionsError,
    undefined,
    {},
    {},
    DeleteApiSubprocessorListNotificationSubscriptionsPathParams
  >({
    url: "/api/subprocessor_list_notification_subscriptions/{id}",
    method: "delete",
    ...variables,
  });

/**
 * Unsubscribe a subprocessor list notification subscription from future notifications
 */
export const useDeleteApiSubprocessorListNotificationSubscriptions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DeleteApiSubprocessorListNotificationSubscriptionsResponse,
      DeleteApiSubprocessorListNotificationSubscriptionsError,
      DeleteApiSubprocessorListNotificationSubscriptionsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DeleteApiSubprocessorListNotificationSubscriptionsResponse,
    DeleteApiSubprocessorListNotificationSubscriptionsError,
    DeleteApiSubprocessorListNotificationSubscriptionsVariables
  >(
    (variables: DeleteApiSubprocessorListNotificationSubscriptionsVariables) =>
      fetchDeleteApiSubprocessorListNotificationSubscriptions({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type GetApiTestResultsPathParams = {
  /**
   * ID of the usability test to retrieve results for
   */
  usabilityTestUniqueId: string;
};

export type GetApiTestResultsError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Responses.R401;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type GetApiTestResultsResponse = {
  comments: Schemas.Comment[];
  customer_rating: number | null;
  status: string | null;
  status_tags: (
    | "Waiting for recruitment"
    | "Order active"
    | "Order complete"
    | "Link active"
    | "Link disabled"
  )[];
};

export type GetApiTestResultsVariables = {
  pathParams: GetApiTestResultsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * This is intended to become a BFF-style endpoint powering the test results page.  We're starting off with just comments but eventually it might include the test results themselves.
 *
 * If you need any new data on the test results page, consider if it would fit in here before adding anything new to ReduxStateBuilder!
 */
export const fetchGetApiTestResults = (variables: GetApiTestResultsVariables) =>
  usabilityhubFetch<
    GetApiTestResultsResponse,
    GetApiTestResultsError,
    undefined,
    {},
    {},
    GetApiTestResultsPathParams
  >({
    url: "/api/test_results/{usabilityTestUniqueId}",
    method: "get",
    ...variables,
  });

/**
 * This is intended to become a BFF-style endpoint powering the test results page.  We're starting off with just comments but eventually it might include the test results themselves.
 *
 * If you need any new data on the test results page, consider if it would fit in here before adding anything new to ReduxStateBuilder!
 */
export const useGetApiTestResults = <TData = GetApiTestResultsResponse,>(
  variables: GetApiTestResultsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetApiTestResultsResponse,
      GetApiTestResultsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetApiTestResultsResponse,
    GetApiTestResultsError,
    TData
  >(
    queryKeyFn({
      path: "/api/test_results/{usability_test_unique_id}",
      operationId: "getApiTestResults",
      variables,
    }),
    () => fetchGetApiTestResults({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateProjectError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type CreateProjectRequestBody = {
  name?: string;
};

export type CreateProjectVariables = {
  body?: CreateProjectRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a new (empty) project.
 */
export const fetchCreateProject = (variables: CreateProjectVariables) =>
  usabilityhubFetch<
    Schemas.NewProject,
    CreateProjectError,
    CreateProjectRequestBody,
    {},
    {},
    {}
  >({ url: "/api/projects", method: "post", ...variables });

/**
 * Creates a new (empty) project.
 */
export const useCreateProject = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NewProject,
      CreateProjectError,
      CreateProjectVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.NewProject,
    CreateProjectError,
    CreateProjectVariables
  >(
    (variables: CreateProjectVariables) =>
      fetchCreateProject({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListProjectsQueryParams = {
  /**
   * Pass the value of the previous call's next_page to retrieve further pages
   */
  cursor?: string;
  /**
   * Return only projects where the name includes this string (case insensitive)
   */
  search?: string;
  /**
   * Sort results by this field
   */
  sort_by: "name" | "created_at" | "updated_at";
  /**
   * Sort order ascending or descending
   */
  sort_direction: "asc" | "desc";
  /**
   * Include archived projects
   */
  show_archived?: boolean;
  /**
   * How many projects to return in one request
   *
   * @minimum 1
   * @maximum 1000
   */
  page_size?: number;
};

export type ListProjectsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ListProjectsResponse = {
  projects: Schemas.NewProject[];
  next_page: string | null;
  /**
   * @minimum 0
   */
  total_records: number;
};

export type ListProjectsVariables = {
  queryParams: ListProjectsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns a paginated list of all projects for the current user's account.
 */
export const fetchListProjects = (variables: ListProjectsVariables) =>
  usabilityhubFetch<
    ListProjectsResponse,
    ListProjectsError,
    undefined,
    {},
    ListProjectsQueryParams,
    {}
  >({ url: "/api/projects", method: "get", ...variables });

/**
 * Returns a paginated list of all projects for the current user's account.
 */
export const useListProjects = <TData = ListProjectsResponse,>(
  variables: ListProjectsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ListProjectsResponse, ListProjectsError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<ListProjectsResponse, ListProjectsError, TData>(
    queryKeyFn({
      path: "/api/projects",
      operationId: "listProjects",
      variables,
    }),
    () => fetchListProjects({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateProjectPathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type UpdateProjectError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateProjectRequestBody = {
  name?: string;
};

export type UpdateProjectVariables = {
  body?: UpdateProjectRequestBody;
  pathParams: UpdateProjectPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates project metadata.
 */
export const fetchUpdateProject = (variables: UpdateProjectVariables) =>
  usabilityhubFetch<
    Schemas.NewProject,
    UpdateProjectError,
    UpdateProjectRequestBody,
    {},
    {},
    UpdateProjectPathParams
  >({ url: "/api/projects/{id}", method: "patch", ...variables });

/**
 * Updates project metadata.
 */
export const useUpdateProject = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NewProject,
      UpdateProjectError,
      UpdateProjectVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.NewProject,
    UpdateProjectError,
    UpdateProjectVariables
  >(
    (variables: UpdateProjectVariables) =>
      fetchUpdateProject({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DeleteProjectPathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type DeleteProjectError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type DeleteProjectResponse = {
  refund_point_count: number;
};

export type DeleteProjectVariables = {
  pathParams: DeleteProjectPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Deletes a project based on the given ID.
 */
export const fetchDeleteProject = (variables: DeleteProjectVariables) =>
  usabilityhubFetch<
    DeleteProjectResponse,
    DeleteProjectError,
    undefined,
    {},
    {},
    DeleteProjectPathParams
  >({ url: "/api/projects/{id}", method: "delete", ...variables });

/**
 * Deletes a project based on the given ID.
 */
export const useDeleteProject = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DeleteProjectResponse,
      DeleteProjectError,
      DeleteProjectVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DeleteProjectResponse,
    DeleteProjectError,
    DeleteProjectVariables
  >(
    (variables: DeleteProjectVariables) =>
      fetchDeleteProject({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetProjectPathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type GetProjectError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetProjectResponse = {
  project: Schemas.NewProject;
};

export type GetProjectVariables = {
  pathParams: GetProjectPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchGetProject = (variables: GetProjectVariables) =>
  usabilityhubFetch<
    GetProjectResponse,
    GetProjectError,
    undefined,
    {},
    {},
    GetProjectPathParams
  >({ url: "/api/projects/{id}", method: "get", ...variables });

/**
 * TODO
 */
export const useGetProject = <TData = GetProjectResponse,>(
  variables: GetProjectVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetProjectResponse, GetProjectError, TData>,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<GetProjectResponse, GetProjectError, TData>(
    queryKeyFn({
      path: "/api/projects/{id}",
      operationId: "getProject",
      variables,
    }),
    () => fetchGetProject({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type AddTestsToProjectPathParams = {
  /**
   * Project ID that will receive the tests
   */
  id: string;
};

export type AddTestsToProjectError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type AddTestsToProjectRequestBody = {
  /**
   * @minItems 1
   */
  test_ids: number[];
};

export type AddTestsToProjectVariables = {
  body: AddTestsToProjectRequestBody;
  pathParams: AddTestsToProjectPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchAddTestsToProject = (variables: AddTestsToProjectVariables) =>
  usabilityhubFetch<
    Responses.R204,
    AddTestsToProjectError,
    AddTestsToProjectRequestBody,
    {},
    {},
    AddTestsToProjectPathParams
  >({ url: "/api/projects/{id}/add_tests", method: "post", ...variables });

/**
 * TODO
 */
export const useAddTestsToProject = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      AddTestsToProjectError,
      AddTestsToProjectVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    AddTestsToProjectError,
    AddTestsToProjectVariables
  >(
    (variables: AddTestsToProjectVariables) =>
      fetchAddTestsToProject({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ArchiveProjectPathParams = {
  /**
   * ID of the project to archive
   */
  id: string;
};

export type ArchiveProjectError = Fetcher.ErrorWrapper<undefined>;

export type ArchiveProjectResponse = {
  refund_point_count: number;
};

export type ArchiveProjectVariables = {
  pathParams: ArchiveProjectPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchArchiveProject = (variables: ArchiveProjectVariables) =>
  usabilityhubFetch<
    ArchiveProjectResponse,
    ArchiveProjectError,
    undefined,
    {},
    {},
    ArchiveProjectPathParams
  >({ url: "/api/projects/{id}/archive", method: "post", ...variables });

/**
 * TODO
 */
export const useArchiveProject = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ArchiveProjectResponse,
      ArchiveProjectError,
      ArchiveProjectVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ArchiveProjectResponse,
    ArchiveProjectError,
    ArchiveProjectVariables
  >(
    (variables: ArchiveProjectVariables) =>
      fetchArchiveProject({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UnarchiveProjectPathParams = {
  /**
   * ID of the project to unarchive
   */
  id: string;
};

export type UnarchiveProjectError = Fetcher.ErrorWrapper<undefined>;

export type UnarchiveProjectVariables = {
  pathParams: UnarchiveProjectPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchUnarchiveProject = (variables: UnarchiveProjectVariables) =>
  usabilityhubFetch<
    Schemas.NewProject,
    UnarchiveProjectError,
    undefined,
    {},
    {},
    UnarchiveProjectPathParams
  >({ url: "/api/projects/{id}/unarchive", method: "post", ...variables });

/**
 * TODO
 */
export const useUnarchiveProject = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NewProject,
      UnarchiveProjectError,
      UnarchiveProjectVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.NewProject,
    UnarchiveProjectError,
    UnarchiveProjectVariables
  >(
    (variables: UnarchiveProjectVariables) =>
      fetchUnarchiveProject({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetProjectHasActiveThirdPartyOrdersPathParams = {
  /**
   * ID of the project to unarchive
   */
  id: string;
};

export type GetProjectHasActiveThirdPartyOrdersError =
  Fetcher.ErrorWrapper<undefined>;

export type GetProjectHasActiveThirdPartyOrdersResponse = {
  has_active_third_party_orders: boolean;
};

export type GetProjectHasActiveThirdPartyOrdersVariables = {
  pathParams: GetProjectHasActiveThirdPartyOrdersPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Check if any test has active third party orders in the project
 */
export const fetchGetProjectHasActiveThirdPartyOrders = (
  variables: GetProjectHasActiveThirdPartyOrdersVariables,
) =>
  usabilityhubFetch<
    GetProjectHasActiveThirdPartyOrdersResponse,
    GetProjectHasActiveThirdPartyOrdersError,
    undefined,
    {},
    {},
    GetProjectHasActiveThirdPartyOrdersPathParams
  >({
    url: "/api/projects/{id}/has_active_third_party_orders",
    method: "get",
    ...variables,
  });

/**
 * Check if any test has active third party orders in the project
 */
export const useGetProjectHasActiveThirdPartyOrders = <
  TData = GetProjectHasActiveThirdPartyOrdersResponse,
>(
  variables: GetProjectHasActiveThirdPartyOrdersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetProjectHasActiveThirdPartyOrdersResponse,
      GetProjectHasActiveThirdPartyOrdersError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetProjectHasActiveThirdPartyOrdersResponse,
    GetProjectHasActiveThirdPartyOrdersError,
    TData
  >(
    queryKeyFn({
      path: "/api/projects/{id}/has_active_third_party_orders",
      operationId: "getProjectHasActiveThirdPartyOrders",
      variables,
    }),
    () =>
      fetchGetProjectHasActiveThirdPartyOrders({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListUsabilityTestsQueryParams = {
  /**
   * Pass the value of the previous call's next_page to retrieve further pages
   */
  cursor?: string;
  /**
   * Sort results by this field
   */
  sort_by: "updated_at" | "name" | "created_at";
  /**
   * Sort order ascending or descending
   */
  sort_direction: "asc" | "desc";
  /**
   * Return only tests where the name includes this string (case insensitive)
   */
  search?: string;
};

export type ListUsabilityTestsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type ListUsabilityTestsResponse = {
  usability_tests: Schemas.DashboardUsabilityTest[];
  next_page: string | null;
  total_records: number;
};

export type ListUsabilityTestsRequestBody = {
  filters?: Schemas.UsabilityTestFilter[];
};

export type ListUsabilityTestsVariables = {
  body?: ListUsabilityTestsRequestBody;
  queryParams: ListUsabilityTestsQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns a paginated list of all visible usability tests (ie in the current account), filterable and sortable in various ways.
 */
export const fetchListUsabilityTests = (
  variables: ListUsabilityTestsVariables,
) =>
  usabilityhubFetch<
    ListUsabilityTestsResponse,
    ListUsabilityTestsError,
    ListUsabilityTestsRequestBody,
    {},
    ListUsabilityTestsQueryParams,
    {}
  >({ url: "/api/usability_tests", method: "post", ...variables });

/**
 * Returns a paginated list of all visible usability tests (ie in the current account), filterable and sortable in various ways.
 */
export const useListUsabilityTests = <TData = ListUsabilityTestsResponse,>(
  variables: ListUsabilityTestsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListUsabilityTestsResponse,
      ListUsabilityTestsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListUsabilityTestsResponse,
    ListUsabilityTestsError,
    TData
  >(
    queryKeyFn({
      path: "/api/usability_tests",
      operationId: "listUsabilityTests",
      variables,
    }),
    () => fetchListUsabilityTests({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PutModeratedStudyMembersPathParams = {
  /**
   * Moderated Study ID
   */
  moderatedStudyId: string;
};

export type PutModeratedStudyMembersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PutModeratedStudyMembersRequestBody = {
  host_id: number;
  cohost_ids: number[];
};

export type PutModeratedStudyMembersVariables = {
  body: PutModeratedStudyMembersRequestBody;
  pathParams: PutModeratedStudyMembersPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update the members for a moderated study
 */
export const fetchPutModeratedStudyMembers = (
  variables: PutModeratedStudyMembersVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    PutModeratedStudyMembersError,
    PutModeratedStudyMembersRequestBody,
    {},
    {},
    PutModeratedStudyMembersPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/members",
    method: "put",
    ...variables,
  });

/**
 * Update the members for a moderated study
 */
export const usePutModeratedStudyMembers = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      PutModeratedStudyMembersError,
      PutModeratedStudyMembersVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    PutModeratedStudyMembersError,
    PutModeratedStudyMembersVariables
  >(
    (variables: PutModeratedStudyMembersVariables) =>
      fetchPutModeratedStudyMembers({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type PutModeratedStudyMeetingUrlPathParams = {
  /**
   * Moderated Study ID
   */
  moderatedStudyId: string;
};

export type PutModeratedStudyMeetingUrlError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type PutModeratedStudyMeetingUrlRequestBody = {
  location_type: "none" | "zoom" | "teams" | "custom";
  meeting_url: string | null;
  auto_upload_recordings: boolean;
};

export type PutModeratedStudyMeetingUrlVariables = {
  body: PutModeratedStudyMeetingUrlRequestBody;
  pathParams: PutModeratedStudyMeetingUrlPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchPutModeratedStudyMeetingUrl = (
  variables: PutModeratedStudyMeetingUrlVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    PutModeratedStudyMeetingUrlError,
    PutModeratedStudyMeetingUrlRequestBody,
    {},
    {},
    PutModeratedStudyMeetingUrlPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/meeting_url",
    method: "put",
    ...variables,
  });

/**
 * TODO
 */
export const usePutModeratedStudyMeetingUrl = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      PutModeratedStudyMeetingUrlError,
      PutModeratedStudyMeetingUrlVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    PutModeratedStudyMeetingUrlError,
    PutModeratedStudyMeetingUrlVariables
  >(
    (variables: PutModeratedStudyMeetingUrlVariables) =>
      fetchPutModeratedStudyMeetingUrl({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ArchiveUsabilityTestPathParams = {
  usabilityTestId: string;
};

export type ArchiveUsabilityTestError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ArchiveUsabilityTestResponse = {
  refund_point_count: number;
};

export type ArchiveUsabilityTestVariables = {
  pathParams: ArchiveUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * In order to archive the test, this will cancel any active orders and disable the recruitment link. Returns refunded credits.
 */
export const fetchArchiveUsabilityTest = (
  variables: ArchiveUsabilityTestVariables,
) =>
  usabilityhubFetch<
    ArchiveUsabilityTestResponse,
    ArchiveUsabilityTestError,
    undefined,
    {},
    {},
    ArchiveUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/archive",
    method: "post",
    ...variables,
  });

/**
 * In order to archive the test, this will cancel any active orders and disable the recruitment link. Returns refunded credits.
 */
export const useArchiveUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ArchiveUsabilityTestResponse,
      ArchiveUsabilityTestError,
      ArchiveUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ArchiveUsabilityTestResponse,
    ArchiveUsabilityTestError,
    ArchiveUsabilityTestVariables
  >(
    (variables: ArchiveUsabilityTestVariables) =>
      fetchArchiveUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UnarchiveUsabilityTestPathParams = {
  usabilityTestId: string;
};

export type UnarchiveUsabilityTestError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type UnarchiveUsabilityTestVariables = {
  pathParams: UnarchiveUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Unarchive a test
 */
export const fetchUnarchiveUsabilityTest = (
  variables: UnarchiveUsabilityTestVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UnarchiveUsabilityTestError,
    undefined,
    {},
    {},
    UnarchiveUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/unarchive",
    method: "post",
    ...variables,
  });

/**
 * Unarchive a test
 */
export const useUnarchiveUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UnarchiveUsabilityTestError,
      UnarchiveUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UnarchiveUsabilityTestError,
    UnarchiveUsabilityTestVariables
  >(
    (variables: UnarchiveUsabilityTestVariables) =>
      fetchUnarchiveUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type RenameUsabilityTestPathParams = {
  /**
   * ID of the usability test to rename
   */
  usabilityTestId: string;
};

export type RenameUsabilityTestError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type RenameUsabilityTestRequestBody = {
  name: string;
};

export type RenameUsabilityTestVariables = {
  body: RenameUsabilityTestRequestBody;
  pathParams: RenameUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchRenameUsabilityTest = (
  variables: RenameUsabilityTestVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RenameUsabilityTestError,
    RenameUsabilityTestRequestBody,
    {},
    {},
    RenameUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/rename",
    method: "patch",
    ...variables,
  });

/**
 * TODO
 */
export const useRenameUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RenameUsabilityTestError,
      RenameUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RenameUsabilityTestError,
    RenameUsabilityTestVariables
  >(
    (variables: RenameUsabilityTestVariables) =>
      fetchRenameUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DuplicateUsabilityTestPathParams = {
  usabilityTestId: string;
};

export type DuplicateUsabilityTestError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type DuplicateUsabilityTestResponse = {
  unique_id: string;
  tests_by_creator_count: number;
  test_template_name: string | null;
  has_test_logic: boolean;
};

export type DuplicateUsabilityTestRequestBody = {
  name: string;
  /**
   * @format uuid
   */
  project_id: string;
  test_set_id?: number;
};

export type DuplicateUsabilityTestVariables = {
  body: DuplicateUsabilityTestRequestBody;
  pathParams: DuplicateUsabilityTestPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Duplicate a usability test
 */
export const fetchDuplicateUsabilityTest = (
  variables: DuplicateUsabilityTestVariables,
) =>
  usabilityhubFetch<
    DuplicateUsabilityTestResponse,
    DuplicateUsabilityTestError,
    DuplicateUsabilityTestRequestBody,
    {},
    {},
    DuplicateUsabilityTestPathParams
  >({
    url: "/api/usability_tests/{usabilityTestId}/duplicate",
    method: "post",
    ...variables,
  });

/**
 * Duplicate a usability test
 */
export const useDuplicateUsabilityTest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DuplicateUsabilityTestResponse,
      DuplicateUsabilityTestError,
      DuplicateUsabilityTestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DuplicateUsabilityTestResponse,
    DuplicateUsabilityTestError,
    DuplicateUsabilityTestVariables
  >(
    (variables: DuplicateUsabilityTestVariables) =>
      fetchDuplicateUsabilityTest({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type FindOrCreateModeratedStudyApplicationError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type FindOrCreateModeratedStudyApplicationResponse = {
  moderated_study_application_id: string;
};

export type FindOrCreateModeratedStudyApplicationRequestBody = {
  token: string;
};

export type FindOrCreateModeratedStudyApplicationVariables = {
  body: FindOrCreateModeratedStudyApplicationRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchFindOrCreateModeratedStudyApplication = (
  variables: FindOrCreateModeratedStudyApplicationVariables,
) =>
  usabilityhubFetch<
    FindOrCreateModeratedStudyApplicationResponse,
    FindOrCreateModeratedStudyApplicationError,
    FindOrCreateModeratedStudyApplicationRequestBody,
    {},
    {},
    {}
  >({ url: "/api/moderated_study_applications", method: "post", ...variables });

/**
 * TODO
 */
export const useFindOrCreateModeratedStudyApplication = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      FindOrCreateModeratedStudyApplicationResponse,
      FindOrCreateModeratedStudyApplicationError,
      FindOrCreateModeratedStudyApplicationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    FindOrCreateModeratedStudyApplicationResponse,
    FindOrCreateModeratedStudyApplicationError,
    FindOrCreateModeratedStudyApplicationVariables
  >(
    (variables: FindOrCreateModeratedStudyApplicationVariables) =>
      fetchFindOrCreateModeratedStudyApplication({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type DeleteScreenerQuestionPathParams = {
  screenerQuestionId: string;
};

export type DeleteScreenerQuestionError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type DeleteScreenerQuestionRequestBody = {
  moderated_study_id?: string;
};

export type DeleteScreenerQuestionVariables = {
  body?: DeleteScreenerQuestionRequestBody;
  pathParams: DeleteScreenerQuestionPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Delete a screener question
 */
export const fetchDeleteScreenerQuestion = (
  variables: DeleteScreenerQuestionVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteScreenerQuestionError,
    DeleteScreenerQuestionRequestBody,
    {},
    {},
    DeleteScreenerQuestionPathParams
  >({
    url: "/api/moderated_study_screener_questions/{screenerQuestionId}",
    method: "delete",
    ...variables,
  });

/**
 * Delete a screener question
 */
export const useDeleteScreenerQuestion = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteScreenerQuestionError,
      DeleteScreenerQuestionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteScreenerQuestionError,
    DeleteScreenerQuestionVariables
  >(
    (variables: DeleteScreenerQuestionVariables) =>
      fetchDeleteScreenerQuestion({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type FindOrCreateModeratedStudyScreenerQuestionPathParams = {
  moderatedStudyId: string;
};

export type FindOrCreateModeratedStudyScreenerQuestionError =
  Fetcher.ErrorWrapper<undefined>;

export type FindOrCreateModeratedStudyScreenerQuestionResponse = {
  /**
   * @format uuid
   */
  screener_question_id: string;
};

export type FindOrCreateModeratedStudyScreenerQuestionRequestBody = {
  /**
   * @format uuid
   */
  id: string;
  type: string;
  position: number;
  text: string;
  shuffle_options: boolean;
  screener_question_options?: Schemas.ScreenerQuestionOption[];
};

export type FindOrCreateModeratedStudyScreenerQuestionVariables = {
  body: FindOrCreateModeratedStudyScreenerQuestionRequestBody;
  pathParams: FindOrCreateModeratedStudyScreenerQuestionPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchFindOrCreateModeratedStudyScreenerQuestion = (
  variables: FindOrCreateModeratedStudyScreenerQuestionVariables,
) =>
  usabilityhubFetch<
    FindOrCreateModeratedStudyScreenerQuestionResponse,
    FindOrCreateModeratedStudyScreenerQuestionError,
    FindOrCreateModeratedStudyScreenerQuestionRequestBody,
    {},
    {},
    FindOrCreateModeratedStudyScreenerQuestionPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/find_or_create_screener_question",
    method: "post",
    ...variables,
  });

/**
 * TODO
 */
export const useFindOrCreateModeratedStudyScreenerQuestion = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      FindOrCreateModeratedStudyScreenerQuestionResponse,
      FindOrCreateModeratedStudyScreenerQuestionError,
      FindOrCreateModeratedStudyScreenerQuestionVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    FindOrCreateModeratedStudyScreenerQuestionResponse,
    FindOrCreateModeratedStudyScreenerQuestionError,
    FindOrCreateModeratedStudyScreenerQuestionVariables
  >(
    (variables: FindOrCreateModeratedStudyScreenerQuestionVariables) =>
      fetchFindOrCreateModeratedStudyScreenerQuestion({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type UpdateConflictCalendarsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type UpdateConflictCalendarsRequestBody = {
  user_id: number;
  conflict_calendars: Schemas.ModeratedStudyCalendar[];
};

export type UpdateConflictCalendarsVariables = {
  body: UpdateConflictCalendarsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update the conflict calendars for a user
 */
export const fetchUpdateConflictCalendars = (
  variables: UpdateConflictCalendarsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateConflictCalendarsError,
    UpdateConflictCalendarsRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/moderated_studies/conflict_calendars",
    method: "post",
    ...variables,
  });

/**
 * Update the conflict calendars for a user
 */
export const useUpdateConflictCalendars = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateConflictCalendarsError,
      UpdateConflictCalendarsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateConflictCalendarsError,
    UpdateConflictCalendarsVariables
  >(
    (variables: UpdateConflictCalendarsVariables) =>
      fetchUpdateConflictCalendars({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type SetBookingCalendarPathParams = {
  moderatedStudyId: string;
};

export type SetBookingCalendarError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type SetBookingCalendarRequestBody = {
  user_id: number;
  id: string | null;
  provider?: string | null;
  summary: string | null;
};

export type SetBookingCalendarVariables = {
  body: SetBookingCalendarRequestBody;
  pathParams: SetBookingCalendarPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Set a moderated study's booking calendar for a user
 */
export const fetchSetBookingCalendar = (
  variables: SetBookingCalendarVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    SetBookingCalendarError,
    SetBookingCalendarRequestBody,
    {},
    {},
    SetBookingCalendarPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/booking_calendars",
    method: "post",
    ...variables,
  });

/**
 * Set a moderated study's booking calendar for a user
 */
export const useSetBookingCalendar = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      SetBookingCalendarError,
      SetBookingCalendarVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    SetBookingCalendarError,
    SetBookingCalendarVariables
  >(
    (variables: SetBookingCalendarVariables) =>
      fetchSetBookingCalendar({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type DuplicateModeratedStudyPathParams = {
  moderatedStudyId: string;
};

export type DuplicateModeratedStudyError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type DuplicateModeratedStudyResponse = {
  moderated_study_id: string;
};

export type DuplicateModeratedStudyRequestBody = {
  internal_name: string;
};

export type DuplicateModeratedStudyVariables = {
  body: DuplicateModeratedStudyRequestBody;
  pathParams: DuplicateModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Creates a copy of a moderated study and returns the UUID.
 */
export const fetchDuplicateModeratedStudy = (
  variables: DuplicateModeratedStudyVariables,
) =>
  usabilityhubFetch<
    DuplicateModeratedStudyResponse,
    DuplicateModeratedStudyError,
    DuplicateModeratedStudyRequestBody,
    {},
    {},
    DuplicateModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/duplicate",
    method: "post",
    ...variables,
  });

/**
 * Creates a copy of a moderated study and returns the UUID.
 */
export const useDuplicateModeratedStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DuplicateModeratedStudyResponse,
      DuplicateModeratedStudyError,
      DuplicateModeratedStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    DuplicateModeratedStudyResponse,
    DuplicateModeratedStudyError,
    DuplicateModeratedStudyVariables
  >(
    (variables: DuplicateModeratedStudyVariables) =>
      fetchDuplicateModeratedStudy({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CanArchiveModeratedStudyPathParams = {
  moderatedStudyId: string;
};

export type CanArchiveModeratedStudyError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type CanArchiveModeratedStudyResponse = {
  can_archive: boolean;
  reasons: string[];
};

export type CanArchiveModeratedStudyVariables = {
  pathParams: CanArchiveModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Tells you whether a study can be archived, and if not, what is preventing it
 */
export const fetchCanArchiveModeratedStudy = (
  variables: CanArchiveModeratedStudyVariables,
) =>
  usabilityhubFetch<
    CanArchiveModeratedStudyResponse,
    CanArchiveModeratedStudyError,
    undefined,
    {},
    {},
    CanArchiveModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/archive",
    method: "get",
    ...variables,
  });

/**
 * Tells you whether a study can be archived, and if not, what is preventing it
 */
export const useCanArchiveModeratedStudy = <
  TData = CanArchiveModeratedStudyResponse,
>(
  variables: CanArchiveModeratedStudyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      CanArchiveModeratedStudyResponse,
      CanArchiveModeratedStudyError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    CanArchiveModeratedStudyResponse,
    CanArchiveModeratedStudyError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/archive",
      operationId: "canArchiveModeratedStudy",
      variables,
    }),
    () => fetchCanArchiveModeratedStudy({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ArchiveModeratedStudyPathParams = {
  moderatedStudyId: string;
};

export type ArchiveModeratedStudyError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ArchiveModeratedStudyVariables = {
  pathParams: ArchiveModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Archives a moderated study, essentially soft-deleting it
 */
export const fetchArchiveModeratedStudy = (
  variables: ArchiveModeratedStudyVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ArchiveModeratedStudyError,
    undefined,
    {},
    {},
    ArchiveModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/archive",
    method: "post",
    ...variables,
  });

/**
 * Archives a moderated study, essentially soft-deleting it
 */
export const useArchiveModeratedStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ArchiveModeratedStudyError,
      ArchiveModeratedStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ArchiveModeratedStudyError,
    ArchiveModeratedStudyVariables
  >(
    (variables: ArchiveModeratedStudyVariables) =>
      fetchArchiveModeratedStudy({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UnarchiveModeratedStudyPathParams = {
  moderatedStudyId: string;
};

export type UnarchiveModeratedStudyError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type UnarchiveModeratedStudyVariables = {
  pathParams: UnarchiveModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Restores a moderated study that was previously archived
 */
export const fetchUnarchiveModeratedStudy = (
  variables: UnarchiveModeratedStudyVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UnarchiveModeratedStudyError,
    undefined,
    {},
    {},
    UnarchiveModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/unarchive",
    method: "post",
    ...variables,
  });

/**
 * Restores a moderated study that was previously archived
 */
export const useUnarchiveModeratedStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UnarchiveModeratedStudyError,
      UnarchiveModeratedStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UnarchiveModeratedStudyError,
    UnarchiveModeratedStudyVariables
  >(
    (variables: UnarchiveModeratedStudyVariables) =>
      fetchUnarchiveModeratedStudy({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksPathParams = {
  moderatedStudyId: string;
};

export type GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksError =
  Fetcher.ErrorWrapper<undefined>;

export type GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse = {
  moderated_study_recruitment_links: Schemas.ModeratedStudyRecruitmentLink[];
  moderated_study_orders: Schemas.ModeratedStudyOrder[];
};

export type GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksVariables = {
  pathParams: GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns all recruitment links and orders for the current account
 */
export const fetchGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks = (
  variables: GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksVariables,
) =>
  usabilityhubFetch<
    GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse,
    GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksError,
    undefined,
    {},
    {},
    GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/recruitment_links",
    method: "get",
    ...variables,
  });

/**
 * Returns all recruitment links and orders for the current account
 */
export const useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks = <
  TData = GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse,
>(
  variables: GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse,
      GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksResponse,
    GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/recruitment_links",
      operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks",
      variables,
    }),
    () =>
      fetchGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateModeratedStudiesRecruitmentLinkPathParams = {
  moderatedStudyId: string;
};

export type CreateModeratedStudiesRecruitmentLinkError =
  Fetcher.ErrorWrapper<undefined>;

export type CreateModeratedStudiesRecruitmentLinkResponse = {
  /**
   * @format uuid
   */
  moderated_study_recruitment_link_id: string;
};

export type CreateModeratedStudiesRecruitmentLinkVariables = {
  pathParams: CreateModeratedStudiesRecruitmentLinkPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchCreateModeratedStudiesRecruitmentLink = (
  variables: CreateModeratedStudiesRecruitmentLinkVariables,
) =>
  usabilityhubFetch<
    CreateModeratedStudiesRecruitmentLinkResponse,
    CreateModeratedStudiesRecruitmentLinkError,
    undefined,
    {},
    {},
    CreateModeratedStudiesRecruitmentLinkPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/recruitment_links",
    method: "post",
    ...variables,
  });

/**
 * TODO
 */
export const useCreateModeratedStudiesRecruitmentLink = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateModeratedStudiesRecruitmentLinkResponse,
      CreateModeratedStudiesRecruitmentLinkError,
      CreateModeratedStudiesRecruitmentLinkVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateModeratedStudiesRecruitmentLinkResponse,
    CreateModeratedStudiesRecruitmentLinkError,
    CreateModeratedStudiesRecruitmentLinkVariables
  >(
    (variables: CreateModeratedStudiesRecruitmentLinkVariables) =>
      fetchCreateModeratedStudiesRecruitmentLink({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type StopModeratedStudiesRecruitmentLinksPathParams = {
  moderatedStudyId: string;
};

export type StopModeratedStudiesRecruitmentLinksError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: Responses.R403;
}>;

export type StopModeratedStudiesRecruitmentLinksVariables = {
  pathParams: StopModeratedStudiesRecruitmentLinksPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Disable all recruitment links for a moderated study
 */
export const fetchStopModeratedStudiesRecruitmentLinks = (
  variables: StopModeratedStudiesRecruitmentLinksVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    StopModeratedStudiesRecruitmentLinksError,
    undefined,
    {},
    {},
    StopModeratedStudiesRecruitmentLinksPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/recruitment_links/stop_all",
    method: "post",
    ...variables,
  });

/**
 * Disable all recruitment links for a moderated study
 */
export const useStopModeratedStudiesRecruitmentLinks = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      StopModeratedStudiesRecruitmentLinksError,
      StopModeratedStudiesRecruitmentLinksVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    StopModeratedStudiesRecruitmentLinksError,
    StopModeratedStudiesRecruitmentLinksVariables
  >(
    (variables: StopModeratedStudiesRecruitmentLinksVariables) =>
      fetchStopModeratedStudiesRecruitmentLinks({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type UpdateModeratedStudiesRecruitmentLinkPathParams = {
  moderatedStudyId: string;
  moderatedStudyRecruitmentLinkId: string;
};

export type UpdateModeratedStudiesRecruitmentLinkError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateModeratedStudiesRecruitmentLinkRequestBody = {
  name?: string;
  selection_method?: "handpick" | "automatic";
  number_of_bookings?: number;
  incentive_type?: "none" | "custom";
  incentive_text?: string | null;
  active?: boolean;
};

export type UpdateModeratedStudiesRecruitmentLinkVariables = {
  body?: UpdateModeratedStudiesRecruitmentLinkRequestBody;
  pathParams: UpdateModeratedStudiesRecruitmentLinkPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates details about an individual recruitment link
 */
export const fetchUpdateModeratedStudiesRecruitmentLink = (
  variables: UpdateModeratedStudiesRecruitmentLinkVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateModeratedStudiesRecruitmentLinkError,
    UpdateModeratedStudiesRecruitmentLinkRequestBody,
    {},
    {},
    UpdateModeratedStudiesRecruitmentLinkPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/recruitment_links/{moderatedStudyRecruitmentLinkId}",
    method: "patch",
    ...variables,
  });

/**
 * Updates details about an individual recruitment link
 */
export const useUpdateModeratedStudiesRecruitmentLink = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateModeratedStudiesRecruitmentLinkError,
      UpdateModeratedStudiesRecruitmentLinkVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateModeratedStudiesRecruitmentLinkError,
    UpdateModeratedStudiesRecruitmentLinkVariables
  >(
    (variables: UpdateModeratedStudiesRecruitmentLinkVariables) =>
      fetchUpdateModeratedStudiesRecruitmentLink({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type GetModeratedStudyRecruitmentLinkPathParams = {
  moderatedStudyId: string;
  moderatedStudyRecruitmentLinkId: string;
};

export type GetModeratedStudyRecruitmentLinkError =
  Fetcher.ErrorWrapper<undefined>;

export type GetModeratedStudyRecruitmentLinkVariables = {
  pathParams: GetModeratedStudyRecruitmentLinkPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchGetModeratedStudyRecruitmentLink = (
  variables: GetModeratedStudyRecruitmentLinkVariables,
) =>
  usabilityhubFetch<
    Schemas.ModeratedStudyRecruitmentLink,
    GetModeratedStudyRecruitmentLinkError,
    undefined,
    {},
    {},
    GetModeratedStudyRecruitmentLinkPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/recruitment_links/{moderatedStudyRecruitmentLinkId}",
    method: "get",
    ...variables,
  });

/**
 * TODO
 */
export const useGetModeratedStudyRecruitmentLink = <
  TData = Schemas.ModeratedStudyRecruitmentLink,
>(
  variables: GetModeratedStudyRecruitmentLinkVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ModeratedStudyRecruitmentLink,
      GetModeratedStudyRecruitmentLinkError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.ModeratedStudyRecruitmentLink,
    GetModeratedStudyRecruitmentLinkError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/recruitment_links/{moderated_study_recruitment_link_id}",
      operationId: "getModeratedStudyRecruitmentLink",
      variables,
    }),
    () =>
      fetchGetModeratedStudyRecruitmentLink({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetModeratedStudyApplicationsPathParams = {
  moderatedStudyId: string;
};

export type GetModeratedStudyApplicationsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type GetModeratedStudyApplicationsResponse = {
  applications: {
    /**
     * @format uuid
     */
    id: string;
    /**
     * @format uuid
     */
    moderated_study_applicant_id: string | null;
    status:
      | "applied"
      | "invited"
      | "invitation_revoked"
      | "booked"
      | "canceled"
      | "complete"
      | "auto_complete"
      | "no_show"
      | "reschedule_requested"
      | "reported"
      | "declined_by_applicant"
      | "declined_by_researcher"
      | "declined_by_automatic";
    has_been_invited: boolean;
    email: string | null;
    full_name: string | null;
    preferred_name: string;
    /**
     * @format date-time
     */
    agreed_to_terms_at: string;
    /**
     * @format date-time
     */
    created_at: string;
    /**
     * @format date-time
     */
    deleted_at: string | null;
    source: {
      /**
       * @format uuid
       */
      id: string;
      name: string;
      type: "recruitment_link" | "order";
    };
    recruitment_method: string | null;
    is_panelist: boolean;
    location: {
      type: "country" | "state" | "city";
      id: string;
    } | null;
    age: number | null;
    demographic_attribute_option_ids: number[];
    response: {
      answers: (
        | {
            question_id: string;
            answer: string;
            type: "short_text" | "long_text";
          }
        | {
            question_id: string;
            selected_options: {
              screener_question_option_id: string;
              rule: "qualify" | "disqualify" | "not_relevant";
            }[];
            type: "single_select" | "multi_select";
          }
      )[];
      outcome: "qualified" | "disqualified";
    } | null;
  }[];
  next_page: string | null;
  total_records: number;
  total_records_without_filters: number;
  screener_questions: {
    id: string;
    type: "long_text" | "short_text" | "single_select" | "multi_select";
    text: string;
    options?: {
      id: string;
      value: string;
      deleted_at?: string | null;
    }[];
    /**
     * @format date-time
     */
    deleted_at?: string | null;
  }[];
};

export type GetModeratedStudyApplicationsRequestBody = {
  filters?: Schemas.UsabilityTestFilter[];
};

export type GetModeratedStudyApplicationsVariables = {
  body?: GetModeratedStudyApplicationsRequestBody;
  pathParams: GetModeratedStudyApplicationsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Gets data for the applications table and the screener answers
 */
export const fetchGetModeratedStudyApplications = (
  variables: GetModeratedStudyApplicationsVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyApplicationsResponse,
    GetModeratedStudyApplicationsError,
    GetModeratedStudyApplicationsRequestBody,
    {},
    {},
    GetModeratedStudyApplicationsPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/applications",
    method: "post",
    ...variables,
  });

/**
 * Gets data for the applications table and the screener answers
 */
export const useGetModeratedStudyApplications = <
  TData = GetModeratedStudyApplicationsResponse,
>(
  variables: GetModeratedStudyApplicationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyApplicationsResponse,
      GetModeratedStudyApplicationsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyApplicationsResponse,
    GetModeratedStudyApplicationsError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/applications",
      operationId: "getModeratedStudyApplications",
      variables,
    }),
    () =>
      fetchGetModeratedStudyApplications({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type SetAvailabilityPathParams = {
  moderatedStudyId: string;
};

export type SetAvailabilityError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type SetAvailabilityVariables = {
  body: Schemas.Availability;
  pathParams: SetAvailabilityPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update the researcher's availability to be booked for events
 */
export const fetchSetAvailability = (variables: SetAvailabilityVariables) =>
  usabilityhubFetch<
    Responses.R204,
    SetAvailabilityError,
    Schemas.Availability,
    {},
    {},
    SetAvailabilityPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/availability",
    method: "put",
    ...variables,
  });

/**
 * Update the researcher's availability to be booked for events
 */
export const useSetAvailability = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      SetAvailabilityError,
      SetAvailabilityVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    SetAvailabilityError,
    SetAvailabilityVariables
  >(
    (variables: SetAvailabilityVariables) =>
      fetchSetAvailability({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CreateModeratedStudyApplicantError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CreateModeratedStudyApplicantResponse = {
  /**
   * @format uuid
   */
  moderated_study_applicant_id: string;
};

export type CreateModeratedStudyApplicantRequestBody = {
  /**
   * @format uuid
   */
  moderated_study_application_id: string;
  email: string;
  full_name: string;
  preferred_name: string;
  agreed_to_terms: boolean;
};

export type CreateModeratedStudyApplicantVariables = {
  body: CreateModeratedStudyApplicantRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchCreateModeratedStudyApplicant = (
  variables: CreateModeratedStudyApplicantVariables,
) =>
  usabilityhubFetch<
    CreateModeratedStudyApplicantResponse,
    CreateModeratedStudyApplicantError,
    CreateModeratedStudyApplicantRequestBody,
    {},
    {},
    {}
  >({ url: "/api/moderated_study_applicants", method: "post", ...variables });

/**
 * TODO
 */
export const useCreateModeratedStudyApplicant = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateModeratedStudyApplicantResponse,
      CreateModeratedStudyApplicantError,
      CreateModeratedStudyApplicantVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateModeratedStudyApplicantResponse,
    CreateModeratedStudyApplicantError,
    CreateModeratedStudyApplicantVariables
  >(
    (variables: CreateModeratedStudyApplicantVariables) =>
      fetchCreateModeratedStudyApplicant({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type StartScreenerResponseError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type StartScreenerResponseResponse = {
  /**
   * @format uuid
   */
  screener_response_id: string;
};

export type StartScreenerResponseRequestBody = {
  /**
   * @format uuid
   */
  moderated_study_application_id: string;
};

export type StartScreenerResponseVariables = {
  body: StartScreenerResponseRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchStartScreenerResponse = (
  variables: StartScreenerResponseVariables,
) =>
  usabilityhubFetch<
    StartScreenerResponseResponse,
    StartScreenerResponseError,
    StartScreenerResponseRequestBody,
    {},
    {},
    {}
  >({ url: "/api/screener_responses/start", method: "post", ...variables });

/**
 * TODO
 */
export const useStartScreenerResponse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      StartScreenerResponseResponse,
      StartScreenerResponseError,
      StartScreenerResponseVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    StartScreenerResponseResponse,
    StartScreenerResponseError,
    StartScreenerResponseVariables
  >(
    (variables: StartScreenerResponseVariables) =>
      fetchStartScreenerResponse({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type SubmitScreenerResponsePathParams = {
  screenerResponseId: string;
};

export type SubmitScreenerResponseError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type SubmitScreenerResponseVariables = {
  body: Schemas.SubmitScreenerRequestBody;
  pathParams: SubmitScreenerResponsePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchSubmitScreenerResponse = (
  variables: SubmitScreenerResponseVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    SubmitScreenerResponseError,
    Schemas.SubmitScreenerRequestBody,
    {},
    {},
    SubmitScreenerResponsePathParams
  >({
    url: "/api/screener_responses/{screenerResponseId}/submit",
    method: "patch",
    ...variables,
  });

/**
 * TODO
 */
export const useSubmitScreenerResponse = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      SubmitScreenerResponseError,
      SubmitScreenerResponseVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    SubmitScreenerResponseError,
    SubmitScreenerResponseVariables
  >(
    (variables: SubmitScreenerResponseVariables) =>
      fetchSubmitScreenerResponse({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetModeratedStudyApplicationPathParams = {
  moderatedStudyApplicationId: string;
};

export type GetModeratedStudyApplicationError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type GetModeratedStudyApplicationVariables = {
  pathParams: GetModeratedStudyApplicationPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchGetModeratedStudyApplication = (
  variables: GetModeratedStudyApplicationVariables,
) =>
  usabilityhubFetch<
    Responses.ModeratedStudyApplicationFlow,
    GetModeratedStudyApplicationError,
    undefined,
    {},
    {},
    GetModeratedStudyApplicationPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}",
    method: "get",
    ...variables,
  });

/**
 * TODO
 */
export const useGetModeratedStudyApplication = <
  TData = Responses.ModeratedStudyApplicationFlow,
>(
  variables: GetModeratedStudyApplicationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.ModeratedStudyApplicationFlow,
      GetModeratedStudyApplicationError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Responses.ModeratedStudyApplicationFlow,
    GetModeratedStudyApplicationError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_study_applications/{moderated_study_application_id}",
      operationId: "getModeratedStudyApplication",
      variables,
    }),
    () =>
      fetchGetModeratedStudyApplication({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type PreviewModeratedStudyApplicationPathParams = {
  moderatedStudyId: string;
};

export type PreviewModeratedStudyApplicationQueryParams = {
  recruitment_link_id?: string;
};

export type PreviewModeratedStudyApplicationError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type PreviewModeratedStudyApplicationVariables = {
  pathParams: PreviewModeratedStudyApplicationPathParams;
  queryParams?: PreviewModeratedStudyApplicationQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Return a preview version of the application flow serialization
 */
export const fetchPreviewModeratedStudyApplication = (
  variables: PreviewModeratedStudyApplicationVariables,
) =>
  usabilityhubFetch<
    Responses.ModeratedStudyApplicationFlow,
    PreviewModeratedStudyApplicationError,
    undefined,
    {},
    PreviewModeratedStudyApplicationQueryParams,
    PreviewModeratedStudyApplicationPathParams
  >({
    url: "/api/moderated_study_previews/{moderatedStudyId}",
    method: "get",
    ...variables,
  });

/**
 * Return a preview version of the application flow serialization
 */
export const usePreviewModeratedStudyApplication = <
  TData = Responses.ModeratedStudyApplicationFlow,
>(
  variables: PreviewModeratedStudyApplicationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Responses.ModeratedStudyApplicationFlow,
      PreviewModeratedStudyApplicationError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Responses.ModeratedStudyApplicationFlow,
    PreviewModeratedStudyApplicationError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_study_previews/{moderated_study_id}",
      operationId: "previewModeratedStudyApplication",
      variables,
    }),
    () =>
      fetchPreviewModeratedStudyApplication({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type CreateModeratedStudyBookingPathParams = {
  /**
   * @format uuid
   */
  moderatedStudyApplicationId: string;
};

export type CreateModeratedStudyBookingError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type CreateModeratedStudyBookingResponse = {
  /**
   * @format uuid
   */
  moderated_study_booking_id: string;
};

export type CreateModeratedStudyBookingRequestBody = {
  /**
   * @format date-time
   */
  starts_at: string;
  participant_timezone: string;
};

export type CreateModeratedStudyBookingVariables = {
  body: CreateModeratedStudyBookingRequestBody;
  pathParams: CreateModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Create a ModeratedStudyBooking record for an available interview slot based on the provided start time.
 */
export const fetchCreateModeratedStudyBooking = (
  variables: CreateModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    CreateModeratedStudyBookingResponse,
    CreateModeratedStudyBookingError,
    CreateModeratedStudyBookingRequestBody,
    {},
    {},
    CreateModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/create_booking",
    method: "post",
    ...variables,
  });

/**
 * Create a ModeratedStudyBooking record for an available interview slot based on the provided start time.
 */
export const useCreateModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CreateModeratedStudyBookingResponse,
      CreateModeratedStudyBookingError,
      CreateModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    CreateModeratedStudyBookingResponse,
    CreateModeratedStudyBookingError,
    CreateModeratedStudyBookingVariables
  >(
    (variables: CreateModeratedStudyBookingVariables) =>
      fetchCreateModeratedStudyBooking({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ParticipantCancelModeratedStudyBookingPathParams = {
  /**
   * @format uuid
   */
  moderatedStudyApplicationId: string;
};

export type ParticipantCancelModeratedStudyBookingError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type ParticipantCancelModeratedStudyBookingRequestBody = {
  /**
   * @format uuid
   */
  moderated_study_booking_id: string;
  reason: string;
};

export type ParticipantCancelModeratedStudyBookingVariables = {
  body: ParticipantCancelModeratedStudyBookingRequestBody;
  pathParams: ParticipantCancelModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Cancel a Moderated Study Booking as a participant
 */
export const fetchParticipantCancelModeratedStudyBooking = (
  variables: ParticipantCancelModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ParticipantCancelModeratedStudyBookingError,
    ParticipantCancelModeratedStudyBookingRequestBody,
    {},
    {},
    ParticipantCancelModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/cancel_booking",
    method: "post",
    ...variables,
  });

/**
 * Cancel a Moderated Study Booking as a participant
 */
export const useParticipantCancelModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ParticipantCancelModeratedStudyBookingError,
      ParticipantCancelModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ParticipantCancelModeratedStudyBookingError,
    ParticipantCancelModeratedStudyBookingVariables
  >(
    (variables: ParticipantCancelModeratedStudyBookingVariables) =>
      fetchParticipantCancelModeratedStudyBooking({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ParticipantRescheduleModeratedStudyBookingPathParams = {
  /**
   * @format uuid
   */
  moderatedStudyApplicationId: string;
};

export type ParticipantRescheduleModeratedStudyBookingError =
  Fetcher.ErrorWrapper<
    | {
        status: 400;
        payload: Responses.R400;
      }
    | {
        status: 403;
        payload: Responses.R403;
      }
    | {
        status: 404;
        payload: Responses.R404;
      }
  >;

export type ParticipantRescheduleModeratedStudyBookingResponse = {
  /**
   * @format uuid
   */
  moderated_study_booking_id: string;
};

export type ParticipantRescheduleModeratedStudyBookingRequestBody = {
  /**
   * @format uuid
   */
  moderated_study_booking_id: string;
  /**
   * @format date-time
   */
  starts_at: string;
  participant_timezone: string;
};

export type ParticipantRescheduleModeratedStudyBookingVariables = {
  body: ParticipantRescheduleModeratedStudyBookingRequestBody;
  pathParams: ParticipantRescheduleModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Reschedule a Moderated Study Booking as a participant
 */
export const fetchParticipantRescheduleModeratedStudyBooking = (
  variables: ParticipantRescheduleModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    ParticipantRescheduleModeratedStudyBookingResponse,
    ParticipantRescheduleModeratedStudyBookingError,
    ParticipantRescheduleModeratedStudyBookingRequestBody,
    {},
    {},
    ParticipantRescheduleModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/reschedule_booking",
    method: "post",
    ...variables,
  });

/**
 * Reschedule a Moderated Study Booking as a participant
 */
export const useParticipantRescheduleModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ParticipantRescheduleModeratedStudyBookingResponse,
      ParticipantRescheduleModeratedStudyBookingError,
      ParticipantRescheduleModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    ParticipantRescheduleModeratedStudyBookingResponse,
    ParticipantRescheduleModeratedStudyBookingError,
    ParticipantRescheduleModeratedStudyBookingVariables
  >(
    (variables: ParticipantRescheduleModeratedStudyBookingVariables) =>
      fetchParticipantRescheduleModeratedStudyBooking({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ApplicantDeclineModeratedStudyInvitationPathParams = {
  /**
   * @format uuid
   */
  moderatedStudyApplicationId: string;
};

export type ApplicantDeclineModeratedStudyInvitationError =
  Fetcher.ErrorWrapper<
    | {
        status: 400;
        payload: Responses.R400;
      }
    | {
        status: 404;
        payload: Responses.R404;
      }
  >;

export type ApplicantDeclineModeratedStudyInvitationVariables = {
  pathParams: ApplicantDeclineModeratedStudyInvitationPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Decline an invitation for a Moderated Study Booking as either an applicant or a researcher
 */
export const fetchApplicantDeclineModeratedStudyInvitation = (
  variables: ApplicantDeclineModeratedStudyInvitationVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ApplicantDeclineModeratedStudyInvitationError,
    undefined,
    {},
    {},
    ApplicantDeclineModeratedStudyInvitationPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/decline_invitation",
    method: "post",
    ...variables,
  });

/**
 * Decline an invitation for a Moderated Study Booking as either an applicant or a researcher
 */
export const useApplicantDeclineModeratedStudyInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ApplicantDeclineModeratedStudyInvitationError,
      ApplicantDeclineModeratedStudyInvitationVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ApplicantDeclineModeratedStudyInvitationError,
    ApplicantDeclineModeratedStudyInvitationVariables
  >(
    (variables: ApplicantDeclineModeratedStudyInvitationVariables) =>
      fetchApplicantDeclineModeratedStudyInvitation({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ParticipantDeclineModeratedStudyBookingRescheduleRequestPathParams =
  {
    /**
     * @format uuid
     */
    moderatedStudyApplicationId: string;
  };

export type ParticipantDeclineModeratedStudyBookingRescheduleRequestError =
  Fetcher.ErrorWrapper<{
    status: 404;
    payload: Responses.R404;
  }>;

export type ParticipantDeclineModeratedStudyBookingRescheduleRequestRequestBody =
  {
    /**
     * @format uuid
     */
    moderated_study_booking_id: string;
  };

export type ParticipantDeclineModeratedStudyBookingRescheduleRequestVariables =
  {
    body: ParticipantDeclineModeratedStudyBookingRescheduleRequestRequestBody;
    pathParams: ParticipantDeclineModeratedStudyBookingRescheduleRequestPathParams;
  } & UsabilityhubContext["fetcherOptions"];

/**
 * Decline a reschedule request for a Moderated Study Booking as a participant
 */
export const fetchParticipantDeclineModeratedStudyBookingRescheduleRequest = (
  variables: ParticipantDeclineModeratedStudyBookingRescheduleRequestVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ParticipantDeclineModeratedStudyBookingRescheduleRequestError,
    ParticipantDeclineModeratedStudyBookingRescheduleRequestRequestBody,
    {},
    {},
    ParticipantDeclineModeratedStudyBookingRescheduleRequestPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/decline_reschedule_request",
    method: "post",
    ...variables,
  });

/**
 * Decline a reschedule request for a Moderated Study Booking as a participant
 */
export const useParticipantDeclineModeratedStudyBookingRescheduleRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ParticipantDeclineModeratedStudyBookingRescheduleRequestError,
      ParticipantDeclineModeratedStudyBookingRescheduleRequestVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ParticipantDeclineModeratedStudyBookingRescheduleRequestError,
    ParticipantDeclineModeratedStudyBookingRescheduleRequestVariables
  >(
    (
      variables: ParticipantDeclineModeratedStudyBookingRescheduleRequestVariables,
    ) =>
      fetchParticipantDeclineModeratedStudyBookingRescheduleRequest({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ApplyForModeratedStudyPathParams = {
  /**
   * @format uuid
   */
  moderatedStudyApplicationId: string;
};

export type ApplyForModeratedStudyError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ApplyForModeratedStudyVariables = {
  pathParams: ApplyForModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchApplyForModeratedStudy = (
  variables: ApplyForModeratedStudyVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ApplyForModeratedStudyError,
    undefined,
    {},
    {},
    ApplyForModeratedStudyPathParams
  >({
    url: "/api/moderated_study_applications/{moderatedStudyApplicationId}/apply",
    method: "post",
    ...variables,
  });

/**
 * TODO
 */
export const useApplyForModeratedStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ApplyForModeratedStudyError,
      ApplyForModeratedStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ApplyForModeratedStudyError,
    ApplyForModeratedStudyVariables
  >(
    (variables: ApplyForModeratedStudyVariables) =>
      fetchApplyForModeratedStudy({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetModeratedStudyBookingsPathParams = {
  moderatedStudyId: string;
};

export type GetModeratedStudyBookingsError = Fetcher.ErrorWrapper<undefined>;

export type GetModeratedStudyBookingsResponse = {
  /**
   * @format uuid
   */
  id: string;
  /**
   * @format uuid
   */
  moderated_study_application_id: string;
  /**
   * @format date-time
   */
  starts_at: string;
  /**
   * @format date-time
   */
  ends_at: string;
  full_name: string | null;
  preferred_name: string | null;
  email: string | null;
  location: string;
  location_type: "none" | "zoom" | "teams" | "custom";
  auto_upload_recordings: boolean;
  incentive: string | null;
  host_ids: number[];
  incentive_paid: boolean;
  declined_reschedule_request: boolean | null;
  cancelation_info: {
    canceler_name: string;
    reason: string | null;
    /**
     * @format date-time
     */
    canceled_at: string;
    canceled_with_short_notice: boolean;
  } | null;
  report_info: {
    reporter_name: string;
    reason: string | null;
    /**
     * @format date-time
     */
    reported_at: string;
    state: string;
  } | null;
  state:
    | "booked"
    | "complete"
    | "auto_complete"
    | "no_show"
    | "reported"
    | "canceled_by_participant"
    | "canceled_by_researcher"
    | "rescheduled_by_participant"
    | "rescheduled_by_researcher"
    | "declined_by_applicant"
    | "declined_by_researcher"
    | "declined_by_automatic";
  is_panelist: boolean;
  deleted_participant: boolean;
  recordings: Schemas.ModeratedStudyBookingRecording[];
  clicked_join_link_within_reasonable_time: boolean;
}[];

export type GetModeratedStudyBookingsVariables = {
  pathParams: GetModeratedStudyBookingsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchGetModeratedStudyBookings = (
  variables: GetModeratedStudyBookingsVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyBookingsResponse,
    GetModeratedStudyBookingsError,
    undefined,
    {},
    {},
    GetModeratedStudyBookingsPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings",
    method: "get",
    ...variables,
  });

/**
 * TODO
 */
export const useGetModeratedStudyBookings = <
  TData = GetModeratedStudyBookingsResponse,
>(
  variables: GetModeratedStudyBookingsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyBookingsResponse,
      GetModeratedStudyBookingsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyBookingsResponse,
    GetModeratedStudyBookingsError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/bookings",
      operationId: "getModeratedStudyBookings",
      variables,
    }),
    () => fetchGetModeratedStudyBookings({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetModeratedStudyScreenerResponsesPathParams = {
  moderatedStudyId: string;
};

export type GetModeratedStudyScreenerResponsesError =
  Fetcher.ErrorWrapper<undefined>;

export type GetModeratedStudyScreenerResponsesResponse = {
  /**
   * @format uuid
   */
  id?: string;
}[];

export type GetModeratedStudyScreenerResponsesVariables = {
  pathParams: GetModeratedStudyScreenerResponsesPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * TODO
 */
export const fetchGetModeratedStudyScreenerResponses = (
  variables: GetModeratedStudyScreenerResponsesVariables,
) =>
  usabilityhubFetch<
    GetModeratedStudyScreenerResponsesResponse,
    GetModeratedStudyScreenerResponsesError,
    undefined,
    {},
    {},
    GetModeratedStudyScreenerResponsesPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/screener_responses",
    method: "get",
    ...variables,
  });

/**
 * TODO
 */
export const useGetModeratedStudyScreenerResponses = <
  TData = GetModeratedStudyScreenerResponsesResponse,
>(
  variables: GetModeratedStudyScreenerResponsesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetModeratedStudyScreenerResponsesResponse,
      GetModeratedStudyScreenerResponsesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetModeratedStudyScreenerResponsesResponse,
    GetModeratedStudyScreenerResponsesError,
    TData
  >(
    queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/screener_responses",
      operationId: "getModeratedStudyScreenerResponses",
      variables,
    }),
    () =>
      fetchGetModeratedStudyScreenerResponses({
        ...fetcherOptions,
        ...variables,
      }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type DeleteModeratedStudyApplicantsPathParams = {
  moderatedStudyId: string;
};

export type DeleteModeratedStudyApplicantsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type DeleteModeratedStudyApplicantsRequestBody = {
  moderated_study_applicant_ids: string[];
};

export type DeleteModeratedStudyApplicantsVariables = {
  body: DeleteModeratedStudyApplicantsRequestBody;
  pathParams: DeleteModeratedStudyApplicantsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Delete Moderated Study applicants
 */
export const fetchDeleteModeratedStudyApplicants = (
  variables: DeleteModeratedStudyApplicantsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    DeleteModeratedStudyApplicantsError,
    DeleteModeratedStudyApplicantsRequestBody,
    {},
    {},
    DeleteModeratedStudyApplicantsPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/delete_applicants",
    method: "post",
    ...variables,
  });

/**
 * Delete Moderated Study applicants
 */
export const useDeleteModeratedStudyApplicants = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      DeleteModeratedStudyApplicantsError,
      DeleteModeratedStudyApplicantsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    DeleteModeratedStudyApplicantsError,
    DeleteModeratedStudyApplicantsVariables
  >(
    (variables: DeleteModeratedStudyApplicantsVariables) =>
      fetchDeleteModeratedStudyApplicants({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type InviteApplicationsToModeratedStudyPathParams = {
  moderatedStudyId: string;
};

export type InviteApplicationsToModeratedStudyError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type InviteApplicationsToModeratedStudyRequestBody = {
  moderated_study_application_ids: string[];
};

export type InviteApplicationsToModeratedStudyVariables = {
  body: InviteApplicationsToModeratedStudyRequestBody;
  pathParams: InviteApplicationsToModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Invite Applicants to a Moderated Study
 */
export const fetchInviteApplicationsToModeratedStudy = (
  variables: InviteApplicationsToModeratedStudyVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    InviteApplicationsToModeratedStudyError,
    InviteApplicationsToModeratedStudyRequestBody,
    {},
    {},
    InviteApplicationsToModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/invite",
    method: "post",
    ...variables,
  });

/**
 * Invite Applicants to a Moderated Study
 */
export const useInviteApplicationsToModeratedStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      InviteApplicationsToModeratedStudyError,
      InviteApplicationsToModeratedStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    InviteApplicationsToModeratedStudyError,
    InviteApplicationsToModeratedStudyVariables
  >(
    (variables: InviteApplicationsToModeratedStudyVariables) =>
      fetchInviteApplicationsToModeratedStudy({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type ReinviteApplicationsToModeratedStudyPathParams = {
  moderatedStudyId: string;
};

export type ReinviteApplicationsToModeratedStudyError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Responses.R404;
}>;

export type ReinviteApplicationsToModeratedStudyRequestBody = {
  moderated_study_application_ids: string[];
};

export type ReinviteApplicationsToModeratedStudyVariables = {
  body: ReinviteApplicationsToModeratedStudyRequestBody;
  pathParams: ReinviteApplicationsToModeratedStudyPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Reinvite Applicants to a Moderated Study
 */
export const fetchReinviteApplicationsToModeratedStudy = (
  variables: ReinviteApplicationsToModeratedStudyVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    ReinviteApplicationsToModeratedStudyError,
    ReinviteApplicationsToModeratedStudyRequestBody,
    {},
    {},
    ReinviteApplicationsToModeratedStudyPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/reinvite",
    method: "post",
    ...variables,
  });

/**
 * Reinvite Applicants to a Moderated Study
 */
export const useReinviteApplicationsToModeratedStudy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      ReinviteApplicationsToModeratedStudyError,
      ReinviteApplicationsToModeratedStudyVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    ReinviteApplicationsToModeratedStudyError,
    ReinviteApplicationsToModeratedStudyVariables
  >(
    (variables: ReinviteApplicationsToModeratedStudyVariables) =>
      fetchReinviteApplicationsToModeratedStudy({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type RevokeModeratedStudyInvitationsPathParams = {
  moderatedStudyId: string;
};

export type RevokeModeratedStudyInvitationsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type RevokeModeratedStudyInvitationsRequestBody = {
  moderated_study_application_ids: string[];
};

export type RevokeModeratedStudyInvitationsVariables = {
  body: RevokeModeratedStudyInvitationsRequestBody;
  pathParams: RevokeModeratedStudyInvitationsPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Revoke invitations for a Moderated Study
 */
export const fetchRevokeModeratedStudyInvitations = (
  variables: RevokeModeratedStudyInvitationsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RevokeModeratedStudyInvitationsError,
    RevokeModeratedStudyInvitationsRequestBody,
    {},
    {},
    RevokeModeratedStudyInvitationsPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/revoke_invitations",
    method: "post",
    ...variables,
  });

/**
 * Revoke invitations for a Moderated Study
 */
export const useRevokeModeratedStudyInvitations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RevokeModeratedStudyInvitationsError,
      RevokeModeratedStudyInvitationsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RevokeModeratedStudyInvitationsError,
    RevokeModeratedStudyInvitationsVariables
  >(
    (variables: RevokeModeratedStudyInvitationsVariables) =>
      fetchRevokeModeratedStudyInvitations({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type GetRecruitmentLinkQueryParams = {
  /**
   * unique_id of the usability test to look up
   */
  usability_test_unique_id?: string;
  /**
   * unique_id of the test set to look up
   */
  test_set_unique_id?: string;
};

export type GetRecruitmentLinkError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type GetRecruitmentLinkVariables = {
  queryParams?: GetRecruitmentLinkQueryParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Returns information about the recruitment link status for the provided usability test or set.  You must provide either `usability_test_unique_id` or `test_set_unique_id` but not both.
 */
export const fetchGetRecruitmentLink = (
  variables: GetRecruitmentLinkVariables,
) =>
  usabilityhubFetch<
    Schemas.RecruitmentLink,
    GetRecruitmentLinkError,
    undefined,
    {},
    GetRecruitmentLinkQueryParams,
    {}
  >({ url: "/api/recruitment_links", method: "get", ...variables });

/**
 * Returns information about the recruitment link status for the provided usability test or set.  You must provide either `usability_test_unique_id` or `test_set_unique_id` but not both.
 */
export const useGetRecruitmentLink = <TData = Schemas.RecruitmentLink,>(
  variables: GetRecruitmentLinkVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RecruitmentLink,
      GetRecruitmentLinkError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    Schemas.RecruitmentLink,
    GetRecruitmentLinkError,
    TData
  >(
    queryKeyFn({
      path: "/api/recruitment_links",
      operationId: "getRecruitmentLink",
      variables,
    }),
    () => fetchGetRecruitmentLink({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateRecruitmentLinkError = Fetcher.ErrorWrapper<{
  status: 403;
  payload: {
    /**
     * Error message
     */
    error?: string;
  };
}>;

export type UpdateRecruitmentLinkRequestBody = {
  usability_test_unique_id?: string;
  test_set_unique_id?: string;
  recruitment_link: {
    active?: boolean;
    allow_multiple_responses?: boolean;
    enable_response_limit?: boolean;
    response_limit?: number | null;
    enable_demographics?: boolean;
    capture_age?: boolean;
    capture_country?: boolean;
    enable_redirect?: boolean;
    redirect_link?: string | null;
    capture_demographic_attribute_ids?: number[];
    enable_custom_thank_you?: boolean;
    thank_you_heading?: string | null;
    thank_you_message?: string | null;
  };
};

export type UpdateRecruitmentLinkVariables = {
  body: UpdateRecruitmentLinkRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates the recruitment link status for the provided usability test or set.  You must provide either `usability_test_unique_id` or `test_set_unique_id` but not both.
 */
export const fetchUpdateRecruitmentLink = (
  variables: UpdateRecruitmentLinkVariables,
) =>
  usabilityhubFetch<
    Schemas.RecruitmentLink,
    UpdateRecruitmentLinkError,
    UpdateRecruitmentLinkRequestBody,
    {},
    {},
    {}
  >({ url: "/api/recruitment_links", method: "patch", ...variables });

/**
 * Updates the recruitment link status for the provided usability test or set.  You must provide either `usability_test_unique_id` or `test_set_unique_id` but not both.
 */
export const useUpdateRecruitmentLink = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RecruitmentLink,
      UpdateRecruitmentLinkError,
      UpdateRecruitmentLinkVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Schemas.RecruitmentLink,
    UpdateRecruitmentLinkError,
    UpdateRecruitmentLinkVariables
  >(
    (variables: UpdateRecruitmentLinkVariables) =>
      fetchUpdateRecruitmentLink({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdateOrganizationSettingsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
>;

export type UpdateOrganizationSettingsRequestBody = {
  organization_name: string;
  /**
   * @format binary
   */
  logo?: Blob;
  delete_logo: "true" | "false";
  default_test_button_color: string;
  default_test_language_code: string;
};

export type UpdateOrganizationSettingsVariables = {
  body: UpdateOrganizationSettingsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update organization settings
 */
export const fetchUpdateOrganizationSettings = (
  variables: UpdateOrganizationSettingsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateOrganizationSettingsError,
    UpdateOrganizationSettingsRequestBody,
    {},
    {},
    {}
  >({ url: "/api/settings/organization", method: "patch", ...variables });

/**
 * Update organization settings
 */
export const useUpdateOrganizationSettings = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateOrganizationSettingsError,
      UpdateOrganizationSettingsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateOrganizationSettingsError,
    UpdateOrganizationSettingsVariables
  >(
    (variables: UpdateOrganizationSettingsVariables) =>
      fetchUpdateOrganizationSettings({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListTimezonesError = Fetcher.ErrorWrapper<undefined>;

export type ListTimezonesResponse = {
  timezones: {
    label: string;
    value: string;
  }[];
};

export type ListTimezonesVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Returns the master list of timezones we support in the application.
 */
export const fetchListTimezones = (variables: ListTimezonesVariables) =>
  usabilityhubFetch<
    ListTimezonesResponse,
    ListTimezonesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/timezones", method: "get", ...variables });

/**
 * Returns the master list of timezones we support in the application.
 */
export const useListTimezones = <TData = ListTimezonesResponse,>(
  variables: ListTimezonesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListTimezonesResponse,
      ListTimezonesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<ListTimezonesResponse, ListTimezonesError, TData>(
    queryKeyFn({
      path: "/api/timezones",
      operationId: "listTimezones",
      variables,
    }),
    () => fetchListTimezones({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type UpdateZoomSettingsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Responses.R400;
}>;

export type UpdateZoomSettingsRequestBody = {
  waiting_room: boolean;
  join_before_host: boolean;
};

export type UpdateZoomSettingsVariables = {
  body: UpdateZoomSettingsRequestBody;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates Zoom integration settings for the current user
 */
export const fetchUpdateZoomSettings = (
  variables: UpdateZoomSettingsVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateZoomSettingsError,
    UpdateZoomSettingsRequestBody,
    {},
    {},
    {}
  >({ url: "/api/zoom_settings", method: "patch", ...variables });

/**
 * Updates Zoom integration settings for the current user
 */
export const useUpdateZoomSettings = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateZoomSettingsError,
      UpdateZoomSettingsVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateZoomSettingsError,
    UpdateZoomSettingsVariables
  >(
    (variables: UpdateZoomSettingsVariables) =>
      fetchUpdateZoomSettings({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdateDeviceRequirementPathParams = {
  id: string;
};

export type UpdateDeviceRequirementError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateDeviceRequirementRequestBody = {
  /**
   * Devices that may be used for the study
   */
  device_types: string[];
  /**
   * Peripherals required for the study
   */
  device_peripherals: string[];
};

export type UpdateDeviceRequirementVariables = {
  body: UpdateDeviceRequirementRequestBody;
  pathParams: UpdateDeviceRequirementPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Update device requirement
 */
export const fetchUpdateDeviceRequirement = (
  variables: UpdateDeviceRequirementVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateDeviceRequirementError,
    UpdateDeviceRequirementRequestBody,
    {},
    {},
    UpdateDeviceRequirementPathParams
  >({ url: "/api/device_requirements/{id}", method: "put", ...variables });

/**
 * Update device requirement
 */
export const useUpdateDeviceRequirement = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateDeviceRequirementError,
      UpdateDeviceRequirementVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateDeviceRequirementError,
    UpdateDeviceRequirementVariables
  >(
    (variables: UpdateDeviceRequirementVariables) =>
      fetchUpdateDeviceRequirement({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type UpdateModeratedStudyBookingPathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type UpdateModeratedStudyBookingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: {
        code?: "AuthenticationError";
        message: string;
      };
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type UpdateModeratedStudyBookingRequestBody = {
  incentive?: string;
  location?: string;
  location_type?: "none" | "zoom" | "teams" | "custom";
  auto_upload_recordings?: boolean;
  hosts?: number[];
};

export type UpdateModeratedStudyBookingVariables = {
  body?: UpdateModeratedStudyBookingRequestBody;
  pathParams: UpdateModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Updates on a ModeratedStudyBooking object.
 */
export const fetchUpdateModeratedStudyBooking = (
  variables: UpdateModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    UpdateModeratedStudyBookingError,
    UpdateModeratedStudyBookingRequestBody,
    {},
    {},
    UpdateModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}",
    method: "patch",
    ...variables,
  });

/**
 * Updates on a ModeratedStudyBooking object.
 */
export const useUpdateModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      UpdateModeratedStudyBookingError,
      UpdateModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    UpdateModeratedStudyBookingError,
    UpdateModeratedStudyBookingVariables
  >(
    (variables: UpdateModeratedStudyBookingVariables) =>
      fetchUpdateModeratedStudyBooking({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type CancelModeratedStudyBookingPathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type CancelModeratedStudyBookingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type CancelModeratedStudyBookingRequestBody = {
  reason: string | null;
  booking_begins_soon: boolean;
};

export type CancelModeratedStudyBookingVariables = {
  body: CancelModeratedStudyBookingRequestBody;
  pathParams: CancelModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Cancels a Moderated Study Booking.
 */
export const fetchCancelModeratedStudyBooking = (
  variables: CancelModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    CancelModeratedStudyBookingError,
    CancelModeratedStudyBookingRequestBody,
    {},
    {},
    CancelModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/cancel",
    method: "post",
    ...variables,
  });

/**
 * Cancels a Moderated Study Booking.
 */
export const useCancelModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      CancelModeratedStudyBookingError,
      CancelModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    CancelModeratedStudyBookingError,
    CancelModeratedStudyBookingVariables
  >(
    (variables: CancelModeratedStudyBookingVariables) =>
      fetchCancelModeratedStudyBooking({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type RescheduleModeratedStudyBookingPathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type RescheduleModeratedStudyBookingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type RescheduleModeratedStudyBookingRequestBody = {
  reason: string | null;
  booking_begins_soon: boolean;
};

export type RescheduleModeratedStudyBookingVariables = {
  body: RescheduleModeratedStudyBookingRequestBody;
  pathParams: RescheduleModeratedStudyBookingPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Reschedule Moderated Study Booking as a researcher
 */
export const fetchRescheduleModeratedStudyBooking = (
  variables: RescheduleModeratedStudyBookingVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    RescheduleModeratedStudyBookingError,
    RescheduleModeratedStudyBookingRequestBody,
    {},
    {},
    RescheduleModeratedStudyBookingPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/reschedule",
    method: "post",
    ...variables,
  });

/**
 * Reschedule Moderated Study Booking as a researcher
 */
export const useRescheduleModeratedStudyBooking = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      RescheduleModeratedStudyBookingError,
      RescheduleModeratedStudyBookingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    RescheduleModeratedStudyBookingError,
    RescheduleModeratedStudyBookingVariables
  >(
    (variables: RescheduleModeratedStudyBookingVariables) =>
      fetchRescheduleModeratedStudyBooking({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type MarkModeratedStudyBookingAsCompletePathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type MarkModeratedStudyBookingAsCompleteError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type MarkModeratedStudyBookingAsCompleteResponse = {
  events: Schemas.Event[];
};

export type MarkModeratedStudyBookingAsCompleteRequestBody = {
  mark_as_paid: boolean;
  rating: string | null;
  comment: string | null;
};

export type MarkModeratedStudyBookingAsCompleteVariables = {
  body: MarkModeratedStudyBookingAsCompleteRequestBody;
  pathParams: MarkModeratedStudyBookingAsCompletePathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Marks a Moderated Study Booking as complete.
 */
export const fetchMarkModeratedStudyBookingAsComplete = (
  variables: MarkModeratedStudyBookingAsCompleteVariables,
) =>
  usabilityhubFetch<
    MarkModeratedStudyBookingAsCompleteResponse,
    MarkModeratedStudyBookingAsCompleteError,
    MarkModeratedStudyBookingAsCompleteRequestBody,
    {},
    {},
    MarkModeratedStudyBookingAsCompletePathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/mark_as_complete",
    method: "post",
    ...variables,
  });

/**
 * Marks a Moderated Study Booking as complete.
 */
export const useMarkModeratedStudyBookingAsComplete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      MarkModeratedStudyBookingAsCompleteResponse,
      MarkModeratedStudyBookingAsCompleteError,
      MarkModeratedStudyBookingAsCompleteVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    MarkModeratedStudyBookingAsCompleteResponse,
    MarkModeratedStudyBookingAsCompleteError,
    MarkModeratedStudyBookingAsCompleteVariables
  >(
    (variables: MarkModeratedStudyBookingAsCompleteVariables) =>
      fetchMarkModeratedStudyBookingAsComplete({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type MarkModeratedStudyBookingAsReportedPathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type MarkModeratedStudyBookingAsReportedError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type MarkModeratedStudyBookingAsReportedRequestBody = {
  reason: string;
  link: string | null;
};

export type MarkModeratedStudyBookingAsReportedVariables = {
  body: MarkModeratedStudyBookingAsReportedRequestBody;
  pathParams: MarkModeratedStudyBookingAsReportedPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Marks a Moderated Study Booking as reported.
 */
export const fetchMarkModeratedStudyBookingAsReported = (
  variables: MarkModeratedStudyBookingAsReportedVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    MarkModeratedStudyBookingAsReportedError,
    MarkModeratedStudyBookingAsReportedRequestBody,
    {},
    {},
    MarkModeratedStudyBookingAsReportedPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/mark_as_reported",
    method: "post",
    ...variables,
  });

/**
 * Marks a Moderated Study Booking as reported.
 */
export const useMarkModeratedStudyBookingAsReported = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      MarkModeratedStudyBookingAsReportedError,
      MarkModeratedStudyBookingAsReportedVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    MarkModeratedStudyBookingAsReportedError,
    MarkModeratedStudyBookingAsReportedVariables
  >(
    (variables: MarkModeratedStudyBookingAsReportedVariables) =>
      fetchMarkModeratedStudyBookingAsReported({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type MarkModeratedStudyBookingAsNoShowPathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type MarkModeratedStudyBookingAsNoShowError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type MarkModeratedStudyBookingAsNoShowVariables = {
  pathParams: MarkModeratedStudyBookingAsNoShowPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Marks a Moderated Study Booking as no-show.
 */
export const fetchMarkModeratedStudyBookingAsNoShow = (
  variables: MarkModeratedStudyBookingAsNoShowVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    MarkModeratedStudyBookingAsNoShowError,
    undefined,
    {},
    {},
    MarkModeratedStudyBookingAsNoShowPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/mark_as_no_show",
    method: "post",
    ...variables,
  });

/**
 * Marks a Moderated Study Booking as no-show.
 */
export const useMarkModeratedStudyBookingAsNoShow = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      MarkModeratedStudyBookingAsNoShowError,
      MarkModeratedStudyBookingAsNoShowVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    MarkModeratedStudyBookingAsNoShowError,
    MarkModeratedStudyBookingAsNoShowVariables
  >(
    (variables: MarkModeratedStudyBookingAsNoShowVariables) =>
      fetchMarkModeratedStudyBookingAsNoShow({
        ...fetcherOptions,
        ...variables,
      }),
    options,
  );
};

export type MarkModeratedStudyBookingAsPaidPathParams = {
  moderatedStudyBookingId: string;
  moderatedStudyId: string;
};

export type MarkModeratedStudyBookingAsPaidError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Responses.R400;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type MarkModeratedStudyBookingAsPaidVariables = {
  pathParams: MarkModeratedStudyBookingAsPaidPathParams;
} & UsabilityhubContext["fetcherOptions"];

/**
 * Marks a Moderated Study Booking as having had its incentive paid out.
 */
export const fetchMarkModeratedStudyBookingAsPaid = (
  variables: MarkModeratedStudyBookingAsPaidVariables,
) =>
  usabilityhubFetch<
    Responses.R204,
    MarkModeratedStudyBookingAsPaidError,
    undefined,
    {},
    {},
    MarkModeratedStudyBookingAsPaidPathParams
  >({
    url: "/api/moderated_studies/{moderatedStudyId}/bookings/{moderatedStudyBookingId}/mark_as_paid",
    method: "post",
    ...variables,
  });

/**
 * Marks a Moderated Study Booking as having had its incentive paid out.
 */
export const useMarkModeratedStudyBookingAsPaid = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Responses.R204,
      MarkModeratedStudyBookingAsPaidError,
      MarkModeratedStudyBookingAsPaidVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useUsabilityhubContext();
  return reactQuery.useMutation<
    Responses.R204,
    MarkModeratedStudyBookingAsPaidError,
    MarkModeratedStudyBookingAsPaidVariables
  >(
    (variables: MarkModeratedStudyBookingAsPaidVariables) =>
      fetchMarkModeratedStudyBookingAsPaid({ ...fetcherOptions, ...variables }),
    options,
  );
};

export type ListCountriesError = Fetcher.ErrorWrapper<undefined>;

export type ListCountriesResponse = {
  countries?: Schemas.Country[];
};

export type ListCountriesVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Returns the list of countries in the application
 */
export const fetchListCountries = (variables: ListCountriesVariables) =>
  usabilityhubFetch<
    ListCountriesResponse,
    ListCountriesError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/countries", method: "get", ...variables });

/**
 * Returns the list of countries in the application
 */
export const useListCountries = <TData = ListCountriesResponse,>(
  variables: ListCountriesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListCountriesResponse,
      ListCountriesError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<ListCountriesResponse, ListCountriesError, TData>(
    queryKeyFn({
      path: "/api/countries",
      operationId: "listCountries",
      variables,
    }),
    () => fetchListCountries({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type GetGoogleCalendarsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type GetGoogleCalendarsResponse = Schemas.ModeratedStudyCalendar[];

export type GetGoogleCalendarsVariables = UsabilityhubContext["fetcherOptions"];

/**
 * Get a list of users Google calendars for the current user
 */
export const fetchGetGoogleCalendars = (
  variables: GetGoogleCalendarsVariables,
) =>
  usabilityhubFetch<
    GetGoogleCalendarsResponse,
    GetGoogleCalendarsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/google_calendars", method: "get", ...variables });

/**
 * Get a list of users Google calendars for the current user
 */
export const useGetGoogleCalendars = <TData = GetGoogleCalendarsResponse,>(
  variables: GetGoogleCalendarsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetGoogleCalendarsResponse,
      GetGoogleCalendarsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    GetGoogleCalendarsResponse,
    GetGoogleCalendarsError,
    TData
  >(
    queryKeyFn({
      path: "/api/google_calendars",
      operationId: "getGoogleCalendars",
      variables,
    }),
    () => fetchGetGoogleCalendars({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type ListOutlookCalendarsError = Fetcher.ErrorWrapper<
  | {
      status: 403;
      payload: Responses.R403;
    }
  | {
      status: 404;
      payload: Responses.R404;
    }
>;

export type ListOutlookCalendarsResponse = Schemas.ModeratedStudyCalendar[];

export type ListOutlookCalendarsVariables =
  UsabilityhubContext["fetcherOptions"];

/**
 * Get a list of Outlook calendars for the current user
 */
export const fetchListOutlookCalendars = (
  variables: ListOutlookCalendarsVariables,
) =>
  usabilityhubFetch<
    ListOutlookCalendarsResponse,
    ListOutlookCalendarsError,
    undefined,
    {},
    {},
    {}
  >({ url: "/api/outlook_calendars", method: "get", ...variables });

/**
 * Get a list of Outlook calendars for the current user
 */
export const useListOutlookCalendars = <TData = ListOutlookCalendarsResponse,>(
  variables: ListOutlookCalendarsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListOutlookCalendarsResponse,
      ListOutlookCalendarsError,
      TData
    >,
    "queryKey" | "queryFn"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useUsabilityhubContext(options);
  return reactQuery.useQuery<
    ListOutlookCalendarsResponse,
    ListOutlookCalendarsError,
    TData
  >(
    queryKeyFn({
      path: "/api/outlook_calendars",
      operationId: "listOutlookCalendars",
      variables,
    }),
    () => fetchListOutlookCalendars({ ...fetcherOptions, ...variables }),
    {
      ...options,
      ...queryOptions,
    },
  );
};

export type QueryOperation =
  | {
      path: "/api/admin/demographic_profiles/{id}/fluencies";
      operationId: "adminDemographicProfileFluencies";
      variables: AdminDemographicProfileFluenciesVariables;
    }
  | {
      path: "/api/admin/demographics";
      operationId: "adminListDemographics";
      variables: AdminListDemographicsVariables;
    }
  | {
      path: "/api/admin/demographics/groups";
      operationId: "adminListDemographicGroups";
      variables: AdminListDemographicGroupsVariables;
    }
  | {
      path: "/api/account/current";
      operationId: "getCurrentAccount";
      variables: GetCurrentAccountVariables;
    }
  | {
      path: "/api/account/total_credits";
      operationId: "getAccountTotalCredits";
      variables: GetAccountTotalCreditsVariables;
    }
  | {
      path: "/api/credits/batches";
      operationId: "listCreditBatches";
      variables: ListCreditBatchesVariables;
    }
  | {
      path: "/api/demographics";
      operationId: "listDemographics";
      variables: ListDemographicsVariables;
    }
  | {
      path: "/api/demographic_profiles/demographic_profiles_for_user";
      operationId: "demographicProfileForUser";
      variables: DemographicProfileForUserVariables;
    }
  | {
      path: "/api/demographic_profiles/demographic_profiles_index";
      operationId: "demographicProfileIndex";
      variables: DemographicProfileIndexVariables;
    }
  | {
      path: "/api/feature_flags";
      operationId: "getActiveFeatureFlags";
      variables: GetActiveFeatureFlagsVariables;
    }
  | {
      path: "/api/feature_flags/available";
      operationId: "listAllFeatureFlags";
      variables: ListAllFeatureFlagsVariables;
    }
  | {
      path: "/api/languages";
      operationId: "listLanguages";
      variables: ListLanguagesVariables;
    }
  | {
      path: "/api/plans";
      operationId: "listPlans";
      variables: ListPlansVariables;
    }
  | {
      path: "/api/translations";
      operationId: "listTranslations";
      variables: ListTranslationsVariables;
    }
  | {
      path: "/api/moderated_studies";
      operationId: "listModeratedStudies";
      variables: ListModeratedStudiesVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}";
      operationId: "getModeratedStudy";
      variables: GetModeratedStudyVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/locations";
      operationId: "getModeratedStudyLocations";
      variables: GetModeratedStudyLocationsVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/summary";
      operationId: "getModeratedStudySummary";
      variables: GetModeratedStudySummaryVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/bookings/{moderated_study_booking_id}/recordings";
      operationId: "getModeratedStudyBookingRecordings";
      variables: GetModeratedStudyBookingRecordingsVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/bookings/{moderated_study_booking_id}/recordings/{recording_id}/transcript";
      operationId: "getModeratedStudyInterviewTranscript";
      variables: GetModeratedStudyInterviewTranscriptVariables;
    }
  | {
      path: "/api/moderated_study_applications/{moderated_study_application_id}/booking_slots";
      operationId: "listModeratedStudyBookingSlots";
      variables: ListModeratedStudyBookingSlotsVariables;
    }
  | {
      path: "/api/moderated_study_previews/{moderated_study_id}/booking_slots";
      operationId: "previewModeratedStudyBookingSlots";
      variables: PreviewModeratedStudyBookingSlotsVariables;
    }
  | {
      path: "/api/user_notices";
      operationId: "getNotices";
      variables: GetNoticesVariables;
    }
  | {
      path: "/redirect_session";
      operationId: "getRedirectSession";
      variables: GetRedirectSessionVariables;
    }
  | {
      path: "/api/storage/multipart/{key}/{upload_id}";
      operationId: "listParts";
      variables: ListPartsVariables;
    }
  | {
      path: "/api/team_members/deleting_user_info/{user_id}";
      operationId: "getDeletingUserInfo";
      variables: GetDeletingUserInfoVariables;
    }
  | {
      path: "/api/team_members/summary";
      operationId: "getTeamMembersSummary";
      variables: GetTeamMembersSummaryVariables;
    }
  | {
      path: "/api/team_members";
      operationId: "listTeamMembers";
      variables: ListTeamMembersVariables;
    }
  | {
      path: "/api/test_results/{usability_test_unique_id}/responses/{response_id}";
      operationId: "getTestResultsIndividualResponse";
      variables: GetTestResultsIndividualResponseVariables;
    }
  | {
      path: "/api/usercrowd/notification_preferences";
      operationId: "getPanelistNotificationPreferences";
      variables: GetPanelistNotificationPreferencesVariables;
    }
  | {
      path: "/api/order_assignments";
      operationId: "listUsercrowdOrderAssignments";
      variables: ListUsercrowdOrderAssignmentsVariables;
    }
  | {
      path: "/api/order_assignments/{id}";
      operationId: "getUsercrowdOrderAssignment";
      variables: GetUsercrowdOrderAssignmentVariables;
    }
  | {
      path: "/api/usercrowd/notification_subscriptions";
      operationId: "listNotificationSubscriptions";
      variables: ListNotificationSubscriptionsVariables;
    }
  | {
      path: "/api/usercrowd/panelist/availability";
      operationId: "getPanelistAvailability";
      variables: GetPanelistAvailabilityVariables;
    }
  | {
      path: "/api/usercrowd/panelist/me";
      operationId: "currentPanelistInfo";
      variables: CurrentPanelistInfoVariables;
    }
  | {
      path: "/api/usercrowd/panelist/stats";
      operationId: "panelistStats";
      variables: PanelistStatsVariables;
    }
  | {
      path: "/api/usercrowd/panelist/history";
      operationId: "panelistHistory";
      variables: PanelistHistoryVariables;
    }
  | {
      path: "/api/usercrowd/panelist/payouts";
      operationId: "panelistPayouts";
      variables: PanelistPayoutsVariables;
    }
  | {
      path: "/api/usercrowd/panelist/settings";
      operationId: "getPanelistSettings";
      variables: GetPanelistSettingsVariables;
    }
  | {
      path: "/api/usercrowd/moderated_study_bookings/upcoming";
      operationId: "listUsercrowdUpcomingBookings";
      variables: ListUsercrowdUpcomingBookingsVariables;
    }
  | {
      path: "/api/version";
      operationId: "getVersion";
      variables: GetVersionVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/orders/quote";
      operationId: "getModeratedStudyOrderQuote";
      variables: GetModeratedStudyOrderQuoteVariables;
    }
  | {
      path: "/api/dynamic_templates";
      operationId: "getDynamicTemplates";
      variables: GetDynamicTemplatesVariables;
    }
  | {
      path: "/api/dynamic_templates/{id}";
      operationId: "checkDynamicTemplateExists";
      variables: CheckDynamicTemplateExistsVariables;
    }
  | {
      path: "/api/account/moderated_studies_quota";
      operationId: "getModeratedStudyQuota";
      variables: GetModeratedStudyQuotaVariables;
    }
  | {
      path: "/api/onboarding";
      operationId: "getOnboarding";
      variables: GetOnboardingVariables;
    }
  | {
      path: "/api/disabled_features";
      operationId: "listDisabledFeatures";
      variables: ListDisabledFeaturesVariables;
    }
  | {
      path: "/api/tests_with_recording_enabled";
      operationId: "listTestsWithRecordingEnabled";
      variables: ListTestsWithRecordingEnabledVariables;
    }
  | {
      path: "/api/test_sets/variation_sets";
      operationId: "listVariationSets";
      variables: ListVariationSetsVariables;
    }
  | {
      path: "/api/test_sets/{unique_id}/preview";
      operationId: "previewTestSet";
      variables: PreviewTestSetVariables;
    }
  | {
      path: "/api/test_sets/{unique_id}/variation_set";
      operationId: "getVariationSet";
      variables: GetVariationSetVariables;
    }
  | {
      path: "/api/filters/options";
      operationId: "listFilterOptions";
      variables: ListFilterOptionsVariables;
    }
  | {
      path: "/api/usability_tests/new";
      operationId: "usabilityTestNew";
      variables: UsabilityTestNewVariables;
    }
  | {
      path: "/api/usability_tests/{usability_test_id}/edit";
      operationId: "usabilityTestEdit";
      variables: UsabilityTestEditVariables;
    }
  | {
      path: "/api/usability_tests/{usability_test_unique_id}/preview";
      operationId: "previewUsabilityTest";
      variables: PreviewUsabilityTestVariables;
    }
  | {
      path: "/api/tests/{usability_test_id}/results/{private_id}/sections/{section_id}/tree_testing/aggregated";
      operationId: "getAggregatedTreeTestResults";
      variables: GetAggregatedTreeTestResultsVariables;
    }
  | {
      path: "/api/usability_tests/{usability_test_id}/orders/new";
      operationId: "getNewOrderPage";
      variables: GetNewOrderPageVariables;
    }
  | {
      path: "/api/usability_tests/{usability_test_id}/orders";
      operationId: "usabilityTestOrders";
      variables: UsabilityTestOrdersVariables;
    }
  | {
      path: "/api/usability_tests/{usability_test_id}/recruit";
      operationId: "usabilityTestRecruitPage";
      variables: UsabilityTestRecruitPageVariables;
    }
  | {
      path: "/api/usability_tests/{usability_test_id}/page";
      operationId: "usabilityTestPage";
      variables: UsabilityTestPageVariables;
    }
  | {
      path: "/api/responses/{response_id}";
      operationId: "getUsabilityTestResponseFlow";
      variables: GetUsabilityTestResponseFlowVariables;
    }
  | {
      path: "/api/tree_testing/trees/{id}";
      operationId: "getTreeTestTree";
      variables: GetTreeTestTreeVariables;
    }
  | {
      path: "/api/ordeal";
      operationId: "getExperiments";
      variables: GetExperimentsVariables;
    }
  | {
      path: "/admin/ordeal/api/experiments";
      operationId: "getOrdealExperiments";
      variables: GetOrdealExperimentsVariables;
    }
  | {
      path: "/api/demographic_presets";
      operationId: "listDemographicPresets";
      variables: ListDemographicPresetsVariables;
    }
  | {
      path: "/api/dashboard_new";
      operationId: "getDashboardNew";
      variables: GetDashboardNewVariables;
    }
  | {
      path: "/api/test_builder/{usability_test_unique_id}";
      operationId: "getTestBuilder";
      variables: GetTestBuilderVariables;
    }
  | {
      path: "/api/targetable_locations/{scope}";
      operationId: "listTargetableLocations";
      variables: ListTargetableLocationsVariables;
    }
  | {
      path: "/api/integrations";
      operationId: "getIntegrations";
      variables: GetIntegrationsVariables;
    }
  | {
      path: "/api/test_results/{usability_test_unique_id}";
      operationId: "getApiTestResults";
      variables: GetApiTestResultsVariables;
    }
  | {
      path: "/api/projects";
      operationId: "listProjects";
      variables: ListProjectsVariables;
    }
  | {
      path: "/api/projects/{id}";
      operationId: "getProject";
      variables: GetProjectVariables;
    }
  | {
      path: "/api/projects/{id}/has_active_third_party_orders";
      operationId: "getProjectHasActiveThirdPartyOrders";
      variables: GetProjectHasActiveThirdPartyOrdersVariables;
    }
  | {
      path: "/api/usability_tests";
      operationId: "listUsabilityTests";
      variables: ListUsabilityTestsVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/archive";
      operationId: "canArchiveModeratedStudy";
      variables: CanArchiveModeratedStudyVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/recruitment_links";
      operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks";
      variables: GetApiModeratedStudiesModeratedStudyIdRecruitmentLinksVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/recruitment_links/{moderated_study_recruitment_link_id}";
      operationId: "getModeratedStudyRecruitmentLink";
      variables: GetModeratedStudyRecruitmentLinkVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/applications";
      operationId: "getModeratedStudyApplications";
      variables: GetModeratedStudyApplicationsVariables;
    }
  | {
      path: "/api/moderated_study_applications/{moderated_study_application_id}";
      operationId: "getModeratedStudyApplication";
      variables: GetModeratedStudyApplicationVariables;
    }
  | {
      path: "/api/moderated_study_previews/{moderated_study_id}";
      operationId: "previewModeratedStudyApplication";
      variables: PreviewModeratedStudyApplicationVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/bookings";
      operationId: "getModeratedStudyBookings";
      variables: GetModeratedStudyBookingsVariables;
    }
  | {
      path: "/api/moderated_studies/{moderated_study_id}/screener_responses";
      operationId: "getModeratedStudyScreenerResponses";
      variables: GetModeratedStudyScreenerResponsesVariables;
    }
  | {
      path: "/api/recruitment_links";
      operationId: "getRecruitmentLink";
      variables: GetRecruitmentLinkVariables;
    }
  | {
      path: "/api/timezones";
      operationId: "listTimezones";
      variables: ListTimezonesVariables;
    }
  | {
      path: "/api/countries";
      operationId: "listCountries";
      variables: ListCountriesVariables;
    }
  | {
      path: "/api/google_calendars";
      operationId: "getGoogleCalendars";
      variables: GetGoogleCalendarsVariables;
    }
  | {
      path: "/api/outlook_calendars";
      operationId: "listOutlookCalendars";
      variables: ListOutlookCalendarsVariables;
    };
