import { FormControl, FormLabel, Input, Text, Tooltip } from "@chakra-ui/react"
import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"

export const SAMLSettingsCard = () => {
  const currentAccount = useCurrentAccount()
  return (
    <Card>
      <CardHeader>SAML settings</CardHeader>
      <CardBody>
        <Text>Require all team members to be authenticated via SAML SSO.</Text>
        <FormControl isDisabled>
          <FormLabel htmlFor="saml_fingerprint">SAML fingerprint</FormLabel>
          <Tooltip
            label="Contact support to change this setting"
            hasArrow
            placement="top"
          >
            <Input
              id="saml_fingerprint"
              name="saml_fingerprint"
              value={currentAccount.saml_fingerprint || ""}
              isDisabled
            />
          </Tooltip>
        </FormControl>
        <FormControl isDisabled>
          <FormLabel htmlFor="saml_sign_in_url">SAML sign in URL</FormLabel>
          <Tooltip
            label="Contact support to change this setting"
            hasArrow
            placement="top"
          >
            <Input
              id="saml_sign_in_url"
              name="saml_sign_in_url"
              value={currentAccount.saml_sign_in_url || ""}
              isDisabled
            />
          </Tooltip>
        </FormControl>
      </CardBody>
    </Card>
  )
}
