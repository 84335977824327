/**
 * Based on container, not viewport
 */
export const threeColumnsOrMore = "48rem"

// two columns (compact cards)
const cq2Cols = "@container (min-width: 32rem)"
// three columns or more (regular cards)
const cq3Cols = `@container (min-width: ${threeColumnsOrMore})`
const cq4Cols = "@container (min-width: 62rem)"
const cq5Cols = "@container (min-width: 79rem)"

export const projectsPageGridSxProps = {
  [cq2Cols]: { "&": { gridTemplateColumns: "repeat(2, 1fr)" } },
  [cq3Cols]: { "&": { gridTemplateColumns: "repeat(3, 1fr)" } },
  [cq4Cols]: { "&": { gridTemplateColumns: "repeat(4, 1fr)" } },
  [cq5Cols]: { "&": { gridTemplateColumns: "repeat(5, 1fr)" } },
}

export const recentProjectsGridSxProps = {
  // one column (compact cards)
  "> :nth-last-child(-n+2)": { display: "none" },
  [cq2Cols]: {
    ...projectsPageGridSxProps[cq2Cols],
    "> :nth-child(4)": { display: "block" },
  },
  [cq3Cols]: {
    ...projectsPageGridSxProps[cq3Cols],
    "> :nth-child(4)": { display: "none" },
  },
  [cq4Cols]: {
    ...projectsPageGridSxProps[cq4Cols],
    "> :nth-child(4)": { display: "block" },
  },
  [cq5Cols]: {
    ...projectsPageGridSxProps[cq5Cols],
    "> :nth-child(5)": { display: "block" },
  },
}
