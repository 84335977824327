import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"
import { useForm } from "react-hook-form"
import * as Yup from "yup"

import { interviewCreatedGoogle } from "JavaScripts/analytics/google"
import { useIsInterviewsEnabled } from "UsabilityHub/hooks/useIsInterviewsEnabled"
import { FunctionalModal } from "Utilities/modals/types"
import { usePostApiModeratedStudiesModeratedStudyId } from "~/api/generated/usabilityhub-components"

const CreateNewModeratedStudyFormSchema = Yup.object({
  internal_name: Yup.string()
    .max(100, "Study name is too long (max 100 characters)")
    .required("Study name is required"),
})

type FormValues = Yup.InferType<typeof CreateNewModeratedStudyFormSchema>

export const CreateNewModeratedStudyModal: FunctionalModal<{
  onCreate: (id: string) => void
}> = ({ onCreate, onClose }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const interviewsEnabled = useIsInterviewsEnabled()

  const { mutateAsync: createModeratedStudy } =
    usePostApiModeratedStudiesModeratedStudyId({
      onSuccess: (data) => {
        onCreate(data.moderated_study_id)
        interviewCreatedGoogle()
        return queryClient.invalidateQueries(["api", "moderated_studies"])
      },
    })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(CreateNewModeratedStudyFormSchema),
    defaultValues: {
      internal_name: "",
    },
  })

  const onSubmit = async (values: FormValues) => {
    try {
      await createModeratedStudy({
        body: {
          ...values,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
    } catch (e) {
      toast({
        title: e.payload?.message ?? "Error creating study",
        status: "error",
      })
    }
  }

  return (
    <Modal size="lg" isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Create new interview study</ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl isInvalid={!!errors.internal_name}>
                <FormLabel htmlFor="email">Study name</FormLabel>
                <Input
                  variant="filled"
                  isDisabled={!interviewsEnabled}
                  {...register("internal_name")}
                />
                <FormHelperText>
                  This is an internal name that participants won{"\u2019"}t see.
                </FormHelperText>
                {errors.internal_name && (
                  <FormErrorMessage>
                    {errors.internal_name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Tooltip
                isDisabled={interviewsEnabled}
                hasArrow
                rounded="sm"
                label="This feature is not available on your account."
              >
                <Button
                  isDisabled={!interviewsEnabled || isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="brand.primary"
                  data-qa="submit-create-new-moderated-study-modal"
                >
                  Create
                </Button>
              </Tooltip>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
