import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react"
import React from "react"

import coinImage from "Images/app-illustrations/lyssna/coin.png"

interface Props {
  readonly formattedTotal: string
  readonly formattedDiscount: string | null
}

export const WelcomePresent: React.FC<React.PropsWithChildren<Props>> = ({
  formattedTotal,
  formattedDiscount,
}) => {
  return (
    <Box bg="gray.100" p={2}>
      <Stack>
        <Flex gap={3} align="center">
          <Image src={coinImage} w={53} />
          <Text>
            As a welcome present we are applying a {formattedDiscount} discount
            on your first order.
          </Text>
        </Flex>

        <Text fontWeight="bold" fontSize="md">
          Your total cost is {formattedTotal}
        </Text>
      </Stack>
    </Box>
  )
}
