import { Spinner } from "@chakra-ui/react"
import { Elements } from "@stripe/react-stripe-js"
import { Stripe } from "@stripe/stripe-js"
import React, { Component, ReactNode } from "react"

import { getStripe } from "Services/stripe"
import { reportError } from "Utilities/error"

interface Props {
  readonly children: ReactNode
}

interface State {
  readonly stripe: Stripe | null
}

export default class AsyncStripeProvider extends Component<Props> {
  private _isMounted = false

  state: State = { stripe: null }

  async componentDidMount() {
    this._isMounted = true
    try {
      const stripe = await getStripe()
      if (this._isMounted) {
        this.setState({ stripe })
      }
    } catch (error) {
      this.setState({ error })
      reportError(error)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { stripe } = this.state
    const { children } = this.props
    if (stripe === null) {
      return <Spinner size="xs" />
    }
    return <Elements stripe={stripe}>{children}</Elements>
  }
}
