import { Box, HStack, Radio, RadioGroup, Stack } from "@chakra-ui/react"
import React, { ChangeEvent, useRef, useState } from "react"

import { TextInput } from "Components/form/text-input/text-input"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ReactOption } from "Types"
import { makeMultipleChoiceOptionsToOptions } from "UsabilityHub/components/UsabilityTestSectionQuestion/helpers"

export const otherSymbol = Symbol("other")

export interface Props {
  options: ReadonlyArray<string>
  randomized: boolean
  showOtherOption: boolean
  onChange: (value: string | symbol) => void
  value: string
  required: boolean
}

export const QuestionRadioButtonList: React.FC<
  React.PropsWithChildren<Props>
> = ({
  onChange,
  required,
  options: baseOptions,
  randomized,
  showOtherOption,
}) => {
  const otherInput = useRef<HTMLInputElement>(null)

  const translate = useTranslate()
  const [multipleChoiceOptionsToOptions] = useState(
    makeMultipleChoiceOptionsToOptions
  )

  const [otherValue, setOtherValue] = useState<string | symbol>("")
  const [selected, setSelected] = useState<string | symbol>("")

  const handleChange = (value: string | symbol) => {
    setSelected(value)
    onChange(typeof value === "string" ? value : otherValue)
    if (value === otherSymbol) {
      otherInput.current?.focus()
    }
  }

  const handleOtherChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(otherSymbol)
    setOtherValue(event.target.value)
    onChange(event.target.value)
  }

  const handleOtherFocus = () => {
    handleChange(otherSymbol)
  }

  const options: ReadonlyArray<ReactOption<string | symbol>> =
    multipleChoiceOptionsToOptions(baseOptions, randomized)

  return (
    <RadioGroup value={String(selected)} onChange={handleChange}>
      <Stack gap="2">
        {options.map(({ label, value }) => (
          <Radio
            key={String(value)}
            value={String(value)}
            required={required}
            verticalAlign="flex-start"
            onChange={() => handleChange(value)}
          >
            <Box>{label}</Box>
          </Radio>
        ))}
        {showOtherOption && (
          <HStack>
            <Radio
              value={String(otherSymbol)}
              required={required}
              verticalAlign="flex-start"
              onChange={() => handleChange(otherSymbol)}
            />
            <TextInput
              ref={otherInput}
              value={otherValue as string}
              onChange={handleOtherChange}
              onFocus={handleOtherFocus}
              required={selected === otherSymbol}
              placeholder={translate("test.other")}
            />
          </HStack>
        )}
      </Stack>
    </RadioGroup>
  )
}
