import { State } from "Redux/app-store"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import { ThunkAction } from "Types"
import { reportError } from "Utilities/error"

export const reportTestFormError =
  (error: Error, extra: Record<string, unknown> = {}): ThunkAction<State> =>
  (_, getState) => {
    const state = getState()
    const test = getFormValues(state)
    reportError(error, { extra: { test, ...extra } })
  }

export const reportTestResultsError =
  (error: Error, extra: Record<string, unknown> = {}): ThunkAction<State> =>
  () => {
    reportError(error, { extra: { ...extra } })
  }
