import { Box, Divider, Flex, Spinner, Stack, Text } from "@chakra-ui/react"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { ThirdPartyQuote } from "Types"
import { formatMinutesRange } from "Utilities/duration"
import { capitalizeFirstLetter, pluralizeWithCount } from "Utilities/string"
import React from "react"
import { CalculatePriceAndEstimatedDurationResponse } from "~/api/generated/usabilityhub-components"
import { CreditPricePopover } from "./CreditPricePopover"
import { Quote, QuoteState } from "./types"

type Props = {
  quote: Quote["quote"] | null
  quoteState: QuoteState
}

export const QuoteTable: React.FC<Props> = ({ quote, quoteState }) => {
  const fromYourBalance = quote
    ? quote.credit_cost - quote.credits_to_be_purchased
    : 0

  const hasQuoteData = quote && (!("available" in quote) || quote.available)

  const quoteHasCostBreakdown = quote && "per_response_cost_breakdown" in quote

  return (
    <Stack color={quoteState === "loading" ? "gray.400" : "text.primary"}>
      {hasQuoteData && (
        <>
          <ResponseCostBreakdown
            requestedResponseCount={quote.requested_response_count}
            creditCost={quote.credit_cost}
            creditCostPerResponse={
              quoteHasCostBreakdown ? undefined : quote.credit_cost_per_response
            }
            testDurationRange={
              quoteHasCostBreakdown ? quote.test_duration_range : undefined
            }
            perResponseCostBreakdown={
              quoteHasCostBreakdown
                ? quote.per_response_cost_breakdown
                : undefined
            }
          />
          <Flex
            justify="space-between"
            data-intercom-target="from-your-balance"
          >
            <Text>From your balance</Text>
            <Text>{pluralizeCredits(fromYourBalance)}</Text>
          </Flex>

          <Flex justify="space-between" data-intercom-target="credits-required">
            <Text>Credits required</Text>
            <Text>{pluralizeCredits(quote.credits_to_be_purchased)}</Text>
          </Flex>

          {quote.credits_to_be_purchased > 0 ? (
            <Flex justify="space-between">
              <Box display="inline-flex" alignItems="center">
                Cost per credit{" "}
                <HelpPopover>
                  <CreditPricePopover scale={quote.credits_scale} />
                </HelpPopover>
              </Box>
              <Text>{quote.price_per_credit}</Text>
            </Flex>
          ) : null}

          <Flex justify="space-between" data-intercom-target="total-cost">
            <Text>
              <strong>Total cost</strong>
            </Text>
            <Text>
              <strong>{quote.formatted_price}</strong>
            </Text>
          </Flex>
        </>
      )}

      {/* Show a spinner for the initial load, after that the old data continues to display but grayed out */}
      {quoteState === "loading" && !hasQuoteData && (
        <Stack justify="center" align="center" h="192px">
          <Spinner />
        </Stack>
      )}
    </Stack>
  )
}

const pluralizeResponses = (n: number) =>
  pluralizeWithCount(n, "response", "responses")

const pluralizeCredits = (n: number) =>
  pluralizeWithCount(n, "credit", "credits")

type CostProps = {
  requestedResponseCount: CalculatePriceAndEstimatedDurationResponse["requested_response_count"]
  creditCost: CalculatePriceAndEstimatedDurationResponse["credit_cost"]
  creditCostPerResponse?: ThirdPartyQuote["credit_cost_per_response"]
  testDurationRange?: CalculatePriceAndEstimatedDurationResponse["test_duration_range"]
  perResponseCostBreakdown?: CalculatePriceAndEstimatedDurationResponse["per_response_cost_breakdown"]
}

const ResponseCostBreakdown: React.FC<CostProps> = ({
  requestedResponseCount,
  creditCost,
  creditCostPerResponse,
  testDurationRange,
  perResponseCostBreakdown,
}) => {
  if (
    (!perResponseCostBreakdown || !testDurationRange) &&
    creditCostPerResponse
  ) {
    // For an external order
    return (
      <Flex
        justify="space-between"
        data-intercom-target="credit-cost-calculation"
      >
        <Box display="inline-flex" alignItems="center">
          {pluralizeResponses(requestedResponseCount)} &times;{" "}
          {pluralizeCredits(creditCostPerResponse)}{" "}
          <HelpPopover>
            Based on the length of this test each response will cost{" "}
            {pluralizeCredits(creditCostPerResponse)}.
          </HelpPopover>
        </Box>
        <Text>{pluralizeCredits(creditCost)}</Text>
      </Flex>
    )
  }

  if (perResponseCostBreakdown && testDurationRange) {
    // For a Lyssna order
    const costBreakdown = [
      {
        label: `${capitalizeFirstLetter(
          formatMinutesRange(
            testDurationRange.lower_minutes,
            testDurationRange.upper_minutes,
            true
          )
        )} test duration`,
        cost: perResponseCostBreakdown.test_cost,
      },
      {
        label: "Screener fee",
        cost: perResponseCostBreakdown.screener_cost,
      },
      {
        label: "Recording fee",
        cost: perResponseCostBreakdown.recording_cost,
      },
      {
        label: "Total cost per response",
        cost: perResponseCostBreakdown.total_cost,
        color: "ds.text.default",
        hasPreviousDivider: true,
      },
    ]

    return (
      <Flex
        justify="space-between"
        data-intercom-target="credit-cost-calculation"
      >
        <Box display="inline-flex" alignItems="center">
          {pluralizeResponses(requestedResponseCount)} &times;{" "}
          {pluralizeCredits(perResponseCostBreakdown.total_cost)}{" "}
          <HelpPopover>
            <Flex
              p={2}
              gap={1}
              direction="column"
              textStyle="ds.paragraph.primary"
              color="ds.text.subtle"
            >
              {costBreakdown.map(
                ({ label, cost, color, hasPreviousDivider }, index) =>
                  cost > 0 && (
                    <>
                      {hasPreviousDivider && <Divider my={2} />}
                      <Flex key={index} justify="space-between" color={color}>
                        <Box display="inline-flex" alignItems="center">
                          {label}
                        </Box>
                        <Text>{pluralizeCredits(cost)}</Text>
                      </Flex>
                    </>
                  )
              )}
            </Flex>
          </HelpPopover>
        </Box>
        <Text>{pluralizeCredits(creditCost)}</Text>
      </Flex>
    )
  }

  throw new Error("Invalid cost breakdown")
}
