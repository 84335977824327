import { State } from "Redux/app-store"
import * as ResponseActions from "Redux/reducers/current-response/action-creators"
import {
  getCurrentResponse,
  isConnected,
  responseStateSelector,
} from "Redux/reducers/current-response/selectors"
import { loadUsabilityTest } from "Redux/reducers/participant-usability-test/action-creators"
import { usabilityTestScreenshotsSelector } from "Redux/reducers/screenshots/selectors"
import {
  Language,
  TestBranding,
  ThankYouMessageCopy,
  WelcomeMessageCopy,
} from "Types"
import { UsabilityTestImpl } from "UsabilityHub/components/UsabilityTest/UsabilityTestImpl"
import { connect } from "react-redux"
import type {
  RecordingType,
  RecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"
interface Props {
  language: Readonly<Language>
  recruitmentLink: RecruitmentLink | null
  testBranding: Readonly<TestBranding> | null
  thankYouCopy: Readonly<ThankYouMessageCopy> | null
  welcomeCopy: Readonly<WelcomeMessageCopy> | null
  allowedRecordingTypes: ReadonlyArray<RecordingType> | null
  isExternalStudy: boolean
}

export const UsabilityTest = connect(
  (state: Readonly<State>, props: Readonly<Props>) => {
    const {
      language,
      recruitmentLink,
      testBranding,
      thankYouCopy,
      welcomeCopy,
      allowedRecordingTypes,
      isExternalStudy,
    } = props
    const usabilityTest = state.participantUsabilityTest
    if (usabilityTest === null) {
      throw new TypeError("usabilityTest === null")
    }
    const responseState = responseStateSelector(
      state,
      usabilityTest,
      recruitmentLink,
      [] // TODO
    )

    const response = getCurrentResponse(state)
    return {
      isConnected: isConnected(state),
      language,
      response,
      recruitmentLink,
      responseState,
      screenshots: usabilityTestScreenshotsSelector(state, usabilityTest),
      testBranding,
      thankYouCopy,
      didLoadFail: usabilityTest._didLoadFail,
      isLoaded: usabilityTest._isLoaded,
      welcomeCopy,
      hasPlayableScreenshot: usabilityTest.has_playable_screenshot,
      allowedRecordingTypes,
      isExternalStudy,
    }
  },
  {
    ...ResponseActions,
    loadUsabilityTest,
  }
)(UsabilityTestImpl)
