import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import React, { ReactNode } from "react"

import emptyAllSrc from "Images/app-illustrations/lyssna/empty-all.png"
import { CreateStudyInterviewIcon } from "Shared/icons/CreateStudyInterviewIcon"
import { CreateStudyTemplateIcon } from "Shared/icons/CreateStudyTemplateIcon"
import { CreateStudyTestIcon } from "Shared/icons/CreateStudyTestIcon"
import externalStudiesIcon from "Shared/icons/externalStudiesIcon.png"
import { CreateNewExternalStudyModal } from "UsabilityHub/components/CreateNewExternalStudyModal/CreateNewExternalStudyModal"
import { CreateNewModeratedStudyModal } from "UsabilityHub/components/CreateNewModeratedStudyModal/CreateNewModeratedStudyModal"
import { useIsExternalStudiesEnabled } from "UsabilityHub/hooks/useIsExternalStudiesEnabled"
import { useIsInterviewsEnabled } from "UsabilityHub/hooks/useIsInterviewsEnabled"
import { useModal } from "Utilities/modals/use-modal"
import { useNavigate } from "react-router"
import { ROUTES } from "../../routes"

export const EmptyDashboard: React.FC = () => {
  const navigate = useNavigate()
  const interviewsEnabled = useIsInterviewsEnabled()
  const externalStudiesEnabled = useIsExternalStudiesEnabled()

  const {
    open: openCreateNewModeratedStudyModal,
    close: closeCreateNewModeratedStudyModal,
  } = useModal(CreateNewModeratedStudyModal)

  const { open: openCreateExternalStudyModal } = useModal(
    CreateNewExternalStudyModal
  )

  const openCreateInterviewModal = () => {
    openCreateNewModeratedStudyModal({
      onCreate: (id) => {
        navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: id }))
        closeCreateNewModeratedStudyModal()
      },
    })
  }

  return (
    <Flex
      maxW="400px"
      direction="column"
      align="center"
      gap={10}
      mt={[0, "56px"]}
      mx="auto"
    >
      <Flex direction="column" align="center" gap={3}>
        <Image src={emptyAllSrc} maxWidth="104px" />
        <Heading
          as="h1"
          variant="h1"
          fontFamily="promo"
          fontSize="22px"
          color="text.primary"
          textAlign="center"
        >
          Get the answers to your burning questions by creating your first
          study.
        </Heading>
      </Flex>

      <Flex w="full" direction="column" gap={4}>
        <CreateNewCard
          title="Create a test"
          description="Usability tests, surveys, card sort and more"
          icon={<CreateStudyTestIcon boxSize={12} />}
          onClick={() => navigate(ROUTES.TESTS.NEW.path)}
        />

        {interviewsEnabled && (
          <CreateNewCard
            title="Create interview study"
            description="Setup, recruit and manage remote interviews"
            icon={<CreateStudyInterviewIcon boxSize={12} />}
            onClick={openCreateInterviewModal}
          />
        )}

        {externalStudiesEnabled && (
          <CreateNewCard
            title="Create external study"
            description="Use our panel with a study on another platform"
            icon={<Image src={externalStudiesIcon} boxSize={12} />}
            onClick={() => openCreateExternalStudyModal({})}
          />
        )}

        <CreateNewCard
          title="Start with a template"
          description="Browse our template library to create your test"
          icon={<CreateStudyTemplateIcon boxSize={12} />}
          onClick={() => navigate(ROUTES.DASHBOARD.TEMPLATES.path)}
        />
      </Flex>
    </Flex>
  )
}

type CreateNewCardProps = {
  icon: ReactNode
  title: ReactNode
  description: string
  onClick: () => void
}

const CreateNewCard: React.FC<CreateNewCardProps> = ({
  icon,
  title,
  description,
  onClick,
}) => (
  <Button
    w="full"
    h="auto"
    textAlign="initial"
    justifyContent="flex-start"
    gap={3}
    rounded="md"
    borderWidth={1}
    borderColor="border.default"
    whiteSpace="initial"
    bg="white"
    p={3}
    onClick={onClick}
    _hover={{ background: "gray.100" }}
  >
    {icon}

    <Flex direction="column" justify="center" gap={1}>
      <Text
        fontSize="sm"
        color="text.primary"
        fontWeight="medium"
        pos="relative"
      >
        {title}
      </Text>
      <Text fontSize="xs" fontWeight="normal" color="text.primary">
        {description}
      </Text>
    </Flex>
  </Button>
)
