import { Box, Link, Stack, Text } from "@chakra-ui/react"
import { Icon } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Alert, Button } from "DesignSystem/components"
import { InterviewCalendarIcon } from "Shared/icons/InterviewCalendarIcon"
import { ConflictCalendarModal } from "UsabilityHub/components/ConflictCalendarModal/ConflictCalendarModal"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React, { PropsWithChildren, useMemo } from "react"
import {
  ModeratedStudy,
  ModeratedStudyCalendar,
  ModeratedStudyMember,
} from "~/api/generated/usabilityhubSchemas"
import { useModeratedStudyContext } from "../../ModeratedStudyContext"
import { Card } from "../Card"
import { useStudyDetails } from "../StudyDetailsProvider"
import { BookingCalendarDropdown } from "./BookingCalendarDropdown"

export const EventCalendar: React.FC = () => {
  const user = useCurrentUser()
  const { moderatedStudy } = useModeratedStudyContext()
  const mainHost = useMemo(
    () => moderatedStudy.members.find((member) => member.role === "main_host"),
    [moderatedStudy.members]
  )
  const currentMember = moderatedStudy.members.find(
    (member) => member.id === user.id
  )
  const currentUserIsMainHost = currentMember?.id === mainHost?.id

  return (
    <Card.Root>
      <Card.Header title="Event calendar" />
      <Card.Body>
        <Stack spacing={6}>
          <Text as="p" m={0} textStyle="ds.paragraph.primary">
            An event will be created in this calendar for each session when it
            {"\u2019"}s booked. Hosts are added as guests on the session
            {"\u2019"}s calendar event.{" "}
            <Link
              isExternal
              href={Constants.HELP_CENTER_INTERVIEWS_ADDING_STUDY_DETAILS_URL}
              style={{ textDecoration: "none" }}
            >
              Learn more
            </Link>
          </Text>
          {currentUserIsMainHost ? (
            <MainHostSection
              moderatedStudy={moderatedStudy}
              currentMember={currentMember!}
            />
          ) : (
            <OtherHostSection
              moderatedStudy={moderatedStudy}
              mainHostName={mainHost?.name}
            />
          )}
        </Stack>
      </Card.Body>
    </Card.Root>
  )
}

const MainHostSection: React.FC<{
  moderatedStudy: ModeratedStudy
  currentMember: ModeratedStudyMember
}> = ({ moderatedStudy, currentMember }) => {
  const hasConnectedCalendars = useMemo(() => {
    return (
      currentMember?.has_google_oauth_credentials ||
      currentMember?.has_microsoft_oauth_credentials
    )
  }, [currentMember])

  const {
    teamMembersForm: {
      watch: bookingCalendarWatch,
      setValue: bookingCalendarSetValue,
    },
  } = useStudyDetails()
  const currentBookingCalendar = bookingCalendarWatch()
  const setBookingCalendar = (calendar: ModeratedStudyCalendar) => {
    bookingCalendarSetValue("bookingCalendarId", calendar.id, {
      shouldDirty: true,
    })
    bookingCalendarSetValue("bookingCalendarType", calendar.provider ?? null, {
      shouldDirty: true,
    })
    bookingCalendarSetValue("bookingCalendarSummary", calendar.summary, {
      shouldDirty: true,
    })
  }

  if (hasConnectedCalendars) {
    const bookingCalendarDropdown = (
      <BookingCalendarDropdown
        bookingCalendar={moderatedStudy.booking_calendar}
        newBookingCalendar={
          currentBookingCalendar.bookingCalendarId !==
          moderatedStudy.booking_calendar?.id
            ? {
                summary: currentBookingCalendar.bookingCalendarSummary ?? "",
                provider:
                  currentBookingCalendar.bookingCalendarType ?? undefined,
              }
            : null
        }
        currentMember={currentMember}
        setBookingCalendar={setBookingCalendar}
      />
    )
    if (moderatedStudy.booking_calendar) {
      return bookingCalendarDropdown
    } else {
      return (
        <AlertWrapper mainHostName={currentMember?.name}>
          {bookingCalendarDropdown}
        </AlertWrapper>
      )
    }
  } else {
    return <CalendarConnection member={currentMember} />
  }
}

const AlertWrapper: React.FC<
  PropsWithChildren<{ mainHostName: string | undefined }>
> = ({ mainHostName, children }) => {
  const mainHostLabel = mainHostName ? `(${mainHostName}) ` : ""
  return (
    <Box background="ds.background.information.subtle.resting" rounded={"8px"}>
      <Alert
        status="info"
        title="No calendar selected"
        description={`Only the main host ${mainHostLabel}can add an event calendar.`}
      />
      <Box mb={3} marginStart="52px">
        {children}
      </Box>
    </Box>
  )
}

const CalendarConnection: React.FC<{ member: ModeratedStudyMember }> = ({
  member,
}) => {
  const { open: openConflictCalendarModal } = useModal(ConflictCalendarModal)

  return (
    <Button
      variant="default"
      alignSelf="flex-start"
      leftIcon={<Icon as={InterviewCalendarIcon} boxSize={4} />}
      onClick={() =>
        openConflictCalendarModal({
          initialSelectedCalendars: member.conflict_calendars,
          hasGoogleCalendarError: member.has_google_calendar_error,
        })
      }
    >
      Connect a calendar
    </Button>
  )
}

const OtherHostSection: React.FC<{
  moderatedStudy: ModeratedStudy
  mainHostName: string | undefined
}> = ({ moderatedStudy, mainHostName }) => {
  const mainHostLabel = mainHostName ? `(${mainHostName}) ` : ""
  return moderatedStudy.booking_calendar ? (
    <Alert
      status="success"
      title={moderatedStudy.booking_calendar.summary}
      description={`Only the main host ${mainHostLabel}can edit the event calendar.`}
    />
  ) : (
    <Alert
      status="info"
      title="No calendar selected"
      description={`Only the main host ${mainHostLabel}can add an event calendar.`}
    />
  )
}
