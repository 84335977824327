import React, { useState } from "react"
import { useSelector } from "react-redux"

import { ThemedButton } from "Components/button/themed-button"
import { ClickPreview } from "Components/clickable-screenshot-overlay/ClickPreview"
import { ClickableScreenshotOverlay } from "Components/clickable-screenshot-overlay/clickable-screenshot-overlay"
import { DisplayInlineMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { getScreenshotClickBySectionScreenshotId } from "Redux/reducers/current-response/selectors"
import { useTranslate } from "Shared/hooks/useTranslate"
import { Point } from "Types"
import { ScreenshotMask } from "UsabilityHub/components/UsabilityTestSectionTask/ScreenshotMask"
import ConditionalScreenshotDeviceFrame from "UsabilityHub/components/UsabilityTestSectionTask/conditional-screenshot-device-frame"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import { reportError } from "Utilities/error"
import {
  isTaskComplete,
  isTaskStarted,
  sectionStartTime,
} from "Utilities/response"
import {
  SectionTask,
  SectionTaskContent,
  SectionTaskHeader,
  SectionTaskInstructions,
} from "../SectionTask"

export const FirstClickTestTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({
  addScreenshotClick,
  deviceFrame,
  responseSection,
  updateResponseSection,
  usabilityTestSection,
}) => {
  const translate = useTranslate()

  const [clickStartTime, setClickStartTime] = useState<number | null>(null)
  const usabilityTestSectionId = usabilityTestSection.id
  const sectionScreenshot = usabilityTestSection.section_screenshots[0]
  const screenshotClick = useSelector(
    getScreenshotClickBySectionScreenshotId(sectionScreenshot.id)
  )
  const isStarted = isTaskStarted(responseSection)
  const isComplete = isTaskComplete(responseSection)

  // -- Handlers --

  const handleClickStart = () => {
    const clickStartTime = performance.now()
    setClickStartTime(clickStartTime)

    updateResponseSection(usabilityTestSectionId, {
      instructions_duration_ms:
        clickStartTime - sectionStartTime(responseSection),
    })
  }

  const handleClick = (point: Point, timestamp: number) => {
    if (clickStartTime === null) {
      reportError(new Error(`added click before instructions were read`))
      return
    }

    // Time the click from the start of the task up until the time of the click.
    // This should not include the time confirming the click, but does
    // include the time spent placing changed clicks.
    const clickDurationMs = timestamp - clickStartTime
    addScreenshotClick({
      duration_ms: clickDurationMs,
      hit: null,
      usability_test_section_screenshot_id: sectionScreenshot.id,
      ...point,
    })
    updateResponseSection(usabilityTestSectionId, {
      task_duration_ms: clickDurationMs,
    })
  }

  return (
    <SectionTask>
      <SectionTaskHeader>
        <SectionTaskInstructions variant={isComplete ? "secondary" : "primary"}>
          <DisplayInlineMarkdownText text={usabilityTestSection.text} />
        </SectionTaskInstructions>
        {!isComplete && (
          <SectionTaskInstructions variant="secondary">
            {translate("test.instructions.click_test.click_image")}
          </SectionTaskInstructions>
        )}
        {!isStarted && (
          <ThemedButton onClick={handleClickStart}>
            {translate("test.buttons.click_to_view_image")}
          </ThemedButton>
        )}
      </SectionTaskHeader>
      <SectionTaskContent>
        <ConditionalScreenshotDeviceFrame
          screenshotId={sectionScreenshot.screenshot_id}
          deviceFrame={deviceFrame}
          isScrollable={isStarted}
          canSkipAheadOnFirstWatch
        >
          {isComplete && screenshotClick ? (
            <ClickPreview point={screenshotClick} />
          ) : isStarted ? (
            <ClickableScreenshotOverlay
              onClick={handleClick}
              requireClickConfirmation
            />
          ) : (
            <ScreenshotMask />
          )}
        </ConditionalScreenshotDeviceFrame>
      </SectionTaskContent>
    </SectionTask>
  )
}
