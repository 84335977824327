import { boolean, route, string, union } from "react-router-typesafe-routes/dom"

export const ROUTES = {
  ICONS: route("icons"),
  DASHBOARD: route(
    "dashboard",
    {
      searchParams: {
        tab: union("tests", "interviews"),
      },
    },
    {
      TEMPLATES: route(
        "templates",
        {},
        {
          DETAILS: route(":templateId", {
            params: { templateId: string() },
          }),
        }
      ),
    }
  ),
  SEARCH: route("search"),
  PROJECT: route("projects/:projectWithSlug", {
    params: { projectWithSlug: string().defined() },
  }),
  PROJECTS: route("projects"),
  TESTS: route(
    "tests",
    {},
    {
      NEW: route("new", {
        searchParams: {
          project_id: string(),
          template: string(),
        },
      }),
    }
  ),
  TEST: route(
    "tests/:testId",
    {
      params: { testId: string().defined() },
    },
    {
      EDIT: route("edit"),
      RECRUIT: route(
        "recruit",
        {},
        {
          ORDERS: route("orders"),
        }
      ),
      RESULTS: route("results"),
    }
  ),
  TEST_SET: route(
    "sets/:testSetId",
    { params: { testSetId: string().defined() } },
    {
      RECRUIT: route("recruit"),
    }
  ),
  INTERVIEW: route(
    "interviews/:moderatedStudyId",
    {
      params: { moderatedStudyId: string().defined() },
    },
    {
      EDIT: route(
        "edit",
        {},
        {
          DETAILS: route("details"),
          SCREENER: route("screener"),
          TEAM: route("team"),
          AVAILABILITY: route("availability"),
          EMAILS: route("emails"),
          LINK: route("link"),
          CALENDAR: route("calendar"),
        }
      ),
      RECRUIT: route(
        "recruit",
        {},
        {
          LINK: route(":moderatedStudyRecruitmentLinkId", {
            params: { moderatedStudyRecruitmentLinkId: string().defined() },
          }),
          ORDERS: route("orders", {}),
        }
      ),
      APPLICANTS: route("applicants"),
      SESSIONS: route("sessions"),
      RECORDINGS: route(
        "recordings",
        {},
        {
          SESSION: route(":sessionId", {
            params: { sessionId: string() },
          }),
        }
      ),
      PREVIEW: route(
        "preview",
        {
          searchParams: {
            recruitmentLinkId: string(),
          },
        },
        {
          APPLY: route("apply"),
          QUESTIONS: route("questions"),
          THANKYOU: route("thankyou"),
          CLOSED: route("closed"),
          BOOK: route("book"),
          CONFIRMED: route("confirmed"),
        }
      ),
    }
  ),
  CREDITS: route(
    "credits",
    {},
    { HISTORY: route("history"), PURCHASE: route("purchase") }
  ),
  CODES: route("codes", {}, { CLAIM: route("claim") }),
  SETTINGS: route(
    "settings",
    {},
    {
      BILLING: route(
        "billing",
        {
          searchParams: {
            showPlans: boolean(),
          },
        },
        { HISTORY: route("history") }
      ),
      SECURITY: route("security"),
      ORGANIZATION: route("organization"),
      FEATURE_ACCESS: route("feature-access"),
      TEST: route("test"),
      TEAM: route("team", {
        searchParams: {
          onboarding: boolean(),
        },
      }),
      PROFILE: route("profile"),
    }
  ),
  OAUTH: route(
    "",
    {},
    {
      SUCCESS: route("oauth_success"),
      ERROR: route("oauth_error"),
    }
  ),
  LEGAL: route(
    "",
    {},
    {
      TERMS: route("terms"),
      PRIVACY: route("privacy"),
      SECURITY: route(
        "security",
        {},
        {
          RESPONSIBLE_DISCLOSURE: route("responsible_disclosure"),
        }
      ),
      DPA: route("legal/dpa"),
      SUBPROCESSORS: route(
        "legal/sub-processors",
        { searchParams: { token: string() } },
        {
          UNSUBSCRIBE: route("unsubscribe"),
        }
      ),
      COOKIES: route("legal/cookies"),
    }
  ),
  PANEL_ESTIMATE: route("research-panel-calculator"),
  INTERVIEW_APPLICATIONS: route(
    "",
    {},
    {
      APPLY: route("apply/:moderatedStudyRecruitmentLinkToken", {
        params: {
          moderatedStudyRecruitmentLinkToken: string(),
        },
      }),
      APPLICATION: route(
        "applications/:moderatedStudyApplicationId",
        {
          params: {
            moderatedStudyApplicationId: string(),
          },
        },
        {
          WELCOME: route("welcome"),
          QUESTIONS: route("questions"),
          THANKYOU: route("thankyou"),
          CLOSED: route("closed"),
          BOOK: route("book"),
          CONFIRMED: route("confirmed"),
          RESCHEDULE: route("reschedule"),
          INVITATION_DECLINE: route("decline-invitation"),
          RESCHEDULE_DECLINE: route("decline-reschedule"),
          JOIN: route("join"),
        }
      ),
    }
  ),
  USERS: route(
    "users",
    {},
    {
      SIGN_IN: route("sign_in"),
      SAML_SIGN_IN: route("saml_sign_in"),
      SIGN_UP: route("sign_up"),
      PASSWORD: route(
        "password",
        {},
        {
          NEW: route("new"),
          EDIT: route("edit"),
        }
      ),
    }
  ),
  SETUP: route(
    "setup",
    {},
    {
      TRIAL: route("trial"),
      ONBOARDING: route(":stepId", {
        params: {
          // can't get this programmatically because of a circular requirement :(
          stepId: union("role", "research", "collaborators", "grapevine"),
        },
      }),
    }
  ),
  TEAM: route(
    "",
    {},
    {
      SIGN_UP: route("setup/team"),
    }
  ),
}
