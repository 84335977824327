import { Flex, Progress, Skeleton, Tag, Text } from "@chakra-ui/react"
import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { longWithTime } from "Utilities/date-formats"
import React from "react"
import { useGetModeratedStudyQuota } from "~/api/generated/usabilityhub-components"

type Props = {
  isFreePlan: boolean
}

export const ModeratedStudiesQuotaInformation: React.FC<Props> = ({
  isFreePlan,
}) => {
  const { data } = useGetModeratedStudyQuota({})

  return (
    <Card>
      <CardHeader>Usage</CardHeader>
      <CardBody>
        <Flex direction="column" gap={6}>
          <QuotaBar
            label="Video storage"
            data={data?.video_storage_gb}
            unit="GB"
            periodEnd={data?.period_end}
          />

          <QuotaBar
            label="Transcription hours"
            isMonthly={!isFreePlan}
            data={data?.transcription_hours}
            unit=" hours"
            periodEnd={data?.period_end}
          />

          <QuotaBar
            label="Interview sessions from your network"
            isMonthly
            data={data?.self_recruited_sessions}
            unit=" sessions"
            periodEnd={data?.period_end}
          />
        </Flex>
      </CardBody>
    </Card>
  )
}

const QuotaBar: React.FC<{
  label: string
  data?: { quota: number; used: number }
  unit: string
  isMonthly?: boolean
  periodEnd?: string
}> = ({ label, data, unit, isMonthly = false, periodEnd }) => {
  return (
    <Flex direction="column">
      <Flex justify="space-between" align="center" mb={4}>
        <Text fontWeight="bold" fontSize="lg" color="text.subtle" mb={4}>
          {label}
        </Text>

        {isMonthly && periodEnd && (
          <Tag>Resets {longWithTime(new Date(periodEnd))}</Tag>
        )}
      </Flex>

      <Flex justify="space-between" mb={2}>
        {data ? (
          <Text fontSize="sm" color="text.subtle">
            {Math.round(data.used)}
            {unit} used
          </Text>
        ) : (
          <Skeleton w="100px" h="21px" />
        )}

        {data ? (
          <Text fontSize="sm" color="text.subtle">
            {Math.max(Math.round(data.quota - data.used), 0)}
            {unit} available
          </Text>
        ) : (
          <Skeleton w="150px" h="21px" />
        )}
      </Flex>

      <Progress
        h={2}
        rounded="md"
        colorScheme="teal"
        isIndeterminate={!data}
        value={data?.used}
        max={data?.quota}
      />
    </Flex>
  )
}
