import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import React from "react"
import { useMatch, useNavigate } from "react-router-dom"

import emptyAllSecondarySrc from "Images/app-illustrations/lyssna/empty-all.png"
import { ROUTES } from "../routes"

interface Props {
  readonly isUserAllowedToCreateTests: boolean
  createTestHref?: string
}

export const EmptyStateNoTests: React.FC<Props> = ({
  isUserAllowedToCreateTests,
  createTestHref,
}) => {
  const navigate = useNavigate()

  const illustration = (
    <Image
      src={emptyAllSecondarySrc}
      transform="auto"
      translateX="10px"
      boxSize="6.5rem"
    />
  )

  // If we're on an individual project page, let's pass that through to the test builder as a query
  // param so that it can pre-select the project.
  const projectIdWithSlug = useMatch("/projects/:idWithSlug")?.params.idWithSlug
  const viewingProjectId = projectIdWithSlug
    ?.replaceAll(/[^0-9a-f]+/gi, "")
    .slice(-32)
  const createTestUrl = ROUTES.TESTS.NEW.buildPath(
    {},
    viewingProjectId ? { project_id: viewingProjectId } : {}
  )

  return (
    <Flex align="center" flexDirection="column" gap={8} color="text.primary">
      {isUserAllowedToCreateTests ? (
        <a href={createTestHref}>{illustration}</a>
      ) : (
        illustration
      )}
      <Flex flexDirection="column" gap={2}>
        <Heading textAlign="center" as="h2" size="lg" fontWeight="medium">
          You have no tests
        </Heading>

        <Text textAlign="center" w={["13.5rem", "auto"]}>
          Looks like you haven{"\u2019"}t created a test yet.
          {isUserAllowedToCreateTests && (
            <>
              <br />
              You can create one by clicking the button below.
            </>
          )}
        </Text>
      </Flex>

      {isUserAllowedToCreateTests && (
        <Button
          onClick={() => navigate(createTestUrl)}
          colorScheme="brand.primary"
        >
          Create new test
        </Button>
      )}
    </Flex>
  )
}
