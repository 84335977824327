import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { FunctionalModal } from "Utilities/modals/types"
import { useDeleteVariationSet } from "~/api/generated/usabilityhub-components"

interface Props {
  variationSet: {
    unique_id: string
    name: string
  }
  onSuccess?: () => void
}

export const DeleteVariationSetModal: FunctionalModal<Props> = ({
  variationSet,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutateAsync: deleteVariationSet, isLoading } = useDeleteVariationSet({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
      void queryClient.invalidateQueries(["api", "test_sets", "variation_sets"])
    },
  })

  const onSubmit = async () => {
    try {
      await deleteVariationSet({
        pathParams: { uniqueId: variationSet.unique_id },
      })

      toast({
        title: "Variation set deleted",
        description: `Deleted variation set ${variationSet.name}`,
        status: "success",
        duration: 3000,
      })

      onClose()
      onSuccess?.()
    } catch (error) {
      toast({
        title: "Variation set delete failed",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-qa="delete-test-modal">
        <JsForm onSubmit={onSubmit}>
          <ModalHeader>Delete this variation set?</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to permanently delete this set -{" "}
              {variationSet.name}? This action cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <SubmitButton
                colorScheme="red"
                isLoading={isLoading}
                loadingAction="Deleting"
              >
                Delete
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </JsForm>
      </ModalContent>
    </Modal>
  )
}
