import {
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from "@chakra-ui/react"
import { ExternalLinkIcon } from "@heroicons/react/outline"
import { useQueryClient } from "@tanstack/react-query"
import { DeleteStudyModal } from "Components/delete-usability-test-modal/DeleteStudyModal"
import { DuplicateStudyModal } from "Components/duplicate-usability-test-modal/DuplicateStudyModal"
import { IconButton } from "DesignSystem/components"
import { DotsVerticalIcon } from "Shared/icons/untitled-ui/DotsVerticalIcon"
import { ArchiveStudyModal } from "UsabilityHub/components/ArchiveStudyModal/ArchiveStudyModal"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { useMatch, useNavigate } from "react-router"
import FrontendApi from "~/api/frontendApi"
import {
  useUnarchiveUsabilityTest,
  useUsabilityTestPage,
} from "~/api/generated/usabilityhub-components"
import { DashboardUsabilityTest } from "~/api/generated/usabilityhubSchemas"
import PreviewsApi from "~/api/previewsApi"
import { MoveUsabilityTestsModal } from "./MoveUsabilityTestsModal"
import { RenameUsabilityTestModal } from "./RenameUsabilityTestModal"

// Only the data that is required to render this component, since it is called from
// a few different places which have access to different types.
type MinimalTestType = Pick<
  DashboardUsabilityTest,
  | "id"
  | "name"
  | "unique_id"
  | "archived"
  | "project"
  | "variation_set"
  | "has_active_third_party_orders"
> &
  (Pick<DashboardUsabilityTest, "sections"> | { is_external_study: boolean })

type Props = {
  test: MinimalTestType
  isDisabled?: boolean
  redirectAfterDeletion?: boolean
}

export const UsabilityTestContextMenu: React.FC<Props> = ({
  test,
  isDisabled = false,
  redirectAfterDeletion = false,
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const isOnTestBuilder = !!useMatch("/tests/:id/edit")
  const navigate = useNavigate()

  const { data: pageData, status: pageStatus } = useUsabilityTestPage({
    pathParams: { usabilityTestId: test.unique_id },
  })

  const userCanManageTests = useCurrentUser().can_manage_tests

  // External studies can't be previewed in their initial state since they are not valid when created.
  // Disable the preview link in that case.
  const canPreview =
    pageStatus === "success" &&
    (!pageData.usability_test.is_external_study ||
      pageData.usability_test.is_valid_external_study)

  const { open: openDuplicateTestModal } = useModal(DuplicateStudyModal)
  const { open: openDeleteTestModal } = useModal(DeleteStudyModal)
  const { open: openArchiveTestModal } = useModal(ArchiveStudyModal)
  const { open: openMoveModal } = useModal(MoveUsabilityTestsModal)
  const { open: openRenameTestModal } = useModal(RenameUsabilityTestModal)

  const { mutateAsync: unarchiveStudy } = useUnarchiveUsabilityTest({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
    },
  })

  const onUnarchiveStudy = async () => {
    try {
      await unarchiveStudy({
        pathParams: { usabilityTestId: test.id.toString() },
      })

      toast({
        title: "Test unarchived",
        description: `Unarchived test ${test.name}`,
        status: "success",
        duration: 3000,
      })
    } catch (error) {
      toast({
        title: "Test unarchive failed",
        status: "error",
        duration: 3000,
      })
    }
  }
  return (
    <Menu placement="bottom-end" isLazy>
      <MenuButton
        data-qa="test-row-context-menu-button"
        as={IconButton}
        isDisabled={isDisabled}
        aria-label="Options"
        icon={<DotsVerticalIcon boxSize={4} />}
        variant="subtle"
        onClick={(e) => e.stopPropagation()}
        zIndex={1}
      />
      <Portal>
        <MenuList
          onClick={(e) => e.stopPropagation()}
          overflow="hidden"
          zIndex="dropdown"
        >
          <MenuItem
            as="a"
            target="_blank"
            isDisabled={!canPreview}
            href={PreviewsApi.test.path({
              unique_id: test.unique_id,
            })}
            justifyContent="space-between"
          >
            Preview
            <Icon
              as={ExternalLinkIcon}
              alignSelf="center"
              boxSize={4}
              color="ds.icon.subtle"
            />
          </MenuItem>
          {userCanManageTests && (
            <>
              <MenuDivider m={0} />
              {!isOnTestBuilder && (
                <MenuItem
                  onClick={() => openRenameTestModal({ usabilityTest: test })}
                >
                  Rename
                </MenuItem>
              )}
              <MenuItem
                as="a"
                href={FrontendApi.usabilityhub.path({
                  id: test.unique_id,
                })}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => openDuplicateTestModal({ usabilityTest: test })}
              >
                Duplicate
              </MenuItem>
              {!isOnTestBuilder && (
                <MenuItem
                  onClick={() => {
                    openMoveModal({
                      testIds: [test.id],
                    })
                  }}
                >
                  Move
                </MenuItem>
              )}
              {test.archived ? (
                <MenuItem onClick={onUnarchiveStudy}>Unarchive</MenuItem>
              ) : (
                <MenuItem
                  onClick={() =>
                    openArchiveTestModal({
                      usabilityTestId: test.id,
                      name: test.name,
                      hasActiveThirdPartyOrders:
                        test.has_active_third_party_orders,
                    })
                  }
                >
                  Archive
                </MenuItem>
              )}
              <MenuItem
                color="red.600"
                onClick={() =>
                  openDeleteTestModal({
                    tests: [
                      {
                        usabilityTestId: test.id,
                        name: test.name,
                      },
                    ],
                    // Redirect to the dashboard if redirect flag is set to true
                    onSuccess: () => {
                      if (redirectAfterDeletion) navigate("/dashboard")
                    },
                  })
                }
              >
                Delete
              </MenuItem>
            </>
          )}
        </MenuList>
      </Portal>{" "}
    </Menu>
  )
}
