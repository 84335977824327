// JsFromRoutes CacheKey f7ee56f02f844f8a69721df97b211aa3
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  upcomingInvoice: /* #__PURE__ */ definePathHelper('get', '/settings/upcoming_invoice'),
  stripeInvoice: /* #__PURE__ */ definePathHelper('get', '/settings/stripe_invoice/:id'),
  billing: /* #__PURE__ */ definePathHelper('patch', '/settings/billing'),
  passwordComplexity: /* #__PURE__ */ definePathHelper('patch', '/settings/password_complexity'),
}
