import { Flex, Icon } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import React, { PropsWithChildren } from "react"

import { MicrophoneIcon } from "Shared/icons/MicrophoneIcon"
import { MonitorIcon } from "Shared/icons/MonitorIcon"
import { VideoRecorderOutlineIcon } from "Shared/icons/untitled-ui/VideoRecorderOutlineIcon"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"

interface Props {
  allowedRecordingTypes: RecordingType[]
}

export const RecordingRequirements: React.FC<Props> = ({
  allowedRecordingTypes = [],
}) => {
  const translate = useTranslate()

  const camera = allowedRecordingTypes?.includes("camera")
  const microphone = allowedRecordingTypes?.includes("microphone")

  return (
    <>
      {allowedRecordingTypes?.includes("screen") && (
        <RecordingsPermissionItem>
          <Icon as={MonitorIcon} textColor="ds.icon.default" boxSize={6} />
          {translate("test.recording.screen.label")}
        </RecordingsPermissionItem>
      )}
      {camera && microphone ? (
        // Displays Camera and Audio requirement
        <RecordingsPermissionItem>
          <Icon
            as={VideoRecorderOutlineIcon}
            textColor="ds.icon.default"
            boxSize={6}
          />
          {translate("test.recording.camera.label")}
        </RecordingsPermissionItem>
      ) : (
        microphone && (
          <RecordingsPermissionItem>
            <Icon as={MicrophoneIcon} textColor="ds.icon.default" boxSize={6} />
            {translate("test.recording.microphone.label")}
          </RecordingsPermissionItem>
        )
      )}
    </>
  )
}

const RecordingsPermissionItem: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Flex
    p={6}
    borderRadius={4}
    border="1px solid"
    borderColor="border.default"
    fontWeight="medium"
    color="ds.text.default"
    gap={4}
    alignItems="center"
  >
    {children}
  </Flex>
)
