import {
  Box,
  HStack,
  Text,
  UseCheckboxProps,
  forwardRef,
  useCheckbox,
} from "@chakra-ui/react"
import { layerStyles } from "DesignSystem/layerStyles"
import React, { PropsWithChildren } from "react"

export type CheckboxProps = PropsWithChildren<
  UseCheckboxProps & {
    onCheckedChange?: (checked: boolean) => void
  }
>

export const Checkbox = forwardRef<CheckboxProps, "input">(
  ({ children, onCheckedChange, width = "max-content", ...props }, ref) => {
    const { state, getInputProps } = useCheckbox(props)

    const { style, isChecked, isDisabled, ...inputProps } = getInputProps(
      props,
      ref
    )

    const changed = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkbox = event.currentTarget
      if (onCheckedChange && state.isChecked !== checkbox.checked) {
        onCheckedChange(checkbox.checked)
      }
    }

    const filled = state.isChecked || state.isIndeterminate

    return (
      <HStack as="label" gap="1" alignItems="start" data-checkbox w={width}>
        <Box
          position="relative"
          boxSize={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexShrink={0}
        >
          <Box
            as="svg"
            boxSize="0.875rem"
            rounded={2}
            viewBox="0 0 14 14"
            layerStyle={state.isFocused ? "focused" : undefined}
            sx={
              state.isDisabled
                ? undefined
                : {
                    "[data-checkbox]:has(:active) &": layerStyles.focused,
                  }
            }
          >
            <Box
              as="rect"
              fill={
                filled
                  ? state.isDisabled
                    ? "ds.background.disabled"
                    : "ds.background.selected.bold.resting"
                  : "ds.background.input.resting"
              }
              stroke={
                filled
                  ? "none"
                  : state.isDisabled
                    ? "ds.border.disabled"
                    : "ds.border.input"
              }
              strokeWidth={4}
              r={2}
              x={0}
              y={0}
              width="14px"
              height="14px"
              sx={{
                "[data-checkbox]:hover &, [data-checkbox]:has(:active) &": {
                  fill: filled
                    ? state.isDisabled
                      ? "ds.background.disabled"
                      : "ds.background.selected.bold.hovered"
                    : state.isDisabled
                      ? "ds.background.input.resting"
                      : "ds.background.input.hovered",
                  stroke: filled
                    ? "none"
                    : state.isDisabled
                      ? "ds.border.disabled"
                      : "ds.border.hovered",
                },
              }}
            />
            <Box
              as="path"
              d="M4,7l2,2l4-4"
              pathLength={1}
              stroke="ds.icon.inverse"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
              strokeDasharray={1}
              strokeDashoffset={
                state.isChecked && !state.isIndeterminate ? 0 : 1
              }
              transition={
                state.isChecked ? "stroke-dashoffset 0.2s ease-in" : "none"
              }
            />
            {state.isIndeterminate && (
              <Box
                as="path"
                d="M4,7h6"
                stroke="ds.icon.inverse"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
              />
            )}
          </Box>
          <Box
            as="input"
            type="checkbox"
            {...inputProps}
            pos="absolute"
            inset={0}
            w="full"
            h="full"
            opacity={0}
            cursor="pointer"
            onChange={changed}
          />
        </Box>
        <Text
          as="div"
          textStyle="ds.paragraph.primary"
          color={state.isDisabled ? "ds.text.disabled" : "ds.text.default"}
          py="0.125rem"
          flexGrow={1}
        >
          {children}
        </Text>
      </HStack>
    )
  }
)
