import { RawResponseSectionRecording } from "JavaScripts/types/recording"
import { reject } from "lodash"

import { Actions } from "Redux/reducers/test-results/action-creators"
import { ActionType } from "Redux/reducers/test-results/constants"
import {
  AnswerTag,
  CardSortCategoryCard,
  CardSortOpenCategoriesRanking,
  NoopAction,
  PublicTestResultsAccount,
  RawQuestionTag,
  Response,
  ResponseAnswer,
  ResponseSection,
  ResponseSectionFigmaTask,
  ResponseSectionTreeTestPath,
  ScreenshotClick,
  TestResultsFigmaFileVersion,
  TestResultsOrder,
  UsabilityTest,
  UsabilityTestSection,
} from "Types"
import { reportError } from "Utilities/error"
import { createArrayUtility } from "Utilities/immutable-array"

type ReviewInfo = {
  allReviewerNames: string[]
  reviewerName: string | null
  reviewerIsSelf: boolean
  reviewerAssignedAt: string | null
}

export interface PresentRawTestResultsState {
  account: Readonly<PublicTestResultsAccount>
  isReadonly: boolean
  isReview: boolean
  isRevising: boolean
  orders: readonly TestResultsOrder[]
  responseAnswers: ReadonlyArray<Readonly<ResponseAnswer>>
  responseSectionFigmaTasks: ReadonlyArray<Readonly<ResponseSectionFigmaTask>>
  responseSectionCardSorts: CardSortCategoryCard[]
  cardSortOpenCategoriesRanking: CardSortOpenCategoriesRanking
  responseSectionTreeTestPaths: ReadonlyArray<ResponseSectionTreeTestPath>
  responseSectionRecordings: RawResponseSectionRecording[]
  responseSections: ReadonlyArray<Readonly<ResponseSection>>
  responses: ReadonlyArray<Readonly<Response>>
  responsesCount: number
  hiddenResponseCount: number
  responsesLimit: number
  reviewingOrderId: number | null
  screenshotClicks: ReadonlyArray<Readonly<ScreenshotClick>>
  answerTags: ReadonlyArray<Readonly<AnswerTag>>
  usabilityTest: Readonly<UsabilityTest>
  figmaFileVersions: TestResultsFigmaFileVersion[]
  reviewInfo: ReviewInfo | null
}

interface PresentTestResultsState extends PresentRawTestResultsState {
  isSavingReview: boolean
  isTagging: boolean
}

export type TestResultsState = null | PresentTestResultsState
export type RawTestResultsState = undefined | PresentRawTestResultsState

const responseArray = createArrayUtility<Response>("test results response")
const questionTagArray = createArrayUtility<RawQuestionTag>("question tag")
const testSectionArray =
  createArrayUtility<UsabilityTestSection>("test section")

export default function testResults(
  state: Readonly<TestResultsState> = null,
  action: Actions | NoopAction = { type: "__NOOP__" }
): Readonly<TestResultsState> {
  if (action.type === ActionType.INITIALIZE_TEST_RESULTS) return action.payload

  if (state === null) return state

  switch (action.type) {
    case ActionType.UPDATE_ORDER_RESPONSES: {
      const { reviewStatus, responseId } = action.payload
      const responses = responseArray.mergeFirst(
        state.responses,
        { id: responseId },
        { review_status: reviewStatus }
      )
      return { ...state, responses }
    }
    case ActionType.SAVE_ORDER_RESPONSES_REVIEW_REQUEST:
      return { ...state, isSavingReview: true }
    case ActionType.SAVE_ORDER_RESPONSES_REVIEW_FAILURE:
    case ActionType.SAVE_ORDER_RESPONSES_REVIEW_SUCCESS:
      return { ...state, isSavingReview: false }
    case ActionType.DELETE_RESPONSE_SUCCESS: {
      return {
        ...state,
        responses: reject(state.responses, { id: action.payload }),
        responseAnswers: reject(state.responseAnswers, {
          response_id: action.payload,
        }),
        screenshotClicks: reject(state.screenshotClicks, {
          response_id: action.payload,
        }),
        responseSections: reject(state.responseSections, {
          response_id: action.payload,
        }),
      }
    }
    case ActionType.REMOVE_QUESTION_TAG_REQUEST:
    case ActionType.TAG_ANSWERS_REQUEST:
    case ActionType.CREATE_QUESTION_TAG_REQUEST:
    case ActionType.UPDATE_QUESTION_TAG_REQUEST:
      return { ...state, isTagging: true }
    case ActionType.REMOVE_QUESTION_TAG_FAILURE:
    case ActionType.TAG_ANSWERS_FAILURE:
    case ActionType.CREATE_QUESTION_TAG_FAILURE:
    case ActionType.UPDATE_QUESTION_TAG_FAILURE:
      return { ...state, isTagging: false }
    case ActionType.CREATE_QUESTION_TAG_SUCCESS: {
      // Add question tag...
      const questionId =
        action.payload.questionTag.usability_test_section_question_id
      let questionIndex = -1
      const sectionIndex = state.usabilityTest.sections.findIndex((s) => {
        questionIndex = s.questions.findIndex(
          (question) => question.id === questionId
        )
        return questionIndex !== -1
      })
      if (sectionIndex === -1) {
        reportError(new Error(`Unable to find question with ID ${questionId}`))
        return state
      }
      const section = state.usabilityTest.sections[sectionIndex]
      const nextQuestions = [...section.questions]
      nextQuestions[questionIndex] = {
        ...nextQuestions[questionIndex],
        question_tags: [
          ...nextQuestions[questionIndex].question_tags,
          action.payload.questionTag,
        ],
      }
      const nextSections = [...state.usabilityTest.sections]
      nextSections[sectionIndex] = {
        ...nextSections[sectionIndex],
        questions: nextQuestions,
      }
      const nextUsabilityTest = {
        ...state.usabilityTest,
        sections: nextSections,
      }

      return {
        ...state,
        isTagging: false,
        usabilityTest: nextUsabilityTest,
        answerTags: [...state.answerTags, ...action.payload.answerTags],
      }
    }
    case ActionType.UPDATE_QUESTION_TAG_SUCCESS: {
      const { questionTag } = action.payload
      return {
        ...state,
        isTagging: false,
        // Update question tag
        usabilityTest: {
          ...state.usabilityTest,
          sections: testSectionArray.updateFirst(
            state.usabilityTest.sections,
            (section) =>
              section.questions.some(
                (question) =>
                  question.id === questionTag.usability_test_section_question_id
              ),
            (section) => ({
              ...section,
              questions: section.questions.map((question) =>
                question.id === questionTag.usability_test_section_question_id
                  ? {
                      ...question,
                      question_tags: questionTagArray.updateFirst(
                        question.question_tags,
                        { id: questionTag.id },
                        () => action.payload.questionTag
                      ),
                    }
                  : question
              ),
            })
          ) as UsabilityTestSection[],
        },
      }
    }
    case ActionType.TAG_ANSWERS_SUCCESS: {
      const newAnswerTags = action.payload.answerTags.filter(
        (newAnswerTag: { id: number }) => {
          return state.answerTags.every(
            (answerTag) => answerTag.id !== newAnswerTag.id
          )
        }
      )

      return {
        ...state,
        isTagging: false,
        answerTags: [...state.answerTags, ...newAnswerTags],
      }
    }
    case ActionType.UNTAG_ANSWERS_SUCCESS: {
      const { answerIds, questionTagId } = action.payload
      const nextAnswerTags = state.answerTags.filter((answerTag) => {
        return !(
          answerIds.includes(answerTag.response_answer_id) &&
          answerTag.question_tag_id === questionTagId
        )
      })
      return {
        ...state,
        isTagging: false,
        answerTags: nextAnswerTags,
      }
    }
    case ActionType.REMOVE_QUESTION_TAG_SUCCESS: {
      const { questionTag } = action.payload

      // Remove answer tags
      const nextAnswerTags = state.answerTags.filter((answerTag) => {
        return answerTag.question_tag_id !== questionTag.id
      })

      return {
        ...state,
        isTagging: false,
        answerTags: nextAnswerTags,
        // Remove question tag
        usabilityTest: {
          ...state.usabilityTest,
          sections: testSectionArray.updateFirst(
            state.usabilityTest.sections,
            (section) =>
              section.questions.some(
                (question) =>
                  question.id === questionTag.usability_test_section_question_id
              ),
            (section) => ({
              ...section,
              questions: section.questions.map((question) =>
                question.id === questionTag.usability_test_section_question_id
                  ? {
                      ...question,
                      question_tags: questionTagArray.removeFirst(
                        question.question_tags,
                        { id: questionTag.id }
                      ),
                    }
                  : question
              ),
            })
          ) as UsabilityTestSection[],
        },
      }
    }
    default:
      return state
  }
}
