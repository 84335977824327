import { Box, Flex, Heading, Link, Select, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import React from "react"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"
import { DemographicTargetingSummary } from "./DemographicTargetingSummary"
import { ExternalPanelTab } from "./ExternalPanelOrders/ExternalPanelTab"
import { LyssnaPanelTab } from "./LyssnaPanelOrders/LyssnaPanelTab"
import { useOrderForm } from "./OrderFormProvider"
import { TestTargetingSummary } from "./TestTargetingSummary"
import { Panel } from "./types"

interface Props {
  demographics: DemographicAttributeGroup[]
  panel: Panel
  setPanel: (panel: Panel) => void
}

export const OrderSummaryPanel: React.FC<React.PropsWithChildren<Props>> = ({
  demographics,
  panel,
  setPanel,
}) => {
  const {
    ageRange,
    targetLocations,
    selectedOptionIds,
    setFocusAttributeId,
    clearAgeRange,
    clearTargetLocations,
    clearAllOptionsForAttribute,
    estimatedIncidenceRate,
    setEstimatedIncidenceRate,
    testHasScreener,
    testHasRecordings,
    testIsExternal,
  } = useOrderForm()

  const activeDemographicAttributes = demographics.flatMap((group) => {
    return group.demographic_attributes
      .map((attribute) => ({
        ...attribute,
        options: attribute.options?.filter((option) =>
          selectedOptionIds.includes(option.id)
        ),
      }))
      .filter((attribute) => attribute.options?.length)
  })

  return (
    <Box
      pos="sticky"
      top="4rem"
      h="fit-content"
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="md"
      bg="white"
    >
      <Heading
        as="h2"
        color="text.primary"
        fontSize="18px"
        fontWeight="semibold"
        mx={4}
        my={6}
      >
        Order summary
      </Heading>

      <Flex
        justify="space-between"
        align="center"
        borderBottomWidth={1}
        borderColor="gray.200"
        px={4}
        py={3}
      >
        <Flex align="center">
          <Text>Participant source</Text>
          {testIsExternal ? (
            <HelpPopover>
              Only the Lyssna panel is available when working with external
              studies.
            </HelpPopover>
          ) : (
            <HelpPopover>
              Consider using the External panel when the Lyssna panel isn't able
              to provide enough participants.{" "}
              <Link
                isExternal
                href={Constants.HELP_CENTER_EXTERNAL_PANEL_ORDERS_URL}
              >
                Learn more
              </Link>
              .
            </HelpPopover>
          )}
        </Flex>
        {testIsExternal ? (
          <Text>Lyssna panel</Text>
        ) : (
          <Select
            name="participant_source"
            data-intercom-target="external-panel-select"
            w="fit-content"
            value={panel}
            onChange={(e) => {
              if (isStringAPanel(e.target.value)) {
                setPanel(e.target.value)
              }
            }}
          >
            <option value="usabilityhub">Lyssna panel</option>
            <option value="external">External panel</option>
          </Select>
        )}
      </Flex>

      <Box data-intercom-target="order-summary">
        <TestTargetingSummary
          screenerEnabled={testHasScreener}
          recordingEnabled={testHasRecordings}
          setEstimatedIncidenceRate={setEstimatedIncidenceRate}
          estimatedIncidenceRate={estimatedIncidenceRate}
          missingIncidenceRate={estimatedIncidenceRate === null}
        />

        <DemographicTargetingSummary
          showPresets
          ageRange={ageRange}
          targetLocations={targetLocations}
          activeDemographicAttributes={activeDemographicAttributes}
          setFocusAttributeId={setFocusAttributeId}
          validationErrors={{}} // TODO: ideally get validationErrors back here too
          clearAgeRange={clearAgeRange}
          clearTargetLocations={clearTargetLocations}
          clearDemographicAttribute={clearAllOptionsForAttribute}
        />
      </Box>

      <Box p={4}>
        {panel === "usabilityhub" && <LyssnaPanelTab setPanel={setPanel} />}
        {panel === "external" && <ExternalPanelTab />}
      </Box>
    </Box>
  )
}

const isStringAPanel = (panel: string): panel is Panel => {
  return panel === "usabilityhub" || panel === "external"
}
