import { Stack } from "@chakra-ui/react"
import React, { useRef, useState } from "react"
import { GetModeratedStudyBookingRecordingsResponse } from "~/api/generated/usabilityhub-components"
import { Transcript } from "./Transcript"
import { VideoPlayer, VideoPlayerProps } from "./VideoPlayer"

type RecordingProps = {
  recording: GetModeratedStudyBookingRecordingsResponse[number]
} & Pick<VideoPlayerProps, "onDelete" | "onRename">

export const Recording: React.FC<RecordingProps> = ({
  recording,
  ...props
}) => {
  const video = useRef<HTMLVideoElement>(null)

  const seek = (time: number) => {
    const media = video.current
    if (!media) return

    media.currentTime = time / 1000
    if (media.paused) media.play()
  }

  const [time, setTime] = useState(0)

  const [playing, setPlaying] = useState(false)

  const timeUpdate = () => {
    const media = video.current
    if (!media) return

    setTime(media.currentTime * 1000)
  }

  return (
    <Stack spacing={4} maxH="85vh" display="grid" gridTemplateRows="auto 1fr">
      <VideoPlayer
        recording={recording}
        w="100%"
        height="auto"
        ref={video}
        onPlaying={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
        onTimeUpdate={timeUpdate}
        {...props}
      />
      <Transcript
        recording={recording}
        time={time}
        playing={playing}
        onSeek={seek}
      />
    </Stack>
  )
}
