import { Alert, AlertIcon, Text } from "@chakra-ui/react"
import React from "react"

export const ValidationError: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => (
  <Alert status="warning">
    <AlertIcon />
    <Text>{children}</Text>
  </Alert>
)
