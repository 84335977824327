import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react"
import { Button, Heading, Tag } from "DesignSystem/components"
import { VariationIcon } from "Shared/icons/VariationIcon"
import { ArrowCircleRightSolidIcon } from "Shared/icons/untitled-ui/ArrowCircleRightSolidIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { Cursor04SolidIcon } from "Shared/icons/untitled-ui/Cursor04SolidIcon"
import { FolderOutlineIcon } from "Shared/icons/untitled-ui/FolderOutlineIcon"
import { Laptop01OutlineIcon } from "Shared/icons/untitled-ui/Laptop01OutlineIcon"
import { Beacon } from "UsabilityHub/components/Beacon"
import Created from "UsabilityHub/components/Created"
import { UsabilityTestContextMenu } from "UsabilityHub/components/UsabilityTestContextMenu/UsabilityTestContextMenu"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { isExternalStudy } from "UsabilityHub/utils/isExternalStudy"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Link } from "react-router-dom"
import { DashboardUsabilityTest } from "~/api/generated/usabilityhubSchemas"

type SelectableTestRow = {
  isSelectable: true
  isSelected: boolean
  handleSelect: (newValue: boolean) => void
}

type UnselectableTestRow = {
  isSelectable: false
}

type Props = {
  test: DashboardUsabilityTest
  showProjectInfo?: boolean
  showVariationSetInfo?: boolean
  disableContextMenu: boolean
  combineAdjacent?: boolean
} & (SelectableTestRow | UnselectableTestRow)

export const testRowGridTemplateColumns = [
  "auto 2rem",
  "auto 2rem",
  "2rem 1fr 7rem 12.5rem 3.5rem",
  "2rem 1fr 7rem 12.5rem 3.5rem",
  "2rem 1fr 6rem 12.5rem 12rem 8rem 4rem",
]

const ROW_Y_PADDING = 6

// We need these to allow each grid cell to have a child anchor that spans the
// full width and height without interfering with other interactive elements in
// the cell
const testRowMainAnchorStyles = {
  tabIndex: -1,
  _before: {
    content: `""`,
    position: "absolute",
    inset: 0,
    zIndex: 0,
  },
}

export const TestRow: React.FC<Props> = ({
  test,
  disableContextMenu,
  showProjectInfo = true,
  showVariationSetInfo = true,
  combineAdjacent = false,
  ...props
}) => {
  const userCanManageTests = useCurrentUser().can_manage_tests
  const testLink = `/tests/${test.unique_id}/results`

  const [isLargerThan48em] = useMediaQuery("(min-width: 48em)", { ssr: false })

  const ResponseCountAndStatusCompact = () => (
    <Flex
      wrap="wrap"
      alignItems="baseline"
      columnGap={5}
      mt={2}
      mb={[0, null, 2]}
    >
      <Flex justifyContent="center" alignItems="baseline">
        <Text textStyle="ds.heading.primary" color="ds.text.default">
          {test.response_count}
        </Text>
        <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle" ms={1}>
          {test.response_count === 1 ? "Response" : "Responses"}
        </Text>
      </Flex>
      <Flex flexDir="column" justifyContent="center">
        <TestStatus status={test.status} showDetail={false} />
      </Flex>
    </Flex>
  )

  const ResponseCountAndStatusLarge = () => (
    <>
      <Flex pos="relative">
        <Flex as={Link} to={testLink} {...testRowMainAnchorStyles} />
        <Flex
          flexDir="column"
          basis="full"
          py={ROW_Y_PADDING}
          justifyContent="center"
        >
          <Text textStyle="ds.heading.secondary" color="ds.text.default">
            {test.response_count}
          </Text>
          <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle">
            {test.response_count === 1 ? "Response" : "Responses"}
          </Text>
        </Flex>
      </Flex>
      <Flex pos="relative">
        <Flex as={Link} to={testLink} {...testRowMainAnchorStyles} />
        <Flex
          flexDir="column"
          basis="full"
          py={ROW_Y_PADDING}
          justifyContent="center"
        >
          <TestStatus
            status={test.status}
            statusTags={test.status_tags}
            showDetail
          />
        </Flex>
      </Flex>
    </>
  )

  return (
    <Flex
      minH="88px"
      zIndex={0}
      align="stretch"
      rounded="16px"
      shadow={combineAdjacent ? undefined : "ds.raised"}
      bg={test.archived ? "gray.100" : "white"}
      basis="100%"
      ps={[4, null, 0]}
      pe={[2, null, 4]}
      pos="relative"
      cursor="pointer"
      outline={props.isSelectable && props.isSelected ? "1px solid" : undefined}
      outlineColor="ds.border.selected"
      outlineOffset={-1}
      borderWidth={1}
      borderColor="transparent"
      _hover={{
        borderColor: "ds.border.hovered",
      }}
      data-qa="test-row"
      role="group"
      sx={{
        // When two test rows are placed next to each other they meld together visually
        // (for example, inside a test set)
        borderRadius: combineAdjacent ? 0 : undefined,
        "&:last-of-type": {
          borderBottomRadius: combineAdjacent ? "16px" : undefined,
        },
      }}
    >
      <Grid flexGrow="1" gridTemplateColumns={testRowGridTemplateColumns}>
        {isLargerThan48em &&
          (props.isSelectable && userCanManageTests ? (
            <Flex align="center">
              <Checkbox
                isChecked={props.isSelected}
                onChange={(e) => props.handleSelect(e.target.checked)}
                px={2}
                display="flex"
                alignItems="flex-start"
                visibility={props.isSelected ? "visible" : "hidden"}
                _groupHover={{ visibility: "visible" }}
              />
            </Flex>
          ) : (
            <Box />
          ))}
        <Flex pos="relative">
          <Flex as={Link} to={testLink} {...testRowMainAnchorStyles} />
          <Flex
            display="flex"
            alignItems="center"
            minWidth={0}
            py={ROW_Y_PADDING}
            pe={6}
          >
            <Box wordBreak="break-word">
              <Flex
                textStyle="ds.interface.medium"
                color="ds.text.subtle"
                gap={2}
                mb={2}
                pos="relative"
                align="center"
                zIndex={1}
                _focusVisible={{
                  outline: "none",
                  boxShadow: "outline",
                }}
              >
                {isExternalStudy(test) ? (
                  <Tag
                    label="External study"
                    leftIcon={Laptop01OutlineIcon}
                    colorScheme="orange"
                  />
                ) : (
                  <Tag
                    label="Test"
                    leftIcon={Cursor04SolidIcon}
                    colorScheme="purple"
                  />
                )}
                {showProjectInfo && test.project && (
                  <Button
                    as={Link}
                    to={generateFriendlyProjectPath(test.project)}
                    variant="subtle"
                    size="flush"
                  >
                    <Flex gap={1.5} align="center">
                      <FolderOutlineIcon />
                      <Text
                        textStyle="ds.interface.medium"
                        color="ds.text.subtle"
                        // noOfLines below requires an overflow: hidden rule,
                        // so we need a bigger line height so the descenders don't get cut off
                        lineHeight={1.25}
                        noOfLines={1}
                      >
                        {test.project.name}
                      </Text>
                    </Flex>
                  </Button>
                )}
              </Flex>

              <Heading
                as="h3"
                textStyle="ds.heading.primary"
                color="ds.text.default"
              >
                <Text
                  as={Link}
                  to={`/tests/${test.unique_id}/results`}
                  noOfLines={2}
                  _focusVisible={{
                    outline: "none",
                    boxShadow: "outline",
                  }}
                >
                  {test.name}
                </Text>
                {test.admin_disabled && (
                  <Text as="span" color="red.500" fontSize="xs" ps={1} pt={1}>
                    ADMIN DISABLED
                  </Text>
                )}
              </Heading>

              {!isLargerThan48em && <ResponseCountAndStatusCompact />}

              {showVariationSetInfo && test.variation_set && (
                <Tooltip
                  hasArrow
                  label="Variation set"
                  placement="top"
                  rounded="md"
                >
                  <Button
                    as={Link}
                    to={ROUTES.TEST_SET.buildPath({
                      testSetId: test.variation_set.unique_id,
                    })}
                    variant="secondary"
                    size="flush"
                    zIndex={1}
                    pos="relative"
                    leftIcon={<VariationIcon boxSize={3.5} />}
                    mt={3.5}
                  >
                    {test.variation_set.name}
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Flex>
        </Flex>
        {isLargerThan48em && <ResponseCountAndStatusLarge />}

        <Flex display={["none", null, null, null, "flex"]} pos="relative">
          <Flex as={Link} to={testLink} {...testRowMainAnchorStyles} />
          <Flex
            basis="full"
            justifyContent="center"
            alignItems="center"
            py={ROW_Y_PADDING}
          >
            {test.archived ? (
              <Text
                fontSize="md"
                lineHeight={4}
                fontWeight="medium"
                color="gray.600"
              >
                Archived
              </Text>
            ) : (
              !test.admin_disabled &&
              userCanManageTests && (
                <Button
                  as={Link}
                  to={`/tests/${test.unique_id}/recruit`}
                  zIndex={1}
                >
                  Manage recruitment
                </Button>
              )
            )}
          </Flex>
        </Flex>
        <Flex display={["none", null, null, null, "flex"]} pos="relative">
          <Flex as={Link} to={testLink} {...testRowMainAnchorStyles} />
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="flex-end"
            basis="full"
            pe={1}
            py={ROW_Y_PADDING}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              whiteSpace="nowrap"
              zIndex={1}
            >
              <Created
                createdAt={test.created_at}
                name={test.creator?.name ?? "User"}
                email={test.creator?.email}
                avatarUrl={test.creator?.avatar_url ?? ""}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex pos="relative">
          <Flex as={Link} to={testLink} {...testRowMainAnchorStyles} />
          <Flex
            justifyContent="flex-end"
            basis="full"
            alignItems={["flex-start", null, "center"]}
            pe={[0, null, 2]}
            mt={[-1, null, 0]}
            py={ROW_Y_PADDING}
          >
            <UsabilityTestContextMenu
              test={test}
              isDisabled={disableContextMenu}
            />
          </Flex>
        </Flex>
      </Grid>
    </Flex>
  )
}

export const TestStatus: React.FC<{
  status: string
  statusTags?: string[]
  showDetail?: boolean
}> = ({ status, statusTags, showDetail }) => {
  const showDetailsLine = statusTags && showDetail

  return (
    <Flex
      alignItems={showDetailsLine ? "center" : "baseline"}
      color="ds.text.default"
    >
      <Flex
        me={2}
        transform={[
          "translateY(2px)",
          null,
          showDetailsLine ? "translateY(-9px)" : null,
        ]}
      >
        {status === "Ready" ? (
          <ArrowCircleRightSolidIcon boxSize={4} color="ds.icon.subtle" />
        ) : status === "Complete" ? (
          <CheckCircleSolidIcon color="ds.icon.success" />
        ) : (
          <Beacon colorScheme="green" boxSize={4} />
        )}
      </Flex>
      <Flex flexDir="column" gap={0.5}>
        <Text textStyle="ds.heading.secondary">{status}</Text>
        {showDetailsLine && (
          <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle">
            {statusTags.join(", ")}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
