import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import ExclamationIcon from "@heroicons/react/solid/ExclamationIcon"
import { Button } from "DesignSystem/components"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { Settings04OutlineIcon } from "Shared/icons/untitled-ui/Settings04OutlineIcon"
import { StopCircleSolidIcon } from "Shared/icons/untitled-ui/StopCircleSolidIcon"
import { Beacon } from "UsabilityHub/components/Beacon"
import { useStopModeratedStudiesRecruitmentLinks } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyListEntry } from "~/api/generated/usabilityhubSchemas"

type RecruitButtonProps = {
  study: ModeratedStudyListEntry
  linkTo: (path: string) => string
}

const ButtonIcon: React.FC<{ study: ModeratedStudyListEntry }> = ({
  study,
}) => {
  const label = study.google_calendar_error
    ? "Calendar integration action required"
    : !study.has_availability
      ? "No availability"
      : undefined

  if (label) {
    return (
      <Tooltip hasArrow placement="top" label={label}>
        <Icon as={ExclamationIcon} color="red.600" />
      </Tooltip>
    )
  }
}

const RecruitButton: React.FC<RecruitButtonProps> = ({ study, linkTo }) => {
  const [recruiting, setRecruiting] = useState(study.recruiting)

  useEffect(() => {
    setRecruiting(study.recruiting)
  }, [study.recruiting])

  const { mutateAsync } = useStopModeratedStudiesRecruitmentLinks({})

  const stopRecruiting = async () => {
    setRecruiting(false)
    await mutateAsync({
      pathParams: {
        moderatedStudyId: study.id,
      },
    }).catch(() => {
      setRecruiting(study.recruiting)
    })
  }

  return recruiting ? (
    <Menu isLazy>
      <MenuButton
        as={Button}
        aria-label="Options"
        rightIcon={<ButtonIcon study={study} />}
        w="8.5rem"
      >
        <Flex justifyContent="center" alignItems="center" gap={2}>
          <Beacon colorScheme="green" boxSize={4} />
          <Text>Recruiting</Text>
        </Flex>
      </MenuButton>
      <MenuList overflow="hidden">
        <MenuItem
          as={Link}
          to={linkTo("recruit")}
          icon={<Settings04OutlineIcon />}
        >
          Manage
        </MenuItem>
        <MenuItem
          color="red.500"
          icon={<StopCircleSolidIcon color="red.500" />}
          onClick={stopRecruiting}
        >
          Stop
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <Button
      gridArea="recruit"
      as={Link}
      to={linkTo("recruit")}
      justifySelf="end"
      w="8.5rem"
    >
      Recruit
    </Button>
  )
}

export default RecruitButton
