import { Flex, Grid, Icon, Text } from "@chakra-ui/react"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { BankNote02OutlineIcon } from "Shared/icons/untitled-ui/BankNote02OutlineIcon"
import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { MarkerPin02OutlineIcon } from "Shared/icons/untitled-ui/MarkerPin02OutlineIcon"
import { MessageQuestionSquareOutlineIcon } from "Shared/icons/untitled-ui/MessageQuestionSquareOutlineIcon"
import { Monitor04OutlineIcon } from "Shared/icons/untitled-ui/Monitor04OutlineIcon"
import { formatDuration } from "date-fns"
import React, { ReactNode } from "react"
import { DeviceRequirement } from "~/api/generated/usabilityhubSchemas"
import { deviceRequirementsLabel } from "./deviceRequirementsLabel"

type Props = {
  incentive?: ReactNode
  deviceRequirement: Omit<DeviceRequirement, "id">
  eventDurationMins: number
  hasScreener?: boolean
  showLanguage?: boolean
}
/**
 * Shows a grid of details for an interview.
 *
 * Note: `hasScreener` will only show the screener field if a boolean is provided.
 * We want to show this field only in some cases.
 */
export const InterviewDetails: React.FC<Props> = ({
  incentive,
  deviceRequirement,
  eventDurationMins,
  hasScreener,
  showLanguage = false,
}) => {
  const deviceTypesLabel = deviceRequirementsLabel(deviceRequirement)

  return (
    <Grid
      as="dl"
      templateColumns="max-content 1fr"
      rowGap={3}
      columnGap={6}
      alignItems="flex-start"
      py={3}
      color="text.primary"
    >
      {showLanguage && (
        <>
          <Flex as="dt" align="center" gap={2} color="text.secondary">
            <Text>Language</Text>
          </Flex>
          <Text
            as="dd"
            display="inline-flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            English
            <HelpPopover>
              Initially, only participants who speak English will be eligible
              for interviews.
            </HelpPopover>
          </Text>
        </>
      )}
      {incentive && (
        <>
          <Flex as="dt" align="center" gap={2} color="text.secondary">
            <Icon as={BankNote02OutlineIcon} />
            <Text>Incentive</Text>
          </Flex>
          <Text as="dd" textAlign="right">
            {incentive}
          </Text>
        </>
      )}
      <Flex as="dt" align="center" gap={2} color="text.secondary">
        <Icon as={ClockOutlineIcon} />
        <Text>Duration</Text>
      </Flex>
      <Text as="dd" textAlign="right">
        {formatDuration(
          { minutes: eventDurationMins },
          { format: ["minutes"] }
        )}
      </Text>
      <Flex as="dt" align="center" gap={2} color="text.secondary">
        <Icon as={MarkerPin02OutlineIcon} />
        <Text>Location</Text>
      </Flex>
      <Text as="dd" textAlign="right">
        Online meeting
      </Text>
      <Flex as="dt" align="center" gap={2} color="text.secondary">
        <Icon as={Monitor04OutlineIcon} />
        <Text>Requirements</Text>
      </Flex>
      <Text as="dd" textAlign="right">
        {deviceTypesLabel}
      </Text>
      {hasScreener !== undefined && (
        <>
          <Flex as="dt" align="center" gap={2} color="text.secondary">
            <MessageQuestionSquareOutlineIcon />
            <Text>Screener</Text>
          </Flex>
          <Text as="dd" textAlign="right">
            {hasScreener ? "Enabled" : "Disabled"}
          </Text>
        </>
      )}
    </Grid>
  )
}
