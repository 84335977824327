import { Alert, AlertDescription, AlertIcon, Box } from "@chakra-ui/react"
import { NumericDictionary, groupBy, orderBy } from "lodash"
import React from "react"
import { useSelector } from "react-redux"

import { useLocalCommentContext } from "Components/comment-provider/CommentProvider"
import { CommentThread } from "Components/comment-thread/CommentThread"
import { entityIdentifiersMatch } from "Components/comment-thread/entities"
import { LinearScaleQuestionResults } from "Components/test-results/question-results/linear-scale-question-results"
import { MultipleChoiceQuestionResults } from "Components/test-results/question-results/multiple-choice-question-results"
import { Props } from "Components/test-results/question-results/props"
import { RankingQuestionResults } from "Components/test-results/question-results/ranking-question-results"
import { WrittenAnswerQuestionResults } from "Components/test-results/question-results/written-answer-question-results/written-answer-question-results"
import { getAllResponseAnswers } from "Redux/reducers/test-results/selectors"
import { QuestionType, Response, ResponseAnswer } from "Types"
import { TestCardDivider } from "UsabilityHub/components/TestCard/TestCard"
import { QuestionProvider, useSectionContext } from "UsabilityHub/contexts"

import { useFilteredResponses } from "../hooks/use-filtered-responses"

const filteredResponseAnswersByQuestionId = (
  responses: ReadonlyArray<Response>,
  responseAnswers: ReadonlyArray<ResponseAnswer>
): NumericDictionary<ReadonlyArray<ResponseAnswer>> => {
  const responsesById = groupBy(responses, "id")
  const filteredResponseAnswers = responseAnswers.filter(
    (answer) => responsesById[answer.response_id] !== undefined
  )
  const sortedResponseAnswers = orderBy(
    filteredResponseAnswers,
    (answer) => responsesById[answer.response_id][0].submitted_at,
    "desc"
  )
  return groupBy(sortedResponseAnswers, "usability_test_section_question_id")
}

type QuestionResultsListInnerProps = {
  answersByQuestionId: NumericDictionary<ReadonlyArray<ResponseAnswer>>
  // Optionally provide an allowlist and only show questions with IDs in the list.
  // Useful for when you want to show only some questions from the section, for example
  // Live Website Tests which have the questions divided over several tasks.
  questionIdAllowlist?: number[]
}

const QuestionResultsListInner: React.FC<
  React.PropsWithChildren<QuestionResultsListInnerProps>
> = ({ answersByQuestionId, questionIdAllowlist }) => {
  const { section } = useSectionContext()
  const { activeThread } = useLocalCommentContext()

  const nodes = section.questions.map((question) => {
    if (questionIdAllowlist && !questionIdAllowlist.includes(question.id)) {
      return null
    }

    const commentableEntity = {
      entityContext: "test_results",
      entityType: "usability_test_section_question",
      entityId: String(question.id),
    } as const
    const isCommentThreadOpen = activeThread
      ? entityIdentifiersMatch(activeThread, commentableEntity)
      : false

    return (
      <QuestionProvider
        key={question.id}
        value={{ question, questionIndex: question.position }}
      >
        <TestCardDivider
          // <TestCardBody> is a stack so we need to override the added margins
          // for all questions except the first — this space is re-added by the p={x}
          // on the Box element below.
          mt={question.position === 0 ? undefined : "0 !important"}
        />
        <Box
          left={-8}
          width="calc(100% + 64px)"
          p={8}
          mt="0 !important" // The <TestCardBody> stack adds this margin too (see comment above)
          position="relative"
          boxShadow={
            isCommentThreadOpen
              ? "inset 1px 1px 0px var(--chakra-colors-teal-600), inset -1px -1px 0px var(--chakra-colors-teal-600);"
              : undefined
          }
          data-commentable={`usability_test_section_question:${question.id}`} // Needed for showing the comment widget on hover
          data-qa={`results-question-${question.position}`}
        >
          <QuestionResults
            key={question.id}
            question={question}
            answers={answersByQuestionId[question.id] || []}
          />
          <CommentThread
            isEntityPersisted
            entity={commentableEntity}
            offsetX={22}
            offsetY={16}
          />
        </Box>
      </QuestionProvider>
    )
  })
  return (
    <>
      {section.questions_randomized && (
        <Alert>
          <AlertIcon />
          <AlertDescription>
            The order of questions in this section was randomized
          </AlertDescription>
        </Alert>
      )}
      {nodes}
    </>
  )
}

export const QuestionResultsList: React.FC<
  Omit<QuestionResultsListInnerProps, "answersByQuestionId">
> = ({ questionIdAllowlist }) => {
  const responseAnswers = useSelector(getAllResponseAnswers)
  const filteredResponses = useFilteredResponses()
  const answersByQuestionId = filteredResponseAnswersByQuestionId(
    filteredResponses,
    responseAnswers
  )
  return (
    <QuestionResultsListInner
      answersByQuestionId={answersByQuestionId}
      questionIdAllowlist={questionIdAllowlist}
    />
  )
}

const QuestionResults: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { question } = props
  switch (question.type) {
    case QuestionType.LongAnswer:
    case QuestionType.ShortAnswer:
      return <WrittenAnswerQuestionResults {...props} />
    case QuestionType.RadioMultipleChoice:
    case QuestionType.CheckboxMultipleChoice:
      return <MultipleChoiceQuestionResults {...props} />
    case QuestionType.LinearScale:
      return <LinearScaleQuestionResults {...props} />
    case QuestionType.Ranking:
      return <RankingQuestionResults {...props} />
  }
}
