import { Button, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { PresetModal } from "./PresetModal"

export const SelectPresetButton: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="solid" onClick={onOpen}>
        Select a saved group
      </Button>

      {isOpen && <PresetModal onClose={onClose} />}
    </>
  )
}
