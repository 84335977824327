import { List, ListItem } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { FigmaOauthCredentialsProvider } from "UsabilityHub/contexts/FigmaOauthCredentialsContext"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { FigmaIntegrationStatus } from "UsabilityHub/views/settings/profile/integrations/FigmaIntegrationStatus"
import { GoogleIntegrationStatus } from "UsabilityHub/views/settings/profile/integrations/GoogleIntegrationStatus"
import { MicrosoftIntegrationStatus } from "UsabilityHub/views/settings/profile/integrations/MicrosoftIntegrationStatus"
import { ZoomIntegrationStatus } from "UsabilityHub/views/settings/profile/integrations/ZoomIntegrationStatus"
import React, { useCallback } from "react"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

type Integrations = React.FC<React.PropsWithChildren<unknown>>

export const Integrations: Integrations = () => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { data } = useIntegrations()

  const invalidateIntegrationsQuery = useCallback(() => {
    return queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/integrations",
        operationId: "getIntegrations",
        variables: {},
      })
    )
  }, [queryClient, queryKeyFn])

  return (
    <Card>
      <CardHeader>Integrations</CardHeader>
      <CardBody>
        <List spacing={4}>
          <ListItem>
            <GoogleIntegrationStatus
              data={data}
              onConnectDisconnect={invalidateIntegrationsQuery}
            />
          </ListItem>

          <ListItem>
            <ZoomIntegrationStatus
              data={data}
              onConnectDisconnect={invalidateIntegrationsQuery}
            />
          </ListItem>

          <ListItem>
            <MicrosoftIntegrationStatus
              data={data}
              onConnectDisconnect={invalidateIntegrationsQuery}
            />
          </ListItem>

          <ListItem>
            <FigmaOauthCredentialsProvider
              currentUserHasFigmaOauthCredentials={!!data?.figma}
            >
              <FigmaIntegrationStatus
                data={data}
                onConnectDisconnect={invalidateIntegrationsQuery}
              />
            </FigmaOauthCredentialsProvider>
          </ListItem>
        </List>
      </CardBody>
    </Card>
  )
}
