import { Flex, Image, Text } from "@chakra-ui/react"
import React, { useState } from "react"

import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { ListModeratedStudiesResponse } from "~/api/generated/usabilityhub-components"

import NoResultsImage from "Images/app-illustrations/lyssna/empty-all.png"
import ModeratedStudyCard from "../ModeratedStudy/interviewer/ModeratedStudyCard/ModeratedStudyCard"

interface Props {
  moderatedStudies: ListModeratedStudiesResponse
}

const PAGE_SIZE = 20

export const SearchResultsModeratedStudiesTab: React.FC<Props> = ({
  moderatedStudies,
}) => {
  const [numVisible, setNumVisible] = useState(PAGE_SIZE)
  const paginationRef = useInfiniteScroll(() => {
    if (numVisible < moderatedStudies.length) {
      setNumVisible((v) => v + PAGE_SIZE)
    }
  })

  if (moderatedStudies.length === 0) {
    return (
      <Flex direction="column" align="center" mt={10}>
        <Image src={NoResultsImage} maxW={36} alt="No results" />

        <Text>No interview study results found</Text>
      </Flex>
    )
  }

  const visibleModeratedStudies = moderatedStudies.slice(0, numVisible)

  return (
    <Flex direction="column" gap={4}>
      {visibleModeratedStudies.map((moderatedStudy) => (
        <ModeratedStudyCard key={moderatedStudy.id} study={moderatedStudy} />
      ))}

      <div ref={paginationRef} />
    </Flex>
  )
}
