import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React from "react"

import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"
import { BoxOutlineIcon } from "Shared/icons/untitled-ui/BoxOutlineIcon"
import { BoxSolidIcon } from "Shared/icons/untitled-ui/BoxSolidIcon"

import { useViewMenuParams } from "./useViewMenuParams"

interface Props {
  iconOnly?: boolean
}

export const ActiveMenu: React.FC<ButtonProps & Props> = ({
  iconOnly = false,
  ...buttonProps
}) => {
  const [, setSearchParams] = useMutableQueryString()
  const { showArchived } = useViewMenuParams()

  const boxIcon = showArchived ? BoxSolidIcon : BoxOutlineIcon

  return (
    <Menu>
      {iconOnly ? (
        <MenuButton
          as={IconButton}
          size="md"
          icon={<Icon as={boxIcon} boxSize={5} color="brand.neutral.default" />}
          variant="outline"
          bg={showArchived ? "gray.200" : undefined}
        />
      ) : (
        <MenuButton
          as={Button}
          size="md"
          rightIcon={
            <Icon
              as={ChevronDownIcon}
              boxSize={5}
              color="brand.neutral.default"
            />
          }
          variant="outline"
          color="brand.neutral.default"
          {...buttonProps}
        >
          {showArchived ? "Show archived" : "Show unarchived"}
        </MenuButton>
      )}

      <MenuList overflow="hidden">
        <MenuItem
          onClick={() => {
            setSearchParams({
              show_archived: null,
            })
          }}
        >
          Show unarchived
        </MenuItem>

        <MenuItem
          onClick={() => {
            setSearchParams({
              show_archived: "true",
            })
          }}
        >
          Show archived
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
