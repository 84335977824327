// biome-ignore lint/suspicious/noShadowRestrictedNames: legacy code
import { isNaN } from "lodash"

import { reportError } from "Utilities/error"
import { pluralizeWithCount } from "Utilities/string"

export const SToMs = 1000

export function msToS(ms: number): number {
  return ms / SToMs
}

export function floorMsToS(ms: number): number {
  return Math.floor(msToS(ms))
}

function invalidDuration(durationMs: number) {
  reportError(new TypeError(`Tried to format ${durationMs} as duration`))
}

function msToMinutesAndSeconds(durationMs: number): {
  m: number
  s: number
} {
  const totalSeconds = Math.round(msToS(durationMs))
  return {
    m: Math.floor(totalSeconds / 60),
    s: totalSeconds % 60,
  }
}

export function formatDigitalClockTimeString(durationMs: number): string {
  if (isNaN(durationMs) || durationMs < 0) {
    return "n/a"
  }
  const minSec = msToMinutesAndSeconds(durationMs)
  const m = `${minSec.m}`.padStart(2, "0")
  const s = `${minSec.s}`.padStart(2, "0")
  return `${m}:${s}`
}

export function formatPreciseDuration(durationMs: number): string {
  if (isNaN(durationMs)) {
    return "n/a"
  }
  const { m, s } = msToMinutesAndSeconds(durationMs)
  return m > 0 ? `${m}m ${s}s` : `${s}s`
}

export function formatHumanizedIntegerDuration(durationMs: number): string {
  if (isNaN(durationMs)) {
    invalidDuration(durationMs)
    durationMs = 0
  }
  const { m, s } = msToMinutesAndSeconds(durationMs)
  const secondString = pluralizeWithCount(s, "second", "seconds")
  return m > 0
    ? `${pluralizeWithCount(m, "minute", "minutes")} ${secondString}`
    : secondString
}

/**
 * Generate an ISO8601-esque string that is defined by the HTML5 spec. Can be
 * used as the `datetime` attribute of a `HTMLTimeElement`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
 * @see https://www.w3.org/TR/2014/REC-html5-20141028/infrastructure.html#valid-duration-string
 */
export function durationStringS(durationS: number): string {
  if (durationS < 60) {
    return durationString(durationS)
  }
  const totalMinutes = Math.floor(durationS / 60)
  const seconds = durationS % 60
  if (totalMinutes < 60) {
    return durationString(seconds, totalMinutes)
  }
  const totalHours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  if (totalHours < 24) {
    return durationString(seconds, minutes, totalHours)
  }
  const hours = totalHours % 24
  const days = Math.floor(totalHours / 24)
  return durationString(seconds, totalMinutes, hours, days)
}

/**
 * Generate an ISO8601-esque string that is defined by the HTML5 spec. Can be
 * used as the `datetime` attribute of a `HTMLTimeElement`.
 *
 * NOTE: All params but `seconds` must be integers to be spec conformant.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
 * @see https://www.w3.org/TR/2014/REC-html5-20141028/infrastructure.html#valid-duration-string
 */
function durationString(
  seconds: number,
  minutes = 0,
  hours = 0,
  days = 0
): string {
  let result = "P"
  if (seconds > 60) {
    reportError(new TypeError(`seconds > 60: ${seconds}`))
  }
  if (days > 0) {
    result += days
    result += "D"
  }
  result += "T"
  if (hours > 0) {
    result += hours
    result += "H"
  }
  if (minutes > 0) {
    result += minutes
    result += "M"
  }
  result += seconds.toFixed(3)
  result += "S"
  return result
}

/**
 * @example
 * getHumanizedTimePeriodFromUTC("2023-08-07T23:30:00.000Z", "2023-08-08T00:15:00.000Z")
 * // Tuesday, 8 August 2023, 09:30 – 10:15 (Australia/Brisbane)
 * getHumanizedTimePeriodFromUTC("2023-08-07T23:30:00.000Z")
 * // Tuesday, 8 August 2023, 09:30 (Australia/Brisbane)
 */
export const getHumanizedTimePeriodFromUTC = (
  startsAt: string | Date,
  endsAt?: string | Date
) => {
  if (!startsAt) return ""

  startsAt = typeof startsAt === "string" ? new Date(startsAt) : startsAt
  endsAt = typeof endsAt === "string" ? new Date(endsAt) : endsAt

  const date = startsAt.toLocaleString(navigator.language, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const times = [startsAt, endsAt]
    .filter(Boolean)
    .map((t) =>
      t?.toLocaleTimeString(navigator.language, { timeStyle: "short" })
    )
    .join(" – ")
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return `${date}, ${times} (${timeZone})`
}

export const minutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  return [
    hours > 0 && pluralizeWithCount(hours, "hour"),
    remainingMinutes > 0 && pluralizeWithCount(remainingMinutes, "minute"),
  ]
    .filter(Boolean)
    .join(" ")
}
