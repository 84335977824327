export const DATA_PROCESSING_AGREEMENT = {
  downloadUrl: "/documents/Lyssna-Data-Processing-Agreement-v3.1.pdf",
  currentVersion: "3.1",
  lastUpdated: "2024/07/19",
}

export const PRIVACY_POLICY_DOCUMENT = {
  downloadUrl: "/documents/Lyssna-Privacy-Policy-v2.3.pdf",
  currentVersion: "2.3",
  lastUpdated: "2024/07/19",
}

export const SERVICES_AGREEMENT_DOCUMENT = {
  downloadUrl: "/documents/Lyssna-Services-Agreement-v2.2.pdf",
  currentVersion: "2.2",
  lastUpdated: "2024/09/20",
}

export const SUBPROCESSOR_LIST = {
  currentVersion: "2.0",
  lastUpdated: "2023/11/23",
}

export const COOKIES_DOCUMENT = {
  lastUpdated: "2023/08/15",
}

export const USERCROWD_TERMS_AND_CONDITIONS_DOCUMENT = {
  lastUpdated: "2024/09/20",
}
