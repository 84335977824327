import {
  Button,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import React from "react"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { FunctionalModal } from "Utilities/modals/types"
import { useDeleteUsabilityTest } from "~/api/generated/usabilityhub-components"

interface Props {
  tests: Array<{
    usabilityTestId: number
    name: string
  }>
  onSuccess?: () => void
}

export const DeleteStudyModal: FunctionalModal<Props> = ({
  tests,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutateAsync: deleteStudy, isLoading } = useDeleteUsabilityTest({
    onSuccess: () => {
      void queryClient.invalidateQueries(["api", "usability_tests"])
      void queryClient.invalidateQueries(["api", "account", "total_credits"])
    },
  })

  const onSubmit = async () => {
    try {
      let totalRefundPoints = 0

      const results = await Promise.allSettled(
        tests.map(async (test) => {
          const data = await deleteStudy({
            pathParams: { usabilityTestId: test.usabilityTestId.toString() },
          })

          totalRefundPoints += data.refund_point_count
        })
      )

      const successfulDeletes = results.filter(
        (result) => result.status === "fulfilled"
      ).length
      const failedDeletes = tests.length - successfulDeletes

      let message =
        successfulDeletes === 1
          ? `Deleted test "${tests[0].name}"`
          : `Deleted ${successfulDeletes} tests`

      if (totalRefundPoints > 0) {
        message += ` and refunded ${totalRefundPoints} unused credits`
      }

      if (failedDeletes > 0) {
        message += ` (${failedDeletes} test${
          failedDeletes === 1 ? "" : "s"
        } failed to delete)`
      }

      if (successfulDeletes === 0 && failedDeletes > 0) {
        toast({
          title:
            tests.length === 1 ? "Test delete failed" : "Test deletes failed",
          status: "error",
          duration: 3000,
        })
      } else {
        toast({
          title: successfulDeletes === 1 ? "Test deleted" : "Tests deleted",
          description: message,
          status: "success",
          duration: 3000,
        })
      }

      onSuccess?.()
      onClose()

      // Redirect to the dashbaord after deleting a test from inside the test builder / recruit / results.
      // We should replace this with history.push and useRouteMatch once we have access
      // to the Router context inside modals (see PRD-2107)
      if (window.location.href.match(/\/tests\//)) {
        window.location.href = "/dashboard"
      }
    } catch (error) {
      toast({
        title: "Test delete failed",
        status: "error",
        duration: 3000,
      })
    }
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-qa="delete-test-modal">
        <JsForm onSubmit={onSubmit}>
          <ModalHeader>
            Delete{" "}
            {tests.length === 1 ? "this test?" : `${tests.length} tests?`}
          </ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to permanently delete{" "}
              {tests.length === 1 ? "your test" : "these tests"} and all
              responses? This action cannot be undone.
            </Text>
            <UnorderedList mt={2}>
              {tests.map((test) => (
                <ListItem key={test.usabilityTestId}>{test.name}</ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton
                colorScheme="red"
                isLoading={isLoading}
                loadingAction="Deleting"
              >
                Delete
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </JsForm>
      </ModalContent>
    </Modal>
  )
}
