import {
  Box,
  Flex,
  Grid,
  GridProps,
  Icon,
  Button as LegacyButton,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react"
import { Button, Heading } from "DesignSystem/components"
import { cqContainer } from "Shared/helpers/containerQuerySx"
import { Grid01OutlineIcon } from "Shared/icons/untitled-ui/Grid01OutlineIcon"
import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { DashboardLoadingIndicator } from "UsabilityHub/components/DashboardLoadingIndicator/dashboard-loading-indicator"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { NewProject } from "~/api/generated/usabilityhubSchemas"
import { ROUTES } from "../routes"
import { CreateProjectModal } from "./CreateProjectModal"
import { ProjectCard } from "./ProjectCard"
import { ProjectListSkeleton } from "./ProjectListSkeleton"
import { recentProjectsGridSxProps } from "./projectListGridBreakpoints"

type Props = {
  projects: NewProject[]
  projectQuery: { isFetching: boolean; isError: boolean }
  isLoading: boolean
}

export const RecentProjectList: React.FC<Props> = ({
  projects,
  projectQuery,
  isLoading,
}) => {
  const isUserAllowedToCreateTests = useCurrentUser().can_manage_tests
  const { open: openCreateProjectModal } = useModal(CreateProjectModal)
  const [isLargerThan48em] = useMediaQuery("(min-width: 48em)", { ssr: false })

  const gridProps: GridProps = {
    templateColumns: "1fr",
    gap: 4,
    mt: 6,
    mb: 8,
    sx: recentProjectsGridSxProps,
  }

  return (
    <>
      <Flex justifyContent="space-between" align="flex-end">
        <Flex align="center" gap={3}>
          <Heading
            as="h2"
            textStyle="ds.display.primary"
            color="ds.text.default"
          >
            Recent projects
          </Heading>
          <DashboardLoadingIndicator query={projectQuery} />
        </Flex>
        <Button
          as={RouterLink}
          variant="secondary"
          to={ROUTES.PROJECTS.path}
          leftIcon={<Icon as={Grid01OutlineIcon} />}
        >
          {isLargerThan48em ? "View all projects" : "View all"}
        </Button>
      </Flex>

      {isLoading ? (
        <Box sx={cqContainer()}>
          <ProjectListSkeleton numberOfItems={5} gridProps={gridProps} />
        </Box>
      ) : projects.length > 0 ? (
        <Box sx={cqContainer()}>
          <Grid {...gridProps}>
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </Grid>
        </Box>
      ) : (
        <VStack
          borderColor="ds.border.default"
          borderWidth={1}
          rounded="md"
          spacing={3}
          py={8}
          mt={4}
          mb={12}
        >
          <Text color="text.secondary">
            {isUserAllowedToCreateTests
              ? "Organize all of your studies with projects."
              : "Projects will be shown here."}
          </Text>
          {isUserAllowedToCreateTests && (
            <LegacyButton
              onClick={openCreateProjectModal}
              shadow={"none"}
              color="primary"
              backgroundColor="transparent"
              variant="outline"
              leftIcon={
                <PlusSolidIcon
                  color="primary"
                  fontSize="lg"
                  transform="translateY(-1px)"
                />
              }
            >
              Create your first project
            </LegacyButton>
          )}
        </VStack>
      )}
    </>
  )
}
