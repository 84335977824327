import { Box, Collapse, HStack } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { CommentThread } from "Components/comment-thread/CommentThread"
import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { getId } from "Redux/reducers/test-builder/selectors/test-form"
import {
  TestCard,
  TestCardActions,
  TestCardBody,
  TestCardHeader,
  TestCardHeading,
  TestCardTitle,
} from "UsabilityHub/components/TestCard/TestCard"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"

import { CustomizableScreenToggle } from "../CustomizableScreenToggle"
import { PaidFeatureIndicator } from "../PaidFeatureIndicator"

import { WelcomeScreenFields } from "./WelcomeScreenFields"

const COMMENTABLE_ENTITY = {
  entityContext: "test_builder",
  entityType: "welcome",
} as const

export const WelcomeScreenCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const isEnabled = useSelector(getFormValue("customize_welcome")) as boolean
  const usabilityTestId = useSelector(getId)
  const { readOnly } = useUsabilityTestUserActivityContext()
  const screenType = "welcome"

  return (
    <TestCard id="welcome-screen-card" commentableEntity={COMMENTABLE_ENTITY}>
      <TestCardHeader>
        <TestCardTitle>
          <TestCardHeading>Welcome screen</TestCardHeading>
        </TestCardTitle>
        <TestCardActions>
          <HStack>
            <PaidFeatureIndicator />
            <CustomizableScreenToggle
              disabled={readOnly}
              screenType={screenType}
            />
          </HStack>
        </TestCardActions>

        <CommentThread
          entity={COMMENTABLE_ENTITY}
          isEntityPersisted={usabilityTestId !== null}
        />
      </TestCardHeader>
      <Box
        sx={
          isEnabled
            ? {
                // Chakra's <Collapse> below adds overflow: hidden which cuts off the side of
                // the inputs. It's only needed during the animations so this is a workaround.
                // See: https://github.com/chakra-ui/chakra-ui/issues/2966
                ".chakra-collapse": {
                  overflow: "initial !important",
                },
              }
            : {}
        }
      >
        <Collapse in={isEnabled} unmountOnExit>
          <TestCardBody>
            <WelcomeScreenFields />
          </TestCardBody>
        </Collapse>
      </Box>
    </TestCard>
  )
}
