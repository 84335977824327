import {
  Box,
  Flex,
  FlexProps,
  Icon,
  Text,
  createStylesContext,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import { CheckIcon } from "@heroicons/react/outline"
import React from "react"

const [StylesProvider, useStyles] = createStylesContext("Steps")

type Steps = React.FC<React.PropsWithChildren<FlexProps>>

export const Steps: Steps = (props) => {
  const styles = useMultiStyleConfig("Steps", {})

  return (
    <StylesProvider value={styles}>
      <Flex as="nav" justify="center" sx={styles.container} {...props} />
    </StylesProvider>
  )
}

type StepsDivider = React.FC<React.PropsWithChildren<FlexProps>>

export const StepsDivider: StepsDivider = (props) => {
  const styles = useStyles()
  return (
    <Flex
      direction="column"
      role="presentation"
      sx={styles.divider}
      {...props}
    />
  )
}

type Step = React.FC<
  React.PropsWithChildren<
    {
      label: string
      href?: string
      isDisabled?: boolean
      isActive?: boolean
      isComplete?: boolean
      count?: number
    } & FlexProps
  >
>

export const Step: Step = ({
  label,
  isDisabled = false,
  isActive = false,
  isComplete = false,
  count,
  ...props
}) => {
  const styles = useStyles()

  return (
    <Flex
      flex={[1, null, "initial"]}
      align="center"
      justify="center"
      aria-current={isActive ? "page" : undefined}
      data-disabled={isDisabled ? true : undefined}
      role="group"
      sx={styles.step}
      {...props}
    >
      {isComplete && (
        <Flex
          align="center"
          justify="center"
          data-component="number"
          sx={styles.stepIndicator}
        >
          <Icon as={CheckIcon} sx={styles.stepIcon} />
        </Flex>
      )}
      <Box position="relative">
        <Text position="absolute">{label}</Text>
        {/* This second copy of the label is just to keep the width consistent so
          that things don't jump around when the font weight changes */}
        <Text fontWeight="bold" visibility="hidden">
          {label}
        </Text>
      </Box>
      {count && <Text sx={styles.stepCount}>{count}</Text>}
    </Flex>
  )
}
