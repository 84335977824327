import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  Text,
} from "@chakra-ui/react"
import { omit, size } from "lodash"
import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState, useTransition } from "react"

import { SearchInput } from "Components/form/search-input/search-input"
import AnswerList from "Components/test-results/question-results/answer-list/answer-list-container"
import { ResponseAnswer, UsabilityTestSectionQuestion } from "Types"
import { textSearch } from "Utilities/search"

import { AnswerTagEditorTooltipTarget } from "./answer-tag-editor-tooltip-target"
import styles from "./edit-answer-list-tags.module.scss"

function toLookup(array: ReadonlyArray<ResponseAnswer>): {
  [id: number]: true
} {
  return array.reduce(
    (acc, answer) => {
      acc[answer.id] = true
      return acc
    },
    {} as { [id: number]: true }
  )
}

type SelectedByIdObject = { [id: number]: boolean }

interface Props {
  answers: ReadonlyArray<Readonly<ResponseAnswer>>
  question: Readonly<UsabilityTestSectionQuestion>
  isReadonly: boolean
}

export function EditAnswerListTags({ answers, question, isReadonly }: Props) {
  const [selectAllId] = useState(nanoid())
  const [searchTerm, setSearchTerm] = useState("")
  const [visibleAnswers, setVisibleAnswers] =
    useState<ReadonlyArray<ResponseAnswer>>(answers)
  const [selectedById, setSelectedById] = useState<SelectedByIdObject>({})
  const [isPending, startTransition] = useTransition()

  const selectedCount = size(selectedById)

  const allSelected =
    visibleAnswers.length > 0 && visibleAnswers.length === selectedCount
  const someSelected =
    selectedCount > 0 && selectedCount < visibleAnswers.length
  const isSelectable = !isReadonly

  const updateVisibleAnswers = useCallback(() => {
    const visibleAnswers = textSearch(
      answers,
      searchTerm,
      (answer) => answer.answer || ""
    )

    setVisibleAnswers(visibleAnswers)
  }, [answers, searchTerm, setVisibleAnswers])

  const handleSearchChange = (searchTerm: string) => {
    setSearchTerm(searchTerm)

    startTransition(() => {
      updateVisibleAnswers()
    })
  }
  const handleClearSearch = () => setSearchTerm("")

  const handleSelect = (answer: Readonly<ResponseAnswer>) => {
    setSelectedById((selectedById) => ({
      ...selectedById,
      [answer.id]: true,
    }))
  }

  const handleDeselect = (answer: Readonly<ResponseAnswer>) => {
    setSelectedById((selectedById) => omit(selectedById, answer.id))
  }

  const handleSelectAll = useCallback(() => {
    setSelectedById((selectedById) => {
      if (visibleAnswers.length === size(selectedById)) {
        return {}
      }

      return toLookup(visibleAnswers)
    })
  }, [visibleAnswers])

  useEffect(() => {
    updateVisibleAnswers()
  }, [updateVisibleAnswers, answers])

  return (
    <div>
      <div className={styles.controls}>
        {isSelectable && (
          <Checkbox
            id={selectAllId}
            variant="mdWithSmFont"
            isChecked={allSelected}
            isIndeterminate={someSelected}
            isDisabled={visibleAnswers.length === 0}
            onChange={handleSelectAll}
            mr={3}
          >
            Select all
          </Checkbox>
        )}
        <SearchInput
          className={styles.searchInput}
          onChange={handleSearchChange}
          placeholder="Search responses"
          value={searchTerm}
        />
        {!isReadonly && (
          <AnswerTagEditorTooltipTarget
            answers={answers.filter((answer) => selectedById[answer.id])}
            question={question}
          >
            <Button ml={2} data-qa="edit-tags-button">
              Add/edit tags
            </Button>
          </AnswerTagEditorTooltipTarget>
        )}
      </div>
      {visibleAnswers.length === 0 ? (
        <div className={styles.emptySearchWrapper}>
          <Alert status="info">
            <AlertIcon />
            <AlertDescription>
              <Text>
                No answers matched search “{searchTerm.trim()}”.{" "}
                <Button variant="link" onClick={handleClearSearch}>
                  Clear search
                </Button>
                .
              </Text>
            </AlertDescription>
          </Alert>
        </div>
      ) : (
        <AnswerList
          answers={visibleAnswers}
          isSelectable={isSelectable}
          isSelectedById={selectedById}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
          listClassName={isPending ? styles.invalid : styles.valid}
          question={question}
          showAnswerTagButton
        />
      )}
    </div>
  )
}
