import { Box, BoxProps, Flex, Icon, Text } from "@chakra-ui/react"
import React from "react"
import { Link } from "react-router-dom"

import { FolderOpenCustomIcon } from "Shared/icons/untitled-ui/FolderOpenCustomIcon"
import { FolderOutlineIcon } from "Shared/icons/untitled-ui/FolderOutlineIcon"
import { NewProject } from "~/api/generated/usabilityhubSchemas"

import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { ProjectContextMenu } from "../projects/$projectWithSlug/ProjectContextMenu"

import { Heading } from "DesignSystem/components/Heading"
import { cq } from "Shared/helpers/containerQuerySx"
import { threeColumnsOrMore } from "./projectListGridBreakpoints"

type Props = {
  project: NewProject
} & BoxProps

export const ProjectCard: React.FC<Props> = ({ project, ...props }) => {
  return (
    <Box
      pos="relative"
      wordBreak="break-word"
      bg={project.archived ? "gray.50" : "ds.surface.raised.resting"}
      rounded="16px"
      shadow="ds.raised"
      borderWidth={1}
      borderColor="transparent"
      _hover={{
        borderColor: "ds.border.hovered",
        bg: "ds.surface.raised.hovered",
      }}
      data-role="project-card"
      data-qa="project-card"
      sx={{
        // For devices that can hover
        "@media (hover: hover)": {
          "&:hover [data-folder-icon]": {
            color: project.archived ? "gray.400" : "ds.icon.accent.gray",
          },
          "&:hover [data-folder-icon=closed]": {
            display: "none",
          },
          "&:hover [data-folder-icon=open]": {
            display: "block",
          },
        },
      }}
      {...props}
    >
      <Flex
        as={Link}
        to={generateFriendlyProjectPath(project)}
        flexDir={"row"}
        justifyContent={"normal"}
        gap={4}
        h={"auto"}
        sx={{
          ...cq(threeColumnsOrMore, {
            flexDir: "column",
            justifyContent: "space-between",
            gap: 0,
            h: "9.5rem",
          }),
        }}
        p={4}
        _focusVisible={{
          outline: "none",
          boxShadow: "outline",
        }}
      >
        <Flex
          alignItems="start"
          basis={10}
          shrink={0}
          mb={0}
          sx={cq(threeColumnsOrMore, { mb: 3 })}
        >
          <Flex
            bg={
              project.archived ? "gray.200" : "ds.background.accent.gray.subtle"
            }
            borderRadius="8px"
            w={8}
            h={8}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              data-folder-icon="closed"
              as={FolderOutlineIcon}
              boxSize={5}
              color={project.archived ? "gray.400" : "ds.icon.accent.gray"}
            />
            <Icon
              data-folder-icon="open"
              display="none"
              as={FolderOpenCustomIcon}
              boxSize={5}
              color={project.archived ? "gray.400" : "ds.icon.accent.gray"}
            />
          </Flex>
        </Flex>
        <Box pe={4} sx={cq(threeColumnsOrMore, { pe: 0 })}>
          <Heading
            as="h3"
            textStyle="ds.heading.primary"
            color="ds.text.default"
            noOfLines={2}
            mb={1.5}
          >
            {project.name}
          </Heading>
          <Text textStyle="ds.paragraph.secondary" color="ds.text.subtle">
            {project.test_count} {project.test_count === 1 ? "test" : "tests"}
          </Text>
          {project.archived && (
            <Text
              fontSize="sm"
              fontWeight="semibold"
              color="gray.400"
              position="absolute"
              bottom="18px"
              right="auto"
              left={36}
              sx={{
                "@media (hover: hover)": {
                  visibility: "hidden",
                  "[data-role='project-card']:hover &": {
                    visibility: "visible",
                  },
                },
                ...cq(threeColumnsOrMore, {
                  bottom: 4,
                  right: 6,
                  left: "auto",
                }),
              }}
            >
              Archived
            </Text>
          )}
        </Box>
      </Flex>

      <Box
        pos="absolute"
        // context menu button is 32px tall
        top={"calc(50% - 16px)"}
        sx={cq(threeColumnsOrMore, { top: 3 })}
        right={2}
      >
        <ProjectContextMenu project={project} />
      </Box>
    </Box>
  )
}
