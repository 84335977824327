// JsFromRoutes CacheKey a3adbc33dcf6a56686703e5425208609
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  dismissDiscount: /* #__PURE__ */ definePathHelper('post', '/orders/dismiss_discount'),
  poll: /* #__PURE__ */ definePathHelper('get', '/tests/:id/orders/poll'),
  cancel: /* #__PURE__ */ definePathHelper('post', '/tests/:usability_test_id/orders/:order_unique_id/cancel'),
}
