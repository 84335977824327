import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react"
import React from "react"

import { LinkButton } from "Components/button/link-button"

interface Props {
  testSetId: number
}

export const EmptyTestSet = ({ testSetId }: Readonly<Props>) => (
  <Flex w="350px" my="100px" mx="auto" textAlign="center">
    <Box flexGrow={1}>
      <Stack spacing={6}>
        <Heading as="h1" variant="h1" size="lg" fontWeight="medium">
          Add some tests
        </Heading>
        <Text as="p" color="gray.500">
          Create a new test or add
          <br />
          existing tests to get started.
        </Text>
        <LinkButton
          colorScheme="brand.primary"
          href={`/tests/new?test_set_id=${testSetId}`}
          width="full"
        >
          Create test
        </LinkButton>
      </Stack>
    </Box>
  </Flex>
)
