import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { SortDescendingIcon } from "@heroicons/react/solid"
import React from "react"

import { useMutableQueryString } from "Shared/hooks/useMutableQueryString"

import { SORT_OPTIONS } from "./sorting"
import { useViewMenuParams } from "./useViewMenuParams"

interface Props {
  iconOnly?: boolean
}

export const SortMenu: React.FC<Props> = ({ iconOnly = false }) => {
  const [, setSearchParams] = useMutableQueryString()
  const { sortBy, sortDirection } = useViewMenuParams()

  return (
    <Menu>
      {iconOnly ? (
        <MenuButton
          as={IconButton}
          size="md"
          icon={
            <Icon
              as={SortDescendingIcon}
              boxSize={5}
              color="brand.neutral.default"
            />
          }
          variant="outline"
        />
      ) : (
        <MenuButton
          as={Button}
          size="md"
          leftIcon={
            <Icon
              as={SortDescendingIcon}
              boxSize={5}
              color="brand.neutral.default"
            />
          }
          variant="outline"
          color="brand.neutral.default"
        >
          Sort
        </MenuButton>
      )}
      <MenuList overflow="hidden">
        {SORT_OPTIONS.map(([field, direction, label]) => {
          return (
            <MenuItem
              key={`${field}-${direction}`}
              bg={
                sortBy === field && sortDirection === direction
                  ? "gray.100"
                  : "white"
              }
              onClick={() => {
                setSearchParams({
                  sort: field,
                  sort_dir: direction,
                })
              }}
            >
              {label}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
