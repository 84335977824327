import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Spinner,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { useLanguage } from "Hooks/use-language"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { PermittedDeviceType } from "Types"
import { DEVICE_TYPES } from "Utilities/device-types"
import React from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useUsabilityTestRecruitPage } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"

type Props = {
  screenerEnabled: boolean
  recordingEnabled: boolean
  estimatedIncidenceRate: number | null
  setEstimatedIncidenceRate: (estimatedIncidenceRate: number | null) => void
  missingIncidenceRate: boolean
}

export const TestTargetingSummary: React.FC<Props> = ({
  screenerEnabled,
  recordingEnabled,
  estimatedIncidenceRate,
  setEstimatedIncidenceRate,
  missingIncidenceRate,
}) => {
  const { testId: usabilityTestId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const { data, isLoading, isError } = useUsabilityTestRecruitPage({
    pathParams: { usabilityTestId },
  })

  const permittedDeviceType = data?.usability_test
    ?.permitted_device_type as PermittedDeviceType
  const languageCode = data?.usability_test?.language_code ?? null

  const testLanguage = useLanguage(languageCode)?.display_string

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }
  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        Something went wrong. Please try again later.
      </Alert>
    )
  }

  return (
    <>
      <Flex justify="space-between" p={4}>
        <Box display="inline-flex" alignItems="center">
          Language{" "}
          <HelpPopover>
            Only participants who speak {testLanguage} will be eligible. This is
            set in the test builder.
          </HelpPopover>
        </Box>

        <Text>{testLanguage}</Text>
      </Flex>

      <StackDivider borderTopWidth="1px" borderColor="gray.200" />

      <Flex justify="space-between" p={4}>
        <Box display="inline-flex" alignItems="center">
          Devices{" "}
          <HelpPopover>
            <Text>
              Devices this test can be viewed on. This is set in the test
              builder.
            </Text>
            <Link
              href={Constants.HELP_CENTER_DEVICE_TARGETING_URL}
              target="_blank"
              rel="noopener noreferer"
            >
              Learn more.
            </Link>
          </HelpPopover>
        </Box>
        <Text>{DEVICE_TYPES[permittedDeviceType]}</Text>
      </Flex>

      <>
        <StackDivider borderTopWidth="1px" borderColor="gray.200" />

        <Flex justify="space-between" p={4}>
          <Box display="inline-flex" alignItems="center">
            Recording{" "}
            <HelpPopover>
              <Text>
                Recording requirements may impact the turnaround time of your
                order.
              </Text>
            </HelpPopover>
          </Box>
          <Text>{recordingEnabled ? "Enabled" : "None"}</Text>
        </Flex>
      </>

      <StackDivider borderTopWidth="1px" borderColor="gray.200" />

      <Flex justify="space-between" p={4}>
        <Box display="inline-flex" alignItems="center">
          Screener{" "}
          <HelpPopover>
            <Text>Preliminary questions to assess panelist suitability.</Text>
          </HelpPopover>
        </Box>
        <Text>{screenerEnabled ? "Enabled" : "None"}</Text>
      </Flex>

      {screenerEnabled && (
        <FormControl p={4} pt={0} isInvalid={missingIncidenceRate}>
          <FormLabel fontSize={16} fontWeight={500}>
            Estimated screen-in rate
          </FormLabel>
          <Text fontSize={14} color="color.text.suble" mb={2}>
            Please estimate the percentage of panelists matching your
            demographic criteria that you expect will also meet your screener
            {"\u2019"}s criteria. This helps us estimate the turnaround time of
            your order.{" "}
            <Link
              href={Constants.HELP_CENTER_SCREEN_IN_RATE_URL}
              target="_blank"
              rel="noopener noreferer"
            >
              Learn more about screen-in rates
            </Link>
            .
          </Text>

          <Flex gap={2}>
            {/* We pass the `min` values through to the backend which are validated against the `VALID_INCIDENCE_RATES`
                constant in the `Orders::BuildOrder` operation. The `max` values are used for display purposes only. */}
            {Constants.VALID_SIR_RANGES.map((option) => (
              <Button
                key={option.min}
                size="sm"
                variant="solid"
                colorScheme={
                  estimatedIncidenceRate === option.min
                    ? "brand.primary"
                    : "gray"
                }
                onClick={() => setEstimatedIncidenceRate(option.min)}
              >
                {option.min}-{option.max}%
              </Button>
            ))}
          </Flex>
        </FormControl>
      )}

      <StackDivider borderTopWidth="1px" borderColor="gray.200" />
    </>
  )
}
