import { ResponseFilter } from "../filters/types"

export function getFiltersWithoutCategories<
  Filters extends ResponseFilter,
  FilterCategory extends Filters["category"],
>(responseFilters: Filters[], filterCategories: FilterCategory[]) {
  return responseFilters.filter(
    (responseFilter) =>
      !filterCategories.some((category) => category === responseFilter.category)
  ) as Exclude<Filters, { category: FilterCategory }>[]
}

export function getFiltersWithoutCategory<
  Filters extends ResponseFilter,
  FilterCategory extends Filters["category"],
>(responseFilters: Filters[], filterCategory: FilterCategory) {
  return getFiltersWithoutCategories<Filters, FilterCategory>(responseFilters, [
    filterCategory,
  ])
}

export function getFiltersWithoutTypes<
  Filters extends ResponseFilter,
  FilterType extends Filters["type"],
>(responseFilters: Filters[], types: FilterType[]) {
  return responseFilters.filter(
    (responseFilter) => !types.some((type) => type === responseFilter.type)
  ) as Exclude<Filters, { type: FilterType }>[]
}

export function getFiltersWithoutType<
  Filters extends ResponseFilter,
  FilterType extends Filters["type"],
>(responseFilters: Filters[], type: FilterType) {
  return getFiltersWithoutTypes<Filters, FilterType>(responseFilters, [type])
}
