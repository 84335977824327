import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Skeleton,
  useOutsideClick,
} from "@chakra-ui/react"
import { useDebouncedValue } from "Shared/hooks/useDebouncedValue"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { GranularLocationTags } from "UsabilityHub/components/GranularLocationTags/GranularLocationTags"
import React from "react"
import {
  ListTargetableLocationsResponse,
  useListTargetableLocations,
} from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { TargetLocation } from "../types"
import { LocationProvider } from "./LocationProvider"
import { LocationSearchMenu } from "./LocationSearchMenu"
import { LocationSelectMenu } from "./LocationSelectMenu"

export const GranularLocationSection: React.FC = () => {
  const { context, targetLocations, setTargetLocations } = useOrderForm()

  const { data, isLoading, isError } = useListTargetableLocations(
    {
      pathParams: { scope: context },
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )

  const handleRemoveLocations = (locations: TargetLocation[]) => {
    setTargetLocations(
      targetLocations.filter(
        (tl) =>
          !locations.some(
            (targetLocation) =>
              tl.type === targetLocation.type && tl.id === targetLocation.id
          )
      )
    )
  }

  return (
    <Flex direction="column" gap={4}>
      <GranularLocationTags
        scope={context}
        targetLocations={targetLocations}
        handleRemoveLocations={handleRemoveLocations}
      />

      {isLoading ? (
        <Skeleton h={10} />
      ) : isError ? (
        <Alert status="error">
          <AlertTitle>Error loading country data.</AlertTitle>
          <AlertDescription>Try refreshing the page.</AlertDescription>
        </Alert>
      ) : (
        <LocationSelect locations={data.locations} />
      )}
    </Flex>
  )
}

type LocationSelectProps = {
  locations: ListTargetableLocationsResponse["locations"]
}

const LocationSelect: React.FC<LocationSelectProps> = ({ locations }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")
  const menuRef = React.useRef<HTMLDivElement>(null)

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 250)

  const ref = React.useRef<HTMLDivElement>(null)
  useOutsideClick({ ref, handler: () => setIsMenuOpen(false) })

  return (
    <Box pos="relative" ref={ref}>
      <InputGroup>
        <Input
          type="text"
          placeholder="Enter a location..."
          value={searchTerm}
          borderRight="none"
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          onFocus={() => setIsMenuOpen(true)}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              setIsMenuOpen(false)
            }
          }}
          _groupHover={{ borderColor: "ds.border.input" }}
        />
        <InputRightAddon
          bg="white"
          p={0}
          _groupHover={{ borderColor: "ds.border.input" }}
          transition="border-color 0.2s ease" // Has to match the input to look good
        >
          <IconButton
            h="38px" // If it's flush against the container the borders look weird
            icon={<Icon as={ChevronDownOutlineIcon} boxSize={5} />}
            variant="ghost"
            aria-label="Open location list"
            roundedLeft="none"
            onClick={() => setIsMenuOpen((b) => !b)}
          />
        </InputRightAddon>
      </InputGroup>
      {isMenuOpen && (
        <LocationProvider locations={locations}>
          <Box
            ref={menuRef}
            pos="absolute"
            top={12}
            left={0}
            right={0}
            maxH="300px"
            zIndex="dropdown"
            shadow="ds.overlay"
            bg="ds.surface.overlay.default"
            borderRadius="md"
            overflowY="auto"
          >
            {searchTerm.length > 0 ? (
              <LocationSearchMenu
                searchTerm={debouncedSearchTerm}
                menuRef={menuRef}
              />
            ) : (
              <LocationSelectMenu menuRef={menuRef} />
            )}
          </Box>
        </LocationProvider>
      )}
    </Box>
  )
}
