import { NumericDictionary, compact, groupBy, keyBy } from "lodash"
import { createSelector } from "reselect"

import { RawResponseSectionRecording } from "JavaScripts/types/recording"
import { State } from "Redux/app-store"
import {
  AnswerTag,
  CardSortCategoryCard,
  CardSortOpenCategoriesRanking,
  PublicTestResultsAccount,
  QuestionTag,
  Range,
  Response,
  ResponseAnswer,
  ResponseSection,
  ResponseSectionFigmaTask,
  ResponseSectionTreeTestPath,
  ScreenshotClick,
  ScreenshotHitzone,
  TestResultsFigmaFileVersion,
  TestResultsOrder,
  UsabilityTest,
} from "Types"
import { reportError } from "Utilities/error"
import { isMultipleAnswer } from "Utilities/usability-test-section-question"
import { assertPresent, isBlank } from "Utilities/values"

// -- General --

export const getUsabilityTest = (state: State): UsabilityTest =>
  assertPresent(state.testResults).usabilityTest

export const getAnswerTags = (
  state: State
): ReadonlyArray<Readonly<AnswerTag>> =>
  assertPresent(state.testResults).answerTags

export const isReadonly = (state: State): boolean =>
  assertPresent(state.testResults).isReadonly

export const getAllClicks = (state: State): ReadonlyArray<ScreenshotClick> =>
  assertPresent(state.testResults).screenshotClicks

export const getCreatorAccount = (state: State): PublicTestResultsAccount =>
  assertPresent(state.testResults).account

// -- Review --

export const getIsSavingReview = (state: State): boolean =>
  assertPresent(state.testResults).isSavingReview

export const getIsReview = (state: State): boolean =>
  assertPresent(state.testResults).isReview

export const getIsRevising = (state: State): boolean =>
  assertPresent(state.testResults).isRevising

export const getReviewingOrderId = (state: State): number | null =>
  assertPresent(state.testResults).reviewingOrderId

// -- Hitzones --

export const hitzonesSelector = createSelector(
  getUsabilityTest,
  (usabilityTest) => {
    return usabilityTest.sections.flatMap((section) =>
      // see https://github.com/Microsoft/TypeScript/issues/22685
      section.section_screenshots.flatMap(
        (ss) => ss.screenshot_hitzones as ScreenshotHitzone[]
      )
    )
  }
)

// -- Tags --

export function isTaggingSelector(state: State): boolean {
  return state.testResults!.isTagging
}

const tagsByIdByQuestionIdSelector = createSelector(
  getUsabilityTest,
  (usabilityTest) => {
    return usabilityTest.sections.reduce(
      (acc, section) => {
        section.questions.forEach((question) => {
          acc[question.id] = keyBy(
            question.question_tags.map((tag, index) => ({
              ...tag,
              _index: index,
            })),
            "id"
          )
        })
        return acc
      },
      {} as NumericDictionary<NumericDictionary<QuestionTag>>
    )
  }
)

export const tagsByIdForQuestionIdSelector = createSelector(
  tagsByIdByQuestionIdSelector,
  (tagsByIdByQuestionId) =>
    (questionId: number): NumericDictionary<QuestionTag> =>
      tagsByIdByQuestionId[questionId] || {}
)

export const makeAnswerTagsForAnswerIdSelector = () =>
  createSelector(
    getAnswerTags,
    (_state: State, id: number) => id,
    (answerTags, answerId) => {
      return answerTags.filter((answerTag) => {
        return answerTag.response_answer_id === answerId
      })
    }
  )

// -- Responses --

export const getAllResponses = (
  state: State
): ReadonlyArray<Readonly<Response>> =>
  assertPresent(state.testResults).responses

export const getAllResponseSections = (
  state: State
): ReadonlyArray<ResponseSection> =>
  assertPresent(state.testResults).responseSections

export const getAllResponseAnswers = (
  state: State
): ReadonlyArray<ResponseAnswer> =>
  assertPresent(state.testResults).responseAnswers

export const getHiddenResponseCount = (state: State): number =>
  assertPresent(state.testResults).hiddenResponseCount

/**
 * Each ResponseSectionFigmaTask is attached to a ResponseSection.
 */
export const getAllResponseSectionFigmaTasks = (
  state: State
): ReadonlyArray<ResponseSectionFigmaTask> =>
  assertPresent(state.testResults).responseSectionFigmaTasks

const getAllResponseSectionRecordings = (
  state: State
): RawResponseSectionRecording[] =>
  assertPresent(state.testResults).responseSectionRecordings

export const getAllResponseSectionCardSorts = (
  state: State
): CardSortCategoryCard[] =>
  assertPresent(state.testResults).responseSectionCardSorts

export const getAllResponseSectionTreeTestPaths = (
  state: State
): ReadonlyArray<ResponseSectionTreeTestPath> =>
  assertPresent(state.testResults).responseSectionTreeTestPaths

export const getResponseSectionRecordingsForResponseSections = createSelector(
  getAllResponseSectionRecordings,
  (_state: State, responseSections: ResponseSection[]) => responseSections,
  (recordings, responseSections) =>
    recordings
      .filter((recording) =>
        responseSections.some((r) => r.id === recording.response_section_id)
      )
      .map((recording) => ({
        ...recording,
        responseSection: responseSections.find(
          (s) => s.id === recording.response_section_id
        )!,
        isAudio:
          recording.types.length === 1 && recording.types[0] === "microphone",
      }))
)

export const getResponseSectionCardSortsForResponseSection = createSelector(
  getAllResponseSectionCardSorts,
  (_state: State, responseSectionId: number) => responseSectionId,
  (cardSorts, responseSectionId) =>
    cardSorts.filter(
      (cardSort) => cardSort.response_section_id === responseSectionId
    )
)

export const getResponseSectionCardSortsForCardSort = createSelector(
  getAllResponseSectionCardSorts,
  getAllResponseSections,
  (_state: State, cardSortId: number) => cardSortId,
  (cardSorts, responseSections, cardSortId) =>
    cardSorts
      .filter((cardSort) => cardSort.card_sort_id === cardSortId)
      .map((cardSort) => ({
        ...cardSort,
        // We have response_section_id but our filtering will be easier later if we attach
        // the response_id to each record as well.
        response_id: responseSections.find(
          (section) => section.id === cardSort.response_section_id
        )?.response_id,
      }))
)

export const getAllCardSortOpenCategoriesRanking = (
  state: State
): CardSortOpenCategoriesRanking =>
  assertPresent(state.testResults).cardSortOpenCategoriesRanking

export const getResponsesCount = (state: State): number =>
  assertPresent(state.testResults).responsesCount

export const getCompletedPanelResponsesCount = (state: State): number =>
  assertPresent(state.testResults).responses.filter((r) => r.order_id !== null)
    .length

export const getResponsesLimit = (state: State): number =>
  assertPresent(state.testResults).responsesLimit

export const createHasSingleAnswerQuestionEverBeenPassed = () =>
  createSelector(
    getAllResponseAnswers,
    (_state: State, questionId: number) => questionId,
    (answers, questionId) =>
      answers.some(
        (answer) =>
          answer.usability_test_section_question_id === questionId &&
          isBlank(answer.answer)
      )
  )

export const createHasQuestionEverReceivedOtherAnswer = () =>
  createSelector(
    getAllResponseAnswers,
    (state: State, questionId: number) => {
      const { sections } = getUsabilityTest(state)
      for (const { questions } of sections) {
        for (const question of questions) {
          if (question.id === questionId) {
            return question
          }
        }
      }
      reportError(
        new TypeError(`Could not find question with ID ${questionId}`)
      )
      return null
    },
    (answers, question) => {
      if (question === null) return false
      if (isMultipleAnswer(question.type)) {
        return answers.some((a) =>
          question.multiple_choice_options.some((o) => !a.answers.includes(o))
        )
      }
      return answers.some(
        (a) =>
          a.answer !== null &&
          !question.multiple_choice_options.includes(a.answer)
      )
    }
  )

export const getResponseOrNull = (
  state: State,
  responseId: number
): Readonly<Response> | null => {
  return (
    getAllResponses(state).find((response) => response.id === responseId) ||
    null
  )
}

export const getResponse = (
  state: State,
  responseId: number
): Readonly<Response> => {
  return assertPresent(getResponseOrNull(state, responseId))
}

export const makeGetResponseIdForResponseSection = (
  responseSectionId: number
) =>
  createSelector(
    getAllResponseSections,
    (allResponseSections): number | null => {
      const responseSection = allResponseSections.find(
        (rs) => rs.id === responseSectionId
      )
      return responseSection?.response_id || null
    }
  )

const getResponseSectionsBySectionId = createSelector(
  getAllResponseSections,
  (responseSections): NumericDictionary<ReadonlyArray<ResponseSection>> =>
    groupBy(responseSections, "usability_test_section_id")
)

export const getResponseSection = (responseSectionId: number) =>
  createSelector(
    getAllResponseSections,
    (responseSections): ResponseSection | null =>
      responseSections.find((rs) => rs.id === responseSectionId) || null
  )

export const getResponseSectionForSectionIdAndResponseId = (
  sectionId: number,
  responseId: number
) =>
  createSelector(
    getResponseSectionsBySectionId,
    (responseSections) =>
      responseSections[sectionId]?.find(
        (rs) => rs.response_id === responseId
      ) || null
  )

// -- Order --

export const getAllOrders = (state: State): ReadonlyArray<TestResultsOrder> =>
  assertPresent(state.testResults).orders

export const getPanelOrdersCount = (state: State): number =>
  assertPresent(state.testResults).orders.filter((o) => o.is_external === false)
    .length

const getOrdersByResponseId = createSelector(
  getAllOrders,
  getAllResponses,
  (orders, responses) => {
    const orderById = keyBy(orders, "id")
    return responses.reduce(
      (acc, response) => {
        if (response.order_id != null) {
          acc[response.id] = orderById[response.order_id]
        }
        return acc
      },
      {} as NumericDictionary<TestResultsOrder>
    )
  }
)

export function getOrderForResponseId(
  state: State,
  id: number
): TestResultsOrder | null {
  return getOrdersByResponseId(state)[id] || null
}

// Age ranges

const ageRangeInterval = 5
const minAgeRangeUpperBound = 19
const maxAgeRangeLowerBound = 90

const AGE_RANGE_LOOKUP = new Map<number, Range>()
AGE_RANGE_LOOKUP.set(-Infinity, { min: -Infinity, max: minAgeRangeUpperBound })
for (
  let i = minAgeRangeUpperBound + 1;
  i < maxAgeRangeLowerBound;
  i += ageRangeInterval
) {
  AGE_RANGE_LOOKUP.set(i, { min: i, max: i + ageRangeInterval - 1 })
}
AGE_RANGE_LOOKUP.set(maxAgeRangeLowerBound, {
  min: maxAgeRangeLowerBound,
  max: Infinity,
})

// -- Answer tags --

export const countAnswerTags = createSelector(
  getAnswerTags,
  (answerTags) => (questionTagId: number) =>
    answerTags.filter(
      (answerTag) => answerTag.question_tag_id === questionTagId
    ).length
)

// -- Prototypes --

/**
 * Fetch all ResponseSectionFigmaTasks attached to the specified section.
 * A Section has 0—many ResponseSections, each of which have 0—1 ResponseSectionFigmaTasks.
 */
export const makeGetFigmaTasksForSectionId = (sectionId: number) =>
  createSelector(
    (state: State) => state,
    getAllResponseSectionFigmaTasks,
    (state, allFigmaTasks): ReadonlyArray<ResponseSectionFigmaTask> => {
      // Get all the ResponseSections for this Section.
      const responseSectionsForSection =
        getResponseSectionsBySectionId(state)[sectionId] || []

      // Get the Figma tasks that correspond to each ResponseSection.
      return compact(
        responseSectionsForSection.map((rs) => {
          return allFigmaTasks.find(
            (path) => path.response_section_id === rs.id
          )
        })
      )
    }
  )

/**
 * We use a different serializer for figma file versions in results
 * that includes images
 */
export const getAllFigmaFileVersions = (
  state: State
): ReadonlyArray<TestResultsFigmaFileVersion> =>
  assertPresent(state.testResults).figmaFileVersions

export const getFigmaFileVersionById = (id: number) =>
  createSelector(
    getAllFigmaFileVersions,
    (ffvs): TestResultsFigmaFileVersion => {
      const ffv = ffvs.find((ffv) => ffv.id === id)
      if (ffv == null) {
        throw new Error(`No figma file version with id: ${id}`)
      } else {
        return ffv
      }
    }
  )

export const getFigmaTaskForResponseSection = (
  responseSectionId: number | null
) =>
  createSelector(
    getAllResponseSectionFigmaTasks,
    (allFigmaTasks): ResponseSectionFigmaTask | null => {
      // If there's no response section (i.e. no response from participant) just return null.
      // I don't think this can happen with prototypes, but we're assuming everywhere that
      // it's possible for any section type.
      if (responseSectionId === null) {
        return null
      }

      const figmaTask = allFigmaTasks.find(
        (ft) => ft.response_section_id === responseSectionId
      )
      if (figmaTask === undefined) {
        // Figma data is calculated asynchronously, sometimes it's not available yet. See PRD-1685.
        return null
      }

      return figmaTask
    }
  )

// Get the account from testResults instead of currentAccount
// So it can be used in the shared result page when not logged in
export const canExportCsv = createSelector(
  (state: State) => state,
  (state) =>
    getUsabilityTest(state).upgraded ||
    getAllOrders(state).length > 0 ||
    getCreatorAccount(state).has_csv_exports_feature
)

export const getResponseSectionRecording = (responseSectionId: number | null) =>
  createSelector(
    (state: State) => state.testResults?.responseSectionRecordings || [],
    (recordings: RawResponseSectionRecording[]) =>
      recordings.find((r) => r.response_section_id === responseSectionId)
  )
