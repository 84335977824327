import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import { MarketingSiteNavbar } from "Components/exports"
import { Page, PageMain } from "Shared/components/Page/Page"
import { debounce } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  PanelEstimateRequestBody,
  PanelEstimateResponse,
  useListDemographics,
  usePanelEstimate,
} from "~/api/generated/usabilityhub-components"
import { DemographicAttributeGroup } from "~/api/generated/usabilityhubSchemas"
import { useBeforeUnload } from "~/usabilityhub/hooks/useBeforeUnload"
import { DemographicsPanel } from "../NewOrderPage/DemographicsPanel"
import { NoOfPanelistsSection } from "../NewOrderPage/NoOfPanelistsSection"
import {
  OrderFormProvider,
  useOrderForm,
} from "../NewOrderPage/OrderFormProvider"
import { DEFAULT_AGE_RANGE, RESPONSE_SCALE } from "../NewOrderPage/constants"
import { Panel, QuoteState, TargetLocation } from "../NewOrderPage/types"
import { UhSummaryPanel } from "./UhSummaryPanel"

const DEFAULT_NUM_PARTICIPANTS = 50
const QUOTE_DEBOUNCE_MS = 3000

export type StudyType =
  | "short_survey"
  | "long_survey"
  | "preference_test"
  | "figma_prototype_test"
  | "open_card_sort"

export const ResearchPanelCalculatorRoute: React.FC = () => {
  const { data: demographics, isLoading, isError } = useListDemographics({})

  if (isLoading) {
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    )
  }

  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text fontWeight="bold">
          There was an error loading the demographics. Try refreshing the page.
        </Text>
      </Alert>
    )
  }

  return (
    <OrderFormProvider
      context="usability_tests"
      testHasScreener={false} // We don't show screener info on the calculator currently
      testHasRecordings={false} // We don't show recordings info on the calculator currently
      testIsExternal={false}
      demographics={demographics}
      defaultNumberOfPanelists={DEFAULT_NUM_PARTICIPANTS}
    >
      <PanelEstimatePageInner demographics={demographics} />
    </OrderFormProvider>
  )
}

type PanelEstimatePageInner = {
  demographics: DemographicAttributeGroup[]
}

const PanelEstimatePageInner: React.FC<PanelEstimatePageInner> = ({
  demographics,
}) => {
  const { selectedOptionIds, numParticipants, ageRange, targetLocations } =
    useOrderForm()
  const { mutateAsync: calculateEstimate } = usePanelEstimate()

  const panel: Panel = "usabilityhub"

  const [quoteState, setQuoteState] = useState<QuoteState>("loading")
  const [latestQuote, setLatestQuote] = useState<PanelEstimateResponse | null>(
    null
  )
  const [languageCode, setLanguageCode] = useState("en")
  const [studyType, setStudyType] = useState<StudyType>("short_survey")
  const totalDemographicChanges = useRef<number>(0)

  const isDirty =
    selectedOptionIds.length > 0 ||
    numParticipants !== DEFAULT_NUM_PARTICIPANTS ||
    ageRange[0] !== DEFAULT_AGE_RANGE[0] ||
    ageRange[1] !== DEFAULT_AGE_RANGE[1] ||
    targetLocations.length > 0

  useBeforeUnload(isDirty)

  const debouncedQuote = useMemo(
    () =>
      debounce(
        async (
          numParticipants: number,
          demographicOptions: number[],
          minAge: number,
          maxAge: number,
          targetLocations: TargetLocation[],
          languageCode: string,
          studyType: StudyType
        ) => {
          const demographicChanges = totalDemographicChanges.current

          const body: PanelEstimateRequestBody = {
            demographic_attribute_option_ids: demographicOptions.join(","),
            target_locations: targetLocations,
            requested_response_count: numParticipants,
            study_type: studyType,
            language_code: languageCode,
          }
          if (minAge !== DEFAULT_AGE_RANGE[0]) body.min_age = minAge
          if (maxAge !== DEFAULT_AGE_RANGE[1]) body.max_age = maxAge

          try {
            const quote = await calculateEstimate({ body })

            // Ignore result if it's not the most recent API call
            if (demographicChanges !== totalDemographicChanges.current) {
              return
            }

            setLatestQuote(quote)
            setQuoteState(quote.estimated_duration ? "success" : "unavailable")
          } catch (error) {
            setLatestQuote(null)
            setQuoteState("error")
          }
        },
        QUOTE_DEBOUNCE_MS
      ),
    []
  )

  useEffect(() => {
    setQuoteState("loading")
    ++totalDemographicChanges.current

    void debouncedQuote(
      numParticipants,
      selectedOptionIds,
      ageRange[0],
      ageRange[1],
      targetLocations,
      languageCode,
      studyType
    )
  }, [
    selectedOptionIds,
    numParticipants,
    debouncedQuote,
    ageRange,
    JSON.stringify(targetLocations),
    languageCode,
    studyType,
  ])

  return (
    <Page bg="white">
      <MarketingSiteNavbar />
      <PageMain>
        <Stack align="center">
          <Stack align="center" my={10}>
            <Heading
              as="h1"
              variant="h1"
              fontSize="5xl"
              fontWeight="semibold"
              mb={2}
              color="black"
              textAlign="center"
            >
              Research panel calculator
            </Heading>
            <Text
              w={["90%", null, "min(90vw, 928px)"]}
              textAlign="center"
              fontSize="xl"
              fontWeight="semibold"
              color="blackAlpha.600"
            >
              Don{"\u2019"}t have your own participants? Lyssna{"\u2019"}s
              research panel connects you with the right people for your study.
              Choose your study size, type, and audience below to get an
              estimate of the cost and turnaround time.
            </Text>
          </Stack>

          <SimpleGrid
            alignItems="flex-start"
            w={["90%", null, "min(90vw, 928px)"]}
            columns={[1, null, 2]}
            gap={4}
            pb={10}
          >
            <Flex direction="column" gap={4}>
              <Box
                borderWidth={1}
                bg="white"
                borderColor="gray.200"
                borderRadius="md"
                overflow="hidden"
              >
                <NoOfPanelistsSection responseScale={RESPONSE_SCALE}>
                  Number of participants
                </NoOfPanelistsSection>
              </Box>

              <Box
                borderWidth={1}
                bg="white"
                borderColor="gray.200"
                borderRadius="md"
                overflow="hidden"
              >
                <DemographicsPanel
                  showPresets={false}
                  demographics={demographics}
                  activePanel={panel}
                />
              </Box>
            </Flex>

            <UhSummaryPanel
              demographics={demographics}
              languageCode={languageCode}
              setLanguageCode={setLanguageCode}
              studyType={studyType}
              setStudyType={setStudyType}
              quote={latestQuote}
              quoteState={quoteState}
            />
          </SimpleGrid>
        </Stack>
      </PageMain>
    </Page>
  )
}
