import { useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { debounce } from "lodash"
import { useCallback, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { reportError } from "Utilities/error"
import { useSetBookingCalendar } from "~/api/generated/usabilityhub-components"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"

import { FORM_SAVE_DEBOUNCE_TIME } from "./common"

const BookingCalendarSchema = Yup.object({
  userId: Yup.number().required(),
  bookingCalendarId: Yup.string().nullable().defined(),
  bookingCalendarSummary: Yup.string().nullable().defined(),
  bookingCalendarType: Yup.string()
    .oneOf(["microsoft", "google"])
    .nullable()
    .defined(),
})
type BookingCalendarValues = Yup.InferType<typeof BookingCalendarSchema>

export const useTeamMembersForm = (moderatedStudy: ModeratedStudy) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { booking_calendar: bookingCalendar } = moderatedStudy
  const mainHost = moderatedStudy.members.find((m) => m.role === "main_host")

  const form = useForm<BookingCalendarValues>({
    resolver: yupResolver(BookingCalendarSchema),
    defaultValues: {
      userId: mainHost?.id,
      bookingCalendarId: bookingCalendar?.id,
      bookingCalendarSummary: bookingCalendar?.summary,
      bookingCalendarType: bookingCalendar?.provider ?? null,
    },
  })
  const { handleSubmit, watch, reset, getValues } = form

  const { mutate: setBookingCalendarMutation } = useSetBookingCalendar({
    onSuccess: () => reset(getValues(), { keepValues: true }),
    onError: (error) => {
      toast({
        title: "Failed to set booking calendar",
        description: error?.payload.message,
        status: "error",
      })
      reportError(new Error(error.payload.message))
    },
    onSettled: () => {
      // If there is an error, refresh the study to get the latest data, most
      // likely case is someone else editing at the same time and the client /
      // server being out of sync.
      //
      // If success, we want to refresh the data to ensure we can see things
      // such as "google_calendar_error" which only comes from the API when
      // there is a booking calendar set.
      return queryClient.invalidateQueries(
        ["api", "moderated_studies", moderatedStudy.id],
        { exact: true }
      )
    },
  })

  const onBookingCalendarSubmit: SubmitHandler<BookingCalendarValues> =
    useCallback(
      (formData) => {
        setBookingCalendarMutation({
          pathParams: {
            moderatedStudyId: moderatedStudy.id,
          },
          body: {
            user_id: formData.userId,
            id: formData.bookingCalendarId,
            summary: formData.bookingCalendarSummary,
            provider: formData.bookingCalendarType,
          },
        })
      },
      [moderatedStudy.id, setBookingCalendarMutation]
    )

  const debouncedBookingCalendarSubmit = useCallback(
    debounce(handleSubmit(onBookingCalendarSubmit), FORM_SAVE_DEBOUNCE_TIME),
    [handleSubmit, onBookingCalendarSubmit]
  )

  useEffect(() => {
    const subscription = watch(() => {
      void debouncedBookingCalendarSubmit()
    })

    return () => subscription.unsubscribe()
  }, [watch, debouncedBookingCalendarSubmit])

  return form
}
