import { Avatar, HStack, Text, Tooltip } from "@chakra-ui/react"
import { getDateString } from "Utilities/date-formats"
import React from "react"

type CreatedProps = {
  createdAt: string
  name: string
  email?: string
  avatarUrl: string
}

const Created: React.FC<CreatedProps> = ({
  createdAt,
  name,
  email,
  avatarUrl,
}) => {
  const friendlyDate = getDateString(createdAt, "medium")

  const tooltipContent = (
    <HStack px={3} py={2} gap={2}>
      <Avatar
        title={name}
        name={name}
        src={avatarUrl || undefined}
        ms={1}
        size="sm"
      />
      <div>
        <Text color="white" textStyle="ds.heading.small">
          {name}
        </Text>
        {email ? (
          <Text color="white" opacity="0.8" textStyle="ds.paragraph.secondary">
            {email}
          </Text>
        ) : null}
        <Text
          color="white"
          opacity="0.8"
          textStyle="ds.paragraph.secondary"
        >{`Created on ${getDateString(createdAt, "medium")}`}</Text>
      </div>
    </HStack>
  )

  return (
    <Tooltip rounded="md" placement="top" label={tooltipContent}>
      <HStack gap={2} justifySelf="end">
        <Text
          color="ds.text.subtle"
          textStyle="ds.paragraph.secondary"
          textAlign="right"
          w="6rem"
        >
          {friendlyDate}
        </Text>
        <Avatar
          title={name}
          name={name}
          src={avatarUrl || undefined}
          ms={1}
          size="xs"
        />
      </HStack>
    </Tooltip>
  )
}

export default Created
