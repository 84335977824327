import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react"
import { axios, isAxiosErrorWithMessage } from "Services/axios"
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "Shared/components/Card/Card"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { Field, FieldProps, Form, Formik } from "formik"
import React from "react"
import SettingsApi from "~/api/settingsApi"

type BillingDetails = React.FC<React.PropsWithChildren<unknown>>

export const BillingDetails: BillingDetails = () => {
  const currentAccount = useCurrentAccount()
  const toast = useToast()
  const { billing_details, billing_email, contact_name } = currentAccount

  return (
    <Formik
      initialValues={{
        account: {
          billing_details,
          billing_email,
          contact_name: contact_name || "",
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await axios.patch(SettingsApi.billing.path(), values)
          toast({
            title: response.data.message,
            status: "success",
          })
          setSubmitting(false)
        } catch (error) {
          if (isAxiosErrorWithMessage(error)) {
            toast({
              title: error.response.data.message,
              status: "error",
            })
          } else {
            throw error
          }
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card>
            <CardHeader>Billing details</CardHeader>
            <CardBody>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Field name="account.billing_email" type="email">
                  {({ field }: FieldProps) => <Input {...field} />}
                </Field>
                <FormHelperText>
                  Invoices will be sent to this address.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Contact name</FormLabel>
                <Field name="account.contact_name" type="text">
                  {({ field }: FieldProps) => <Input {...field} />}
                </Field>
                <FormHelperText>
                  Invoices will be made out to this name.
                </FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Additional details</FormLabel>
                <Field name="account.billing_details">
                  {({ field }: FieldProps) => <Textarea {...field} />}
                </Field>
                <FormHelperText>
                  If you need to add a billing address or a tax information to
                  your invoices, put it here.
                </FormHelperText>
              </FormControl>
              <CardFooter>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="brand.primary"
                >
                  Save billing details
                </Button>
              </CardFooter>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  )
}
