import {
  Box,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { DashboardLoadingIndicator } from "UsabilityHub/components/DashboardLoadingIndicator/dashboard-loading-indicator"
import { useInfiniteListUsabilityTests } from "UsabilityHub/hooks/useInfiniteUsabilityTestList"
import { useIsInterviewsEnabled } from "UsabilityHub/hooks/useIsInterviewsEnabled"
import React from "react"
import { Navigate } from "react-router"
import { useLocation } from "react-router-dom"
import { useTypedSearchParams } from "react-router-typesafe-routes/dom"
import { useListModeratedStudies } from "~/api/generated/usabilityhub-components"
import { useListProjects } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyList } from "../ModeratedStudy/interviewer/ModeratedStudyList"
import { ROUTES } from "../routes"
import { EmptyDashboard } from "./EmptyDashboard/EmptyDashboard"
import { RecentProjectList } from "./RecentProjectList"
import { TestRowList } from "./TestRowList"

export const DashboardList: React.FC = () => {
  const location = useLocation()
  const interviewsEnabled = useIsInterviewsEnabled()
  const [{ tab }] = useTypedSearchParams(ROUTES.DASHBOARD)
  const recentTestsQuery = useInfiniteListUsabilityTests(
    {
      sort_by: "updated_at",
      sort_direction: "desc",
    },
    [
      {
        attribute: "archived",
        comparator: "neq",
        values: null,
      },
    ]
  )
  const recentProjectsQuery = useListProjects({
    queryParams: {
      sort_by: "updated_at",
      sort_direction: "desc",
      page_size: 5,
    },
  })
  const recentInterviewsQuery = useListModeratedStudies({})
  const infiniteScrollerRef = useInfiniteScroll<HTMLDivElement>(
    recentTestsQuery.fetchNextPage
  )

  const anyLoading =
    recentTestsQuery.isLoading ||
    recentProjectsQuery.isLoading ||
    recentInterviewsQuery.isLoading
  const anyError =
    recentTestsQuery.isError ||
    recentProjectsQuery.isError ||
    recentInterviewsQuery.isError

  const allRecentTests =
    recentTestsQuery.data?.pages.flatMap((page) => page.usability_tests) ?? []
  const allRecentProjects = recentProjectsQuery.data?.projects ?? []
  const allRecentInterviewStudies = recentInterviewsQuery.data ?? []

  if (anyError) {
    return (
      <Flex direction="column" textAlign="center" gap={6}>
        <Heading>Something went wrong</Heading>
        <Text>
          <CustomerSupportMailtoLink>
            Send us an email
          </CustomerSupportMailtoLink>{" "}
          if you{"\u2019"}re having trouble and we{"\u2019"}ll help out.
        </Text>
      </Flex>
    )
  }

  // If there are no projects, tests, or studies, show the full page empty state.
  if (
    !anyLoading &&
    allRecentTests.length === 0 &&
    allRecentProjects.length === 0 &&
    allRecentInterviewStudies.length === 0
  ) {
    return <EmptyDashboard />
  }

  // Until the "Recent" tab exists, we need to handle the edge case where you have 1 or more interviews
  // but no tests and no projects. We'll do that by redirecting you to the interviews tab.
  if (
    !tab &&
    !recentTestsQuery.isLoading &&
    allRecentTests.length === 0 &&
    allRecentInterviewStudies.length > 0
  ) {
    const params = new URLSearchParams(location.search)
    params.set("tab", "interviews")
    return <Navigate to={`${location.pathname}?${params.toString()}`} />
  }

  const queryForActiveTab =
    tab === "interviews" ? recentInterviewsQuery : recentTestsQuery

  const tabStyles = {
    _selected: {
      color: "ds.text.selected",
      borderColor: "ds.border.selected",
    },
    _focus: { boxShadow: "none" },
    _active: { bg: "none" },
    whitespace: "nowrap",
    pb: 5,
  }

  return (
    <Box w="full">
      <RecentProjectList
        projects={allRecentProjects}
        projectQuery={recentProjectsQuery}
        isLoading={
          // Until both queries finish we don't know which empty state to show
          recentProjectsQuery.isLoading ||
          (!recentProjectsQuery.isLoading &&
            allRecentProjects.length === 0 &&
            (recentTestsQuery.isLoading || recentInterviewsQuery.isLoading))
        }
      />

      <Tabs
        textStyle="ds.interface.large"
        borderBottom="transparent"
        index={tab === undefined || tab === "tests" ? 0 : 1}
      >
        <TabList>
          <Tab
            as={RoutedLink}
            to={ROUTES.DASHBOARD.buildPath({}, { tab: "tests" })}
            data-intercom-target="dashboard-tests-tab"
            {...tabStyles}
          >
            Tests
            <Text ms={1}>{recentTestsQuery.data?.pages[0].total_records}</Text>
          </Tab>
          <Tooltip
            hasArrow
            rounded="sm"
            label="This feature is not available on your account."
            isDisabled={interviewsEnabled}
          >
            <Tab
              as={RoutedLink}
              to={ROUTES.DASHBOARD.buildPath({}, { tab: "interviews" })}
              isDisabled={!interviewsEnabled}
              data-intercom-target="dashboard-interviews-tab"
              {...tabStyles}
            >
              Interviews
              <Text fontWeight="normal" ms={1}>
                {recentInterviewsQuery.isLoading
                  ? null
                  : allRecentInterviewStudies.length}
              </Text>
            </Tab>
          </Tooltip>

          <Flex w="full" justify="flex-end" align="center">
            <DashboardLoadingIndicator query={queryForActiveTab} />
          </Flex>
        </TabList>

        <TabPanels mt={4}>
          <TabPanel p={0}>
            <TestRowList
              usabilityTests={allRecentTests}
              compactPrompt
              isLoading={
                // Until both queries finish we don't know which empty state to show
                recentTestsQuery.isLoading ||
                (!recentTestsQuery.isLoading &&
                  allRecentTests.length === 0 &&
                  (recentProjectsQuery.isLoading ||
                    recentInterviewsQuery.isLoading))
              }
            />

            {recentTestsQuery.isFetchingNextPage && (
              <Flex justify="center" mt={10}>
                <Spinner />
              </Flex>
            )}

            {recentTestsQuery.hasNextPage && <div ref={infiniteScrollerRef} />}
          </TabPanel>

          <TabPanel p={0}>
            <ModeratedStudyList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
