import { Box, Flex, Spinner, useToast } from "@chakra-ui/react"
import { NoPermissionWarning } from "Components/not-allowed-warning/not-allowed-warning"
import { useCanCurrentUserDo } from "Hooks/use-can-current-user-do"
import { Dispatch, State } from "Redux/app-store"
import { initializeFigmaFileVersions } from "Redux/reducers/figma-file-versions/actions"
import { setScreenshots } from "Redux/reducers/screenshots/action-creators"
import { getInitializeScreenshots } from "Redux/reducers/screenshots/helper"
import { initializeUsabilityTest } from "Redux/reducers/test-builder/action-creators/initialize-test"
import { DefinedRawTestBuilderState } from "Redux/reducers/test-builder/reducer"
import { initUsabilityTests } from "Redux/reducers/usability-tests/action-creators"
import { getUsabilityTestByUniqueId } from "Redux/reducers/usability-tests/selectors"
import { PageMain } from "Shared/components/Page/Page"
import { LocaleProvider } from "Shared/contexts/LocaleContext"
import {
  DeviceFrame,
  FigmaFileVersion,
  S3Config,
  Screenshot,
  ShallowUsabilityTest,
  Translations,
} from "Types"
import { TestForm } from "UsabilityHub/components/TestForm/TestForm"
import { FigmaOauthCredentialsProvider } from "UsabilityHub/contexts/FigmaOauthCredentialsContext"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router"
import { unstable_usePrompt } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { isDirty } from "redux-form"
import { fetchUsabilityTestEdit } from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../../../routes"

interface InitUsabilityTestResponseData {
  current_user_has_figma_oauth_credentials: boolean
  device_frames: DeviceFrame[]
  figmaFileVersions: FigmaFileVersion[]
  test_builder: DefinedRawTestBuilderState
  screenshots: Screenshot[]
  i18n: Translations
  projects: string[]
  s3Config: S3Config
  usability_tests: ShallowUsabilityTest[]
}

export const EditUsabilityTestRoute: React.FC = () => {
  const dispatch: Dispatch = useDispatch()
  const toast = useToast()
  const canUserManageTests = useCanCurrentUserDo("manage_tests")
  const testFormIsDirty = useSelector<State>(isDirty("test-form")) as boolean
  const [s3Config, setS3Config] = useState<S3Config | null>(null)

  const { testId } = useTypedParams(ROUTES.TEST.EDIT)
  const test = useSelector(getUsabilityTestByUniqueId(testId))

  const [isInitialized, setIsInitialized] = useState(!canUserManageTests)
  const [error, setError] = useState<undefined | "redirect-to-dashboard">()
  const [deviceFrames, setDeviceFrames] = useState<DeviceFrame[]>([])
  const [
    currentUserHasFigmaOauthCredentials,
    setCurrentUserHasFigmaOauthCredentials,
  ] = useState<boolean>(false)

  const showTestForm = canUserManageTests && isInitialized

  useEffect(() => {
    if (!testId) return
    if (!canUserManageTests) return

    const fetchData = async () => {
      const data = (await fetchUsabilityTestEdit({
        pathParams: {
          usabilityTestId: testId,
        },
      }).catch((e) => {
        setError("redirect-to-dashboard")

        if (typeof e?.payload === "object" && "message" in e.payload) {
          toast({
            title: e.payload.message,
            status: "error",
          })
        } else {
          toast({
            title: "Lyssna has encountered an error while fetching data",
            status: "error",
          })
        }
      })) as InitUsabilityTestResponseData | undefined
      // TODO: We have to use `as` here because the OpenAPI definition for this
      // endpoint hasn't been completed

      if (!data) {
        return void setIsInitialized(true)
      }

      setCurrentUserHasFigmaOauthCredentials(
        data.current_user_has_figma_oauth_credentials
      )

      const initializedScreenshots = getInitializeScreenshots(data.screenshots)

      dispatch(initializeFigmaFileVersions(data.figmaFileVersions))
      dispatch(setScreenshots(initializedScreenshots))
      dispatch(
        initializeUsabilityTest(data.test_builder, initializedScreenshots)
      )

      // Question: should we add or replace the "usabilityTests" state or add loaded tests? do we even need it?
      dispatch(initUsabilityTests(data.usability_tests))

      setS3Config(data.s3Config)
      setDeviceFrames(data.device_frames)

      setIsInitialized(true)
    }

    void fetchData()
  }, [canUserManageTests, testId])

  unstable_usePrompt({
    when: testFormIsDirty,
    message: `You${"\u2019"}ve made changes to this test that haven${"\u2019"}t been saved. Are you sure you want to leave without saving these changes?`,
  })

  if (error === "redirect-to-dashboard") {
    return <Navigate to={ROUTES.DASHBOARD.path} />
  }

  return (
    <PageMain>
      <Helmet>
        <title>{test?.name}</title>
      </Helmet>
      <Box width="100%">
        {!canUserManageTests && (
          <Box maxW={1024} mx="auto" my="7">
            <NoPermissionWarning permission="manage_tests" />
          </Box>
        )}
        {!isInitialized && (
          <Flex mt="32" justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        )}

        {showTestForm && (
          <LocaleProvider keys={["permitted_device_type"]}>
            <FigmaOauthCredentialsProvider
              currentUserHasFigmaOauthCredentials={
                currentUserHasFigmaOauthCredentials
              }
            >
              <TestForm s3Config={s3Config!} deviceFrames={deviceFrames} />
            </FigmaOauthCredentialsProvider>
          </LocaleProvider>
        )}
      </Box>
    </PageMain>
  )
}
